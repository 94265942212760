import React, { useState, useEffect, useContext } from "react";
import Prince from "../../Components/Prince";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../Components/TopHeader";
import { toast } from "react-toastify";
import { DataContext } from "../../context/DataContext";
function CustomPlans() {
  const apiUrl = process.env.REACT_APP_URL;

  const result = useContext(DataContext);
  const Token = localStorage.getItem("Token");

  const [customPlans, setCustomPlans] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({});
  useEffect(() => {
    setEmployee(result.employeeData);
  });
  useEffect(() => {
    fetch(`${apiUrl}/plan/getAllCustomPlan`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setCustomPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching Plans:", error);
      });
  }, [refresh]);

  const viewCustomPlan = (e) => {
    e.preventDefault();
    navigate(`/customPlan/${e.target.id}`);
  };

  const handleApprove = async (e, id) => {
    const url = `${apiUrl}/plan/customPlanApprovel/${id}`;

    const formData = new FormData();
    formData.append("status", e.target.value);

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formData,
    });
    response = await response.json();

    if (response.status === true) {
      toast.success(response.message);
      setRefresh(!refresh);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div className="page">
      <TopHeader />
      <Prince />

      <div className="row row-sm w-90 m-3">
        <div className="col-lg-13 mt-3">
          <h3> Custom Plans</h3>
          <div className="card custom-card">
            <div className="card-body">
              <div className="text-wrap">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered text-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Employee Name</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customPlans?.map((p, i) => {
                        return (
                          <tr key={p.id}>
                            <td>{p.id}</td>
                            <td>{p.planName}</td>
                            <td>
                              {p.employee?.firstName?.charAt(0).toUpperCase() +
                                p.employee?.firstName?.slice(1)}{" "}
                              {p.employee?.lastName}
                            </td>
                            <td
                              className={
                                p.isApproved === 0 || p.isApproved === 2
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {p.isApproved === 0 || p.isApproved === 2 ? (
                                employee.userType === "1" &&
                                p.isApproved === 0 ? (
                                  <>
                                    <button
                                      // className="btn btn-sm btn-primary m-1"
                                      className="btn btn-outline-success m-1"
                                      value={1}
                                      onClick={(e) => {
                                        handleApprove(e, p.id);
                                      }}
                                    >
                                      Approve
                                    </button>
                                    <button
                                      // className="btn btn-sm btn-primary m-1"
                                      className="btn btn-outline-danger m-1"
                                      value={2}
                                      onClick={(e) => {
                                        handleApprove(e, p.id);
                                      }}
                                    >
                                      Reject
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    {p.thresholdCrossed && (
                                      <span>
                                        {p.isApproved === 2
                                          ? "Rejected"
                                          : "Pending"}
                                      </span>
                                    )}
                                  </>
                                )
                              ) : (
                                "Approved"
                              )}
                              <br />
                            </td>
                            <td>
                              <button
                                id={p.id}
                                onClick={viewCustomPlan}
                                // className="btn btn-primary ripple btn-info btn-xs w-50"
                                className="btn btn-outline-primary m-1"
                              >
                                View Plan
                              </button>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Main Footer*/}

                {/*End Footer*/}
              </div>
            </div>
          </div>
          <div className="main-footer text-center">
            <div className="container">
              <div className="row row-sm">
                <div className="col-md-12">
                  <span>
                    Copyright © 2024 <a href="">FSHIP</a>. Designed by{" "}
                    <a href="https://fship.in">FSHIP</a> All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomPlans;
