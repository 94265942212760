import React, { useState, useEffect } from "react";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import Box from "@mui/material/Box";
import InputField from "../../Components/createTicket/InputField";
import CreateButton from "../../Components/createTicket/CreateButton";
import { toast } from "react-toastify";
import DescribeBox from "../../Components/createTicket/DescribeBox";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketRemittence({ caseId, customerId, selectedValue4Id }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [issueOption, setIssueOption] = useState([]);
  const [otherIssueDescribe, setOtherIssueDescribe] = useState("");

  const handleInputValue = (e) => {
    setOtherIssueDescribe(e.target.value);
  };
  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("selectReason", selectedOption);
    formData.append("otherIssue", otherIssueDescribe);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/22`, {
      headers: {
        // Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleChange = (e) => {
    setSelectedOption(e.name);
  };

  return (
    <Box className="mt-3">
      <SelectDropdown
        dropDownData={issueOption}
        handleChange={handleChange}
        selectedData={selectedOption}
        Text={"Select Reason"}
      ></SelectDropdown>
      {`${selectedOption}` == " Others " ? (
        <DescribeBox
          text={"Describe Issue :"}
          selectedOption={selectedOption}
          handleInputValue={handleInputValue}
          value={otherIssueDescribe}
        ></DescribeBox>
      ) : (
        ""
      )}
      <p className="fw-bold mt-3 fs-12">
        NOTE : If your payment is currently being processed (which means a CRF
        ID has been created for same-day remittance), it will be sent to your
        existing bank account. However, we will make sure that any future
        payments are sent to your new account.
      </p>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketRemittence;
