import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function InputField({ text, handleInputValue, value }) {
  return (
    <Box
      sx={{
        "& .MuiTextField-root":
          text?.includes("AWB") || text?.includes("Beneficiary")
            ? { width: "51ch" }
            : { width: "103.5ch" },
      }}
    >
      <TextField
        label={text}
        id="outlined-size-small"
        size="small"
        value={value}
        onChange={handleInputValue}
        InputLabelProps={{
          style: {
            fontSize: "0.75rem",
            fontWeight: "400",
            marginTop: "3px",
            color: "#000",
          },
        }}
        InputProps={{
          sx: {
            "& input": {
              color: "#373A40",
            },
          },
        }}
      />
    </Box>
  );
}

export default InputField;
