import React from "react";

const WonModal = ({
  IsWonModalOpen,
  handleWonCloseModal,
  selectedKamTypeOption,
  handleChange,
  options,
  kamTypeEmployee,
  handleKamEmployee,
  kamEmployee,
  handleAssignKam,
}) => {
  return (
    <div
      className={`modal bg-black-7 ${IsWonModalOpen ? "show" : ""}`}
      style={{ display: IsWonModalOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content rounded-3">
          <div className="modal-header">
            <h5 className="modal-title">Assigned To Customer</h5>
            <button
              type="button"
              className="close"
              onClick={handleWonCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="modal-body"
              style={{ width: "20px", paddingRight: "10px" }}
            >
              <label>Select KamType</label>
              <select
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #808080",
                  width: "200px",
                  height: "45px",
                  color: "#808080",
                  background: "#fff",
                }}
                value={selectedKamTypeOption}
                onChange={handleChange}
              >
                <option>Select</option>
                {options.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="modal-body"
              style={{ width: "20px", paddingLeft: 0 }}
            >
              <label>Select Employee</label>
              <select
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #808080",
                  width: "200px",
                  height: "45px",
                  color: "#808080",
                  background: "#fff",
                }}
                value={kamTypeEmployee}
                onChange={handleKamEmployee}
              >
                <option>Select</option>
                {kamEmployee.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-info"
              onClick={handleAssignKam}
              style={{
                height: "30px",
                marginTop: "55px",
                marginRight: "50px",
                backgroundColor: "#032863",
              }}
            >
              Create Customer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WonModal;
