import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

function DescribeBox({ text, handleInputValue, value, selectedOption }) {
  return (
    <Box
      sx={{
        // gap: 2,
        mt: 3,
        "& .MuiTextField-root": {
          width: selectedOption === " Others " ? "51ch" : "103ch",
        },
      }}
    >
      <TextField
        label={text}
        id="outlined-size-small"
        // defaultValue="eg:awb no..."
        value={value}
        onChange={handleInputValue}
        size="small"
        InputLabelProps={{
          style: {
            fontSize: "0.75rem",
            fontWeight: "400",
            marginTop: "3px",
            color: "#000",
          },
        }}
        InputProps={{
          sx: {
            "& input": {
              color: "#373A40",
            },
          },
        }}
      />
    </Box>
  );
}

export default DescribeBox;
