import React from "react";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RadioButton from "../../Components/createTicket/RadioButton";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import InputField from "../../Components/createTicket/InputField";
import NotesComp from "../../Components/createTicket/NotesComp";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import CreateButton from "../../Components/createTicket/CreateButton";
import BrowesFileBox from "../../Components/createTicket/BrowesFileBox";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketChannel({
  selectedData,
  optionData1,
  optionData2,
  text,
  caseId,
  customerId,
  selectedValue4Id,
  setOpen,
}) {
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [selectedOption3, setSelectedOption3] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [description, setDescription] = useState("");
  const [radioOption, setRadioOption] = useState("");
  const [issueOption, setIssueOption] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isExcel, setIsExcel] = useState(false);

  const handleFileChangeExcel = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsExcel(true);
    }
  };
  const [otherIssueDescribe, setOtherIssueDescribe] = useState("");

  const handleInputValueOther = (e) => {
    setOtherIssueDescribe(e.target.value);
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const handleChange1 = (event) => {
    setSelectedOption1(event.name);
  };
  const handleChange2 = (event) => {
    setSelectedOption2(event.name);
  };
  const handleChange3 = (event) => {
    setSelectedOption3(event.name);
  };
  const handleChangeRadio = (event) => {
    setRadioOption(event.target.value);
  };
  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleInputValue = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/34`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);

    formData.append("uploadImage", selectedImage);
    formData.append(
      "selectIssue",
      radioOption !== "Channel Integration" ? selectedOption3 : selectedOption2
    );
    formData.append("relatedIssue", radioOption);
    formData.append("selectChannel", selectedOption1);
    formData.append("discriptionIssue", description);
    formData.append("orderType", selectedOption2.trim());
    formData.append("orderId", inputValue);
    formData.append("isExcel", isExcel);
    formData.append("orderIdExcel", selectedFile);
    formData.append("otherIssue", otherIssueDescribe);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {},
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box className="mt-3">
      <>
        {" "}
        {selectedData != 21 && (
          <RadioButton
            toggle={selectedData}
            handleChange={handleChangeRadio}
          ></RadioButton>
        )}
      </>
      {radioOption !== "Channel Integration" && (
        <SelectDropdown
          Text={"Select Issue"}
          dropDownData={issueOption}
          handleChange={handleChange3}
          selectedData={selectedOption3}
        ></SelectDropdown>
      )}
      {`${selectedOption3}` == " Others " ? (
        <DescribeBox
          text={"Describe Issue :"}
          handleInputValue={handleInputValueOther}
          value={otherIssueDescribe}
        ></DescribeBox>
      ) : (
        ""
      )}
      {selectedData != 21 && (
        <h6 className="fw-bold mb-4 mt-4">Additional Information</h6>
      )}

      {radioOption !== "Channel Integration" && selectedData !== 21 && (
        <DescribeBox
          text={"Describe the Issue :"}
          handleInputValue={handleInputValueDescription}
        ></DescribeBox>
      )}

      {(radioOption === "Channel Integration" || selectedData === 21) && (
        <>
          {radioOption === "Channel Integration" && (
            <>
              <Box className="mt-3">
                <SelectDropdown
                  dropDownData={optionData1}
                  handleChange={handleChange1}
                  selectedData={selectedOption1}
                  Text={selectedData != 21 ? "Select Channel" : "Select Issue"}
                ></SelectDropdown>
              </Box>
              <>
                {(selectedData == 21 && selectedOption2 == " Others ") ||
                (selectedData != 21 && selectedOption1 == "Others") ? (
                  <DescribeBox
                    text={"Describe Issue :"}
                    // handleInputValue={handleInputValueDescription}
                    handleInputValue={handleInputValueOther}
                    value={otherIssueDescribe}
                  />
                ) : (
                  ""
                )}
              </>
            </>
          )}

          <Box className="mt-3">
            <SelectDropdown
              className="mt-4"
              dropDownData={optionData2}
              handleChange={handleChange2}
              selectedData={selectedOption2}
              Text={selectedData != 21 ? "Select Issue" : "Select Order Type"}
            ></SelectDropdown>
            {`${selectedOption3}` == " Others " ||
            `${selectedOption2}` == "Others" ? (
              `${selectedData}` != 21 ? (
                <DescribeBox
                  text={"Describe Issue :"}
                  handleInputValue={handleInputValueOther}
                  value={otherIssueDescribe}
                ></DescribeBox>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <Box className="mt-3">
              <InputField
                text={text}
                handleInputValue={handleInputValue}
                value={inputValue}
              ></InputField>
            </Box>
          </Box>
          {selectedData === 20 && (
            <>
              <NotesComp
                text={"Provide your Order IDs for a quicker resolution."}
              ></NotesComp>
            </>
          )}
        </>
      )}

      <UploadImageBox
        text={" Upload Attachments (optional): "}
        handleFileChange={handleFileChange}
        handleRemoveImage={handleRemoveImage}
        selectedImage={selectedImage}
        selectedImagePreview={selectedImagePreview}
      ></UploadImageBox>

      {selectedData == 21 ? (
        selectedData == 20 ? (
          <NotesComp
            text={"File should be in xlsx format only. Max Size: 2 MB."}
          ></NotesComp>
        ) : (
          <NotesComp
            text={
              "File should be in png, jpg, jpeg format only. Max Size: 2 MB."
            }
          ></NotesComp>
        )
      ) : (
        <NotesComp
          text={"File should be in png, jpg, jpeg format only. Max Size: 2 MB."}
        ></NotesComp>
      )}
      {(radioOption === "Channel Integration" || selectedData === 21) && (
        <>
          {selectedData === 21 && (
            <>
              <BrowesFileBox
                handleDeleteFile={handleDeleteFile}
                handleFileChange={handleFileChangeExcel}
                selectedFile={selectedFile}
                text={"Uplaod your Order Id"}
                url={"https://fshipcrm.webkype.co/uploads/orderExcel.xlsx"}
              ></BrowesFileBox>

              {selectedData == 21 ? (
                <NotesComp
                  text={
                    "File should be in xls, xlsx, csv format only. Max Size: 2 MB."
                  }
                ></NotesComp>
              ) : (
                <NotesComp
                  text={
                    "File should be in png, jpg, jpeg format only. Max Size: 2 MB."
                  }
                ></NotesComp>
              )}
            </>
          )}
          {selectedData === 20 && (
            <DescribeBox
              text={"Enter your channel credentials (optional):"}
              handleInputValue={handleInputValueDescription}
            ></DescribeBox>
          )}
        </>
      )}
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketChannel;
