const mediaQuery = window.matchMedia("(max-width: 768px)");

const handleMediaQueryChange = (e) => {
  if (e.matches) {
    const navigationMenu = document.getElementById("navigation-menu");
    if (navigationMenu) {
      navigationMenu.style.display = "none";
    }
  } else {
    const navigationMenu = document.getElementById("navigation-menu");
    if (navigationMenu) {
      navigationMenu.style.display = "block";
    }
  }
};

mediaQuery.addListener(handleMediaQueryChange);
handleMediaQueryChange(mediaQuery);

export default mediaQuery;
