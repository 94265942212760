import React from "react";
import {
  XAxis,
  YAxis,
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  Cell,
  CartesianGrid,
} from "recharts";

import { useCallback, useState } from "react";

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red", "pink"];

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  }
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
  Z`;
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const Chart = ({ count, employee, formData }) => {
  const data2 = [
    {
      name: "Total Leads",
      value: formData.id == "" ? employee.leadCount : count.leadCount,
    },
    {
      name: "Open Leads",
      value: formData.id == "" ? employee.openLeadCount : count.openLeadCount,
    },
    {
      name: "Won Leads",
      value: formData.id == "" ? employee.wonLeadCount : count.wonLeadCount,
    },
    {
      name: "Lost Leads",
      value: formData.id == "" ? employee.lostLeadCount : count.lostLeadCount,
    },
  ];
  const data1 = [
    {
      name: "New",
      uv: formData.id == "" ? employee.newLeadCount : count.newLeadCount,
    },
    {
      name: "Call Attempted",
      uv:
        formData.id == ""
          ? employee.callattemptedLeadCount
          : count.callattemptedLeadCount,
    },

    {
      name: "Follow Up",
      uv: formData.id == "" ? employee.followUPCount : count.followUPCount,
    },
    {
      name: "Proposal",
      uv:
        formData.id == ""
          ? employee.proposalLeadCount
          : count.proposalLeadCount,
    },
    {
      name: "Negotiation",
      uv:
        formData.id == ""
          ? employee.negotiationLeadCount
          : count.negotiationLeadCount,
    },

    {
      name: "Closed-Won-Syts",
      uv: formData.id == "" ? employee.closeCount : count.closeCount,
    },
    {
      name: "Lost",
      uv: formData.id == "" ? employee.loststageCount : count.loststageCount,
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <div style={{ padding: "0px 30px" }}>
      <h2
        style={{
          textAlign: "center",
          marginTop: "80px",
          fontWeight: "900",
          color: "#173B45",
        }}
      >
        Chart Analysis of Business
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // marginTop: "100px",
          padding: "50px",
        }}
      >
        <div>
          <h5>Bar Chart Analysis</h5>
          <BarChart
            width={850}
            height={400}
            data={data1}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Bar
              dataKey="uv"
              fill="#8884d8"
              shape={<TriangleBar />}
              label={{ position: "top" }}
            >
              {data1.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
          <h5 style={{ textAlign: "center", marginTop: "30px" }}>
            Sales Stage Analysis{" "}
          </h5>
        </div>
        <div>
          <h5>Pie Chart Analysis</h5>
          <PieChart width={500} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data2}
              cx={235}
              cy={200}
              innerRadius={50}
              outerRadius={90}
              fill="#987D9A"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
            {/* <h3>Stage Analysis & Leads Count</h3> */}
          </PieChart>
          <h5 style={{ textAlign: "center", marginTop: "24px" }}>
            Leads Analysis{" "}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Chart;
