import React, { useState } from "react";
import Prince from "../Components/Prince";
import TopHeader from "../Components/TopHeader";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function CaseSubtypeValue() {
  const initialBodyData = {
    mastersId: "",
    name: "",
  };

  const [caseList, setCaseList] = useState([]);
  const [bodyData, setbodyData] = useState(initialBodyData);
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_URL;

  function handleChange(event) {
    const { name, value } = event.target;
    setbodyData({
      ...bodyData,
      [name]: value,
    });
  }

  const handlemastersId = (event) => {
    const selectedCaseId = event.target.value;

    setbodyData({
      ...bodyData,
      mastersId: selectedCaseId,
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const bodyDataToSend = new FormData();
    for (const key in bodyData) {
      if (bodyData[key] !== null) {
        bodyDataToSend.append(key, bodyData[key]);
      }
    }

    try {
      const response = await fetch(`${apiUrl}/caseMaster/addCaseSubType`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: bodyDataToSend,
      });
      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message);
      }
      toast.success("Case sub type added successfully");
      navigate("/case-subtype");
    } catch (error) {
      toast.error(error.message);
      // console.log(error);
    }
  }

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getCaseType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      {/* Main Header*/}
      <div className="page">
        <TopHeader />
        <Prince />
        {/* End Sidemenu */}
        {/* Main Content*/}
        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Case Sub type
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Internal Case </a>
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center"></div>
                </div>
              </div>

              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">ONBOARDING</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Case Type</label>
                          <select
                            onChange={handlemastersId}
                            className="form-control form-select"
                            name="mastersId"
                            /* value={formData.mastersId} */
                          >
                            <option>Select Case Type</option>
                            {caseList.map((list) => (
                              <option key={list.id} value={list.id}>
                                {list.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Case Sub type</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter value"
                            name="name"
                            value={bodyData.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-4 form-group">
                          <div className="input-group"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}

              {/* End Row */}
              <div className="row row-sm">
                <div className="col-12 mb-3">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-outline-danger"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CaseSubtypeValue;
