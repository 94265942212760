import React, { useEffect } from "react";
import "./index.css"; // Import the CSS for the loader

const Loader = () => {
  useEffect(() => {
    const hideLoader = () => {
      document.getElementById("loading").style.display = "none";
    };

    // Hide loader after 20 seconds
    const timeout = setTimeout(hideLoader, 3000);

    // Hide loader when the page is fully loaded
    window.addEventListener("load", hideLoader);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("load", hideLoader);
    };
  }, []);

  return <div id="loading" className="loading"></div>;
};

export default Loader;
