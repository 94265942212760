import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import NotesComp from "../../Components/createTicket/NotesComp";
import CreateButton from "../../Components/createTicket/CreateButton";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketKYC({ caseId, customerId, selectedValue4Id, setOpen }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [typeOption, setTypeOption] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/25`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setTypeOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  // console.log(customerId);
  const handleCreateCase = async (event) => {
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("discriptionIssue", description);
    formData.append("uploadImage", selectedImage);
    formData.append("selectIssue", selectedOption);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {},
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };
  const handleChange = (event) => {
    setSelectedOption(event.name);
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };
  return (
    <Box className="mt-3">
      {selectedValue4Id !== 40 && (
        <Box>
          <p className="fs-12 ">
            We are pleased to inform you that your KYC details have been
            verified successfully on 11 October 2022.
          </p>
          <p className="fs-12 fw-bold">
            Help us understand how we can help you by selecting an option below:
          </p>
          <SelectDropdown
            dropDownData={typeOption}
            handleChange={handleChange}
            selectedData={selectedOption}
            Text={"My Company Type"}
          ></SelectDropdown>
        </Box>
      )}

      <DescribeBox
        text={" Describe the Issue (optional): "}
        handleInputValue={handleInputValueDescription}
      ></DescribeBox>
      <UploadImageBox
        text={" Upload Attachments (optional): "}
        handleFileChange={handleFileChange}
        handleRemoveImage={handleRemoveImage}
        selectedImage={selectedImage}
        selectedImagePreview={selectedImagePreview}
      ></UploadImageBox>
      <NotesComp
        text={" File should be in png, jpg, jpeg format only. Max Size: 2 MB."}
      ></NotesComp>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketKYC;
