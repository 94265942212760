import React from "react";
import { DateRangePicker } from "react-date-range";

function DateFilterModal({
  dateFilterModalOpen,
  handleCloseDateFilterModal,
  date,
  onDateChange,
}) {
  const handleSelect = (ranges) => {
    onDateChange(ranges.selection); // Call the parent's function to update the date
  };
  return (
    <div
      className={`modal ${dateFilterModalOpen ? "show" : ""} bg-black-7`}
      style={{ display: dateFilterModalOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content rounded-3">
          <div className="modal-header">
            <h5 className="modal-title">Select Date Range</h5>
            <button
              type="button"
              className="close"
              onClick={handleCloseDateFilterModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ width: "100%" }}>
            <DateRangePicker ranges={[date]} onChange={handleSelect} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateFilterModal;
