import React, { useState } from "react";
import { format, set } from "date-fns";
import DateFilterModal from "./Sales Crm Modal/DateFilterModal";

const DateFilterComponent = ({ setDateForApi }) => {
  const [dateFilterModalOpen, setDateFilterModalOpen] = useState(false);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };

  const handleDateChange = (ranges) => {
    setDate(ranges);
    setDateForApi(ranges); // Handle date change logic here
  };

  //console.log(date);
  return (
    <>
      <div className="col-sm-2 mt-3">
        <label>Date Filter</label>
        <button
          style={{
            fontSize: "12px",
            width: "220px",
          }}
          // onClick={handleDateFilterClick}
          onClick={() => setDateFilterModalOpen(true)}
          className="btn btn-outline-success"
        >{`${format(date.startDate, "MMM dd yyyy")} -- ${format(
          date.endDate,
          "MMM dd yyyy"
        )}`}</button>
      </div>
      <DateFilterModal
        dateFilterModalOpen={dateFilterModalOpen}
        handleCloseDateFilterModal={handleCloseDateFilterModal}
        date={date}
        onDateChange={handleDateChange}
      ></DateFilterModal>
    </>
  );
};
export default DateFilterComponent;
