import React, { useState, useEffect } from "react";
import MainPage from "../Components/MainPage";
import Prince from "../Components/Prince";
import DropdownMenu from "../Components/DropdownMenu";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";
import TopHeader from "../Components/TopHeader";
import Logo from "../assets/img/logo.png";

import Img from "../assets/img/svgs/sales_quotation.png";
import Img2 from "../assets/img/users/user-img2.jpg";
import Img3 from "../assets/img/svgs/callback.png";
import Img4 from "../assets/img/svgs/4029413.png";
import Img5 from "../assets/img/truck-driver.jpg";
import Loader from "../assets/img/loader.gif";
import Prompt from "../Components/Promt";

const CaseDetails = () => {
  const initialFormData = {
    comment: "",
    attachment: "",
  };
  const initialFormData2 = {
    contactType: "",
    callBackDate: "",
    callBackTime: "",
    lastCallSummary: "",
    occuranceType: "",
  };
  const initialFormData3 = {
    meetingDate: "",
    meetingTime: "",
    meetingType: "",
    occuranceType: "",
    meetingVenue: "",
  };
  const initialFormData4 = {
    // companyName: '' ,
    consumerName: "",
    consumerEmail: "",
    consumerPhone: "",
    plan: "",
    customePlanId: "",
  };
  const initialFormData5 = {
    buyer: "",
    buyerAddress: "",
    notifyParty: "",
    notifyPartyAddress: "",
    consignee: "",
    consigneeAddress: "",
    riceType: "",
    swarnaParboiled: "",
    chalky: "",
    dd: "",
    whiteNess: "",
    moisture: "",
    grainLength: "",
    paymentTerms: "",
    portOfLoading: "",
    portOfDestination: "",
    etd: "",
    eta: "",
    cif: "",
    size: "",
    totalNoOfBags: "",
    type: "",
    emptyBags: "",
    bagMarkDesign: "",
    fcl: "",
    tons: "",
    lotSize: "",
  };
  const initialFormData6 = {
    followUpId: "",
  };
  let quotCount = 0;
  const [customerData, setCustomerData] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [formData2, setFormData2] = useState(initialFormData2);
  const [formData3, setFormData3] = useState(initialFormData3);
  const [formData4, setFormData4] = useState(initialFormData4);
  const [formData5, setFormData5] = useState(initialFormData5);

  const [profilePicFile, setProfilePicFile] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCallOpen, setIsCallOpen] = useState(false);
  const [isMeetingOpen, setIsMeetingOpen] = useState(false);
  const [isQuotationOpen, setIsQuotationOpen] = useState(false);
  const [genderOptions, setGenderOptions] = useState([]);
  const [genderOptions2, setGenderOptions2] = useState([]);
  const [genderOptions3, setGenderOptions3] = useState([]);
  const [genderOptions4, setGenderOptions4] = useState([]);
  const [genderOptions5, setGenderOptions5] = useState([]);
  const [genderOptions6, setGenderOptions6] = useState([]);
  const [newFollowup, setNewFollowup] = useState([]);

  const [plans, setPlans] = useState([]);
  const [customPlans, setCustomPlans] = useState([]);

  const [leadDetails, setLeadDetails] = useState(/* initial value */);

  const [leadDetailsfollowups, setLeadDetailsfollowups] = useState([]);

  const { empid } = useParams();
  // console.log(empid);
  const [isPlanEnabled, setIsPlanEnabled] = useState(true);
  const [isCustomPlanEnabled, setIsCustomPlanEnabled] = useState(true);
  const [loader, setLoader] = useState(true);
  const [addDoneComment, setAddDoneComment] = useState(false);
  const [addContactDoneComment, setAddContactDoneComment] = useState(false);
  const [doneComment, setDoneComment] = useState("");
  const [doneCommentError, setDoneCommentError] = useState("");

  const apiUrl = process.env.REACT_APP_URL;
  // console.log(apiUrl);

  const Token = localStorage.getItem("Token");
  // console.log("Token:", Token);

  const navigate = useNavigate();

  const viewCustomPlan = (e) => {
    e.preventDefault();
    navigate(`/customPlan/${e.target.id}`);
  };

  const viewPlan = (e) => {
    e.preventDefault();
    navigate(`/plans/${e.target.id}`);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const quotationOpenModal = () => {
    setIsQuotationOpen(true);
    document.body.classList.add("modal-open");
  };

  const quotationCloseModal = () => {
    setIsQuotationOpen(false);
    document.body.classList.remove("modal-open");
  };

  const contactOpenModal = () => {
    setIsCallOpen(true);
    document.body.classList.add("modal-open");
  };

  const contactCloseModal = () => {
    setIsCallOpen(false);
    document.body.classList.remove("modal-open");
  };

  const meetingOpenModal = () => {
    setIsMeetingOpen(true);
    document.body.classList.add("modal-open");
  };

  const meetingCloseModal = () => {
    setIsMeetingOpen(false);
    document.body.classList.remove("modal-open");
  };

  const sendToFship = async (id) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("followUpId", id);

      const url = `${apiUrl}/lead/sendQuotation`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      } else {
        toast.success(response2.message);
        setNewFollowup([...newFollowup, formData]);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const showMarkMeetDone = () => {
    setAddDoneComment(true);
  };
  // console.log(leadDetails);
  const showMarkContactDone = () => {
    setAddContactDoneComment(true);
  };

  const markMeetDone = async (id) => {
    try {
      const url = `${apiUrl}/lead/changeMeetingStatus/${id}`;
      let formData = new FormData();
      formData.append("comment", doneComment);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });
      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      } else {
        toast.success(response2.message);
        setNewFollowup([...newFollowup, formData]);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const sendQuotationToMail = async (id) => {
    try {
      const response = await fetch(`${apiUrl}/lead/send-quotation/${id}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      } else {
        toast.success(response2.message);
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  // const handleCloseModal5 = () => {

  //   setIsModalOpen5(false);
  //   document.body.classList.remove('modal-open');
  // };

  //Types of rice

  // fllop
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/case/getCase?caseId=${empid}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCustomerData(data.data);
      });
    // .catch((error) => {
    //   console.error("Error fetching gender options:", error);
    // });
  }, []);
  // console.log(customerData);
  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const url = `${apiUrl}/lead/getLeadById/${empid}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch lead data");
        }

        const data = await response.json();
        if (data && data.status === "success" && data.data) {
          const fetchedLeadDetails = data.data;
          setLeadDetails(fetchedLeadDetails);
          formData4.consumerEmail = fetchedLeadDetails?.emailId;
          formData4.consumerPhone = fetchedLeadDetails?.mobileNumber;
          formData4.consumerName = fetchedLeadDetails?.primaryContactName;
        } else {
          console.error("Lead data not found in the API response");
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
      }
    };

    fetchLeadData();
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/9`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions6(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  // useEffect(() => {
  //   // Make an API request to fetch gender options
  //   fetch(`${apiUrl}/master/getAllMasterData/8`, {
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data && Array.isArray(data.data)) {
  //         setLeadStatusOptions(data.data);
  //         console.log(data.data);
  //       } else {
  //         console.error("API response does not contain an array:", data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching gender options:", error);
  //     });
  // }, []);

  useEffect(() => {
    fetch(`${apiUrl}/plan/getAllPlansdb`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching Plans:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/plan/customPlanDropdown`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setCustomPlans(data.data);
      })
      .catch((error) => {
        console.error("Error fetching Plans:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/9`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/2`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions2(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/3`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions3(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/4`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions4(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/4`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions5(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleSubmit10 = async (num) => {
    try {
      // console.log(num);
      const response = await fetch(`${apiUrl}/lead/sendEmailProforma/${num}`, {
        method: "POST",
        headers: {
          // 'Content-Type': 'application/json',
        },

        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // console.log(data);
      alert("Proforma sent by email successfully...");
    } catch (error) {
      console.error("There was an error with the fetch operation:", error);
    }
  };

  //pre Per
  const handleFileChange9 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData5((prevData) => ({
          ...prevData,
          profilePhoto: file,
        }));
      } else if (file.type === "application/pdf") {
        // Set aadhaarUpload for PDF files
        setFormData5((prevData) => ({
          ...prevData,
          bagMarkDesign: file,
        }));
      } else {
        console.log("Unsupported file type");
      }
    } else {
      console.log("No file selected");
    }
  };

  const handleChange9 = (event) => {
    const { name, value } = event.target;
    setFormData5((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else if (file.type === "application/pdf") {
        // Set aadhaarUpload for PDF files
        setFormData((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log("Unsupported file type");
      }
    } else {
      console.log("No file selected");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // useEffect(() => {
  //   setFormData();
  // }, formData);

  const handleSubmit = async (event) => {
    try {
      // Check if the required fields (comment or attachment) are present
      // if (!formData.comment && !formData.attachment) {
      //   throw new Error('Please fill in at least one field (Comment or Attachment)');
      // }
      event.preventDefault();

      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
      const url = `${apiUrl}/case/postComment/${empid}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      } else {
        toast.success(response2.message);

        handleCloseModal();
        setNewFollowup([...newFollowup, formData]);
        setFormData(initialFormData);
        // Close the modal after successful submission
      }
    } catch (error) {
      toast.error(error.message);
      // Handle errors here or display appropriate messages
    }
  };

  //Call update

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData2((prevData) => ({
          ...prevData,
          profilePhoto: file,
        }));
      } else if (file.type === "application/pdf") {
        // Set aadhaarUpload for PDF files
        setFormData2((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log("Unsupported file type");
      }
    } else {
      console.log("No file selected");
    }
  };
  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setFormData2((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit2 = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();
      for (const key in formData2) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData2[key]);
        }
      }
      const url = `${apiUrl}/case/callUpdate/${empid}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();

      // console.log(response2);
      if (response2.status === "error") {
        throw new Error(response2.message);
      } else {
        toast.success(response2.message);

        // setNewFollowup([...newFollowup, initialFormData2]);
        // setFormData(initialFormData2);
        // contactCloseModal();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  //Mitting update
  const handleFileChange3 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData3((prevData) => ({
          ...prevData,
          profilePhoto: file,
        }));
      } else if (file.type === "application/pdf") {
        // Set aadhaarUpload for PDF files
        setFormData3((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log("Unsupported file type");
      }
    } else {
      console.log("No file selected");
    }
  };
  const handleChange3 = (event) => {
    const { name, value } = event.target;
    setFormData3((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit3 = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();
      for (const key in formData3) {
        if (formData3[key] !== null) {
          formDataToSend.append(key, formData3[key]);
        }
      }
      const url = `${apiUrl}/lead/meetingUpdate/${empid}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      const response2 = await response.json();

      // console.log(response2);
      if (response2.status === "error") {
        throw new Error(response2.message);
      } else {
        toast.success(response2.message);

        // setNewFollowup([...newFollowup, formData3]);
        // setFormData3(initialFormData3);
        // meetingCloseModal();
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  //Quotation update
  const handleFileChange4 = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        // Set profilePicFile for image files
        setProfilePicFile(file);
        setFormData4((prevData) => ({
          ...prevData,
          profilePhoto: file,
        }));
      } else if (file.type === "application/pdf") {
        // Set aadhaarUpload for PDF files
        setFormData4((prevData) => ({
          ...prevData,
          attachment: file,
        }));
      } else {
        console.log("Unsupported file type");
      }
    } else {
      console.log("No file selected");
    }
  };
  const handleChange4 = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    if (name === "plan") {
      if (value.length > 0) {
        setIsPlanEnabled(true);
        setIsCustomPlanEnabled(false);
      } else {
        setIsPlanEnabled(true);
        setIsCustomPlanEnabled(true);
      }
    } else if (name === "customePlanId") {
      if (value.length > 0) {
        setIsPlanEnabled(false);
        setIsCustomPlanEnabled(true);
      } else {
        setIsPlanEnabled(true);
        setIsCustomPlanEnabled(true);
      }
    }
    setFormData4((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit4 = async (event) => {
    event.preventDefault();

    if (formData4.plan === "") {
      formData4["planType"] = "c";
    } else {
      formData["planType"] = "m";
    }
    // console.log(formData4, "here=====>>>>>>>>>>>>>");
    try {
      const formDataToSend = new FormData();
      for (const key in formData4) {
        if (formData4[key] !== null) {
          formDataToSend.append(key, formData4[key]);
        }
      }
      const url = `${apiUrl}/lead/addQuatation/${empid}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      const response2 = await response.json();

      // console.log(response2);
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);

      setNewFollowup([...newFollowup, formData4]);
      quotationCloseModal();
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const commentList = proforma.followUpId.map(comment => {
  //   // Do something with each 'comment' element here
  // });
  // console.log(commentList);

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const url = `${apiUrl}/case/leadFollowUP/${empid}`;
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch lead data");
        }

        const responseData = await response.json();

        if (responseData.status === "success" && responseData.data) {
          const followups = responseData.data;
          // console.log(followups);
          setLeadDetailsfollowups(followups);
          setTimeout(() => {
            setLoader(false);
          }, 800);
        } else {
          console.error("Lead data not found in the API response");
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
      } finally {
      }
    };

    fetchLeadData();
    // console.log(fetchLeadData);
  }, [newFollowup]);
  // console.log(leadDetailsfollowups);
  const handleCancel = () => {
    toast.dismiss();
  };

  let mainStatus = "statusWon";

  const handleYes = () => {
    const updateStatus = async () => {
      await fetch(`${apiUrl}/lead/${mainStatus}/${leadDetails.id}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
    };
    toast.promise(updateStatus, {
      pending: "Updating status...",
      success: "Status updated successfully 👌",
      error: "Unable to update status 🤯",
    });
  };

  const handleWin = () => {
    mainStatus = "statusWon";
    return Prompt(
      "Do you want to change the status to winning?",
      handleYes,
      handleCancel
    );
  };

  const handleLost = () => {
    mainStatus = "statusLost";
    return Prompt(
      "Do you want to change the status to lost?",
      handleYes,
      handleCancel
    );
  };
  // console.log(leadDetailsfollowups);

  return (
    <>
      <div className="page">
        <TopHeader />
        {/* End Main Header*/}
        {/* Sidemenu */}
        <Prince />
        {/* End Sidemenu */}
        {/* Main Content*/}
        <div>
          <div className="main-content pt-0">
            <div className="main-container container-fluid">
              <div className="inner-body">
                {/* Page Header */}
                <div className="page-header">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-5">
                      Case ID : FS{customerData?.id}
                      {loader ? (
                        <div className="d-inline m-2">
                          <img src={Loader} height={"50px"}></img>
                        </div>
                      ) : (
                        <></>
                      )}
                    </h2>
                  </div>
                </div>
                {/* End Page Header */}
                {/* Row */}
                <div className="row row-sm">
                  <div className="col-lg-12 col-md-12">
                    <div className="card custom-card main-content-body-profile">
                      <div className="card-body pb-0">
                        <div className="profile-tab tab-menu-heading">
                          <nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                            <a
                              className="nav-link  active"
                              data-bs-toggle="tab"
                              href="#about"
                            >
                              Followup
                            </a>
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#edit"
                            >
                              Update
                            </a>
                            {/* <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#sales-order"
                          >
                            Sales Order
                          </a> */}
                            <a
                              className="nav-link"
                              data-bs-toggle="tab"
                              href="#plan-quotation"
                            >
                              Plan Quotation
                            </a>
                          </nav>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="main-content-body tab-pane p-3 border-top-0 active"
                          id="about"
                        >
                          <div className="row row-sm">
                            <div className="col-xl-12">
                              <div className="card custom-card border mb-3">
                                <div
                                  className={`card-header pb-2 ${
                                    customerData.masterStatus !== "Open"
                                      ? "d-none"
                                      : "d-block"
                                  } d-block`}
                                >
                                  <h6 className="mb-0">
                                    {" "}
                                    {/* Sales followup History : 10 Comments | 2 Meetings
                                  | 3 Attachment */}
                                    <div>
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1"
                                        onClick={handleOpenModal}
                                      >
                                        Post a Comment
                                      </button>

                                      <div
                                        className={`modal ${
                                          isModalOpen ? "show" : ""
                                        }`}
                                        style={{
                                          display: isModalOpen
                                            ? "block"
                                            : "none",
                                        }}
                                        tabIndex="-1"
                                        role="dialog"
                                      >
                                        <div
                                          className="modal-dialog modal-dialog-centered modal-sl"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5 className="modal-title">
                                                Followup
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={handleCloseModal}
                                              >
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <form>
                                                <div className="row row-sm">
                                                  <div className="col-sm-12 form-group">
                                                    <label className="form-label">
                                                      Comment
                                                    </label>
                                                    <textarea
                                                      className="form-control"
                                                      style={{ height: 150 }}
                                                      defaultValue={""}
                                                      name="comment"
                                                      value={formData.comment}
                                                      onChange={handleChange}
                                                    />
                                                  </div>
                                                  <div className="col-sm-12 form-group">
                                                    <label className="form-label">
                                                      Attachment
                                                    </label>
                                                    <input
                                                      type="file"
                                                      className="form-control"
                                                      name="attachment"
                                                      onChange={
                                                        handleFileChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                className="btn ripple btn-primary"
                                                fetchLeadData
                                                onClick={handleSubmit}
                                                type="button"
                                              >
                                                Post Update
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1"
                                        onClick={contactOpenModal}
                                      >
                                        Contact Update
                                      </button>

                                      <div
                                        className={`modal ${
                                          isCallOpen ? "show" : ""
                                        }`}
                                        style={{
                                          display: isCallOpen
                                            ? "block"
                                            : "none",
                                        }}
                                        tabIndex="-1"
                                        role="dialog"
                                      >
                                        <div
                                          className="modal-dialog modal-dialog-centered modal-sl"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5 className="modal-title">
                                                Contact Update
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={contactCloseModal}
                                              >
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <form>
                                                <div className="row row-sm mb-3">
                                                  <div className="form-check form-check-inline col">
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="contactType"
                                                      value="New Call"
                                                      onChange={(e) => {
                                                        handleChange2(e);
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="call"
                                                    >
                                                      New Call
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-check-inline col">
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="contactType"
                                                      value="Follow Up Call"
                                                      onChange={(e) => {
                                                        handleChange2(e);
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="call"
                                                    >
                                                      Follow up call
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-check-inline col">
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="contactType"
                                                      value="email"
                                                      onChange={(e) => {
                                                        handleChange2(e);
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="email"
                                                    >
                                                      Email
                                                    </label>
                                                  </div>
                                                  <div className="form-check form-check-inline col">
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name="contactType"
                                                      value="sms"
                                                      onChange={(e) => {
                                                        handleChange2(e);
                                                      }}
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="sms"
                                                    >
                                                      SMS
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="row row-sm">
                                                  <div className="col-sm-12" />
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      {" "}
                                                      Date
                                                    </label>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      name="callBackDate"
                                                      value={
                                                        formData2.callBackDate
                                                      }
                                                      onChange={handleChange2}
                                                    />
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      {" "}
                                                      Time
                                                    </label>
                                                    <input
                                                      type="time"
                                                      className="form-control"
                                                      name="callBackTime"
                                                      value={
                                                        formData2.callBackTime
                                                      }
                                                      onChange={handleChange2}
                                                    />
                                                  </div>
                                                  <div className="col-sm-12 form-group">
                                                    <label className="form-label">
                                                      {" "}
                                                      Summary
                                                    </label>
                                                    <textarea
                                                      className="form-control"
                                                      style={{ height: 80 }}
                                                      defaultValue={""}
                                                      name="lastCallSummary"
                                                      value={
                                                        formData2.lastCallSummary
                                                      }
                                                      onChange={handleChange2}
                                                    />
                                                  </div>
                                                  <div className="row row-sm mb-3">
                                                    <div className="form-check form-check-inline col">
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="occuranceType"
                                                        value="scheduled"
                                                        onChange={(e) => {
                                                          handleChange2(e);
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="scheduled"
                                                      >
                                                        Schedule the Activity
                                                      </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col">
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="occuranceType"
                                                        value="done"
                                                        onChange={(e) => {
                                                          handleChange2(e);
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="done"
                                                      >
                                                        Mark as Done
                                                      </label>
                                                    </div>
                                                  </div>
                                                  {/* <div className="row">
                                                  <div className="col">
                                                    <input
                                                      type="checkbox"
                                                      name="remindeMe"
                                                      value={
                                                        formData2.remindeMe
                                                      }
                                                      onChange={handleChange2}
                                                    />
                                                    <span>Reminder Me</span>
                                                  </div>
                                                  <div className="col">
                                                    <input
                                                      type="checkbox"
                                                      name="callBack"
                                                      value={formData2.callBack}
                                                      onChange={handleChange2}
                                                    />
                                                    <span>Call Back</span>
                                                  </div>
                                                </div> */}
                                                </div>
                                              </form>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                className="btn ripple btn-primary"
                                                fetchLeadData
                                                onClick={handleSubmit2}
                                                type="button"
                                              >
                                                Contact Update
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1"
                                        onClick={meetingOpenModal}
                                      >
                                        Meeting Update
                                      </button>
                                      <div
                                        className={`modal ${
                                          isMeetingOpen ? "show" : ""
                                        }`}
                                        style={{
                                          display: isMeetingOpen
                                            ? "block"
                                            : "none",
                                          marginTop: "20vh",
                                        }}
                                        tabIndex="-1"
                                        role="dialog"
                                      >
                                        <div className="modal-dialog modal-md">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5 className="modal-title">
                                                Meeting Update
                                              </h5>
                                              <button
                                                type="button"
                                                className="btn-close"
                                                onClick={meetingCloseModal}
                                                aria-label="Close"
                                              ></button>
                                            </div>
                                            <div className="modal-body">
                                              <form>
                                                <div className="row row-sm">
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      Meeting Date
                                                    </label>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      name="meetingDate"
                                                      value={
                                                        formData3.meetingDate
                                                      }
                                                      onChange={handleChange3}
                                                    />
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      Meeting Time
                                                    </label>
                                                    <input
                                                      type="time"
                                                      className="form-control"
                                                      name="meetingTime"
                                                      value={
                                                        formData3.meetingTime
                                                      }
                                                      onChange={handleChange3}
                                                    />
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      Meeting Type
                                                    </label>
                                                    <select
                                                      className="form-control select select2"
                                                      name="meetingType"
                                                      // value={
                                                      //   formData3.meetingType
                                                      // }
                                                      onChange={handleChange3}
                                                    >
                                                      <option vcalue="">
                                                        Select
                                                      </option>
                                                      <option value="online">
                                                        Online
                                                      </option>
                                                      <option value="offline">
                                                        Offline
                                                      </option>
                                                    </select>
                                                  </div>
                                                  <div className="col-sm-12 form-group">
                                                    <label className="form-label">
                                                      Meeting Venue
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="meetingVenue"
                                                      value={
                                                        formData3.meetingVenue
                                                      }
                                                      onChange={handleChange3}
                                                    />
                                                  </div>

                                                  <div className="row row-sm mb-3">
                                                    <div className="form-check form-check-inline col">
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="occuranceType"
                                                        value="scheduled"
                                                        onChange={(e) => {
                                                          handleChange3(e);
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="scheduled"
                                                      >
                                                        Schedule the Activity
                                                      </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col">
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="occuranceType"
                                                        value="done"
                                                        onChange={(e) => {
                                                          handleChange3(e);
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor="done"
                                                      >
                                                        Mark as Done
                                                      </label>
                                                    </div>
                                                  </div>
                                                  {/* <div className="col-sm-12 ">
                                                  <label className="ckbox">
                                                    <input
                                                      type="checkbox"
                                                      name="remindeMe"
                                                      value={
                                                        formData3.remindeMe
                                                      }
                                                      onChange={handleChange3}
                                                    />
                                                    <span>Reminder Me</span>
                                                  </label>
                                                </div> */}
                                                </div>
                                              </form>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                className="btn ripple btn-primary"
                                                onClick={handleSubmit3}
                                                type="button"
                                              >
                                                Update Meeting
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1"
                                        onClick={quotationOpenModal}
                                      >
                                        Quotation
                                      </button>

                                      <div
                                        className={`modal ${
                                          isQuotationOpen ? "show" : ""
                                        }`}
                                        style={{
                                          display: isQuotationOpen
                                            ? "block"
                                            : "none",
                                        }}
                                        tabIndex="-1"
                                        role="dialog"
                                      >
                                        <div
                                          className="modal-dialog modal-dialog-centered modal-sl"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5 className="modal-title">
                                                Quotation
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={quotationCloseModal}
                                              >
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <form>
                                                <div className="row row-sm">
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      POC Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="consumerName"
                                                      placeholder="Enter"
                                                      defaultValue={
                                                        leadDetails?.primaryContactName
                                                      }
                                                      onChange={handleChange4}
                                                    />
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      {" "}
                                                      POC Email
                                                    </label>
                                                    <input
                                                      className="form-control"
                                                      name="consumerEmail"
                                                      defaultValue={
                                                        customerData.customer
                                                          ?.emailId
                                                      }
                                                      onChange={handleChange4}
                                                    />
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      {" "}
                                                      PLAN
                                                    </label>
                                                    <select
                                                      className="form-select select2"
                                                      name="plan"
                                                      // value={formData8.brandName}
                                                      onChange={handleChange4}
                                                      disabled={!isPlanEnabled}
                                                    >
                                                      <option value="">
                                                        Select plan
                                                      </option>
                                                      {plans?.map(
                                                        (option, index) => (
                                                          <option
                                                            key={option.planId}
                                                            value={
                                                              option.planId
                                                            }
                                                          >
                                                            {option.planName}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      Custom PLAN
                                                    </label>
                                                    <select
                                                      className="form-select select2"
                                                      name="customePlanId"
                                                      // value={formData8.brandName}
                                                      onChange={handleChange4}
                                                      disabled={
                                                        !isCustomPlanEnabled
                                                      }
                                                    >
                                                      <option value="">
                                                        Select plan
                                                      </option>
                                                      {customPlans?.map(
                                                        (option, index) => (
                                                          <option
                                                            key={option.id}
                                                            value={option.id}
                                                          >
                                                            {option.planName}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      POC Phone Number
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="consumerPhone"
                                                      defaultValue={
                                                        customerData.customer
                                                          ?.mobileNumber
                                                      }
                                                      onChange={handleChange4}
                                                    />
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <button
                                                      className="btn btn-primary m-1"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        window.location.href =
                                                          "/plans";
                                                      }}
                                                    >
                                                      create custom plan
                                                    </button>
                                                  </div>
                                                  {/* <div className="col-sm-6 form-group">
                                                  <label className="form-label"> Comapany </label>
                                                  <input type="text" className="form-control"
                                                    name="companyName"
                                                    // value={formData8.companyName}
                                                    onChange={handleChange4} />
                                                </div> */}
                                                  {/* <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      Start Date
                                                    </label>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      name="startDate"
                                                      // value={formData8.startDate}
                                                      min={moment().format(
                                                        "YYYY-MM-DD"
                                                      )}
                                                      onChange={handleChange4}
                                                    />
                                                  </div>
                                                  <div className="col-sm-6 form-group">
                                                    <label className="form-label">
                                                      End Date
                                                    </label>
                                                    <input
                                                      type="date"
                                                      className="form-control"
                                                      name="endDate"
                                                      // value={formData8.endDate}
                                                      onChange={handleChange4}
                                                      min={moment().format(
                                                        "YYYY-MM-DD"
                                                      )}
                                                    />
                                                  </div> */}
                                                </div>
                                              </form>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                className="btn ripple btn-primary"
                                                onClick={handleSubmit4}
                                                type="button"
                                              >
                                                Update Quotation
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {customerData?.status ===
                                      "closed-won-SYTS" ? (
                                        <button
                                          className="me-2 btn ripple btn-success btn-rounded btn-rounded-sm mb-1"
                                          onClick={handleWin}
                                        >
                                          Won
                                        </button>
                                      ) : (
                                        <></>
                                      )}

                                      {customerData?.status === "lost" ? (
                                        <button
                                          className="btn me-1 ripple btn-danger btn-rounded btn-rounded-sm mb-1"
                                          onClick={handleLost}
                                        >
                                          Lost
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {/* <Link
                                    to="/View"
                                    className="btn ripple btn-info btn-rounded pull-right btn-rounded-sm mb-1 me-1"
                                  >
                                    View Invoice
                                  </Link> */}
                                  </h6>
                                </div>

                                <div
                                  className={`card-header pb-2 text-center ${
                                    customerData?.masterStatus === "Won"
                                      ? "d-block"
                                      : "d-none"
                                  }`}
                                >
                                  <p className="badge bg-success fs-13">
                                    YEAHYY !!! - Lead Won.
                                  </p>
                                </div>

                                <div
                                  className={`card-header pb-2 text-center ${
                                    customerData?.masterStatus === "Lost"
                                      ? "d-block"
                                      : "d-none"
                                  }`}
                                >
                                  <p className="badge bg-danger fs-13">
                                    Uh Oh ! - Lead Lost.
                                  </p>
                                </div>

                                <div className="card-body">
                                  <div className="col-12">
                                    <ul className="notification mb-0">
                                      {leadDetailsfollowups.length > 0 ? (
                                        leadDetailsfollowups.map((comment) => {
                                          if (comment.type === "leadComment") {
                                            return (
                                              <li>
                                                <div className="notification-time">
                                                  <p className="mb-0 ">
                                                    {comment.createdAtDate}
                                                    <br />
                                                    {comment.createdAtTime}
                                                  </p>
                                                </div>
                                                <div className="notification-icon">
                                                  <a href=";" />
                                                </div>
                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                  <p className="mb-0">
                                                    {comment.createdAt}
                                                    <br />
                                                  </p>
                                                </div>
                                                <div className="notification-body">
                                                  <div className="media mt-0">
                                                    <div className="media-body ms-2 d-flex">
                                                      <div className="me-5 pt-4 pb-4">
                                                        {/* <img
                                                        src={Logo}
                                                        style={{ width: 40 }}
                                                      /> */}
                                                      </div>
                                                      <div className="">
                                                        <p className="tx-14 text-dark mb-0 tx-semibold">
                                                          {comment.createdBy
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            comment.createdBy.slice(
                                                              1
                                                            )}
                                                          , Posted an update.
                                                        </p>
                                                        <p className="tx-14 text-dark mb-0">
                                                          {comment.comment}{" "}
                                                        </p>
                                                        {comment.attachment !==
                                                          "" && (
                                                          <p className="mb-0 tx-13 text-dark">
                                                            <i className="fe fe-chevrons-right me-1" />{" "}
                                                            Attachment:
                                                            <Link
                                                              to={
                                                                comment.attachment
                                                              }
                                                              target="black"
                                                            >
                                                              {" "}
                                                              View
                                                            </Link>
                                                          </p>
                                                        )}
                                                      </div>
                                                      <div className="notify-time">
                                                        <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                          Comment
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          } else if (
                                            comment.type === "Pre-proforma"
                                          ) {
                                            return (
                                              <li>
                                                <div className="notification-time">
                                                  <p className="mb-0 ">
                                                    {comment.createdAtDate}
                                                    <br />
                                                    {comment.createdAtTime}
                                                  </p>
                                                </div>
                                                <div className="notification-icon">
                                                  <a href=";" />
                                                </div>
                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                  <p className="mb-0">
                                                    10 May 2023 <br /> 12:00 PM
                                                  </p>
                                                </div>
                                                <div className="notification-body">
                                                  <div className="media mt-0">
                                                    <div className=" avatar-md online">
                                                      {/* <img
                                                      alt="avatar"
                                                      className="rounded-6"
                                                      src={Logo}
                                                    /> */}
                                                    </div>
                                                    <div className="media-body ms-2 d-flex">
                                                      <div className="me-5 pt-4">
                                                        <img
                                                          src={Img}
                                                          style={{ width: 110 }}
                                                        />
                                                      </div>
                                                      <div className="">
                                                        <p className="mb-0 tx-13 text-dark">
                                                          AGREED RICE PARAMETERS
                                                        </p>

                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Rice Type:
                                                          {
                                                            comment.proformas
                                                              .riceType
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Swarna Parboiled:{" "}
                                                          {
                                                            comment.proformas
                                                              .swarnaParboiled
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Broken:
                                                          {
                                                            comment.proformas
                                                              .broken
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Chalky:
                                                          {
                                                            comment.proformas
                                                              .chalky
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          DD:{" "}
                                                          {comment.proformas.dd}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          WhiteNess:
                                                          {
                                                            comment.proformas
                                                              .whiteNess
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Moisture:
                                                          {
                                                            comment.proformas
                                                              .moisture
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Moisture:
                                                          {
                                                            comment.proformas
                                                              .grainLength
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Payment Terms:
                                                          {
                                                            comment.proformas
                                                              .paymentTerms
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          PortOf Loading:
                                                          {
                                                            comment.proformas
                                                              .portOfLoading
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Port Of Destination:
                                                          {
                                                            comment.proformas
                                                              .portOfDestination
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          ETD:
                                                          {
                                                            comment.proformas
                                                              .etd
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          ETA:
                                                          {
                                                            comment.proformas
                                                              .eta
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          CIF:
                                                          {
                                                            comment.proformas
                                                              .cif
                                                          }
                                                        </p>
                                                        <hr />
                                                        <p className="mb-0 tx-13 text-dark">
                                                          BRAND & PACKAGING
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Back marck: JCC
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Size:
                                                          {
                                                            comment.proformas
                                                              .size
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Total No Of Bags:
                                                          {
                                                            comment.proformas
                                                              .totalNoOfBags
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Type:
                                                          {
                                                            comment.proformas
                                                              .type
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Empty Bags:
                                                          {
                                                            comment.proformas
                                                              .emptyBags
                                                          }
                                                        </p>
                                                        <br />
                                                        <p className="mb-0 tx-13 text-dark">
                                                          QUANTITY
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          FCL:{" "}
                                                          {
                                                            comment.proformas
                                                              .fcl
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Tons:{" "}
                                                          {
                                                            comment.proformas
                                                              .tons
                                                          }
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Lot Size:{" "}
                                                          {
                                                            comment.proformas
                                                              .lotSize
                                                          }
                                                        </p>

                                                        <p className="mb-0 tx-13 text-dark">
                                                          <br />
                                                          <Link
                                                            to={
                                                              comment.proformas
                                                                .proforma
                                                            }
                                                            className="custom-button mb-1 me-1"
                                                            target="blanck"
                                                          >
                                                            View Pre-proforma
                                                          </Link>
                                                          <Link
                                                            className="custom-button mb-1 me-1"
                                                            onClick={() => {
                                                              handleSubmit10(
                                                                comment
                                                                  .proformas
                                                                  .followUpId
                                                              );
                                                            }}
                                                          >
                                                            Send email
                                                            Pre-proforma
                                                          </Link>
                                                        </p>
                                                      </div>
                                                      <div className="notify-time">
                                                        <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                          Pre-proforma
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          } else if (
                                            comment.type === "quotationUpdate"
                                          ) {
                                            if (comment.quotations.length) {
                                              return comment.quotations.map(
                                                (quot) => {
                                                  quotCount++;
                                                  return (
                                                    <li>
                                                      <div className="notification-time">
                                                        <p className="mb-0 ">
                                                          {
                                                            comment.createdAtDate
                                                          }
                                                          <br />
                                                          {
                                                            comment.createdAtTime
                                                          }
                                                        </p>
                                                      </div>
                                                      <div className="notification-icon">
                                                        <a href=";" />
                                                      </div>
                                                      <div className="notification-time-date mb-2 d-block d-md-none">
                                                        <p className="mb-0">
                                                          10 May 2023 <br />{" "}
                                                          12:00 PM
                                                        </p>
                                                      </div>
                                                      <div className="notification-body">
                                                        <div className="media mt-0">
                                                          <div className=" avatar-md online">
                                                            {/* <img
                                                            alt="avatar"
                                                            className="rounded-6"
                                                            src={Logo}
                                                          /> */}
                                                          </div>
                                                          <div className="media-body ms-2 d-flex">
                                                            <div className="me-5 pt-4">
                                                              <img
                                                                src={Img}
                                                                style={{
                                                                  width: 110,
                                                                }}
                                                              />
                                                            </div>
                                                            <div className="">
                                                              <p className="tx-14 text-dark mb-0 tx-semibold">
                                                                {quot.createdBy
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                                  quot.createdBy.slice(
                                                                    1
                                                                  )}
                                                                , created a
                                                                proposal.
                                                              </p>
                                                              <p className="mb-0 tx-13 text-dark">
                                                                <i className="fe fe-chevrons-right me-1" />
                                                                Consumer Name :{" "}
                                                                {
                                                                  quot.consumerName
                                                                }
                                                              </p>
                                                              <p className="mb-0 tx-13 text-dark">
                                                                <i className="fe fe-chevrons-right me-1" />
                                                                Consumer Email :{" "}
                                                                {
                                                                  quot.consumerEmail
                                                                }
                                                              </p>
                                                              <p className="mb-0 tx-13 text-dark">
                                                                <i className="fe fe-chevrons-right me-1" />
                                                                Consumer Phone :{" "}
                                                                {
                                                                  quot.consumerPhone
                                                                }
                                                              </p>

                                                              {quot.plan && (
                                                                <p className="mb-0 tx-13 text-dark">
                                                                  <i className="fe fe-chevrons-right me-1" />
                                                                  Plan Name :{" "}
                                                                  {quot.plan} (
                                                                  {
                                                                    quot?.Planss
                                                                      ?.planName
                                                                  }
                                                                  )
                                                                </p>
                                                              )}
                                                              {quot.customePlanId && (
                                                                <p className="mb-0 tx-13 text-dark">
                                                                  <i className="fe fe-chevrons-right me-1" />
                                                                  Custom Plan
                                                                  Name :{" "}
                                                                  {
                                                                    quot.customePlanId
                                                                  }{" "}
                                                                  (
                                                                  {
                                                                    quot
                                                                      ?.customisePlans
                                                                      ?.planName
                                                                  }
                                                                  )
                                                                </p>
                                                              )}
                                                              <p className="mb-0 tx-13 text-dark">
                                                                <i className="fe fe-chevrons-right me-1" />
                                                                Quot Id :{" "}
                                                                {
                                                                  quot.followUpId
                                                                }
                                                              </p>
                                                              <p className="mb-0 tx-13 text-dark">
                                                                <i className="fe fe-chevrons-right me-1" />
                                                                Start Date :{" "}
                                                                {quot.startDate}
                                                              </p>
                                                              <p className="mb-0 tx-13 text-dark">
                                                                <i className="fe fe-chevrons-right me-1" />
                                                                End Date :{" "}
                                                                {quot.endDate}
                                                              </p>
                                                              <p className="mb-0 tx-13 text-dark">
                                                                <i className="fe fe-chevrons-right me-1" />
                                                                Stage :{" "}
                                                                {comment.status
                                                                  ?.charAt(0)
                                                                  .toUpperCase() +
                                                                  comment.status?.slice(
                                                                    1
                                                                  )}
                                                              </p>
                                                              {comment
                                                                .quotations[0] !==
                                                                undefined &&
                                                                comment
                                                                  ?.quotations[0]
                                                                  .proposalPdf
                                                                  .length >
                                                                  0 && (
                                                                  <p className="mb-0 tx-13 text-dark">
                                                                    <i className="fe fe-chevrons-right me-1" />
                                                                    PDF :{" "}
                                                                    <a
                                                                      href={
                                                                        comment
                                                                          ?.quotations[0]
                                                                          ?.proposalPdf
                                                                      }
                                                                      target="_blank"
                                                                      rel="noreferrer"
                                                                    >
                                                                      View
                                                                    </a>
                                                                  </p>
                                                                )}
                                                              {quotCount ===
                                                              comment.quotations
                                                                .length ? (
                                                                <>
                                                                  {leadDetails?.isEmailSend && (
                                                                    <p
                                                                      className="row text-muted tx-11 mt-2 badge bg-danger-light"
                                                                      role="button"
                                                                      onClick={() => {
                                                                        sendToFship(
                                                                          quot.followUpId
                                                                        );
                                                                      }}
                                                                    >
                                                                      Send to
                                                                      Fship
                                                                    </p>
                                                                  )}
                                                                  <p
                                                                    className="mx-4 text-muted mt-2 badge bg-danger-light"
                                                                    role="button"
                                                                    onClick={() => {
                                                                      sendQuotationToMail(
                                                                        quot.followUpId
                                                                      );
                                                                    }}
                                                                  >
                                                                    Send to Mail
                                                                  </p>
                                                                </>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </div>
                                                            <div className="notify-time">
                                                              <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                                Quotation
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  );
                                                }
                                              );
                                            }
                                          } else if (
                                            comment.type === "meetingUpdate"
                                          ) {
                                            return (
                                              <li>
                                                <div className="notification-time">
                                                  <p className="mb-0 ">
                                                    {comment.createdAtDate}
                                                    <br />
                                                    {comment.createdAtTime}
                                                  </p>
                                                </div>
                                                <div className="notification-icon">
                                                  <a href=";" />
                                                </div>
                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                  <p className="mb-0">
                                                    {comment.createdAt}
                                                  </p>
                                                </div>
                                                <div className="notification-body">
                                                  <div className="media mt-0">
                                                    <div className="media-body ms-2 d-flex">
                                                      <div className="me-5 pt-4 pb-4">
                                                        {/* <img
                                                        src={Logo}
                                                        style={{ width: 40 }}
                                                      /> */}
                                                      </div>
                                                      <div className="me-5 pt-4 pb-4">
                                                        <img
                                                          src={Img4}
                                                          style={{ width: 100 }}
                                                        />
                                                      </div>
                                                      <div className="">
                                                        <p className="tx-14 text-dark mb-0 tx-semibold">
                                                          {comment.createdBy
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            comment.createdBy.slice(
                                                              1
                                                            )}
                                                          , Posted an update.
                                                        </p>
                                                        <p className="tx-14 text-dark mb-0">
                                                          {
                                                            comment.lastCallSummary
                                                          }{" "}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />{" "}
                                                          Meeting Venue :{" "}
                                                          {comment.meetingVenue}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Meeting Date :{" "}
                                                          {comment.meetingDate}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Meeting Time :{" "}
                                                          {comment.meetingTime !==
                                                            "" &&
                                                            new Date(
                                                              `2000-01-01T${comment.meetingTime}:00`
                                                            ).toLocaleTimeString(
                                                              "en-US",
                                                              {
                                                                hour: "numeric",
                                                                minute:
                                                                  "numeric",
                                                                hour12: true,
                                                              }
                                                            )}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Meeting Type :{" "}
                                                          {comment.meetingType}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Sched/Done :{" "}
                                                          <span className="mb-0 text-muted tx-11 badge bg-pill bg-success-light">
                                                            {
                                                              comment.occuranceType
                                                            }
                                                          </span>
                                                          {comment?.comment
                                                            ? ` "${comment?.comment}"`
                                                            : ""}
                                                        </p>
                                                        {comment.occuranceType ===
                                                          "scheduled" &&
                                                          !addDoneComment && (
                                                            <p
                                                              className="row text-muted tx-11 mt-2 badge bg-danger-light"
                                                              role="button"
                                                              onClick={() => {
                                                                showMarkMeetDone();
                                                              }}
                                                            >
                                                              Mark Done
                                                            </p>
                                                          )}
                                                        {comment.occuranceType ===
                                                          "scheduled" &&
                                                          addDoneComment && (
                                                            <div className="row">
                                                              <input
                                                                placeholder="Please add comment"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setDoneComment(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                              />
                                                              {doneCommentError && (
                                                                <p className="danger">
                                                                  {
                                                                    doneCommentError
                                                                  }
                                                                </p>
                                                              )}
                                                              <p
                                                                className="text-muted tx-11 mt-2 badge bg-danger-light"
                                                                role="button"
                                                                onClick={() => {
                                                                  markMeetDone(
                                                                    comment.id
                                                                  );
                                                                }}
                                                              >
                                                                Done
                                                              </p>
                                                            </div>
                                                          )}
                                                      </div>
                                                      <div className="notify-time">
                                                        <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                          Meeting
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          } else if (
                                            comment.type === "callUpdate"
                                          ) {
                                            return (
                                              <li>
                                                <div className="notification-time">
                                                  <p className="mb-0 ">
                                                    {comment.createdAtDate}
                                                    <br />
                                                    {comment.createdAtTime}
                                                  </p>
                                                </div>
                                                <div className="notification-icon">
                                                  <a href=";" />
                                                </div>
                                                <div className="notification-time-date mb-2 d-block d-md-none">
                                                  <p className="mb-0">
                                                    {comment.createdAt}
                                                  </p>
                                                </div>
                                                <div className="notification-body">
                                                  <div className="media mt-0">
                                                    <div className="media-body ms-2 d-flex">
                                                      <div className="me-5 pt-4 pb-4">
                                                        {/* <img
                                                        src={Logo}
                                                        style={{ width: 40 }}
                                                      /> */}
                                                      </div>

                                                      <div className="me-5 pt-4 pb-4">
                                                        <img
                                                          src={Img3}
                                                          style={{ width: 80 }}
                                                        />
                                                      </div>
                                                      <div className="">
                                                        <p className="tx-14 text-dark mb-0 tx-semibold">
                                                          {comment.createdBy
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            comment.createdBy.slice(
                                                              1
                                                            )}
                                                          , Posted an update.
                                                        </p>
                                                        <p className="tx-14 text-dark mb-0">
                                                          {
                                                            comment.lastCallSummary
                                                          }{" "}
                                                        </p>

                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Call Back Date :{" "}
                                                          {comment.callBackDate}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Call Back Time :{" "}
                                                          {comment.callBackTime !==
                                                            "" &&
                                                            new Date(
                                                              `2000-01-01T${comment.callBackTime}:00`
                                                            ).toLocaleTimeString(
                                                              "en-US",
                                                              {
                                                                hour: "numeric",
                                                                minute:
                                                                  "numeric",
                                                                hour12: true,
                                                              }
                                                            )}
                                                        </p>
                                                        <p className="mb-0 tx-13 text-dark">
                                                          <i className="fe fe-chevrons-right me-1" />
                                                          Sched/Done :{" "}
                                                          <span className="mb-0 text-muted tx-11 badge bg-pill bg-success-light">
                                                            {
                                                              comment.occuranceType
                                                            }
                                                          </span>
                                                          {comment?.comment
                                                            ? ` "${comment?.comment}"`
                                                            : ""}
                                                        </p>
                                                        {comment.occuranceType ===
                                                          "scheduled" &&
                                                          !addContactDoneComment && (
                                                            <p
                                                              className="row text-muted tx-11 mt-2 badge bg-danger-light"
                                                              role="button"
                                                              onClick={() => {
                                                                showMarkContactDone();
                                                              }}
                                                            >
                                                              Mark Done
                                                            </p>
                                                          )}
                                                        {comment.occuranceType ===
                                                          "scheduled" &&
                                                          addContactDoneComment && (
                                                            <div className="row">
                                                              <input
                                                                placeholder="Please add comment"
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setDoneComment(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                }}
                                                              />
                                                              {doneCommentError && (
                                                                <p className="danger">
                                                                  {
                                                                    doneCommentError
                                                                  }
                                                                </p>
                                                              )}
                                                              <p
                                                                className="text-muted tx-11 mt-2 badge bg-danger-light"
                                                                role="button"
                                                                onClick={() => {
                                                                  markMeetDone(
                                                                    comment.id
                                                                  );
                                                                }}
                                                              >
                                                                Done
                                                              </p>
                                                            </div>
                                                          )}
                                                      </div>
                                                      <div className="notify-time">
                                                        <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                          {comment.contactType
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            comment.contactType.slice(
                                                              1
                                                            )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          } else {
                                            return null; // Handle other types or add a default case as needed
                                          }
                                        })
                                      ) : (
                                        <>
                                          <p>No follow-ups available</p>
                                        </>
                                      )}
                                      <li>
                                        {customerData?.id ? (
                                          <div>
                                            <div className="notification-time">
                                              <p className="mb-0">
                                                {
                                                  customerData.customer
                                                    .createdAtDate
                                                }
                                                <br />{" "}
                                                {
                                                  customerData.customer
                                                    .createdAtTime
                                                }
                                              </p>
                                            </div>
                                            <div>
                                              <div className="notification-icon">
                                                <a href=";" />
                                              </div>
                                              <div className="notification-time-date mb-2 d-block d-md-none">
                                                <p className="mb-0">
                                                  10 May 2023 <br /> 12:00 PM
                                                </p>
                                              </div>
                                              <div className="notification-body">
                                                <div className="media mt-0">
                                                  <div className="media-body ms-2 d-flex">
                                                    <div className="me-3">
                                                      {/* <img
                                                      src={Logo}
                                                      style={{ width: 40 }}
                                                      alt="truck driver"
                                                    /> */}
                                                    </div>
                                                    {/* <div className="me-3 ">
                                                    <img
                                                      src={Img5}
                                                      style={{ width: 150 }}
                                                    />
                                                  </div> */}
                                                    <div>
                                                      <p className="tx-14 text-dark mb-0 tx-semibold">
                                                        {
                                                          customerData.customer
                                                            .createdBy
                                                        }{" "}
                                                        Added a New Case.
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Full Name:{" "}
                                                        {
                                                          customerData.customer
                                                            .primaryContactName
                                                        }
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Email:{" "}
                                                        {
                                                          customerData.customer
                                                            .emailId
                                                        }
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Phone: +91{" "}
                                                        {
                                                          customerData.customer
                                                            .mobileNumber
                                                        }
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Company:{" "}
                                                        {
                                                          customerData.customer
                                                            .companyName
                                                        }
                                                      </p>
                                                      <p className="mb-0 tx-13 text-dark">
                                                        <i className="fe fe-chevrons-right me-1" />
                                                        Stage:{" "}
                                                        {customerData.customer.status
                                                          ?.charAt(0)
                                                          .toUpperCase() +
                                                          customerData.customer.status?.slice(
                                                            1
                                                          )}
                                                      </p>

                                                      {/* Add more static details as needed */}
                                                    </div>
                                                    <div className="notify-time">
                                                      <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                                        New Case
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <p></p>
                                        )}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="main-content-body tab-pane p-3 border-top-0"
                          id="edit"
                        >
                          <div className="row row-sm">
                            <div className="col-xl-12">
                              <div className="card custom-card border mb-3">
                                <div className="card-header pb-3">
                                  <h6 className="mb-0">
                                    BASIC INFO :{" "}
                                    <a
                                      href="add-buyer.html"
                                      className="add_icon_"
                                    >
                                      <i
                                        className="ion-compass"
                                        data-bs-toggle="tooltip"
                                        title=""
                                        data-bs-original-title="Edit"
                                        aria-label="Edit"
                                      />
                                    </a>
                                  </h6>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>Primary contact name :</span>
                                            <div>
                                              {" "}
                                              {
                                                customerData.customer
                                                  ?.primaryContactName
                                              }{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>
                                              Primary contact designation :
                                            </span>
                                            <div>
                                              {" "}
                                              {
                                                customerData.customer
                                                  ?.primaryContactDesignation
                                              }{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>Mobile Number :</span>
                                            <div>
                                              {" "}
                                              {
                                                customerData.customer
                                                  ?.mobileNumber
                                              }{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>Company Name :</span>
                                            <div>
                                              {" "}
                                              {
                                                customerData.customer
                                                  ?.companyName
                                              }{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {leadDetails?.companyId ? (
                                      <div className="col-sm-3">
                                        <div className="main-profile-contact-list mb-3">
                                          <div className="media">
                                            <div className="media-icon bg-primary-transparent text-primary">
                                              {" "}
                                              <i className="icon ion-md-globe" />{" "}
                                            </div>
                                            <div className="media-body">
                                              {" "}
                                              <span>Company Id :</span>
                                              <div>
                                                {" "}
                                                {
                                                  customerData.customer
                                                    ?.companyId
                                                }{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>Email ID :</span>
                                            <div>
                                              {" "}
                                              {
                                                customerData.customer?.emailId
                                              }{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>Business Category :</span>
                                            <div>
                                              {" "}
                                              {
                                                customerData.customer
                                                  ?.businessCategory
                                              }{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>
                                              Current Shipping Partner :
                                            </span>
                                            <div>
                                              {" "}
                                              {
                                                customerData.customer
                                                  ?.currentShippingPartner
                                              }{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>Shipment Potential :</span>
                                            <div>
                                              {
                                                customerData.customer
                                                  ?.shipmentPotential
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>
                                              Min Expected Shipment to Fship :
                                            </span>
                                            <div>
                                              {
                                                customerData.customer
                                                  ?.minimumExpectedShipmentToFship
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>Client Persona :</span>
                                            <div>
                                              {
                                                customerData.customer
                                                  ?.clientPersona
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      <div className="main-profile-contact-list mb-3">
                                        <div className="media">
                                          <div className="media-icon bg-primary-transparent text-primary">
                                            {" "}
                                            <i className="icon ion-md-globe" />{" "}
                                          </div>
                                          <div className="media-body">
                                            {" "}
                                            <span>UTM Details :</span>
                                            <div>
                                              Source :-{" "}
                                              {customerData.customer?.utmSource}
                                              <br></br>
                                              Medium :-{" "}
                                              {customerData.customer?.utmMedium}
                                              <br></br>
                                              Campaign :-{" "}
                                              {
                                                customerData.customer
                                                  ?.utmCampaign
                                              }
                                              <br></br>
                                              Term :-{" "}
                                              {customerData.customer?.utmTerm}
                                              <br></br>
                                              Content :-{" "}
                                              {
                                                customerData.customer
                                                  ?.utmContent
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="main-content-body tab-pane p-3 border-top-0"
                          id="plan-quotation"
                        >
                          <div className="row row-sm">
                            <div className="col-lg-12">
                              <div className="card custom-card">
                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table className="table table-striped table-bordered text-nowrap mb-0">
                                      <thead>
                                        <tr>
                                          <th>ID</th>
                                          <th>Name</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {plans?.map((p, i) => {
                                          return (
                                            <tr key={p.planId}>
                                              <td>{p.planId}</td>
                                              <td>{p.planName}</td>
                                              <td>
                                                <button
                                                  id={p.planId}
                                                  onClick={viewPlan}
                                                  className="btn btn-primary ripple btn-info btn-xs w-50"
                                                >
                                                  View Plan
                                                </button>{" "}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                    <h2 className="text-center mt-4 mb-1">
                                      Custom Plans
                                    </h2>
                                    <table className="table table-striped table-bordered text-nowrap mb-0">
                                      <thead>
                                        <tr>
                                          <th>ID</th>
                                          <th>Name</th>
                                          <th>Employee Name</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {customPlans?.map((p, i) => {
                                          return (
                                            <tr key={p.id}>
                                              <td>{p.id}</td>
                                              <td>{p.planName}</td>
                                              <td>
                                                {p.employee?.firstName
                                                  ?.charAt(0)
                                                  .toUpperCase() +
                                                  p.employee?.firstName?.slice(
                                                    1
                                                  )}{" "}
                                                {p.employee?.lastName}{" "}
                                              </td>
                                              <td>
                                                <button
                                                  id={p.id}
                                                  onClick={viewCustomPlan}
                                                  className="btn btn-primary ripple btn-info btn-xs w-50"
                                                >
                                                  View Plan
                                                </button>{" "}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="main-content-body  tab-pane border-top-0 p-3"
                          id="timeline"
                        >
                          <div className="row row-sm">
                            <div className="col-xl-12">
                              <ul className="notification mb-0 subscription_wrap">
                                <li>
                                  <div className="notification-body ms-0">
                                    <div className="media mt-0">
                                      <div className="media-body ms-2 d-flex">
                                        <div className="me-3 pt-4">
                                          <img
                                            src="../assets/img/lots-logo.png"
                                            style={{ width: 200 }}
                                          />
                                        </div>
                                        <div>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Total No of Truck : 100
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Subscription : LOTS Premium
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Rate Per Truck : 100
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Total Amount : 10000{" "}
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Discount (%) : 5{" "}
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            {" "}
                                            <i className="fe fe-chevrons-right me-1" />
                                            Start Date : 01/05/2023
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            End Date : 01/05/2024
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Grace Period : 1 Year{" "}
                                          </p>
                                          <a
                                            href=""
                                            className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light"
                                          >
                                            Accepted
                                          </a>
                                          <a
                                            href=""
                                            className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light"
                                          >
                                            Cancel
                                          </a>
                                          <a
                                            href=""
                                            className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light"
                                          >
                                            Revise
                                          </a>
                                        </div>
                                        <div className="notify-time">
                                          <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                            Quotation
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="notification-body ms-0">
                                    <div className="media mt-0">
                                      <div className="media-body ms-2 d-flex">
                                        <div className="me-3 pt-4">
                                          <img
                                            src="../assets/img/lots-logo.png"
                                            style={{ width: 200 }}
                                          />
                                        </div>
                                        <div>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Total No of Truck : 100
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Subscription : LOTS Premium
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Rate Per Truck : 100
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Total Amount : 10000{" "}
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Discount (%) : 5{" "}
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            {" "}
                                            <i className="fe fe-chevrons-right me-1" />
                                            Start Date : 01/05/2023
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            End Date : 01/05/2024
                                          </p>
                                          <p className="mb-0 tx-13 text-dark">
                                            <i className="fe fe-chevrons-right me-1" />
                                            Grace Period : 1 Year{" "}
                                          </p>
                                          <a
                                            href=""
                                            className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light"
                                          >
                                            Accepted
                                          </a>
                                          <a
                                            href=""
                                            className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light"
                                          >
                                            Cancel
                                          </a>
                                          <a
                                            href=""
                                            className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light"
                                          >
                                            Revise
                                          </a>
                                        </div>
                                        <div className="notify-time">
                                          <p className="mb-0 text-muted tx-11 badge bg-pill bg-danger-light">
                                            Quotation
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div
                          className="main-content-body tab-pane border-top-0 p-3"
                          id="ass-millers"
                        >
                          <div className="row row-sm">
                            <div className="col-xl-12">
                              <div className="table-responsive">
                                <table className="table table-striped table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>
                                        <label className="ckbox">
                                          <input
                                            type="checkbox"
                                            defaultValue={5}
                                          />
                                          <span />
                                        </label>
                                      </th>
                                      <th>Photo</th>
                                      <th>Contact</th>
                                      <th>Company</th>
                                      <th>Requirement</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <label className="ckbox">
                                          <input
                                            type="checkbox"
                                            defaultValue={5}
                                          />
                                          <span />
                                        </label>
                                      </td>
                                      <td>
                                        <img
                                          alt="avatar"
                                          className="rounded-circle me-3"
                                          src="../assets/img/svgs/rm1.jpg"
                                          style={{ width: 60 }}
                                        />
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          Sushil Singh Sharma
                                          <br />
                                          M: 8786467890
                                          <br />
                                          E: NA
                                          <br />
                                          Truck Owner
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          MGS Rice Mill Co
                                          <br />
                                          E: info@mgsricemill.com
                                          <br />
                                          M: 8889787790
                                          <br />
                                          A: C22, Noida Sector 63, UP 201301
                                          <br />
                                          GST No: GHJYTIK89A
                                          <br />
                                          Status : Active Rice Miller
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          Quality : White Rice
                                          <br />
                                          Quantity : 200 Tn
                                          <br />
                                          Date of delivery : 30 Sep 23
                                          <br />
                                          Ship From: Mundra Port, India
                                          <br />
                                          Ship To: Santosa Port, Singapore.{" "}
                                        </p>
                                      </td>
                                      <td>
                                        <a
                                          href="lead-detail.html"
                                          className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                                        >
                                          Details
                                        </a>
                                        <br />
                                        <a
                                          href=""
                                          className="btn ripple btn-success btn-xs w-100 mb-1"
                                        >
                                          Edit Lead
                                        </a>
                                        <br />
                                        <a
                                          href=""
                                          className="btn ripple btn-info btn-xs w-100"
                                        >
                                          Followup
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="main-content-body tab-pane border-top-0 p-3"
                          id="ass-exporters"
                        >
                          <div className="row row-sm">
                            <div className="col-xl-12">
                              <div className="table-responsive">
                                <table className="table table-striped table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>
                                        <label className="ckbox">
                                          <input
                                            type="checkbox"
                                            defaultValue={5}
                                          />
                                          <span />
                                        </label>
                                      </th>
                                      <th>Photo</th>
                                      <th>Contact</th>
                                      <th>Company</th>
                                      <th>Requirement</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <label className="ckbox">
                                          <input
                                            type="checkbox"
                                            defaultValue={5}
                                          />
                                          <span />
                                        </label>
                                      </td>
                                      <td>
                                        <img
                                          alt="avatar"
                                          className="rounded-circle me-3"
                                          src="../assets/img/svgs/ex4.jpg"
                                          style={{ width: 60 }}
                                        />
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          Sushil Singh Sharma
                                          <br />
                                          M: 8786467890
                                          <br />
                                          E: NA
                                          <br />
                                          Truck Owner
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          MGS Rice Mill Co
                                          <br />
                                          E: info@mgsricemill.com
                                          <br />
                                          M: 8889787790
                                          <br />
                                          A: C22, Noida Sector 63, UP 201301
                                          <br />
                                          GST No: GHJYTIK89A
                                          <br />
                                          Status : Active Rice Miller
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          Quality : White Rice
                                          <br />
                                          Quantity : 200 Tn
                                          <br />
                                          Date of delivery : 30 Sep 23
                                          <br />
                                          Ship From: Mundra Port, India
                                          <br />
                                          Ship To: Santosa Port, Singapore.{" "}
                                        </p>
                                      </td>
                                      <td>
                                        <a
                                          href="lead-detail.html"
                                          className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                                        >
                                          Details
                                        </a>
                                        <br />
                                        <a
                                          href=""
                                          className="btn ripple btn-success btn-xs w-100 mb-1"
                                        >
                                          Edit Lead
                                        </a>
                                        <br />
                                        <a
                                          href=""
                                          className="btn ripple btn-info btn-xs w-100"
                                        >
                                          Followup
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="main-content-body tab-pane border-top-0 p-3"
                          id="ass-vendors"
                        >
                          <div className="row row-sm">
                            <div className="col-xl-12">
                              <div className="table-responsive">
                                <table className="table table-striped table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>
                                        <label className="ckbox">
                                          <input
                                            type="checkbox"
                                            defaultValue={5}
                                          />
                                          <span />
                                        </label>
                                      </th>
                                      <th>Photo</th>
                                      <th>Contact</th>
                                      <th>Company</th>
                                      <th>Requirement</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <label className="ckbox">
                                          <input
                                            type="checkbox"
                                            defaultValue={5}
                                          />
                                          <span />
                                        </label>
                                      </td>
                                      <td>
                                        <img
                                          alt="avatar"
                                          className="rounded-circle me-3"
                                          src="../assets/img/svgs/b1.png"
                                          style={{ width: 60 }}
                                        />
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          Sushil Singh Sharma
                                          <br />
                                          M: 8786467890
                                          <br />
                                          E: NA
                                          <br />
                                          Truck Owner
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          MGS Rice Mill Co
                                          <br />
                                          E: info@mgsricemill.com
                                          <br />
                                          M: 8889787790
                                          <br />
                                          A: C22, Noida Sector 63, UP 201301
                                          <br />
                                          GST No: GHJYTIK89A
                                          <br />
                                          Status : Active Rice Miller
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0">
                                          Quality : White Rice
                                          <br />
                                          Quantity : 200 Tn
                                          <br />
                                          Date of delivery : 30 Sep 23
                                          <br />
                                          Ship From: Mundra Port, India
                                          <br />
                                          Ship To: Santosa Port, Singapore.{" "}
                                        </p>
                                      </td>
                                      <td>
                                        <a
                                          href="lead-detail.html"
                                          className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                                        >
                                          Details
                                        </a>
                                        <br />
                                        <a
                                          href=""
                                          className="btn ripple btn-success btn-xs w-100 mb-1"
                                        >
                                          Edit Lead
                                        </a>
                                        <br />
                                        <a
                                          href=""
                                          className="btn ripple btn-info btn-xs w-100"
                                        >
                                          Followup
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="main-content-body tab-pane border-top-0 p-3"
                          id="sales-order"
                        >
                          <div className="row row-sm">
                            <div className="col-xl-12">
                              <div className="media mt-0">
                                <div className="media-body ms-2 d-flex">
                                  <div className="me-5 pt-4">
                                    <img
                                      src="../assets/img/svgs/sales_quotation.png"
                                      style={{ width: 150 }}
                                    />
                                  </div>
                                  <div className="">
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Exporter Name : Adiruchi International
                                      Expe
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Type of Rice : Parboiled Rice Short Grain
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Brand Name : Plain Bags (White)
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Total tons : 135
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      No of tons FCL : 27
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Price per ton : 430
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Total bags : 5400
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Packing size : 25 KG
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Payment terms : Scan BL
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Port of Loading : Chennai
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      ETA : 12 days
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Port of Destination : Singapore
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Consignee : Virago
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Notify - 1 : Virago
                                    </p>
                                    <p className="mb-0 tx-13 text-dark">
                                      <i className="fe fe-chevrons-right me-1" />
                                      Notify - 2 : PWIP
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Row */}
              </div>
            </div>
          </div>
          {/* End Main Content*/}
          {/* Main Footer*/}
          <div className="main-footer text-center">
            <div className="container">
              <div className="row row-sm">
                <div className="col-md-12">
                  <span>
                    Copyright © 2024 <a href="">FSHIP</a>. Developed by{" "}
                    <a href="http://fship.in/">FSHIP</a> All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/*End Footer*/}
          {/* Sidebar */}
          <div className="sidebar sidebar-right sidebar-animate">
            <div className="sidebar-icon">
              <a
                href="#"
                className="text-end float-end text-dark fs-20"
                data-bs-toggle="sidebar-right"
                data-bs-target=".sidebar-right"
              >
                <i className="fe fe-x" />
              </a>
            </div>
            <div className="sidebar-body">
              <h5>Todo</h5>
              <div className="d-flex p-3">
                <label className="ckbox">
                  <input defaultChecked="" type="checkbox" />
                  <span>Hangout With friends</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <div className="d-flex p-3 border-top">
                <label className="ckbox">
                  <input type="checkbox" />
                  <span>Prepare for presentation</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <div className="d-flex p-3 border-top">
                <label className="ckbox">
                  <input type="checkbox" />
                  <span>Prepare for presentation</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <div className="d-flex p-3 border-top">
                <label className="ckbox">
                  <input defaultChecked="" type="checkbox" />
                  <span>System Updated</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <div className="d-flex p-3 border-top">
                <label className="ckbox">
                  <input type="checkbox" />
                  <span>Do something more</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <div className="d-flex p-3 border-top">
                <label className="ckbox">
                  <input type="checkbox" />
                  <span>System Updated</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <div className="d-flex p-3 border-top">
                <label className="ckbox">
                  <input type="checkbox" />
                  <span>Find an Idea</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <div className="d-flex p-3 border-top mb-0">
                <label className="ckbox">
                  <input type="checkbox" />
                  <span>Project review</span>
                </label>
                <span className="ms-auto">
                  <i
                    className="fe fe-edit-2 text-primary me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Edit"
                  />
                  <i
                    className="fe fe-trash-2 text-danger me-2"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-placement="top"
                    data-bs-original-title="Delete"
                  />
                </span>
              </div>
              <h5>Overview</h5>
              <div className="p-3">
                <div className="main-traffic-detail-item">
                  <div>
                    <span>Founder &amp; CEO</span> <span>24</span>
                  </div>
                  <div className="progress">
                    <div
                      aria-valuemax={100}
                      aria-valuemin={0}
                      aria-valuenow={20}
                      className="progress-bar progress-bar-xs wd-20p"
                      role="progressbar"
                    />
                  </div>
                  {/* progress */}
                </div>
                <div className="main-traffic-detail-item">
                  <div>
                    <span>UX Designer</span> <span>1</span>
                  </div>
                  <div className="progress">
                    <div
                      aria-valuemax={100}
                      aria-valuemin={0}
                      aria-valuenow={15}
                      className="progress-bar progress-bar-xs bg-secondary wd-15p"
                      role="progressbar"
                    />
                  </div>
                  {/* progress */}
                </div>
                <div className="main-traffic-detail-item">
                  <div>
                    <span>Recruitment</span> <span>87</span>
                  </div>
                  <div className="progress">
                    <div
                      aria-valuemax={100}
                      aria-valuemin={0}
                      aria-valuenow={45}
                      className="progress-bar progress-bar-xs bg-success wd-45p"
                      role="progressbar"
                    />
                  </div>
                  {/* progress */}
                </div>
                <div className="main-traffic-detail-item">
                  <div>
                    <span>Software Engineer</span> <span>32</span>
                  </div>
                  <div className="progress">
                    <div
                      aria-valuemax={100}
                      aria-valuemin={0}
                      aria-valuenow={25}
                      className="progress-bar progress-bar-xs bg-info wd-25p"
                      role="progressbar"
                    />
                  </div>
                  {/* progress */}
                </div>
                <div className="main-traffic-detail-item">
                  <div>
                    <span>Project Manager</span> <span>32</span>
                  </div>
                  <div className="progress">
                    <div
                      aria-valuemax={100}
                      aria-valuemin={0}
                      aria-valuenow={25}
                      className="progress-bar progress-bar-xs bg-danger wd-25p"
                      role="progressbar"
                    />
                  </div>
                  {/* progress */}
                </div>
              </div>
            </div>
          </div>
          {/* End Sidebar */}
          {/* Large Modal */}
          <div className="modal" id="make-lead">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h6 className="modal-title">Add Incident</h6>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Truck No</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Case Type </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>Overload Challan </option>
                          <option>Accident </option>
                          <option>Theft </option>
                          <option>RTO Dispute</option>
                          <option>Police Check </option>
                          <option>Others</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Incident Location</p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>State </option>
                          <option>City </option>
                          <option>Area </option>
                          <option>Landmark </option>
                          <option>Road Name </option>
                          <option>Address </option>
                          <option>Pin </option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Driver</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <p className="mg-b-5">Comment</p>
                        <textarea
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button className="btn ripple btn-primary" type="button">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*End Large Modal */}
          </div>

          <div className="modal" id="modaldemo-meeting-form">
            <div className="modal-dialog modal-md" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h6 className="modal-title">Meeting Update</h6>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row row-sm">
                      <div className="col-sm-6 form-group">
                        <label className="form-label">Meeting Date</label>
                        <input type="date" className="form-control" />
                      </div>
                      <div className="col-sm-6 form-group">
                        <label className="form-label">Meeting Time</label>
                        <input type="time" className="form-control" />
                      </div>
                      <div className="col-sm-6 form-group">
                        <label className="form-label"> Meeting Type</label>
                        <select className="form-control select select2">
                          <option>Select</option>
                          <option>Online</option>
                          <option>Offline</option>
                        </select>
                      </div>
                      <div className="col-sm-6 form-group">
                        <label className="form-label"> Status</label>
                        <select className="form-control select select2">
                          <option>Select</option>
                          <option>Scheduled</option>
                        </select>
                      </div>
                      <div className="col-sm-12 form-group">
                        <label className="form-label">Meeting Venue</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col-sm-12 form-group">
                        <label className="form-label">Last Call Summary</label>
                        <textarea
                          className="form-control"
                          style={{ height: 60 }}
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-sm-12 ">
                        <label className="ckbox">
                          <input type="checkbox" />
                          <span>Reminder Me</span>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button className="btn ripple btn-primary" type="button">
                    Update Meeting
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*End Large Modal */}

          {/*End Large Modal */}
          {/* Large Modal */}
          <div className="modal" id="add-lead">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h6 className="modal-title">Add Lead</h6>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Source: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>Facebook</option>
                          <option>Instagram</option>
                          <option>News &amp; Press</option>
                          <option>Website</option>
                          <option>Apps</option>
                          <option>Calling</option>
                          <option>Other </option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Type: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>Business Lead </option>
                          <option>LOTS Lead</option>
                        </select>
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Customer Type: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>B2C</option>
                          <option>B2B2B</option>
                          <option>B2B2G</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Type: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>B2C</option>
                          <option>B2B2B</option>
                          <option>B2B2G</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">+ LOTS FOR: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>TRUCK</option>
                          <option>CAR</option>
                          <option>CAB</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">No of Trucks: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>1</option>
                          <option> 1-5</option>
                          <option>5-10</option>
                          <option>10-20</option>
                          <option>20-30</option>
                          <option>30-50</option>
                          <option>50+ </option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Company Alias</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Name</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Email Id</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Phone No.</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5"> I am: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>Truck Owner</option>
                          <option>Fleet Manager</option>
                          <option> Car Owner</option>
                          <option> Car User</option>
                          <option> Car Driver</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-12">
                      <button className="btn ripple btn-primary" type="button">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*End Large Modal */}
          </div>
          {/* End Page */}
          {/* Large Modal */}
          <div className="modal" id="add-bill">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h6 className="modal-title">Bill To</h6>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Company Name</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Contact Email</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Contact Phone</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <p className="mg-b-5">Company Address</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <p className="mg-b-5">Company Website</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <p className="mg-b-5">Upload GST No.</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button className="btn ripple btn-primary" type="button">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*End Large Modal */}
          </div>
          {/* End Page */}
          {/* Large Modal */}
          <div className="modal" id="add-owner-details">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h6 className="modal-title">Add Owner Details</h6>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5"> Same as: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>Fleet Manager</option>
                          <option>New Contact</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Name</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Email Id</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Phone No.</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5"> I am: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>Truck Owner</option>
                          <option>Fleet Manager</option>
                          <option> Car Owner</option>
                          <option> Car User</option>
                          <option> Car Driver</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-12">
                      <button className="btn ripple btn-primary" type="button">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*End Large Modal */}
          </div>
          {/* End Page */}
          {/* Large Modal */}
          <div className="modal" id="add-accounts">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h6 className="modal-title">Add Accounts</h6>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5"> Same as: </p>
                        <select className="form-control select2">
                          <option>Select</option>
                          <option>Fleet Manager</option>
                          <option>Owner</option>
                          <option>New Contact</option>
                        </select>{" "}
                      </div>
                    </div>
                    {/* col-4 */}
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Name</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Email Id</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Phone No.</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button className="btn ripple btn-primary" type="button">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*End Large Modal */}
          </div>
          {/* End Page */}
          {/* Large Modal */}
          <div className="modal" id="add-qoute">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content modal-content-demo">
                <div className="modal-header">
                  <h6 className="modal-title">Add Qoute</h6>
                  <button
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                  />
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Company</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Total Trucks</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Per Truck Cost</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Start Date</p>
                        <input
                          type="date"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">End Date</p>
                        <input
                          type="date"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Grace Period</p>
                        <input
                          type="text"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p className="mg-b-5">Expire Date</p>
                        <input
                          type="date"
                          className="form-control"
                          name="example-text-input"
                          placeholder="Enter"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button className="btn ripple btn-primary" type="button">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a href="#top" id="back-to-top">
            <i className="fe fe-arrow-up" />
          </a>
        </div>
      </div>
    </>
  );
};

export default CaseDetails;
