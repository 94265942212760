import React from "react";
import CompanyDetails from "../../Components/createTicket/CompanyDetails";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CheckBox from "../../Components/createTicket/CheckBox";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import InputField from "../../Components/createTicket/InputField";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import NotesComp from "../../Components/createTicket/NotesComp";
import CreateButton from "../../Components/createTicket/CreateButton";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");
function CreateTicketIssueWithInvoice({
  caseId,
  customerId,
  selectedValue4Id,
  setOpen,
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  const [inputValue, setInputValue] = useState("");

  const checkboxData = [
    { label: "Received Incorrect invoice" },
    { label: "Incorrect/Missing Company Name" },
    { label: "Incorrect/Missing Company Address" },
    { label: "Incorrect/Missing GSTIN" },
    { label: "Other" },
  ];

  const [checked, setChecked] = useState(
    Array(checkboxData.length).fill(false)
  );
  const [checkBoxInputValues, setCheckBoxInputValues] = useState(
    Array(checkboxData.length).fill("")
  );

  const handleCheckboxChange = (index) => {
    const newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
    if (index === 0) {
      const newCheckBoxInputValues = [...checkBoxInputValues];
      newCheckBoxInputValues[0] = newChecked[0] ? checkboxData[0].label : ""; // Set or clear the value
      setCheckBoxInputValues(newCheckBoxInputValues);
    }
  };

  const handleInputChangeCheckBox = (index, event) => {
    const newCheckBoxInputValues = [...checkBoxInputValues];
    newCheckBoxInputValues[index] = event.target.value;
    setCheckBoxInputValues(newCheckBoxInputValues);
  };
  // console.log(checkBoxInputValues);

  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
    // console.log(event.target.value);
  };

  const handleInputValue = (event) => {
    setInputValue(event.target.value);
  };
  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);

    formData.append("uploadImage", selectedImage);

    formData.append("discriptionIssue", description);

    formData.append("invoiceNumber", inputValue);
    formData.append("receivedIncorrectInvoice", checkBoxInputValues[0]);
    formData.append("incorrectMissingCompanyName", checkBoxInputValues[1]);
    formData.append("incorrectMissingCompanyAddress", checkBoxInputValues[2]);
    formData.append("incorrectMissingGSTIN", checkBoxInputValues[3]);
    formData.append("others", checkBoxInputValues[4]);

    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Box>
      <CompanyDetails></CompanyDetails>
      <CheckBox
        handleInputChangeCheckBox={handleInputChangeCheckBox}
        handleCheckboxChange={handleCheckboxChange}
        checkboxData={checkboxData}
        checked={checked}
        checkBoxInputValues={checkBoxInputValues}
      ></CheckBox>
      <DescribeBox
        text={"Describe the Issue (optional):"}
        handleInputValue={handleInputValueDescription}
      ></DescribeBox>
      <Box className="mt-3">
        <InputField
          text={"Enter Invoice Number."}
          handleInputValue={handleInputValue}
          value={inputValue}
        ></InputField>
      </Box>

      <UploadImageBox
        text={"Upload Attachments (optional):"}
        handleFileChange={handleFileChange}
        handleRemoveImage={handleRemoveImage}
        selectedImage={selectedImage}
        selectedImagePreview={selectedImagePreview}
      ></UploadImageBox>
      <NotesComp
        text={"File should be in png, jpg, jpeg format only. Max Size: 2 MB."}
      ></NotesComp>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketIssueWithInvoice;
