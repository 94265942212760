import React from "react";
import { useState, useEffect } from "react";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import { Box } from "@mui/material";
import NotesComp from "../../Components/createTicket/NotesComp";
import RadioButton from "../../Components/createTicket/RadioButton";
import CreateButton from "../../Components/createTicket/CreateButton";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");
function CreateTicketIssueWithEngageService({
  optionData1,
  optionData2,
  optionData3,
  optionData4,
  optionData5,
  selectedValue4Id,
  caseId,
  customerId,
  setOpen,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [radioInput, setRadioInput] = useState([]);
  const [description, setDescription] = useState("");
  const [otherIssueDescribe, setOtherIssueDescribe] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
    // console.log(event.target.value);
  };
  const handleChangeRadio = (event) => {
    setRadioInput(event.target.value);
  };
  const handleOtherIssueDescribe = (event) => {
    setOtherIssueDescribe(event.target.value);
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleChange = (e) => {
    setSelectedOption(e.name);
  };
  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);

    formData.append("uploadImage", selectedImage);

    formData.append("discriptionIssue", description);
    formData.append("otherIssueDescribe", otherIssueDescribe);

    formData.append("selectIssue", selectedOption);
    formData.append("selectChannel", radioInput);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  //console.log(customerId);

  return (
    <Box className={"mt-4"}>
      <p className="mt-2 mb-2 fs-12 fw-bold">
        Please select an option below that best describes your issue:
      </p>
      <SelectDropdown
        dropDownData={
          selectedValue4Id == 32
            ? optionData1
            : selectedValue4Id == 33
            ? optionData3
            : selectedValue4Id == 34
            ? optionData4
            : selectedValue4Id == 35
            ? optionData2
            : optionData5
        }
        handleChange={handleChange}
        selectedData={selectedOption}
        Text={"Select Issue"}
      ></SelectDropdown>
      {`${selectedOption}` == "Others" ? (
        <DescribeBox
          text={"Describe Issue :"}
          selectedOption={selectedOption}
          handleInputValue={handleOtherIssueDescribe}
        ></DescribeBox>
      ) : (
        ""
      )}
      {selectedValue4Id == 35 && (
        <RadioButton
          toggle={selectedValue4Id}
          handleChange={handleChangeRadio}
        ></RadioButton>
      )}
      <DescribeBox
        text={"Describe the Issue: *"}
        handleInputValue={handleInputValueDescription}
      ></DescribeBox>
      <UploadImageBox
        text={"Upload Attachment (optional):"}
        handleFileChange={handleFileChange}
        handleRemoveImage={handleRemoveImage}
        selectedImage={selectedImage}
        selectedImagePreview={selectedImagePreview}
      ></UploadImageBox>
      <NotesComp
        text={"File should be in png, jpg, jpeg format only. Max Size: 2 MB."}
      ></NotesComp>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketIssueWithEngageService;
