import React, { useState, useEffect } from "react";
import Prince from "../Components/Prince";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopHeader from "../Components/TopHeader";
import { toast } from "react-toastify";

function EditCase() {
  const cid = useParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");

  useEffect(() => {
    const fetchCase = async () => {
      try {
        let result = await fetch(
          `${apiUrl}/caseMaster/getCaseType/${cid.empid}`,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        result = await result.json();

        const { data } = result;

        setId(data.id);
        setName(data.name);
      } catch (e) {
        console.log(e);
      }
    };
    fetchCase();
  }, [cid]);

  async function handleUpdate() {
    try {
      const caseData = {
        name,
      };
      let response = await fetch(
        `${apiUrl}/caseMaster/updateCaseType/${cid.empid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify(caseData),
        }
      );

      response = await response.json();

      if (response.status === "success") {
        toast.success("Case Updated Successfully");
        navigate("/case-type");
      } else {
        toast.error("Master Update Failed");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />

        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Add Case type
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Internal Case </a>
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-primary my-2 btn-icon-text"
                    >
                      <i className="fe fe-download-cloud me-2" /> Print
                    </button>
                  </div>
                </div>
              </div>

              <div className="row row-sm">
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3"></h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Case</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="col-lg-4 form-group">
                          <div className="input-group"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row row-sm">
                <div className="col-12 mb-3">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCase;
