import React, { useEffect, useState } from "react";
import axios from "axios";
import TabComponent from "./TabComponent";
import TableComponent from "./TableComponent";
//import { useParams } from "react-router-dom";
import Loader from "../../assets/img/loader.gif";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function TabTableComponent({
  filterByObj,
  currentPage,
  pageSize,
  bulkAssignId,
  setBulkAssignId,
  handleWonLead,
  setLeadId,
  setModalOpen,
  setModalOpen2,
  setModalOpen3,
  setModalOpen4,
  handlePageChange,
  handlePageSize,
  setAssignedLeadId,
  handleSetEditLead,
  setStageStatus,
  setStatusModalOpen,
  handleLeadStatus,
  setNewStatusLeadId,
  userType,
  status,
  stage,
  applyFilter,
  setLoader,
  loader,
}) {
  const [filteredUsers, setFilterUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [linkActive, setLinkActive] = useState("");
  // const [loader, setLoader] = useState(false);
  const [countWon, setCountWon] = useState(0);
  const [countintergrationInPro, setCountintergrationInPro] = useState(0);
  const [countnegotiation, setCountnegotiation] = useState(0);
  const [countLOST, setCountLOST] = useState(0);
  const [countconatactInFuture, setCountconatactInFuture] = useState(0);
  const [countCallAttempted, setCountCallAttempted] = useState(0);
  const [countproposal, setCountproposal] = useState(0);
  const [countFollowup, setCountFollowup] = useState(0);
  const [countNew, setCountNew] = useState(0);
  const [countOpen, setCountOpen] = useState(0);
  //console.log(stage);
  // console.log(filterByObj);
  // const handleApplyFilter = () => {
  //   handleStatusChange(linkActive);
  // };
  // console.log(status === "Open");
  const handleStatusChange = async (stage) => {
    if (stage) {
      //  console.log(stage);
      stage === "" && setLoader(true);
      // console.log(stage);
      // console.log(status);
      // setLoader(true);
      const url =
        stage === "Open" ||
        stage === "Won" ||
        stage === "Lost" ||
        stage === "All-leads"
          ? `${apiUrl}/lead/getAllMasterLeads/${stage}?leadSource=${filterByObj.leadSource}&leadPipeline=${filterByObj.leadPipeline}&businessCategory=${filterByObj.businessCategory}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&to=${filterByObj.to}&from=${filterByObj.from}&search=${filterByObj.search}&isKycDone=${filterByObj.isKycDone}&minimumExpectedShipmentToFship=${filterByObj.minimumExpectedShipmentToFship}&employeeId=${filterByObj.employeeId}&genericEmailPattern=${filterByObj.genericEmailPattern}&page=${currentPage}&pageSize=${pageSize}`
          : `${apiUrl}/lead/getAllLead/${stage}?leadSource=${filterByObj.leadSource}&leadPipeline=${filterByObj.leadPipeline}&businessCategory=${filterByObj.businessCategory}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&to=${filterByObj.to}&from=${filterByObj.from}&search=${filterByObj.search}&isKycDone=${filterByObj.isKycDone}&minimumExpectedShipmentToFship=${filterByObj.minimumExpectedShipmentToFship}&employeeId=${filterByObj.employeeId}&genericEmailPattern=${filterByObj.genericEmailPattern}&page=${currentPage}&pageSize=${pageSize}`;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        });

        const data = response.data;

        if (data.status === "success" && Array.isArray(data.data)) {
          setLinkActive(stage);
          setFilterUsers(data.data); // Set the leads data
          setTotalPages(Math.ceil(data.totalCount / pageSize)); // Set total pages
          setLoader(false);
          setCountCallAttempted(data.callAttemted);
          setCountFollowup(data.Followup);
          setCountLOST(data.LOST);
          setCountNew(data.New);
          setCountWon(data.won); // setLoaded(true);
          setCountconatactInFuture(data.conatactInFuture);
          setCountintergrationInPro(data.intergrationInPro);
          setCountnegotiation(data.negotiation);
          setCountproposal(data.proposal);
          setCountOpen(data.Open);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    }
  };
  useEffect(() => {
    if (filterByObj.search) {
      handleStatusChange("Open");
    } else {
      handleStatusChange(linkActive);
    }
  }, [
    pageSize,
    currentPage,
    applyFilter,
    filterByObj.search,
    filterByObj.genericEmailPattern,
  ]);

  useEffect(() => {
    if (stage) {
      handleStatusChange(stage);
    } else {
      handleStatusChange(status);
    }
  }, [status, stage]);
  // console.log(status);
  //console.log(bulkAssignId);
  return (
    <div className="col-lg-12">
      <div className="card custom-card">
        <div className="card-body">
          <div className="text-wrap">
            <div className="panel panel-primary tabs-style-3 p-0 tabs-style-3-0">
              <div className="tab-menu-heading">
                <div className="tabs-menu">
                  {/* Pass handleStatusChange and other props to TabComponent */}
                  <TabComponent
                    filterByObj={filterByObj}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    handleStatusChange={handleStatusChange}
                    linkActive={linkActive}
                    countCallAttempted={countCallAttempted}
                    countFollowup={countFollowup}
                    countLOST={countLOST}
                    countNew={countNew}
                    countconatactInFuture={countconatactInFuture}
                    countintergrationInPro={countintergrationInPro}
                    countproposal={countproposal}
                    countWon={countWon}
                    countOpen={countOpen}
                    countnegotiation={countnegotiation}
                    status={status}
                    setLoader={setLoader}
                    loader={loader}
                  />
                  {bulkAssignId.length ? (
                    <a
                      // href=""
                      className="btn ripple btn-info btn-sm m-2 text-center"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      Bulk Assign
                    </a>
                  ) : (
                    <></>
                  )}
                  {bulkAssignId.length ? (
                    <a
                      // href=""
                      className="btn ripple btn-danger btn-sm m-2 text-center"
                      onClick={() => {
                        setModalOpen2(true);
                      }}
                    >
                      Bulk Source
                    </a>
                  ) : (
                    <></>
                  )}
                  {bulkAssignId.length ? (
                    <a
                      // href=""
                      className="btn ripple btn-success btn-sm m-2 text-center"
                      onClick={() => {
                        setModalOpen3(true);
                      }}
                    >
                      Bulk Stage
                    </a>
                  ) : (
                    <></>
                  )}
                  {bulkAssignId.length ? (
                    <a
                      // href=""
                      className="btn ripple btn-warning btn-sm m-2 text-center"
                      onClick={() => {
                        setModalOpen4(true);
                      }}
                    >
                      Bulk Pipeline
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pass the filtered users to TableComponent */}
      <TableComponent
        userType={userType}
        filteredUsers={filteredUsers}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
        pageSize={pageSize}
        currentPage={currentPage}
        loader={loader}
        handlePageSize={handlePageSize}
        setModalOpen={setModalOpen}
        setAssignedLeadId={setAssignedLeadId}
        handleSetEditLead={handleSetEditLead}
        setStageStatus={setStageStatus}
        setNewStatusLeadId={setNewStatusLeadId}
        setStatusModalOpen={setStatusModalOpen}
        handleLeadStatus={handleLeadStatus}
        setBulkAssignId={setBulkAssignId}
        bulkAssignId={bulkAssignId}
        handleWonLead={handleWonLead}
        setLeadId={setLeadId}
      />
      {loader ? (
        <>
          {" "}
          <div className="loading-overlay">
            <img src={Loader} alt="Loading..." />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TabTableComponent;
