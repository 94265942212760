import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MainPage from "./MainPage";
import Prince from "./Prince";
import DropdownMenu from "./DropdownMenu";
import TopHeader from "./TopHeader";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const Content2 = () => {
  const { empid } = useParams();
  const navigate = useNavigate();
  const condition = true;
  const initialFormData = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobileNumber: "",
    gender: "",
    dob: "",
    userType: "",
    kamType: "",
    userRole: "",
    currentAddressCountry: "",
    currentAddressState: "",
    currentAddressCity: "",
    currentAddressArea: "",
    currentAddressLane: "",
    currentAddressPinCode: "",
    sameAsCurrentAddress: false,
    permanentAddressCountry: "",
    permanentAddressState: "",
    permanentAddressCity: "",
    permanentAddressArea: "",
    permanentAddressLane: "",
    permanentAddressPinCode: "",
    companyName: "",
    department: "",
    designation: "",
    dateOfJoin: "",
    reportingBossA: "",
    reportingBossB: "",
    permissions: "",
    adharNumber: "",
    panImage: "",
    panNumber: "",
    accountNumber: "",
    accountName: "",
    bankName: "",
    ifsc: "",
    offeredPackage: "",
    offeredCTC: "",
    noticePeriod: "",
    workingShift: "",
    perHourCharges: "",
    perCasesCharges: "",
    perMonthCharges: "",
    consultingFees: "",
    profilePic: "",
    shipmentPotential: [],
    leadSource: [],
    businessCategory: [],
    clientPersona: [],
    state: [],

    // Add other fields as needed
  };
  const [multiSelect4, setMultiSelect4] = useState([]);
  const [multiSelect3, setMultiSelect3] = useState([]);
  const [multiSelect2, setMultiSelect2] = useState([]);
  const [multiSelect1, setMultiSelect1] = useState([]);
  const [multiSelect, setMultiSelect] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [stateOptions, setStateOptions] = useState([]);
  const [genderOption, setGenderOption] = useState([]);
  const [genderOptions2, setGenderOptions2] = useState([]);
  const [genderOptions3, setGenderOptions3] = useState([]);
  const [options, setOptions] = useState([]);
  const [optio, setOptio] = useState([]);
  const [genderOptions4, setGenderOptions4] = useState([]);
  const [genderOptions5, setGenderOptions5] = useState([]);
  const apiUrl = process.env.REACT_APP_URL;
  const [profilePicFile, setProfilePicFile] = useState("");
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [clientPersonaOptions, setClientPersonaOptions] = useState([]);
  const [state, setState] = useState([]);
  const [errors, setErrors] = useState("");
  const [userTypeForInput, setUserTypeForInput] = useState("");
  const [userTypeOption, setUserTypeOption] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [kamType, setKamType] = useState([]);

  const handleUserTypeChange = (event) => {
    const userType = event.target.value;

    setFormData((prevState) => ({
      ...prevState,
      userType: userType,
    }));
  };
  const handleUserRoleChange = (event) => {
    const userRole = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      userRole: userRole,
    }));
    setUserTypeForInput(userRole);
  };
  const handleKamTypeChange = (event) => {
    const kamType = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      kamType: kamType,
    }));
    // setUserTypeForInput(userType);
  };

  const Token = localStorage.getItem("Token");
  // console.log("Token:", Token);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getCurrentDate = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };

  //Gender
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/9`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const url = `${apiUrl}/employee/employee/${empid}`;
        let result = await fetch(url, {
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
          },
        });

        result = await result.json();
        const { data } = result;
        const stateData = JSON.parse(data.state);
        const shipmentPotentialData = JSON.parse(data.shipmentPotential);
        const businessCategoryData = JSON.parse(data.businessCategory);
        const businessCategoryDatas = JSON.parse(data.clientPersona);
        const leadSources = JSON.parse(data.leadSource);

        // Update the state with fetched data
        setFormData({
          // id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          mobileNumber: data.mobileNumber,
          gender: data.gender,
          dob: data.dob,
          userType: data.userType,
          userRole: data.userRole,
          kamType: data.kamType,
          currentAddressCountry: data.currentAddressCountry,
          currentAddressState: data.currentAddressState,
          currentAddressArea: data.currentAddressArea,
          currentAddressLane: data.currentAddressLane,
          currentAddressPinCode: data.currentAddressPinCode,
          permanentAddressCountry: data.permanentAddressCountry,
          permanentAddressArea: data.permanentAddressArea,
          permanentAddressLane: data.permanentAddressLane,
          permanentAddressPinCode: data.permanentAddressPinCode,
          companyName: data.companyName,
          department: data.department,
          designation: data.designation,
          dateOfJoin: data.dateOfJoin,
          reportingBossA: data.reportingBossA,
          reportingBossB: data.reportingBossB,
          permissions: data.permissions,
          adharNumber: data.adharNumber,
          panNumber: data.panNumber,
          accountNumber: data.accountNumber,
          accountName: data.accountName,
          bankName: data.bankName,
          ifsc: data.ifsc,
          offeredPackage: data.offeredPackage,
          offeredCTC: data.offeredCTC,
          noticePeriod: data.noticePeriod,
          workingShift: data.workingShift,
          perHourCharges: data.perHourCharges,
          perCasesCharges: data.perCasesCharges,
          perMonthCharges: data.perMonthCharges,
          consultingFees: data.consultingFees,
          profilePic: data.profilePic,
          shipmentPotential: shipmentPotentialData,
          leadSource: leadSources,
          businessCategory: businessCategoryData,
          clientPersona: businessCategoryDatas,
          state: stateData,
          // Add other fields if necessary
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUser();
  }, [apiUrl, empid]);
  // console.log(formData);
  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const profilePicFile = files[0];

      if (profilePicFile.type.startsWith("image/")) {
        setProfilePicFile(profilePicFile);
        setFormData((prevData) => ({
          ...prevData,
          profilePic: profilePicFile,
        }));
      } else if (profilePicFile.type === "application/pdf") {
        // Assuming other file inputs exist for these file types
        setFormData((prevData) => ({
          ...prevData,
          adharImage: profilePicFile,
          panImage: profilePicFile,
          drivingLicenseImage: profilePicFile,
          offerLetterUpload: profilePicFile,
          chequeImage: profilePicFile,
        }));
      } else {
        console.log("Unsupported file type");
      }
    } else {
      console.log("No file selected");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await fetch(
        `${apiUrl}/employee/employeeUpdate/${empid}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${Token}`,
          },
          body: formDataToSend,
        }
      );

      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);

      navigate("/MainPage2");
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/10`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setUserTypeOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch countries and populate the countryOptions
    fetch(`${apiUrl}/employee/allStates/101`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCountryOptions(data.data);
        } else {
          console.error(
            "API response is not in the expected format for countries."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });
  }, []);

  useEffect(() => {
    if (countryId) {
      // Fetch states based on the selected country
      fetchStates(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId) {
      // Fetch cities based on the selected state
      fetchCities(stateId);
    }
  }, [stateId]);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/19`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setUserRole(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  // kamtype
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/20`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setKamType(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const fetchStates = (countryId) => {
    fetch(`${apiUrl}/employee/allStates/${countryId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setStateOptions(data.data);
        } else {
          console.error(
            "API response is not in the expected format for states."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching state data:", error);
      });
  };

  const fetchCities = (stateId) => {
    fetch(`${apiUrl}/employee/allcities/${stateId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setCityOptions(data.data);
        } else {
          console.error(
            "API response is not in the expected format for cities."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching city data:", error);
      });
  };

  //BUSINESS CATEGORY
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/3`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setBusinessCategoryOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleChange4 = (event) => {
    const { name, value } = event.target;
    multiSelect4.push(value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: multiSelect4,
    }));

    // setCountryId(multiSelect);
    // setFormData({
    //   ...formData,
    //   state: multiSelect,
    // });

    const newErrors = { ...errors };
    setErrors(newErrors);
  };

  //CLIENT PERSONA
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/5`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setClientPersonaOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleChange3 = (event) => {
    const { name, value } = event.target;
    multiSelect3.push(value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: multiSelect3,
    }));
    const newErrors = { ...errors };
    setErrors(newErrors);
  };

  //SHIPMENT POTENTIAL
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    multiSelect2.push(value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: multiSelect2,
    }));
    const newErrors = { ...errors };
    setErrors(newErrors);
  };

  //LEAD SOURCE
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  // console.log(leadSourceOptions);

  const handleChangeLead = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      leadSource: typeof value === "string" ? value.split(",") : value,
    });
  };
  const handleChangeClient = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      clientPersona: typeof value === "string" ? value.split(",") : value,
    });
  };
  const handleChangeBussines = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      businessCategory: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleChangeShipment = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      shipmentPotential: typeof value === "string" ? value.split(",") : value,
    });
  };
  const handleChangeState = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      state: typeof value === "string" ? value.split(",") : value,
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: multiSelect2,
    }));
    const newErrors = { ...errors };
    setErrors(newErrors);
  };

  const validateForm = () => {};
  const nameRegex = /^[A-Za-z]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const mobileRegex = /^[0-9]{10}$/;

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    //  console.log(arrayData);
    multiSelect.push(selectedCountry);

    setCountryId(multiSelect);
    //  const multiData = multiSelect;
    //  console.log(arrayData)

    setFormData({
      ...formData,
      state: Array.from(multiSelect),
    });

    setStateOptions([]); // Clear state options
    setCityOptions([]); // Clear city options
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setStateId(selectedState);
    setFormData({
      ...formData,
      currentAddressState: selectedState,
    });
    setCityOptions([]); // Clear city options
  };
  const handleCityeChange = (event) => {
    const selectedState = event.target.value;
    setStateId(selectedState);
    setFormData({
      ...formData,
      currentAddressCity: selectedState,
    });
    setCityOptions([]); // Clear city options
  };

  //departments
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/7`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions2(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  // Designation
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/6`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions3(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  //Boss a

  useEffect(() => {
    const Token = localStorage.getItem("Token");
    // console.log("Token:", Token);
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/employee/allEmpDesig`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  //Boss b
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/employee/allEmpDesig`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setOptio(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const getCurrentDate2 = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/12`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions4(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  //working shift
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/13`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setGenderOptions5(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  return (
    <>
      {/* Main Header*/}
      <div className="page">
        <TopHeader />
        <Prince />
        {/* End Sidemenu */}
        {/* Main Content*/}
        <div className="main-content pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Update Employee
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"></li>
                  </ol>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-4">
                          PROFILE PHOTO
                        </h6>
                      </div>

                      <div className="">
                        <div className="row row-sm">
                          <div className="col-sm-12 col-md-12">
                            <input
                              type="file"
                              className="dropify"
                              data-height={200}
                              accept="image/jpg*"
                              name="profilePic"
                              onChange={handleFileChange}
                            />
                            {profilePicFile && (
                              <div
                                style={{
                                  border: "2px solid #FFFF80",
                                  padding: "30px",
                                  margin: "30px",
                                  borderRadius: "5px",
                                }}
                              >
                                <img
                                  src={URL.createObjectURL(profilePicFile)}
                                  alt="Selected File"
                                  style={{ maxWidth: "350px" }}
                                />
                              </div>
                            )}{" "}
                            {profilePicFile === null && (
                              <div
                                style={{
                                  border: "2px solid #FFFF80",
                                  padding: "15px",
                                  margin: "30px",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <img
                                  src={formData.profilePic}
                                  alt="Selected File"
                                  style={{ maxWidth: "300px" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">BASIC INFO</h6>
                      </div>
                      <form
                        action="form-validation.html"
                        data-parsley-validate=""
                      >
                        <div className="">
                          <div className="row row-sm">
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                First name: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                value={formData.firstName || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    firstName: e.target.value,
                                  })
                                }
                                placeholder="Enter first name"
                                required=""
                                type="name"
                              />
                            </div>
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                Last name: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                value={formData.lastName || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    lastName: e.target.value,
                                  })
                                }
                                placeholder="Enter last name"
                                required=""
                                type="text"
                              />
                            </div>
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                Email ID: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                value={formData.email || ""}
                                placeholder="Enter email"
                                required=""
                                type="text"
                                readOnly={true}
                              />
                            </div>
                            <div className="col-lg-6 form-group">
                              <label className="form-label">
                                Mobile No: <span className="tx-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                placeholder="Enter mobile number"
                                required=""
                                type="text"
                                value={formData.mobileNumber || ""}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label className="form-label">
                                Gender: <span className="tx-danger">*</span>
                              </label>
                              <select
                                className="form-control select2"
                                value={formData.gender || ""}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    gender: e.target.value,
                                  })
                                }
                                multiple={false}
                              >
                                {genderOption.map((option, index) => (
                                  <option key={option.id} value={option.name}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* col-4 */}
                            <div className="col-lg-6">
                              <label className="form-label">
                                Date of Birth:{" "}
                                <span className="tx-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="date"
                                  className="form-control"
                                  value={formData.dob || ""}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      dob: e.target.value,
                                    })
                                  }
                                  max={getCurrentDate()}
                                  // Restricts selection to current date or earlier
                                />
                              </div>
                            </div>
                            {/* Type of User */}
                            <div className="col-lg-6 mt-3">
                              <label className="form-label">
                                User Type : <span className="tx-danger">*</span>
                              </label>
                              <div className="input-group">
                                <select
                                  name="userType"
                                  className="form-control form-select select2"
                                  value={formData.userType || ""}
                                  onChange={handleUserTypeChange}
                                  // multiple
                                  multiple={false}
                                >
                                  <option value="">Select</option>
                                  {userTypeOption.map((data, index) => {
                                    return (
                                      <option key={index} value={data.name}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div
                              className="col-lg-6"
                              style={{ marginTop: "15px" }}
                            >
                              <label className="form-label">User Role</label>
                              <div className="input-group">
                                <select
                                  name="userRole"
                                  className="form-control form-select select2"
                                  value={
                                    formData.userRole === null
                                      ? ""
                                      : formData.userRole
                                  }
                                  onChange={handleUserRoleChange}
                                  // multiple
                                  multiple={false}
                                >
                                  <option value="">Select</option>
                                  {userRole.map((data, index) => {
                                    return (
                                      <option value={data.name} key={index}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div
                              className="col-lg-6"
                              style={{ marginTop: "15px" }}
                            >
                              <label className="form-label">Kam Type</label>
                              <div className="input-group">
                                <select
                                  name="kamType"
                                  className="form-control form-select select2"
                                  value={formData.kamType || ""}
                                  onChange={handleKamTypeChange}
                                  // multiple
                                  multiple={false}
                                >
                                  <option value="">Select</option>
                                  {kamType.map((data, index) => {
                                    return (
                                      <option value={data.name} key={index}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm d-none">
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">
                          Current Address
                        </h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-6 mg-t-10">
                          <label className="form-label">State</label>
                          <div style={{ overflowX: "auto" }}>
                            {" "}
                            {/* Wrap the select with a div for horizontal scrolling */}
                            <select
                              className="form-control select select2"
                              name="state"
                              value={formData.state || ""}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  state: e.target.value,
                                });
                                handleStateChange(e);
                              }}
                              multiple={true}
                            >
                              <option value="">Select State</option>
                              {countryOptions.map((country) => (
                                <option key={country.id} value={country.id}>
                                  {`${country.name}`}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Permanent Address */}
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-1">
                          Permanent Address
                        </h6>
                      </div>
                      <div className="row row-sm"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">ONBOARDING</h6>
                      </div>
                      <div className="row row-sm ">
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Department</label>

                          <select
                            className="form-control select2"
                            value={formData.department || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                department: e.target.value,
                              })
                            }
                            multiple={false}
                          >
                            <option>Select</option>
                            {genderOptions2.map((option, index) => (
                              <option key={option.id} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Designation</label>
                          <select
                            className="form-control select2"
                            value={formData.designation || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                designation: e.target.value,
                              })
                            }
                            multiple={false}
                          >
                            <option>Select</option>
                            {genderOptions3.map((option, index) => (
                              <option key={option.id} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-4 form-group">
                          <label className="form-label">
                            Date of Joining:{" "}
                            <span className="tx-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="date"
                              value={formData.dateOfJoin || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  dateOfJoin: e.target.value,
                                })
                              }
                              className="form-control"
                              max={getCurrentDate2()} // Restricts selection to current date or earlier
                            />
                          </div>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Reporting Boss-A</label>
                          <select
                            className="form-control select2"
                            value={formData.reportingBossA || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                reportingBossA: e.target.value,
                              })
                            }
                            multiple={false}
                          >
                            <option>Select</option>
                            {options.map((option, index) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Reporting Boss-B</label>
                          <select
                            className="form-control select2"
                            value={formData.reportingBossB || ""}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                reportingBossB: e.target.value,
                              })
                            }
                            multiple={false}
                          >
                            <option>Select</option>
                            {optio.map((option, index) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          className="col-sm-4 form-group"
                          style={{ marginTop: "25px" }}
                        >
                          <FormControl size="small" sx={{ width: 473 }}>
                            {/* <label className="form-label"></label> */}
                            <InputLabel id="lead-source-label">
                              Lead Source
                            </InputLabel>
                            <Select
                              labelId="lead-source-label"
                              id="leadSourceDropdown"
                              multiple={true}
                              value={formData.leadSource || ""}
                              onChange={handleChangeLead}
                              input={<OutlinedInput label="Lead Source" />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Set the maximum height of the list
                                    width: 473,
                                  },
                                },
                              }}
                            >
                              {leadSourceOptions.map((data) => (
                                <MenuItem
                                  key={data.name}
                                  value={data.name || ""}
                                  sx={{
                                    fontSize: "14px",
                                    padding: 0,
                                  }}
                                >
                                  <Checkbox
                                    checked={formData.leadSource.includes(
                                      data.name
                                    )}
                                    sx={{ padding: 0, transform: "scale(0.8)" }}
                                  />
                                  <ListItemText primary={data.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-sm-4 form-group">
                          <FormControl size="small" sx={{ width: 473 }}>
                            <InputLabel id="client-persona-label">
                              Client Persona
                            </InputLabel>
                            <Select
                              labelId="client-persona-label"
                              id="clientPersonaDropdown"
                              multiple={true}
                              size="small"
                              value={formData.clientPersona || ""}
                              onChange={handleChangeClient}
                              input={<OutlinedInput label="Client Persona" />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Set the maximum height of the list
                                    width: 473,
                                  },
                                },
                              }}
                            >
                              {clientPersonaOptions.map((data) => (
                                <MenuItem
                                  key={data.name}
                                  value={data.name || ""}
                                  sx={{
                                    fontSize: "14px",
                                    padding: 0,
                                    // borderBottom: "1px solid #808080",
                                  }}
                                >
                                  <Checkbox
                                    checked={formData.clientPersona.includes(
                                      data.name
                                    )}
                                    sx={{ padding: 0, transform: "scale(0.8)" }}
                                  />
                                  <ListItemText primary={data.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-sm-4 form-group">
                          <FormControl size="small" sx={{ width: 473 }}>
                            <InputLabel id="business-category-label">
                              Business Category
                            </InputLabel>
                            <Select
                              labelId="business-category-label"
                              id="businessCategoryDropdown"
                              multiple={true}
                              value={formData.businessCategory || ""}
                              onChange={handleChangeBussines}
                              input={
                                <OutlinedInput label="Business Category" />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Set the maximum height of the list
                                    width: 473,
                                  },
                                },
                              }}
                            >
                              {businessCategoryOptions.map((data) => (
                                <MenuItem
                                  key={data.name}
                                  value={data.name || ""}
                                  sx={{
                                    fontSize: "14px",
                                    padding: 0,
                                  }}
                                >
                                  <Checkbox
                                    checked={formData.businessCategory.includes(
                                      data.name
                                    )}
                                    sx={{ padding: 0, transform: "scale(0.8)" }}
                                  />
                                  <ListItemText primary={data.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-sm-4 form-group">
                          <FormControl size="small" sx={{ width: 473 }}>
                            <InputLabel id="shipment-potential-label">
                              Shipment Potential
                            </InputLabel>
                            <Select
                              labelId="shipment-potential-label"
                              id="shipmentPotentialDropdown"
                              multiple={true}
                              value={formData.shipmentPotential || ""}
                              onChange={handleChangeShipment}
                              input={
                                <OutlinedInput label="Shipment Potential" />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Set the maximum height of the list
                                    width: 473,
                                  },
                                },
                              }}
                            >
                              {shipmentPotentialOptions.map((data) => (
                                <MenuItem
                                  key={data.name}
                                  value={data.name || ""}
                                  sx={{
                                    fontSize: "14px",
                                    padding: 0,
                                  }}
                                >
                                  <Checkbox
                                    checked={formData.shipmentPotential.includes(
                                      data.name
                                    )}
                                    sx={{ padding: 0, transform: "scale(0.8)" }}
                                  />
                                  <ListItemText primary={data.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <div className="col-sm-3 mg-t-10">
                          <FormControl size="small" sx={{ width: 473 }}>
                            <InputLabel id="state-label">State</InputLabel>
                            <Select
                              labelId="state-label"
                              id="stateDropdown"
                              multiple={true}
                              value={formData.state || ""}
                              onChange={handleChangeState}
                              input={<OutlinedInput label="State" />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Set the maximum height of the list
                                    width: 473,
                                  },
                                },
                              }}
                            >
                              {countryOptions.map((country) => (
                                <MenuItem
                                  key={country.name}
                                  value={country.name || ""}
                                  sx={{
                                    fontSize: "14px",
                                    padding: 0,
                                  }}
                                >
                                  <Checkbox
                                    checked={formData.state.includes(
                                      country.name
                                    )}
                                    sx={{ padding: 0, transform: "scale(0.8)" }}
                                  />
                                  <ListItemText primary={country.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm d-none">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label  mb-3">KYC</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-3 ">
                          <div className="form-group">
                            <label className="form-label">Aadhaar Upload</label>
                            <input
                              className="form-control"
                              required=""
                              type="file"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label className="form-label">Aadhaar No</label>
                            <input
                              className="form-control"
                              required=""
                              type="text"
                              value={formData.adharNumber || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  adharNumber: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-sm-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm d-none">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label  mb-3">
                          Account Details
                        </h6>
                      </div>
                      <div className="row row-sm"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              {/* Row */}
              <div className="row row-sm d-none">
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">OFFER</h6>
                      </div>
                      <div className="row row-sm ">
                        <div className="col-sm-8">
                          <div className="row"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
              <div className="row row-sm">
                <div className="col-12 mb-3 " style={{ marginLeft: "15px" }}>
                  <button
                    className="btn btn-outline-danger"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Main Content*/}
        {/* Main Footer*/}
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">Fship</a>. Developed by{" "}
                  <a href="http://fship.in/">FSHIP</a> All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*End Footer*/}
        {/* Sidebar */}
        <div className="sidebar sidebar-right sidebar-animate">
          <div className="sidebar-icon">
            <a
              href="#"
              className="text-end float-end text-dark fs-20"
              data-bs-toggle="sidebar-right"
              data-bs-target=".sidebar-right"
            >
              <i className="fe fe-x" />
            </a>
          </div>
          <div className="sidebar-body">
            <h5>Todo</h5>
            <div className="d-flex p-3">
              <label className="ckbox">
                <input defaultChecked="" type="checkbox" />
                <span>Hangout With friends</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <div className="d-flex p-3 border-top">
              <label className="ckbox">
                <input type="checkbox" />
                <span>Prepare for presentation</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <div className="d-flex p-3 border-top">
              <label className="ckbox">
                <input type="checkbox" />
                <span>Prepare for presentation</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <div className="d-flex p-3 border-top">
              <label className="ckbox">
                <input defaultChecked="" type="checkbox" />
                <span>System Updated</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <div className="d-flex p-3 border-top">
              <label className="ckbox">
                <input type="checkbox" />
                <span>Do something more</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <div className="d-flex p-3 border-top">
              <label className="ckbox">
                <input type="checkbox" />
                <span>System Updated</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <div className="d-flex p-3 border-top">
              <label className="ckbox">
                <input type="checkbox" />
                <span>Find an Idea</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <div className="d-flex p-3 border-top mb-0">
              <label className="ckbox">
                <input type="checkbox" />
                <span>Project review</span>
              </label>
              <span className="ms-auto">
                <i
                  className="fe fe-edit-2 text-primary me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Edit"
                />
                <i
                  className="fe fe-trash-2 text-danger me-2"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-placement="top"
                  data-bs-original-title="Delete"
                />
              </span>
            </div>
            <h5>Overview</h5>
            <div className="p-4">
              <div className="main-traffic-detail-item">
                <div>
                  <span>Founder &amp; CEO</span> <span>24</span>
                </div>
                <div className="progress">
                  <div
                    aria-valuemax={100}
                    aria-valuemin={0}
                    aria-valuenow={20}
                    className="progress-bar progress-bar-xs wd-20p"
                    role="progressbar"
                  />
                </div>
                {/* progress */}
              </div>
              <div className="main-traffic-detail-item">
                <div>
                  <span>UX Designer</span> <span>1</span>
                </div>
                <div className="progress">
                  <div
                    aria-valuemax={100}
                    aria-valuemin={0}
                    aria-valuenow={15}
                    className="progress-bar progress-bar-xs bg-secondary wd-15p"
                    role="progressbar"
                  />
                </div>
                {/* progress */}
              </div>
              <div className="main-traffic-detail-item">
                <div>
                  <span>Recruitment</span> <span>87</span>
                </div>
                <div className="progress">
                  <div
                    aria-valuemax={100}
                    aria-valuemin={0}
                    aria-valuenow={45}
                    className="progress-bar progress-bar-xs bg-success wd-45p"
                    role="progressbar"
                  />
                </div>
                {/* progress */}
              </div>
              <div className="main-traffic-detail-item">
                <div>
                  <span>Software Engineer</span> <span>32</span>
                </div>
                <div className="progress">
                  <div
                    aria-valuemax={100}
                    aria-valuemin={0}
                    aria-valuenow={25}
                    className="progress-bar progress-bar-xs bg-info wd-25p"
                    role="progressbar"
                  />
                </div>
                {/* progress */}
              </div>
              <div className="main-traffic-detail-item">
                <div>
                  <span>Project Manager</span> <span>32</span>
                </div>
                <div className="progress">
                  <div
                    aria-valuemax={100}
                    aria-valuemin={0}
                    aria-valuenow={25}
                    className="progress-bar progress-bar-xs bg-danger wd-25p"
                    role="progressbar"
                  />
                </div>
                {/* progress */}
              </div>
            </div>
          </div>
        </div>
        {/* End Sidebar */}
      </div>
    </>
  );
};

export default Content2;
