import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataContext } from "../context/DataContext";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setToken } = useContext(DataContext);

  const navigate = useNavigate();
  const notify = () => toast("Login successful ");

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }
    const apiUrl = process.env.REACT_APP_URL;
    // console.log(apiUrl);

    try {
      const response = await fetch(`${apiUrl}/employee/Employeelogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const responseData = await response.json();

      if (responseData.status === "success") {
        // console.log("Login successful");

        toast.success("Login successful");
        localStorage.setItem("Token", responseData.data.Token);

        // Navigate to the next page
        setToken(responseData.data.Token);
        navigate("/Dashboard");
      } else {
        throw new Error(responseData.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="page main-signin-wrapper">
      <div className="row signpages text-center">
        <div className="col-md-12">
          <div className="card">
            <div className="row row-sm">
              <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-white">
                <div className="mt-5 pt-4 p-2 pos-absolute">
                  <img
                    src={logo}
                    className="d-lg-none header-brand-img text-start float-start mb-4 error-logo-light"
                    alt="logo"
                  />
                  <div className="clearfix"></div>
                  <img
                    src={logo}
                    className=" mb-0"
                    alt="user"
                    style={{ width: "50%" }}
                  />
                  <h5 className="mt-4 txt-blue">Create Your Account</h5>
                  <span className="txt-blue tx-13 mb-5 mt-xl-0">
                    Signup to create, discover, and connect with the global
                    community
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form">
                <div className="main-container container-fluid">
                  <div className="row row-sm">
                    <div className="card-body mt-2 mb-2">
                      <form onSubmit={handleLogin}>
                        <h5 className="text-start mb-2">
                          Sign in to Your Account
                        </h5>
                        <p className="mb-4 text-muted tx-13 ms-0 text-start">
                          Sign in to create, discover, and connect with the
                          global community
                        </p>
                        {error && <div className="text-danger">{error}</div>}
                        <div className="form-group text-start">
                          <label>Email</label>
                          <input
                            className="form-control"
                            placeholder="Enter your email"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group text-start">
                          <label>Password</label>
                          <input
                            className="form-control"
                            placeholder="Enter your password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn ripple btn-main-primary btn-block"
                        >
                          Sign In
                        </button>
                        <button
                          onClick={(e) => {
                            navigate("/reset-password");
                          }}
                          className="btn ripple btn-main-primary btn-block"
                        >
                          Forgot Password
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
