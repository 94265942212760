import React, { useEffect, useState, useRef } from "react";
import MainPage from "../../Components/MainPage";
import Prince from "../../Components/Prince";
import DropdownMenu from "../../Components/DropdownMenu";
import { Button } from "bootstrap";
import TopHeader from "../../Components/TopHeader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Prompt from "../../Components/Promt";
import { format } from "date-fns";
import { DateRangePicker } from "react-date-range";
import Loader from "../../assets/img/loader.gif";
import "./index2.css";
import moment from "moment";

function Cases() {
  const [modelOPenAssign, setModalOpenAssign] = useState(false);
  const caseId = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [assignedEmployeeId, setAssignedEmployeeId] = useState("");
  const [options, setOptions] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredOptionsAssign, setFilteredOptionsAssign] = useState([]);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [employee, setEmployee] = useState({});
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [modelOPen, setModalOpen] = useState(false);
  const [modelOPen2, setModalOpen2] = useState(false);
  const [modelOPen3, setModalOpen3] = useState(false);
  const [modelOPen4, setModalOpen4] = useState(false);
  const [statusModalOPen, setStatusModalOpen] = useState(false);
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [userType, setUserType] = useState({});
  const [employees, setEmployees] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [newStatusLeadId, setNewStatusLeadId] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [sourceTo, setSourceTo] = useState("");
  const [stageTo, setStageTo] = useState("");
  const [leadPipelineTo, setPipelineTo] = useState("");
  const [bulkAssignId, setBulkAssignId] = useState([]);
  const [assignedLeadId, setAssignedLeadId] = useState("");
  const { status } = useParams();
  const [loader, setLoader] = useState(true);
  const [isModalOpen4, setIsModalOpen4] = useState(false);

  const [countWon, setCountWon] = useState(0);
  const [countintergrationInPro, setCountintergrationInPro] = useState(0);
  const [countnegotiation, setCountnegotiation] = useState(0);
  const [countLOST, setCountLOST] = useState(0);
  const [countconatactInFuture, setCountconatactInFuture] = useState(0);
  const [countCallAttempted, setCountCallAttempted] = useState(0);
  const [countproposal, setCountproposal] = useState(0);
  const [countFollowup, setCountFollowup] = useState(0);
  const [countNew, setCountNew] = useState(0);
  const [countOpen, setCountOpen] = useState(0);
  const [leadValue, setLeadValue] = useState({});
  const selectAllLead = document.getElementById("bulkSelect");
  const [leadStatusOptions, setLeadStatusOptions] = useState([]);
  const [filterByGenericEmailPattern, setFilterByGenericEmailPattern] =
    useState("true");

  const [filterByObj, setFilterByObj] = useState({
    leadSource: "",
    leadPipeline: "",
    businessCategory: "",
    shipmentPotential: "",
    clientPersona: "",
    partner: "",
    to: "",
    from: "",
    search: "",
    isKycDone: "",
    minimumExpectedShipmentToFship: "",
    employeeId: "",
    genericEmailPattern: "",
  });
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [modelOPen5, setModalOpen5] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [filterByLeadSource, setFilterByLeadSource] = useState([]);
  // Define and initialize 'leadSourceOptions' state
  const [leadPipelineToBulk, setPipelineToBulk] = useState("");
  const [filteredOptionsBulk, setFilteredOptionsBulk] = useState([]);
  const [showDropdownBulk, setShowDropdownBulk] = useState(false);
  const [selectedItemsBulk, setSelectedItemsBulk] = useState([]);
  const modalRef = useRef();
  const handleCloseModal5 = () => {
    setModalOpen5(false);
    setModalOpen5(false);
  };

  const handleAssign5 = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${apiUrl}/case/asignCaseM2M?caseId=${bulkAssignId}&empIds=${selectedItemsBulk}
      `,
        {
          method: "GET", // Change the method to POST
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          // body: JSON.stringify({
          //   caseId: assignedEmployeeId,
          //   employees: selectedItems, // Consider using selectedItems instead of selectedEmployees
          // }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setSuccessMessage(`Case ${assignedEmployeeId} assigned successfully.`);
        toast.success(data.message);
        setModalOpen5(false);
        // navigate("/cases/All");
      } else {
        setErrorMessage(`Failed to assign case ${assignedEmployeeId}.`);
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error assigning case:", error.message);
      setErrorMessage(
        `An error occurred while assigning case ${assignedEmployeeId}.`
      );
    } finally {
      // Reset state
      setAssignedEmployeeId(null);
      setSelectedItems([]);
    }

    // Add your logic for handling the assignment
  };
  const handleCheckboxChangeBulk = (e, id) => {
    if (e.target.checked) {
      setSelectedItemsBulk([...selectedItemsBulk, id]);
    } else {
      setSelectedItemsBulk(selectedItemsBulk.filter((item) => item !== id));
    }
  };
  const handleSearchBulk = (e) => {
    const searchValue = e.target.value;
    const filteredDataBulk = options.filter((data) =>
      data.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredOptionsBulk(filteredDataBulk);
  };

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowDropdownBulk(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Define event handlers
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    // Implement logic to filter lead source options based on searchQuery
  };

  const showCheckboxes = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    // Ensure 'filterByLeadSource' is properly initialized and updated
    if (filterByLeadSource.includes(value)) {
      setFilterByLeadSource(
        filterByLeadSource.filter((source) => source !== value)
      );
    } else {
      setFilterByLeadSource([...filterByLeadSource, value]);
    }
  };

  // Ensure 'filteredOptions' is properly computed based on 'leadSourceOptions' and 'searchQuery'
  const filteredOptions = leadSourceOptions.filter((option) =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    async function getEmp() {
      const url = `${apiUrl}/employee/employee`;

      const Token = localStorage.getItem("Token");
      //   console.log("Token " + Token);

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status === "success") {
        setEmployee(response.data);
        setTimeout(() => {
          setLoaded(true);
        }, 800);
      }
    }
    getEmp();
  }, []);
  const [allCaseList, setAllCaseList] = useState([]);
  useEffect(() => {
    async function getEmp() {
      const url = `${apiUrl}/case/caseList`;

      const Token = localStorage.getItem("Token");
      // console.log("Token " + Token);

      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      // setEmployee(response.data);
      // setTimeout(() => {
      //   setLoaded(true);
      // }, 800);
      setAllCaseList(response.data);
    }
    getEmp();
  }, []);
  //  console.log(allCaseList);
  const filteredAllCase = allCaseList.filter(
    (user) => user.customer && user.customer.id == caseId.caseID
  );
  const handleDateChange = (ranges) => {
    // console.log(
    //   new Date(ranges.selection.startDate).toISOString(),
    //   "date range ==>"
    // );
    setToDate(new Date(ranges.selection.startDate).toISOString());
    setFromDate(new Date(ranges.selection.endDate).toISOString());
    setFilterByObj({
      ...filterByObj,
      to: new Date(ranges.selection.endDate).toISOString(),
      from: new Date(ranges.selection.startDate).toISOString(),
    });
    setDate(ranges.selection);
  };

  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };

  const [leadPipelineOptions, setLeadPipelineOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);
  const [clientPersonaOptions, setClientPersonaOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [isKycDone, setIsKycDone] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [from, setFrom] = useState("");
  // const [filterByGenericEmailPattern, setFilterByGenericEmailPattern] = useState('')

  const apiUrl = process.env.REACT_APP_URL;
  // console.log(apiUrl);

  const Token = localStorage.getItem("Token");
  // console.log("Token:", Token);

  const navigate = useNavigate();

  const loadcontent = (id) => {
    // console.log(id); // Check the value of id
    navigate(`/case-detail/${id}`);
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    const {
      leadSource,
      leadPipeline,
      businessCategory,
      partner,
      shipmentPotential,
      clientPersona,
      minimumExpectedShipmentToFship,
      employeeId,
      isKycDone,
      from,
      to,
    } = filterByObj;
    const url = `${apiUrl}/lead/exportLead?id=&mobileNumber=&emailId=&leadSource=${leadSource}&leadPipeline=${leadPipeline}&businessCategory=${businessCategory}&currentShippingPartner=${partner}&shipmentPotential=${shipmentPotential}&clientPersona=${clientPersona}&minimumExpectedShipmentToFship=${minimumExpectedShipmentToFship}&employeeId=${employeeId}&isKycDone=${isKycDone}&from=${from}&to=${to}`;
    // IMPORT
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // console.log(data.data.fileURL);
          setFrom(data.data.fileURL);
          alert("Response is successful!");
        } else {
          console.error("API request was not successful:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleConfirm = () => {
    // Logic for confirming action
    setIsModalOpen4(false); // Close modal after action is confirmed
    window.location.href = from;
    handleSubmit2(); // Call the handleSubmit2 function
  };

  const handleSubmit2 = () => {
    const initialUrl = { from };

    const url = `${apiUrl}/lead/exportLead`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data1) => {
        if (data1.status === "success") {
          const link = document.createElement("a");
          link.href = data1.data.fileURL;
          const filename = data1.data.fileURL.substring(
            data1.data.fileURL.lastIndexOf("") + 1
          );
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success(data1.message);
        } else {
          console.error("API request was not successful:", data1.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // KYC
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/12`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIsKycDone(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/2`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadPipelineOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  //Quality
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/3`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setBusinessCategoryOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  // LEAD OWNER
  useEffect(() => {
    fetch(`${apiUrl}/employee/allEmpDesig`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/5`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setClientPersonaOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/11`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setPartnerOptions(data.data);
      })
      .catch((error) => {
        console.error("Error fetching Partners:", error);
      });
  }, []);

  const updateStatus = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("status", newStatus);
    const response = await fetch(
      `${apiUrl}/lead/changeStatus/${newStatusLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    // console.log(response, "statuss=======>>>>>>>");

    if (!response.ok) {
      toast.error(response.message);
    }
    setModalOpen(false);

    const data = await response.json();
    toast.success("Status Successfully Updated");

    window.location.reload();
  };

  // Bulk Assign
  const handleAssign = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("empId", assignedTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadsId", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/assignManyLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen(false);

      const data = await response.json();
      toast.success("Assigned successfully");

      window.location.reload();
      return;
    }

    const response = await fetch(
      `${apiUrl}/lead/assignedLead/${assignedLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    // console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    // console.log("Form submitted successfully!", data);
    window.location.reload();
  };

  // Bulk Source

  const handleAssign2 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadSource", sourceTo);
    // console.log(bulkAssignId);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      // console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen2(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      // console.log("Form submitted successfully!", data);
      window.location.reload();
      return;
    }

    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    //console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen2(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    //console.log("Form submitted successfully!", data);
    window.location.reload();
  };

  // Bulk Stage
  const handleAssign3 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("stage", stageTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      //  console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen3(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      // console.log("Form submitted successfully!", data);
      window.location.reload();
      return;
    }

    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    // console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen3(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    //console.log("Form submitted successfully!", data);
    window.location.reload();
  };

  // bulk pipeline
  const handleAssign4 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadPipeline", leadPipelineTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      //console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen4(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      //  console.log("Form submitted successfully!", data);
      window.location.reload();
      return;
    }

    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    // console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen4(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    //  console.log("Form submitted successfully!", data);
    window.location.reload();
  };
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/customer/customerList`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setCustomerData(data.data);
          //  console.log(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatusOptions(data.data);
          // console.log(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const fetchAllLead1 = () => {
    setLoader(true);
    const url = window.location.href.includes("cases")
      ? `${apiUrl}/case/caseList?status=&stage=${
          status === "All" ? "" : status
        }`
      : null;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setFilteredLeads(data.data);
          setCountCallAttempted(data.stageCount.PendingonTechCount);
          setCountFollowup(data.stageCount.PendingonFirstmileOpsTechCount);
          setCountLOST(data.stageCount.PendingonLastmileOpsCount);

          setCountconatactInFuture(data.stageCount.PendingonweightteamCount);
          setCountLOST(data.LOST);
          setCountintergrationInPro(data.intergrationInPro);
          setCountnegotiation(data.stageCount.PendingonsalesCount);
          setCountproposal(data.stageCount.PendingonlegalCount);
          setCountOpen(data.stageCount.PendingonclientCount);
          if (selectAllLead) {
            selectAllLead.checked = false;
          }
          setTimeout(() => {
            setLoader(false);
          }, 800);
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchAllLead1();
    setLoaded(true);
  }, [status, filterByObj]);

  const fetchFilteredLead = () => {
    setLoader(true);
    const url = window.location.href.includes("NewLeads")
      ? `${apiUrl}/lead/getAllLead/${status}?leadSource=${filterByObj.leadSource}&leadPipeline=${filterByObj.leadPipeline}&businessCategory=${filterByObj.businessCategory}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&id=&mobileNumber=&emailId=&to=${filterByObj.to}&from=${filterByObj.from}&search=${filterByObj.search}`
      : `${apiUrl}/lead/getAllMasterLeads/${status}?leadSource=${filterByObj.leadSource}&leadPipeline=${filterByObj.leadPipeline}&businessCategory=${filterByObj.businessCategory}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&id=&mobileNumber=&emailId=&to=${filterByObj.to}&from=${filterByObj.from}&search=${filterByObj.search}`;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            // let filtLeads = data.data.filter(item => item.status === status);
            setFilteredLeads(data.data);
            if (selectAllLead) {
              selectAllLead.checked = false;
            }

            setTimeout(() => {
              setLoader(false);
            }, 800);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsModalOpen(false);
  };

  const handleCloseModal2 = () => {
    setModalOpen2(false);
    setModalOpen2(false);
  };

  const handleCloseModal3 = () => {
    setModalOpen3(false);
    setModalOpen3(false);
  };

  const handleCloseModal4 = () => {
    setModalOpen4(false);
    setModalOpen4(false);
  };

  const handleCloseStatusModal = () => {
    setStatusModalOpen(false);
  };

  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/employee/employees`, {
      headers: {
        Authorization: `Bearer ${Token}`, // Include the Token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            // console.log(data.data, "all employees");
            setEmployees(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    async function getEmp() {
      const url = `${apiUrl}/employee/employee`;

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status === "success") {
        setUserType(response.data);
      } else if (response.status === "error") {
        toast.error(response.message);
      }
    }
    getEmp();
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/lead/exportLead`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setEmployees(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const filteredUsers = filteredLeads.filter((user) => user);

  const [leadData, setLeadData] = useState({});
  const [leadData1, setLeadData1] = useState({});
  const [IsUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [IsUpdateModalOpen1, setIsUpdateModalOpen1] = useState(false);

  return (
    <div className="page">
      <TopHeader />
      <Prince />
      <div className="main-content side-content pt-0 m-0">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Case List</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a>Cases </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    New Cases List
                  </li>
                </ol>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="row">
                <div className="card custom-card">
                  <div className="card-body py-3">
                    <div className="row">
                      <div className="col-sm-6 mt-4">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          aria-controls="example1"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setFilterByObj({
                              ...filterByObj,
                              search: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {loader ? (
                        // <div className="col-lg-2">
                        //   <img src={Loader} height={"50px"}></img>
                        // </div>
                        <div className="loading-overlay">
                          <img src={Loader} alt="Loading..." />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-sm-2">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-sm-2">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-sm-2">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2 mt-3">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-sm-2 mt-3">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-sm-2 mt-3">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2 mt-3">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-sm-2 mt-3">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-sm-2 mt-3">
                        <label>By Lead Source</label>
                        <div className="multiselect">
                          <div className="selectBox" onClick={showCheckboxes}>
                            <div className="searchContainer">
                              <input
                                type="text"
                                placeholder="Click here ..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{
                                  marginBottom: "5px",
                                  width: "200px",
                                  borderRadius: "5px",
                                  height: "40px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="checkboxes"
                            style={{
                              display: expanded ? "block" : "none",
                              borderRadius: "5px",
                              maxHeight: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {/* Ensure 'filteredOptions' is properly defined */}
                            {filteredOptions.map((data, index) => (
                              <label key={index} htmlFor={data.name}>
                                <input
                                  type="checkbox"
                                  id={data.name}
                                  onChange={handleCheckboxChange}
                                  value={data.name}
                                  // Ensure 'filterByLeadSource' is properly initialized and updated
                                  checked={filterByLeadSource.includes(
                                    data.name
                                  )}
                                />
                                {data.name}
                              </label>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          style={{ marginTop: "12px" }}
                          className="calender btn btn-primary"
                          onClick={() => {
                            setFilterByObj({
                              leadSource: "",
                              leadPipeline: "",
                              businessCategory: "",
                              shipmentPotential: "",
                              clientPersona: "",
                              partner: "",
                              to: "",
                              from: "",
                              isKycDone: "",
                              genericEmailPattern: "",
                              employeeId: "",
                              minimumExpectedShipmentToFship: "",
                            });
                            setDate({
                              startDate: new Date(),
                              endDate: new Date(),
                              key: "selection",
                            });
                            window.location.reload();
                          }}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
              {/* Row */}
            </div>
            <div
              className={`modal ${modelOPen5 ? "show" : ""}`}
              style={{ display: modelOPen5 ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Bulk Assign Case</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseModal5}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body" style={{ width: "100%" }}>
                    <div className="row row-sm-1">
                      <div className="col-sm-4 form-group" ref={modalRef}>
                        <label className="form-label">Employee : </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            placeholder="Search..."
                            onChange={handleSearchBulk}
                            onClick={() => setShowDropdownBulk(true)}
                            style={{
                              height: "40px",
                              width: "230px",
                              borderRadius: "4px",
                              zIndex: "9", // Ensure the search input is above other elements
                            }}
                          />
                          {showDropdownBulk && (
                            <div
                              className="dropdown"
                              style={{
                                borderRadius: "5px",
                                maxHeight: "200px",
                                overflowY: "auto",
                                borderLeft: "2px solid #808080",
                                zIndex: "10", // Set dropdown z-index higher than search input
                                position: "absolute", // Position the dropdown absolutely
                                // top: "calc(100% + 5px)", // Position it right below the search input
                                left: 0,
                                width: "100%",
                                backgroundColor: "white", // Add a background color
                              }}
                            >
                              {filteredOptionsBulk.map((data) => (
                                <div key={data.id}>
                                  <input
                                    type="checkbox"
                                    value={data.id}
                                    checked={selectedItemsBulk.includes(
                                      data.id
                                    )}
                                    onChange={(e) =>
                                      handleCheckboxChangeBulk(e, data.id)
                                    }
                                  />
                                  {data.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-primary"
                          type="button"
                          onClick={handleAssign5}
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane active row">
              {loaded ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered text-nowrap mb-0">
                      <thead>
                        <tr>
                          {userType.userType === "1" && (
                            <th className="p-4">
                              <input
                                type="checkbox"
                                id="bulkSelect"
                                onChange={(e) => {
                                  let newArr = [];
                                  const allLeadsCheck =
                                    document.getElementsByClassName(
                                      "selectLead"
                                    );
                                  if (e.target.checked) {
                                    for (
                                      let i = 0;
                                      i < filteredUsers.length;
                                      i++
                                    ) {
                                      newArr.push(filteredUsers[i].id);
                                    }
                                    setBulkAssignId(newArr);
                                    for (
                                      let i = 0;
                                      i < allLeadsCheck.length;
                                      i++
                                    ) {
                                      allLeadsCheck[i].checked = true;
                                    }
                                  } else {
                                    newArr = [];
                                    setBulkAssignId([]);
                                    for (
                                      let i = 0;
                                      i < allLeadsCheck.length;
                                      i++
                                    ) {
                                      allLeadsCheck[i].checked = false;
                                    }
                                  }
                                  //console.log(newArr, "======>>>>changed");
                                }}
                              />
                            </th>
                          )}
                          <th>ID</th>
                          <th>AWB NO</th>
                          {/* <th>PHOTO</th> */}
                          <th>ORDER ID</th>
                          <th>ORDER DETAILS</th>
                          <th>CLIENT</th>
                          <th>ISSUES</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredAllCase.map((user) => (
                          <tr key={user.id}>
                            {userType.userType === "1" && (
                              <td className="p-4">
                                <input
                                  type="checkbox"
                                  className="selectLead"
                                  onChange={(e) => {
                                    let newArray = [...bulkAssignId];
                                    if (e.target.checked) {
                                      newArray.push(user.id);
                                      setBulkAssignId(newArray);
                                    } else {
                                      newArray = [...bulkAssignId];
                                      newArray = newArray.filter((data) => {
                                        return data !== user.id;
                                      });
                                      setBulkAssignId(newArray);
                                    }
                                    // console.log(newArray, "single check");
                                  }}
                                />
                              </td>
                            )}
                            <td>
                              <p>
                                ID-<b>{user.id}</b>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                AWB NO: <b>{user.awbNo}</b>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                {user.orderDetails && (
                                  <div>
                                    {JSON.parse(user.orderDetails).map(
                                      (object, index) => (
                                        <p>
                                          ORDER ID:{" "}
                                          <b>{object.orderDetailId}</b>
                                        </p>
                                      )
                                    )}
                                  </div>
                                )}
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                {" "}
                                {user.orderDetails && (
                                  <p>
                                    {JSON.parse(user.orderDetails).map(
                                      (object, index) => (
                                        <div
                                          key={index}
                                          className="paddingBottom"
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <div style={{ marginRight: "30px" }}>
                                            <p>
                                              Order Detail ID:{" "}
                                              <b> {object.orderDetailId}</b>
                                            </p>
                                            <p>
                                              Shipment Status:{" "}
                                              <b> {object.shipmentStatus}</b>
                                            </p>
                                            <p>
                                              Pickup Location:{" "}
                                              <b> {object.pickupLocation}</b>
                                            </p>
                                            <p>
                                              Pickup Schedule Date:{" "}
                                              <b>
                                                {" "}
                                                {moment(
                                                  object.pickupScheduleDate
                                                ).format("MMM D, YYYY")}
                                              </b>
                                            </p>
                                            <p>
                                              Pickedup Date :{" "}
                                              <b>
                                                {moment(
                                                  object.pickedUpDate
                                                ).format("MMM D, YYYY")}
                                              </b>
                                            </p>
                                            <p>
                                              Shipment Charges:{" "}
                                              <b>{object.shipmentCharges}</b>
                                            </p>
                                            <p>
                                              Manifested Weight:{" "}
                                              <b> {object.manifestedWeight}</b>
                                            </p>
                                            <p>
                                              Manifested Dimension:{" "}
                                              <b>
                                                {object.manifestedDimension}
                                              </b>
                                            </p>
                                            <p>
                                              Billed Weight:{" "}
                                              <b>{object.billedWeight}</b>
                                            </p>
                                            <p>
                                              Manifested Dimension:{" "}
                                              <b>
                                                {object.manifestedDimension}
                                              </b>
                                            </p>
                                          </div>
                                          <div>
                                            <p>
                                              Billed Weight:{" "}
                                              <b>{object.billedWeight}</b>
                                            </p>
                                            <p>
                                              Billed Dimension:{" "}
                                              <b>{object.billedDimension}</b>
                                            </p>
                                            <p>
                                              NDR Reason :{" "}
                                              <b>{object.ndrReason}</b>
                                            </p>
                                            <p>
                                              Shipment Zone:{" "}
                                              <b>{object.shipmentZone}</b>
                                            </p>
                                            <p>
                                              Consignee City:{" "}
                                              <b>{object.consigneeCity}</b>
                                            </p>
                                            <p>
                                              Consignee Pincode:{" "}
                                              <b>{object.consigneePincode}</b>
                                            </p>
                                            <p>
                                              Consignee State:{" "}
                                              <b>{object.consigneeState}</b>
                                            </p>
                                            <p>
                                              Consignee Contact Number:{" "}
                                              <b>
                                                {object.consigneeContactNumber}
                                              </b>
                                            </p>
                                            <p>
                                              Product Name:{" "}
                                              <b>
                                                {
                                                  object
                                                    .crmOrderProductModels[0]
                                                    .productName
                                                }
                                              </b>
                                            </p>
                                            <p>
                                              Product Quantity:{" "}
                                              <b>
                                                {
                                                  object
                                                    .crmOrderProductModels[0]
                                                    .productQuantity
                                                }
                                              </b>
                                            </p>
                                          </div>

                                          {/* Render more data properties here */}
                                        </div>
                                      )
                                    )}
                                  </p>
                                )}
                              </p>
                            </td>

                            <td>
                              {customerData.map(
                                (item, index) =>
                                  item.id === user.customerId && (
                                    <p className="mb-0" key={index}>
                                      <b>
                                        {item.primaryContactName
                                          ?.charAt(0)
                                          .toUpperCase() +
                                          item.primaryContactName?.slice(1)}
                                      </b>
                                      <br />
                                      Email: <b>{item.emailId}</b>
                                      <br />
                                      Phone: <b>{item.mobileNumber}</b>
                                    </p>
                                  )
                              )}
                            </td>
                            <td>
                              <p className="mb-0">
                                issues: <b>{user.issues}</b>
                              </p>
                            </td>
                            <td>
                              <a
                                className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                                // onClick={() => loadcontent(user.id)}
                                onClick={() => navigate(`/support/${user.id}`)}
                              >
                                Activity
                              </a>
                              <br />
                              <a
                                className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                                // onClick={() => loadcontent(user.id)}
                                onClick={() => {
                                  setModalOpenAssign(true);
                                  setAssignedEmployeeId(user.id);
                                }}
                              >
                                Assign
                              </a>
                            </td>

                            <div
                              className={`modal ${
                                statusModalOPen ? "show" : ""
                              }`}
                              style={{
                                display: statusModalOPen ? "block" : "none",
                              }}
                              tabIndex="-1"
                              role="dialog"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered modal-xl"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Lead Stage : {user?.status.toUpperCase()}
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={handleCloseStatusModal}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div
                                    className="modal-body"
                                    style={{ width: "100%" }}
                                  >
                                    <div className="row row-sm-1">
                                      <div className="col-sm-4 form-group">
                                        <label className="form-label">
                                          Set Stage :{" "}
                                        </label>
                                        <select
                                          name="newStatus"
                                          value={newStatus}
                                          className="form-control form-select select2"
                                          onChange={(e) => {
                                            setNewStatus(e.target.value);
                                          }}
                                        >
                                          <option>Select</option>
                                          {leadStatusOptions.map((data) => {
                                            return (
                                              <option value={data.name}>
                                                {data.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      <div className="col-lg-12">
                                        <button
                                          className="btn ripple btn-primary"
                                          type="button"
                                          onClick={updateStatus}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {filteredAllCase.length === 0 && !loader ? (
                      <div className="text-center row m-5">
                        <h1>No Leads Found</h1>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <h1 className="text-center">Loading...</h1>
              )}
              <div
                className={`modal ${modelOPen ? "show" : ""}`}
                style={{ display: modelOPen ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Assign</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="assignedTo"
                            value={assignedTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setAssignedTo(e.target.value);
                            }}
                          >
                            <option>Select</option>
                            {employees.map((data) => {
                              return (
                                <option value={data.id}>
                                  {data.firstName + " " + data.lastName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            onClick={handleAssign}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal ${modelOPen2 ? "show" : ""}`}
                style={{ display: modelOPen2 ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Bulk Lead Source</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal2}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="sourceTo"
                            value={sourceTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setSourceTo(e.target.value);
                            }}
                          >
                            <option value={""}>By Lead Source</option>
                            {leadSourceOptions.map((data) => {
                              return (
                                <option value={data.name}>{data.name}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            onClick={handleAssign2}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal ${modelOPen3 ? "show" : ""}`}
                style={{ display: modelOPen3 ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Bulk Stage</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal3}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="stageTo"
                            value={stageTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setStageTo(e.target.value);
                            }}
                          >
                            <option>Select</option>
                            {leadStatusOptions.map((data) => {
                              return (
                                <option value={data.name}>{data.name}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            onClick={handleAssign3}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal ${modelOPen4 ? "show" : ""}`}
                style={{ display: modelOPen4 ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Bulk PipeLine</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal4}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="leadPipelineTo"
                            value={leadPipelineTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setPipelineTo(e.target.value);
                            }}
                          >
                            <option value={""}>By Lead Pipeline</option>
                            {leadPipelineOptions.map((data) => {
                              return (
                                <option value={data.name}>{data.name}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            onClick={handleAssign4}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cases;
