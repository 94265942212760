import React from "react";
const SearchComponent = ({ handleSearch, setFilterByObj, filterByObj }) => {
  return (
    <div className="col-sm-4 mt-3">
      <label>Search Here</label>
      <input
        type="search"
        className="form-control"
        placeholder="Search..."
        aria-controls="example1"
        onChange={(e) => {
          setFilterByObj({
            ...filterByObj,
            search: e.target.value,
          });
        }}
        style={{ border: "1px Solid #8E3E63" }}
      />
    </div>
  );
};
export default SearchComponent;
