import React, { useMemo, useState } from "react";
import Select from "react-select";

const SelectComponent = ({
  options,
  label,
  handleSelectChange,
  selectedOptions,
  includeNotAssigned, // Pass this prop to control the inclusion of "Not Assigned"
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "auto", // Let the height adjust based on content
      minHeight: "40px",
      borderRadius: "5px",
      border: "1px solid #D2649A",
      overflow: "hidden",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      // maxHeight: "200px",
    }),
    input: (provided) => ({
      ...provided,
      height: "auto", // Let the input adjust based on content
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "60px", // Set a max height for the container
      overflowY: "auto", // Add a vertical scrollbar when the content overflows
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0", // Optional: styling for selected values
    }),
  };

  const optionsArray = [
    ...options.map((dept) => ({
      label: dept.name,
      value: dept.name,
      id: dept.id,
    })),
    ...(includeNotAssigned
      ? [{ label: "Not Assigned", value: "Not Assigned", id: "null" }]
      : []), // Append "Not Assigned" option if includeNotAssigned is true
  ];
  // console.log(selectedOptions);

  return (
    <div
      className={
        label === "KAM" || label === "Case Subtype" || label === "Case Type"
          ? " "
          : "col-sm-2 mt-3"
      }
    >
      <label>{label}</label>
      <Select
        isMulti
        options={optionsArray}
        value={optionsArray?.filter((option) =>
          selectedOptions.includes(option.value)
        )}
        onChange={handleSelectChange}
        placeholder="Select Option"
        styles={customStyles}
      />
    </div>
  );
};

export default React.memo(SelectComponent);
