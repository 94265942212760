import React, { useState } from "react";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import InputField from "../../Components/createTicket/InputField";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import NotesComp from "../../Components/createTicket/NotesComp";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import CreateButton from "../../Components/createTicket/CreateButton";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketTransferMyAmount({
  caseId,
  customerId,
  selectedValue4Id,
  setOpen,
}) {
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryAccount, setBeneficiaryAccount] = useState("");
  const [beneficiaryIfsc, setBeneficiaryIfsc] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("beneficiaryName", beneficiaryName);
    formData.append("beneficiaryAccountNumber", beneficiaryAccount);
    formData.append("beneficiaryIfscCode", beneficiaryIfsc);
    formData.append("uploadImage", selectedImage);
    formData.append("discriptionIssue", description);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",

        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleInputValueName = (event) => {
    setBeneficiaryName(event.target.value);
  };
  const handleInputValueAccount = (event) => {
    setBeneficiaryAccount(event.target.value);
  };
  const handleInputValueIfsc = (event) => {
    setBeneficiaryIfsc(event.target.value);
  };
  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };
  return (
    <>
      <Box>
        <p className="fs-12 fw-bold mt-4">
          Kindly provide your bank account details that are registered with us
          to help us in verifying your information.
        </p>
        <Box className="p-6 bg-light text-dark rounded-3">
          <p className="fs-6 fw-bold mb-0">Helpful Tips</p>
          <p className="fs-6 ">Enter Your Bank Detail</p>
        </Box>
        <Box className="mt-3 d-flex justify-content-between">
          <InputField
            text={"Beneficiary Name"}
            handleInputValue={handleInputValueName}
            value={beneficiaryName}
          ></InputField>
          <InputField
            text={"Beneficiary Account Number"}
            handleInputValue={handleInputValueAccount}
            value={beneficiaryAccount}
          ></InputField>
        </Box>
        <Box className="mt-3">
          {" "}
          <InputField
            text={" Beneficiary IFSC Code "}
            handleInputValue={handleInputValueIfsc}
            value={beneficiaryIfsc}
          ></InputField>
        </Box>
        <UploadImageBox
          text={"Upload Image"}
          handleFileChange={handleFileChange}
          handleRemoveImage={handleRemoveImage}
          selectedImage={selectedImage}
          selectedImagePreview={selectedImagePreview}
        ></UploadImageBox>
        <NotesComp
          text={
            " File should be in jpg, jpeg, png format only. Max Size: 2 MB. "
          }
        ></NotesComp>
        <DescribeBox
          text={" Describe the Issue (optional) "}
          handleInputValue={handleInputValueDescription}
          value={description}
        ></DescribeBox>
        <p className="fw-bold mt-4 fs-12">
          NOTE: We can’t transfer your wallet balance into your bank account
          till we have open shipments and negative wallet balance.
        </p>
        {/* <Paragraph></Paragraph> */}
      </Box>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </>
  );
}

export default CreateTicketTransferMyAmount;
