import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import InputField from "../../Components/createTicket/InputField";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import NotesComp from "../../Components/createTicket/NotesComp";
import { toast } from "react-toastify";
import CreateButton from "../../Components/createTicket/CreateButton";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketRechargingWallet({
  handleShow,
  caseId,
  customerId,
  selectedValue4Id,
  setOpen,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [issueOption, setIssueOption] = useState([]);
  const [transactionId, setTransactionId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [description, setDescription] = useState("");
  const [otherIssueDescribe, setOtherIssueDescribe] = useState("");

  const handleInputValue = (e) => {
    setOtherIssueDescribe(e.target.value);
  };

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("transactionID", transactionId);
    formData.append("discriptionIssue", description);
    formData.append("uploadImage", selectedImage);
    formData.append("selectIssue", selectedOption);
    formData.append("otherIssueDescribe", otherIssueDescribe);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/${handleShow ? 23 : 24}`, {
      headers: {
        // Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.name);
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleInputValueTransactionId = (event) => {
    setTransactionId(event.target.value);
  };
  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };
  return (
    <Box className="mt-4">
      {handleShow != "Hide" && (
        <SelectDropdown
          dropDownData={issueOption}
          handleChange={handleChange}
          selectedData={selectedOption}
          Text={"Select Issue:"}
        ></SelectDropdown>
      )}
      {`${selectedOption}` == " Others " ? (
        <DescribeBox
          text={"Describe Issue :"}
          selectedOption={selectedOption}
          handleInputValue={handleInputValue}
          value={otherIssueDescribe}
        ></DescribeBox>
      ) : (
        ""
      )}
      <Box className="mt-4">
        {handleShow != "Hide" && (
          <Box>
            {handleShow && (
              <InputField
                text={
                  "Enter Transaction ID or Reference ID (Example: pay_LHvyt7yAxrpynA):"
                }
                handleInputValue={handleInputValueTransactionId}
                value={transactionId}
              ></InputField>
            )}
            {/* {!handleShow && (
              <p className="fs-12 fw-bold">
                If on Lite, Basic, Advanced - Looking to level up? Select a plan
                that grows with your business and enjoy lower shipping rates and
                enhanced functionalities.{" "}
                <a href="#" className="text-primary">
                  {" "}
                  Upgrade Now!
                </a>
              </p>
            )} */}
          </Box>
        )}
        <DescribeBox
          text={" Describe the Issue (optional): "}
          handleInputValue={handleInputValueDescription}
          value={description}
        ></DescribeBox>
        <UploadImageBox
          text={
            handleShow && handleShow != "Hide"
              ? "Upload Payment Proof"
              : "Uplaod Attachment (optional):"
          }
          handleFileChange={handleFileChange}
          handleRemoveImage={handleRemoveImage}
          selectedImage={selectedImage}
          selectedImagePreview={selectedImagePreview}
        ></UploadImageBox>
        <NotesComp
          text={"File should be in jpg, jpeg, png format only. Max Size: 2 MB."}
        ></NotesComp>
      </Box>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketRechargingWallet;
