import React, { useState, useEffect, useRef } from "react";

import Prince from "../../Components/Prince";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import "../../amit.css";
import TopHeader from "../../Components/TopHeader";

const NewLeads = ({ employeeData, setLoader }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [leadPipelineOptions, setLeadPipelineOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);
  const [clientPersonaOptions, setClientPersonaOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [states, setStates] = useState([]);
  const { status } = useParams();

  const initialFormData = {
    leadSource: "",
    leadPipeline: "",
    primaryContactName: "",
    primaryContactDesignation: "",
    companyName: "",
    mobileNumber: "",
    emailId: "",
    businessCategory: "",
    currentShippingPartner: "",
    customShippingPartner: "",
    shipmentPotential: "",
    minimumExpectedShipmentToFship: "",
    clientPersona: "",
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmTerm: "",
    utmContent: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [excelFormData, setExcelFormData] = useState({});
  const [newLead, setNewLead] = useState([]);

  const [errors, setErrors] = useState({});

  const navigation = useNavigate();
  const [duplicateExcel, setDuplicateExcel] = useState("");

  const apiUrl = process.env.REACT_APP_URL;

  const Token = localStorage.getItem("Token");
  // console.log("Token:", Token);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        }
      });

    fetch(`${apiUrl}/employee/allStates/101`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setStates(data.data);
        }
      })
      .catch((error) => {
        // console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/2`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadPipelineOptions(data.data);
        } else {
          // console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        // console.error("Error fetching gender options:", error);
      });
  }, []);

  //Quality
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/3`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setBusinessCategoryOptions(data.data);
        } else {
          //console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        //console.error("Error fetching gender options:", error);
      });
  }, []);

  const loadcontent = (id) => {
    // console.log(id); // Check the value of id
    navigation(`/DatelsLeads/${id}`);
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/5`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setClientPersonaOptions(data.data);
        } else {
          // console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        // console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/11`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setPartnerOptions(data.data);
      })
      .catch((error) => {});
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExcelFormData({ excelFile: file });
  };

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    setLoader(true);
    formData.append("excelFile", excelFormData.excelFile);
    try {
      const response = await fetch(`${apiUrl}/lead/uploadExcel`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success("Lead Successfully Added");
      if (response2.duplicateLeadExcel.fileURLD != "") {
        setDuplicateExcel(response2.duplicateLeadExcel.fileURLD);
        setLoader(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
    excelFormData.excelFile = "";
    formData.excelFile = "";
    fileInputRef.current.value = null;
  };
  // console.log(duplicateExcel);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();

      if (formData.currentShippingPartner === "Other") {
        formData.currentShippingPartner = formData.customShippingPartner;
        delete formData.customShippingPartner;
      } else {
        delete formData.customShippingPartner;
      }

      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const response = await fetch(`${apiUrl}/lead/add-Lead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      setNewLead([...newLead, formData]);

      const response2 = await response.json();

      if (response2.status === "error") {
        throw new Error(response2.message);
      }

      toast.success(response2.message);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      handleCloseModal();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors[name] = `${name.toLowerCase()} is required`;
    } else if (
      name === "fullName" &&
      !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[
        name
      ] = `Please enter a valid ${name} with only alphabetical characters`;
    } else if (
      name === "companyName" &&
      !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[
        name
      ] = `Please enter a valid ${name} with only alphabetical characters`;
    } else if (name === "email" && (!value.trim() || !emailRegex.test(value))) {
      newErrors[name] = "Please enter a valid email address";
    } else if (
      name === "companyEmail" &&
      (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = "Please enter a valid Company Email address";
    } else if (
      name === "mobileNo" &&
      (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 10-digit mobile number";
    } else if (
      name === "companyPhone" &&
      (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 10-digit mobile number";
    } else if (
      name === "aadhaarNo" &&
      (!value.trim() || !/^\d{12}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 12-digit Aadhaar number";
    } else if (
      name === "panNo" &&
      (!value.trim() || !/^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value))
    ) {
      newErrors[name] =
        "Please enter a valid PAN number in the format ABCDE1234F";
    } else if (
      name === "accountNo" &&
      (!value.trim() || !/^\d{16}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 16-digit account number";
    } else if (name === "accountName" && !/^[A-Za-z\s]+$/.test(value)) {
      newErrors[name] =
        "Please enter a valid account name with only alphabetical characters";
    } else if (name === "bankName" && !/^[A-Za-z\s]+$/.test(value)) {
      newErrors[name] =
        "Please enter a valid bank name with only alphabetical characters";
    } else if (name === "ifsc" && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
      newErrors[name] =
        "Please enter a valid IFSC code in the format ABCD0123456";
    } else if (name === "billingPinCode" && !/^\d{6}$/.test(value)) {
      newErrors[name] = "Please enter a valid 6-digit pin code";
    } else if (name === "offeredPackage" && !/^\d{8}$/.test(value)) {
      newErrors[name] = "Please enter a valid 8-digit offeredPackage";
    } else if (name === "offeredCTC" && !/^\d{8}$/.test(value)) {
      newErrors[name] = "Please enter a valid 8-digit offeredCTC";
    } else if (name === "perHourCharges" && !/^\d{8}$/.test(value)) {
      newErrors[name] = "Please enter a valid 8-digit perHourCharges";
    } else {
      delete newErrors[name]; // Remove error if field is valid
    }

    setErrors(newErrors);
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors[name] = ``;
    } else if (
      name === "firstName" &&
      !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[
        name
      ] = `Please enter a valid ${name} with only alphabetical characters`;
    } else if (
      name === "companyName" &&
      !/^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value)
    ) {
      newErrors[
        name
      ] = `Please enter a valid ${name} with only alphabetical characters`;
    } else if (name === "email" && (!value.trim() || !emailRegex.test(value))) {
      newErrors[name] = "Please enter a valid email address";
    } else if (
      name === "companyEmail" &&
      (!value.trim() || !emailRegex.test(value))
    ) {
      newErrors[name] = "Please enter a valid Company Email address";
    } else if (
      name === "mobileNo" &&
      (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 10-digit mobile number";
    } else if (
      name === "companyPhone" &&
      (!value.trim() || !/^\d{10}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 10-digit mobile number";
    } else if (
      name === "adharNumber" &&
      (!value.trim() || !/^\d{12}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 16-digit Aadhaar number";
    } else if (
      name === "panNo" &&
      (!value.trim() || !/^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value))
    ) {
      newErrors[name] =
        "Please enter a valid PAN number in the format ABCDE1234F";
    } else if (
      name === "accountNo" &&
      (!value.trim() || !/^\d{16}$/.test(value))
    ) {
      newErrors[name] = "Please enter a valid 16-digit account number";
    } else if (name === "accountName" && !/^[A-Za-z\s]+$/.test(value)) {
      newErrors[name] =
        "Please enter a valid account name with only alphabetical characters";
    } else if (name === "bankName" && !/^[A-Za-z\s]+$/.test(value)) {
      newErrors[name] =
        "Please enter a valid bank name with only alphabetical characters";
    } else if (name === "ifsc" && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
      newErrors[name] =
        "Please enter a valid IFSC code in the format ABCD0123456";
    } else if (name === "billingPinCode" && !/^\d{6}$/.test(value)) {
      newErrors[name] = "Please enter a valid 6-digit pin code";
    } else if (name === "offeredPackage" && !/^\d{8}$/.test(value)) {
      newErrors[name] = "Please enter a valid 8-digit offeredPackage";
    } else if (name === "offeredCTC" && !/^\d{8}$/.test(value)) {
      newErrors[name] = "Please enter a valid 8-digit offeredCTC";
    } else if (name === "perHourCharges" && !/^\d{8}$/.test(value)) {
      newErrors[name] = "Please enter a valid 8-digit perHourCharges";
    } else {
      delete newErrors[name]; // Remove error if field is valid
    }

    setErrors(newErrors);
  };

  return (
    <>
      <div className="main-header">
        <TopHeader employeeData={employeeData} />
        <Prince employeeData={employeeData} />

        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">All Leads</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">CRM </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Leads{" "}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <a
                      href="/#"
                      className="btn btn-outline-primary"
                      onClick={handleOpenModal}
                    >
                      <i className="fe fe-plus me-2" /> Add New Lead
                    </a>
                    <div
                      className={`modal ${
                        isModalOpen ? "show" : ""
                      } bg-black-7`}
                      style={{ display: isModalOpen ? "block" : "none" }}
                      tabIndex="-1"
                      role="dialog"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered modal-xl"
                        role="document"
                      >
                        <div className="modal-content rounded-3">
                          <div className="modal-header">
                            <h5 className="modal-title">Add Lead</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={handleCloseModal}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                          <div className="modal-body" style={{ width: "100%" }}>
                            <div className="row row-sm-1">
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Lead Source{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="leadSource"
                                  value={formData.leadSource}
                                  className="form-control form-select select2"
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {leadSourceOptions.map((data, index) => {
                                    return (
                                      <option key={index} value={data.name}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Lead Pipeline{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="leadPipeline"
                                  value={formData.leadPipeline}
                                  className="form-control form-select select2"
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {leadPipelineOptions.map((data, index) => {
                                    return (
                                      <option value={data.name} key={index}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-lg-4">
                                <label className="form-label">
                                  Primary Contact Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="primaryContactName"
                                  value={formData.primaryContactName}
                                  placeholder="Enter"
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-lg-4 form-group">
                                <label className="form-label">
                                  Primary Contact Designation
                                </label>
                                <input
                                  className="form-control"
                                  name="primaryContactDesignation"
                                  value={formData.primaryContactDesignation}
                                  onChange={handleChange}
                                  placeholder="Enter"
                                  required=""
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-4 form-group">
                                <label className="form-label">
                                  Mobile Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="mobileNumber"
                                  value={formData.mobileNumber}
                                  placeholder="Enter"
                                  required=""
                                  type="text"
                                  min={"0"}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Company Name
                                </label>
                                <input
                                  className="form-control"
                                  name="companyName"
                                  value={formData.companyName}
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Email ID{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  name="emailId"
                                  value={formData.emailId}
                                  onChange={handleChange}
                                  placeholder="Enter email"
                                  required=""
                                  type="text"
                                />
                                {errors.email && (
                                  <p style={{ color: "red" }}>{errors.email}</p>
                                )}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Business Category
                                </label>
                                <select
                                  className="form-control form-select select2"
                                  value={formData.businessCategory}
                                  name="businessCategory"
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {businessCategoryOptions.map(
                                    (data, index) => {
                                      return (
                                        <option value={data.name} key={index}>
                                          {data.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Current Shipping Partner
                                </label>
                                <select
                                  name="currentShippingPartner"
                                  value={formData.partner}
                                  className="form-control form-select select2"
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {partnerOptions.map((data, index) => {
                                    return (
                                      <option value={data.name} key={index}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {formData.currentShippingPartner ===
                                  "Other" && (
                                  <input
                                    className="form-control"
                                    name="customShippingPartner"
                                    // value={formData.partner}
                                    onChange={handleChange}
                                    placeholder="Enter Partner Name"
                                    required=""
                                    type="text"
                                  />
                                )}
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Shipment Potential
                                </label>
                                <select
                                  name="shipmentPotential"
                                  value={formData.shipmentPotential}
                                  className="form-control form-select select2"
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {shipmentPotentialOptions.map(
                                    (data, index) => {
                                      return (
                                        <option value={data.name} key={index}>
                                          {data.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Min. Expected Shipment to Fship
                                </label>
                                <input
                                  className="form-control"
                                  name="minimumExpectedShipmentToFship"
                                  required=""
                                  placeholder="Enter "
                                  value={
                                    formData.minimumExpectedShipmentToFship
                                  }
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleInputBlur}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  Client Persona
                                </label>
                                <select
                                  name="clientPersona"
                                  value={formData.clientPersona}
                                  className="form-control form-select select2"
                                  onChange={handleChange}
                                >
                                  <option>Select</option>
                                  {clientPersonaOptions.map((data, index) => {
                                    return (
                                      <option value={data.name} key={index}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">UTM Source</label>
                                <input
                                  className="form-control"
                                  name="utmSource"
                                  value={formData.utmSource}
                                  placeholder="Enter "
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">UTM Medium</label>
                                <input
                                  className="form-control"
                                  name="utmMedium"
                                  value={formData.utmMedium}
                                  placeholder="Enter "
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  UTM Campaign
                                </label>
                                <input
                                  className="form-control"
                                  name="utmCampaign"
                                  value={formData.utmCampaign}
                                  placeholder="Enter "
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">UTM Term</label>
                                <input
                                  className="form-control"
                                  name="utmTerm"
                                  value={formData.utmTerm}
                                  placeholder="Enter "
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">
                                  UTM Content
                                </label>
                                <input
                                  className="form-control"
                                  name="utmContent"
                                  value={formData.utmContent}
                                  placeholder="Enter "
                                  required=""
                                  type="text"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-sm-4 form-group">
                                <label className="form-label">State</label>
                                <select
                                  className="form-control form-select select2"
                                  value={formData.state}
                                  name="state"
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  {states.map((data, index) => {
                                    return (
                                      <option key={index} value={data.name}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-outline-success"
                                  onClick={handleSubmit}
                                  type="button"
                                >
                                  Save
                                </button>
                              </div>

                              <div className="col-12 text-center border-top mt-3">
                                <br />
                                OR
                              </div>

                              <form className="row">
                                <div className="col-sm-4 form-group">
                                  <label className="form-label">
                                    Excel File
                                  </label>
                                  <input
                                    ref={fileInputRef}
                                    className="form-control"
                                    name="excelFile"
                                    type="file"
                                    accept=".xlsx,.xls"
                                    onChange={handleFileChange}
                                  />
                                  <br />
                                </div>
                                <div
                                  className="col-sm-6 form-group"
                                  style={{
                                    margin: "28px",
                                    display: "flex",
                                    justifyContent: "space-around",
                                    height: "35px",
                                  }}
                                >
                                  <button
                                    className="btn btn-outline-dark"
                                    onClick={() => {
                                      window.location.href = `${apiUrl}/uploads/sample(excel).xlsx`;
                                    }}
                                    type="button"
                                  >
                                    Download Sample File
                                  </button>
                                  {duplicateExcel != "" ? (
                                    <button
                                      className="btn ripple btn-primary btn-sm"
                                      onClick={() => {
                                        window.location.href = duplicateExcel;
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 2000);
                                      }}
                                      type="button"
                                    >
                                      Download Duplicate Excel
                                    </button>
                                  ) : null}
                                </div>

                                <div className="col-sm-12 form-group">
                                  <button
                                    className="btn btn-outline-danger"
                                    onClick={handleFileUpload}
                                    type="button"
                                  >
                                    Upload
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLeads;
