import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../assets/img/loader.gif";
import "./index2.css";
import { DataContext } from "../../context/DataContext";
import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function CaseListPageByUrl() {
  const [currentPage, setCurrentPage] = useState(1);
  const [awbList, setAwbList] = useState([]);
  const [caseId, setCaseId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modelOpenOrderId, setModalOpenOrderId] = useState(false);
  const result = useContext(DataContext);
  const [caseList, setCaseList] = useState([]);
  const [employee, setEmployee] = useState({});
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [modelOpenAwb, setModalOpenAwb] = useState(false);
  const [userType, setUserType] = useState({});
  const { status } = useParams();
  const selectAllLead = document.getElementById("bulkSelect");

  const boxStyle = {
    width: "400px",
    height: "25px",
    padding: "2px",
    border: "1px solid #ccc",
    overflow: "auto",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    borderRadius: "2px",
  };

  const [orderIdList, setOrderIdList] = useState([]);

  const handleActivityClick = (id) => {
    const constantString = "FshipData";
    const valueToEncrypt = `${id}_${constantString}`;
    const key = "Fship";
    const encryptedValue = CryptoJS.AES.encrypt(valueToEncrypt, key).toString();
    navigate(`/support-case/${id}?key=${encodeURIComponent(encryptedValue)}`);
  };
  const query = useQuery();
  const [companyId, setCompanyId] = useState({ customerId: "" });
  const [checkCompanyId, setCheckCompanyId] = useState("");
  const [encryptedValue, setEncryptedValue] = useState("");

  useEffect(() => {
    const pathArray = window.location.pathname.split("/");
    const companyIdFromUrl = pathArray[pathArray.length - 1];

    const encryptedValueFromUrl = query.get("key");

    setCheckCompanyId(companyIdFromUrl);
    setEncryptedValue(encryptedValueFromUrl);
  }, [query]);

  function decryptValue(encryptedValue) {
    const key = "Fship"; // Replace with your actual key
    const bytes = CryptoJS.AES.decrypt(encryptedValue, key);
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedValue;
  }

  function validateCompanyId(checkCompanyId, encryptedValue) {
    if (!encryptedValue) return false;
    const decryptedValue = decryptValue(encryptedValue);
    const [decryptedCompanyId, constantString] = decryptedValue.split("_");
    return (
      decryptedCompanyId === checkCompanyId && constantString === "FshipData"
    );
  }

  useEffect(() => {
    if (encryptedValue) {
      if (validateCompanyId(checkCompanyId, encryptedValue)) {
        setCompanyId({ customerId: checkCompanyId });
      } else {
        toast.error("Invalid Company ID");
      }
    }
  }, [checkCompanyId, encryptedValue]);

  useEffect(() => {
    setUserType(result.employeeData);
  }, []);

  useEffect(() => {
    setEmployee(result.employeeData);
  }, []);

  const apiUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();

  const handleOrderIdList = () => {
    fetch(`${apiUrl}/caseM/ordeIdList?caseId=${caseId}`, {})
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setOrderIdList(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {});
  };

  const handleAwbNoList = () => {
    fetch(`${apiUrl}/caseM/awbList?caseId=${caseId}`, {})
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setAwbList(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching AWB list:", error);
      });
  };

  useEffect(() => {
    handleAwbNoList();
  }, [caseId]);

  useEffect(() => {
    handleOrderIdList();
  }, [caseId]);

  useEffect(() => {
    setCurrentPage(1);
  }, [status]);

  const fetchAllLead1 = () => {
    const url = `${apiUrl}/caseM/caseListFship?companyId=${companyId.customerId}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setFilteredLeads(data.data);

          setIsLoading(true);

          if (selectAllLead) {
            selectAllLead.checked = false;
          }
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (companyId.customerId) {
      fetchAllLead1();
    }
  }, [status, currentPage, companyId]);

  const handleCloseModalAwb = () => {
    setModalOpenAwb(false);
  };

  const handleCloseModalOrderId = () => {
    setModalOpenOrderId(false);
  };

  const isNumber = (status) => !isNaN(status);

  return (
    <div className="page">
      <div className="main-content side-content pt-0 m-0">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Case List</h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a>Cases </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    New Cases List
                  </li>
                </ol>
              </div>
            </div>

            <div className="bg-white m-3 rounded-3">
              {isLoading ? (
                <>
                  <div className="table-responsive p-4">
                    <table className="table table-striped table-bordered text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>CASE CATEGORY</th>
                          <th>CASE SUBCATEGORY</th>
                          <th>CLIENT DETAILS</th>
                          <th>CASE DETAILS</th>

                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isNumber(status) == true
                          ? filteredLeads?.map((user) => (
                              <tr key={user.id}>
                                <td>
                                  <p style={{ marginBottom: "5px" }}>
                                    ID-<b>{user.id}</b>
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0">
                                    <>{user.caseTypeName}</>
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0">
                                    <>{user.caseSubTypeName}</>
                                  </p>
                                </td>

                                <td>
                                  {
                                    <p className="mb-0">
                                      <b>
                                        {user.customer.primaryContactName
                                          ?.charAt(0)
                                          .toUpperCase() +
                                          user.customer.primaryContactName?.slice(
                                            1
                                          )}
                                      </b>
                                      <br />
                                      Email: <b>{user.customer.emailId}</b>
                                      <br />
                                      Phone: <b>{user.customer.mobileNumber}</b>
                                    </p>
                                  }
                                </td>

                                <td>
                                  {user.orderId.length === 1 ? (
                                    <p className="mb-0">
                                      Order Id:{" "}
                                      <b>{user.orderId[0]?.orderId}</b>
                                    </p>
                                  ) : user.orderId.length === 0 ? null : (
                                    <p className="mb-0">
                                      Order Id:{" "}
                                      <b
                                        style={{
                                          cursor: "pointer",
                                          color: "#032852",
                                        }}
                                        onClick={() => {
                                          setCaseId(user.id);

                                          setModalOpenOrderId(true);
                                        }}
                                      >
                                        Click Here
                                      </b>
                                    </p>
                                  )}
                                  {user.awbNo?.length === 1 ? (
                                    <p className="mb-0">
                                      Awb No: <b>{user.awbNo[0]?.awbNo}</b>
                                    </p>
                                  ) : user.awbNo?.length === 0 ? null : (
                                    <p className="mb-0">
                                      Awb No:{" "}
                                      <b
                                        style={{
                                          cursor: "pointer",
                                          color: "#032852",
                                        }}
                                        onClick={() => {
                                          setCaseId(user.id);
                                          // handleAwbNoList();
                                          setModalOpenAwb(true);
                                        }}
                                      >
                                        Click Here
                                      </b>
                                    </p>
                                  )}

                                  {user.selectReason ? (
                                    <p className="mb-0">
                                      Select Reason : <b>{user.selectReason}</b>
                                    </p>
                                  ) : null}
                                  {user.beneficiaryName ? (
                                    <p className="mb-0">
                                      Beneficiary Account Name :{" "}
                                      <b>{user.beneficiaryName}</b>
                                    </p>
                                  ) : null}
                                  {user.beneficiaryAccountNumber ? (
                                    <p className="mb-0">
                                      Beneficiary Account Number :{" "}
                                      <b>{user.beneficiaryAccountNumber}</b>
                                    </p>
                                  ) : null}
                                  {user.beneficiaryIfscCode ? (
                                    <p className="mb-0">
                                      Beneficiary IFSC Code :{" "}
                                      <b>{user.beneficiaryIfscCode}</b>
                                    </p>
                                  ) : null}
                                  {user.uploadImage ? (
                                    <p className="mb-0">
                                      Upload Image:{" "}
                                      <b>
                                        <a
                                          href={user.uploadImage}
                                          target="_blank"
                                        >
                                          Click Here
                                        </a>
                                      </b>
                                    </p>
                                  ) : null}
                                  {user.otherIssueDescribe ? (
                                    <p className="mb-0">
                                      Other Issue Describe :{" "}
                                      <b>{user.otherIssueDescribe}</b>
                                    </p>
                                  ) : null}
                                  {user.otherIssue ? (
                                    <div className="mb-0">
                                      Other Issue Describe :{" "}
                                      <p style={boxStyle}>
                                        {" "}
                                        <b>{user.otherIssue}</b>
                                      </p>
                                    </div>
                                  ) : null}
                                  {user.discriptionIssue ? (
                                    <p className="mb-0">
                                      {user.relatedIssue !==
                                        " API Integration " &&
                                      user.caseSubTypeId === 20
                                        ? "Channel Credentials"
                                        : "Discription Issue :"}
                                      <b>{user.discriptionIssue}</b>
                                    </p>
                                  ) : null}
                                  {user.selectIssue ? (
                                    <p className="mb-0">
                                      Select Issue : <b>{user.selectIssue}</b>
                                    </p>
                                  ) : null}
                                  {user.transactionID ? (
                                    <p className="mb-0">
                                      Transaction ID :{" "}
                                      <b>{user.transactionID}</b>
                                    </p>
                                  ) : null}
                                  {user.companyType ? (
                                    <p className="mb-0">
                                      Company Type: <b>{user.companyType}</b>
                                    </p>
                                  ) : null}
                                  {user.relatedIssue ? (
                                    <p className="mb-0">
                                      Related Issue : <b>{user.relatedIssue}</b>
                                    </p>
                                  ) : null}
                                  {user.selectChannel ? (
                                    <p className="mb-0">
                                      Select Channel :{" "}
                                      <b>{user.selectChannel}</b>
                                    </p>
                                  ) : null}
                                  {user.selectOrderType ? (
                                    <p className="mb-0">
                                      Select Order Type:{" "}
                                      <b>{user.selectOrderType}</b>
                                    </p>
                                  ) : null}
                                  {user.timePeriod ? (
                                    <p className="mb-0">
                                      Time Period : <b>{user.timePeriod}</b>
                                    </p>
                                  ) : null}
                                  {user.receivedIncorrectInvoice ? (
                                    <p className="mb-0">
                                      Received Incorrect Invoice :{" "}
                                      <b>{user.receivedIncorrectInvoice}</b>
                                    </p>
                                  ) : null}
                                  {user.incorrectMissingCompanyName ? (
                                    <p className="mb-0">
                                      Incorrect Missing Company Name :{" "}
                                      <b>{user.incorrectMissingCompanyName}</b>
                                    </p>
                                  ) : null}
                                  {user.incorrectMissingCompanyAddress ? (
                                    <p className="mb-0">
                                      Incorrect Missing Company Address :{" "}
                                      <b>
                                        {user.incorrectMissingCompanyAddress}
                                      </b>
                                    </p>
                                  ) : null}
                                  {user.incorrectMissingGSTIN ? (
                                    <p className="mb-0">
                                      Incorrect Missing GSTIN :{" "}
                                      <b>{user.incorrectMissingGSTIN}</b>
                                    </p>
                                  ) : null}
                                  {user.others ? (
                                    <p className="mb-0">
                                      Other : <b>{user.others}</b>
                                    </p>
                                  ) : null}
                                  {user.invoiceNumber ? (
                                    <p className="mb-0">
                                      Invoice Number :{" "}
                                      <b>{user.invoiceNumber}</b>
                                    </p>
                                  ) : null}
                                  {user.issuePlatform ? (
                                    <p className="mb-0">
                                      Platform Issue :{" "}
                                      <b>{user.issuePlatform}</b>
                                    </p>
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          : filteredLeads?.map((user) => (
                              <tr key={user.id}>
                                <td>
                                  <p style={{ marginBottom: "5px" }}>
                                    ID-<b>{user.id}</b>
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0">
                                    <>{user.caseTypeId}</>
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0">
                                    <>{user.caseSubTypeId}</>
                                  </p>
                                </td>

                                <td>
                                  {
                                    <p className="mb-0">
                                      <b>
                                        {user.customer.primaryContactName
                                          ?.charAt(0)
                                          .toUpperCase() +
                                          user.customer.primaryContactName?.slice(
                                            1
                                          )}
                                      </b>
                                      <br />
                                      Email: <b>{user.customer.emailId}</b>
                                      <br />
                                      Phone: <b>{user.customer.mobileNumber}</b>
                                    </p>
                                  }
                                </td>
                                <td>
                                  {user.orderId.length === 1 ? (
                                    <p className="mb-0">
                                      Order Id:{" "}
                                      <b>{user.orderId[0]?.orderId}</b>
                                    </p>
                                  ) : user.orderId.length === 0 ? null : (
                                    <p className="mb-0">
                                      Order Id:{" "}
                                      <b
                                        style={{
                                          cursor: "pointer",
                                          color: "#032852",
                                        }}
                                        onClick={() => {
                                          setCaseId(user.id);

                                          setModalOpenOrderId(true);
                                        }}
                                      >
                                        Click Here
                                      </b>
                                    </p>
                                  )}

                                  {user.awbNo?.length === 1 ? (
                                    <p className="mb-0">
                                      Awb No: <b>{user.awbNo[0]?.awbNo}</b>
                                    </p>
                                  ) : user.awbNo?.length === 0 ? null : (
                                    <p className="mb-0">
                                      Awb No:{" "}
                                      <b
                                        style={{
                                          cursor: "pointer",
                                          color: "#032852",
                                        }}
                                        onClick={() => {
                                          setCaseId(user.id);

                                          setModalOpenAwb(true);
                                        }}
                                      >
                                        Click Here
                                      </b>
                                    </p>
                                  )}

                                  {user.selectReason ? (
                                    <p className="mb-0">
                                      Select Reason : <b>{user.selectReason}</b>
                                    </p>
                                  ) : null}
                                  {user.beneficiaryName ? (
                                    <p className="mb-0">
                                      Beneficiary Account Name :{" "}
                                      <b>{user.beneficiaryName}</b>
                                    </p>
                                  ) : null}
                                  {user.beneficiaryAccountNumber ? (
                                    <p className="mb-0">
                                      Beneficiary Account Number :{" "}
                                      <b>{user.beneficiaryAccountNumber}</b>
                                    </p>
                                  ) : null}
                                  {user.beneficiaryIfscCode ? (
                                    <p className="mb-0">
                                      Beneficiary IFSC Code :{" "}
                                      <b>{user.beneficiaryIfscCode}</b>
                                    </p>
                                  ) : null}
                                  {user.uploadImage ? (
                                    <p className="mb-0">
                                      Upload Image:{" "}
                                      <b>
                                        <a
                                          href={user.uploadImage}
                                          target="_blank"
                                        >
                                          Click Here
                                        </a>
                                      </b>
                                    </p>
                                  ) : null}
                                  {user.otherIssueDescribe ? (
                                    <div className="mb-0">
                                      Other Issue Describe :{" "}
                                      <p style={boxStyle}>
                                        <b>{user.otherIssueDescribe}</b>
                                      </p>
                                    </div>
                                  ) : null}
                                  {user.otherIssue ? (
                                    <div className="mb-0">
                                      Other Issue Describe :{" "}
                                      <p style={boxStyle}>
                                        {" "}
                                        <b>{user.otherIssue}</b>
                                      </p>
                                    </div>
                                  ) : null}
                                  {user.discriptionIssue ? (
                                    <div className="mb-0">
                                      {user.relatedIssue !==
                                        " API Integration " &&
                                      user.caseSubTypeId === 20
                                        ? "Channel Credentials"
                                        : "Discription Issue :"}
                                      <p style={boxStyle}>
                                        {" "}
                                        <b>{user.discriptionIssue}</b>
                                      </p>
                                    </div>
                                  ) : null}
                                  {user.selectIssue ? (
                                    <p className="mb-0">
                                      Select Issue : <b>{user.selectIssue}</b>
                                    </p>
                                  ) : null}
                                  {user.transactionID ? (
                                    <p className="mb-0">
                                      Transaction ID :{" "}
                                      <b>{user.transactionID}</b>
                                    </p>
                                  ) : null}
                                  {user.companyType ? (
                                    <p className="mb-0">
                                      Company Type: <b>{user.companyType}</b>
                                    </p>
                                  ) : null}
                                  {user.relatedIssue ? (
                                    <p className="mb-0">
                                      Related Issue : <b>{user.relatedIssue}</b>
                                    </p>
                                  ) : null}
                                  {user.selectChannel ? (
                                    <p className="mb-0">
                                      Select Channel :{" "}
                                      <b>{user.selectChannel}</b>
                                    </p>
                                  ) : null}
                                  {user.selectOrderType ? (
                                    <p className="mb-0">
                                      Select Order Type:{" "}
                                      <b>{user.selectOrderType}</b>
                                    </p>
                                  ) : null}
                                  {user.timePeriod ? (
                                    <p className="mb-0">
                                      Time Period : <b>{user.timePeriod}</b>
                                    </p>
                                  ) : null}
                                  {user.receivedIncorrectInvoice ? (
                                    <p className="mb-0">
                                      Received Incorrect Invoice :{" "}
                                      <b>{user.receivedIncorrectInvoice}</b>
                                    </p>
                                  ) : null}
                                  {user.incorrectMissingCompanyName ? (
                                    <p className="mb-0">
                                      Incorrect Missing Company Name :{" "}
                                      <b>{user.incorrectMissingCompanyName}</b>
                                    </p>
                                  ) : null}
                                  {user.incorrectMissingCompanyAddress ? (
                                    <p className="mb-0">
                                      Incorrect Missing Company Address :{" "}
                                      <b>
                                        {user.incorrectMissingCompanyAddress}
                                      </b>
                                    </p>
                                  ) : null}
                                  {user.incorrectMissingGSTIN ? (
                                    <p className="mb-0">
                                      Incorrect Missing GSTIN :{" "}
                                      <b>{user.incorrectMissingGSTIN}</b>
                                    </p>
                                  ) : null}
                                  {user.others ? (
                                    <p className="mb-0">
                                      Other : <b>{user.others}</b>
                                    </p>
                                  ) : null}
                                  {user.invoiceNumber ? (
                                    <p className="mb-0">
                                      Invoice Number :{" "}
                                      <b>{user.invoiceNumber}</b>
                                    </p>
                                  ) : null}
                                  {user.issuePlatform ? (
                                    <p className="mb-0">
                                      Platform Issue :{" "}
                                      <b>{user.issuePlatform}</b>
                                    </p>
                                  ) : null}
                                </td>

                                <td>
                                  <a
                                    className="btn ripple btn-danger btn-xs w-100 mb-1 mt-1"
                                    onClick={() => handleActivityClick(user.id)}
                                    style={{ color: "#fff" }}
                                  >
                                    Activity
                                  </a>
                                  <br />

                                  <br />
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                // <div className="text-center mt-4">
                //   <img src={Loader} height={"50px"}></img>
                // </div>
                <div className="loading-overlay">
                  <img src={Loader} alt="Loading..." />
                </div>
              )}

              <div
                className={`modal ${modelOpenAwb ? "show" : ""} bg-black-7`}
                style={{
                  display: modelOpenAwb ? "block" : "none",
                  overflowY: "auto",
                }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-sm"
                  role="document"
                >
                  <div className="modal-content rounded-3">
                    <div className="modal-header">
                      <h5 className="modal-title">AWB Number List</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModalAwb}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div
                      className="modal-body"
                      style={{ maxHeight: "80vh", overflowY: "auto" }}
                    >
                      <div className="row">
                        <div className="col form-group">
                          <ul className="list-group">
                            {awbList.map((item, index) => (
                              <li
                                className="list-group-item list-group-item-primary"
                                key={index}
                              >
                                {item.awbNo}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal ${modelOpenOrderId ? "show" : ""} bg-black-7`}
                style={{
                  display: modelOpenOrderId ? "block" : "none",
                  overflowY: "auto",
                }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-sm"
                  role="document"
                >
                  <div className="modal-content rounded-3">
                    <div className="modal-header">
                      <h5 className="modal-title">Order Id List</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModalOrderId}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div
                      className="modal-body"
                      style={{ maxHeight: "80vh", overflowY: "auto" }}
                    >
                      <div className="row">
                        <div className="col form-group">
                          <ul className="list-group">
                            {orderIdList.map((item, index) => (
                              <li
                                className="list-group-item list-group-item-primary"
                                key={index}
                              >
                                {item.orderId}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseListPageByUrl;
