import React from "react";
import note from "../../assets/img/note.png";
import Box from "@mui/material/Box";
import styles from "./NotesComp.module.css";

function NotesComp({ text }) {
  return (
    <Box className="mt-3">
      {" "}
      <div className={styles.note}>
        <img src={note} className="me-2 note-icon" alt="Note Icon" />
        <span className="fw-bold note-label" style={{ fontWeight: "bold" }}>
          Note:
        </span>
        {text}
      </div>
    </Box>
  );
}

export default NotesComp;
