import spiner from "../../assets/img/spiner.gif";
const ExportModalComponent = ({
  isModalOpen,
  handleCloseModal,
  from,
  handleConfirm,
}) => (
  <div
    className={`modal ${isModalOpen ? "show" : ""} bg-black-7`}
    style={{ display: isModalOpen ? "block" : "none" }}
    tabIndex="-1"
    role="dialog"
  >
    <div
      className="modal-dialog modal-dialog-centered modal-xl"
      role="document"
    >
      <div className="modal-content rounded-3">
        <div className="modal-header">
          <h5 className="modal-title">Download Excel</h5>
          <button type="button" className="close" onClick={handleCloseModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body " style={{ width: "100%" }}>
          {from !== "" ? (
            <p>Click here to download</p>
          ) : (
            <p>Please wait , Download button will appear soon... </p>
          )}
          {from !== "" ? (
            <button
              className="btn ripple btn-primary"
              type="button"
              onClick={handleConfirm}
            >
              Download
            </button>
          ) : (
            <img src={spiner} height={"50px"}></img>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ExportModalComponent;
