import React, { useState, useEffect } from "react";
import Content from "../Components/Content";
import Employee from "../Employee";
import MainPage3 from "./MainPage";
import DropdownMenu from "../Components/DropdownMenu";
import Prince from "./Prince";
import MainPag from "./Date";
import Content2 from "./Content2";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopHeader from "./TopHeader";
import Loader from "../assets/img/loader.gif";

function MainPage2() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [action, setAction] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [empToggle, setEmpToggle] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigation = useNavigate();
  // console.log(search);
  const apiUrl = process.env.REACT_APP_URL;
  // console.log(apiUrl);

  const handleEyeIconClick = () => {
    setShowModal(true);
  };
  function cleanString(str) {
    // Remove square brackets and double quotes
    let cleanedStr = str.replace(/[\[\]"]/g, "");
    // Remove the first comma
    cleanedStr = cleanedStr.replace(",", "");
    return cleanedStr;
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const loadcontent = (id) => {
    // console.log(id); // Check the value of id
    navigation(`/Content2/${id}`);
  };
  const deletecontent = (id) => {
    const Token = localStorage.getItem("Token");
    // console.log("Token:", Token);

    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/employee/employeeDelete/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
          // "Content-Type": "application/json" // Adjust content type if needed
        },
      })
        .then((res) => {
          if (res.ok) {
            toast.success("Deleted SuccessFully");
            window.location.reload(false);
          } else {
            toast.error("Failed to delete");
            throw new Error("Failed to delete");
          }
        })
        .catch((err) => {
          //   console.log(err);
          toast.error(err.message);
          // Handle error appropriately, maybe show an error message
        });
    }
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");
    // console.log("Token:", Token);

    fetch(`${apiUrl}/employee/employees`, {
      headers: {
        Authorization: `Bearer ${Token}`, // Include the Token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setUsers(data.data);
            setTimeout(() => {
              setLoading(false);
            }, 700);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  let filteredUsers = users.filter((user) => {
    return (
      user.id.toString().includes(search) ||
      user.firstName.includes(search) ||
      user.lastName.includes(search) ||
      user.email.toString().includes(search) ||
      user.mobileNumber.toString().includes(search)
    );
  });

  const toggleEmployee = async (id, status) => {
    //  console.log(id, status);
    const Token = localStorage.getItem("Token");
    const response = await fetch(
      `${apiUrl}/employee/employeeStatus/${id}/${+status}`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      }
    );

    setUsers(
      users.map((user) => {
        return {
          ...user,
          status: user.id === id ? status : user.status,
        };
      })
    );
  };

  return (
    <>
      {/* Page */}

      {/* Main Header*/}
      <div className="page">
        <TopHeader />
        <Prince />

        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Employee List
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">HRMS </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Employee List{" "}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <Link
                      to="/Employee"
                      // className="btn btn-primary my-2 btn-icon-text"
                      className="btn btn-outline-primary"
                      type="button"
                    >
                      {" "}
                      <i className="fe fe-plus me-2" /> Add Employee
                    </Link>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body py-3">
                      <div className="row">
                        <div className="col-sm-6">
                          <input
                            type="search"
                            className="form-control form-control"
                            placeholder="Search..."
                            aria-controls="example1"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>

                        {loading ? (
                          // <div className="col-lg-6">
                          //   <img src={Loader} height={"50px"}></img>
                          // </div>
                          <div className="loading-overlay">
                            <img src={Loader} alt="Loading..." />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>{" "}
                    </div>
                  </div>
                </div>{" "}
              </div>
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          className="table table-striped table-bordered text-nowrap mb-0"
                          style={{ tableLayout: "auto" }}
                        >
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Photo</th>
                              <th>Contact</th>
                              <th>Onboarding</th>
                              <th>Department</th>
                              {/* <th>KYC</th> */}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.map((user) => (
                              <tr key={user.id}>
                                <td>
                                  EMP-{user.id}
                                  <br />
                                </td>
                                <td>
                                  <img
                                    alt="avatar"
                                    className="rounded-circle me-3"
                                    src={
                                      user.profilePic
                                        ? user.profilePic
                                        : `${apiUrl}/uploads/man404.jpg`
                                    }
                                    style={{ width: 60 }}
                                  />
                                </td>
                                <td>
                                  <p className="mb-0">
                                    {user.firstName} {user.lastName}
                                    <br />
                                    M: {user.mobileNumber}
                                    <br />
                                    E: {user.email}
                                    <br />
                                    DOB: {user.dob}
                                    <br />
                                    Gender: {user.gender}
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0">
                                    E: {user.email}
                                    <br />
                                    M: {user.mobileNumber}
                                  </p>
                                </td>
                                <td>
                                  <p>
                                    {user.department}
                                    <br />
                                    {user.designation}
                                    <br />
                                    DOJ : {user.dateOfJoin}
                                    <br />
                                    State : {cleanString(user.state)}
                                    <br />
                                    Business Category :{" "}
                                    {cleanString(user.businessCategory)}
                                    <br />
                                    Lead Source : {cleanString(user.leadSource)}
                                    <br />
                                    Client Persona :{" "}
                                    {cleanString(user.clientPersona)}
                                    <br />
                                    Shipment Potential :{" "}
                                    {cleanString(user.shipmentPotential)}
                                  </p>
                                </td>

                                <td>
                                  <div className="main-toggle-group-demo">
                                    <div
                                      className={`main-toggle main-toggle-success ${
                                        user.status ? `on` : `off`
                                      }`}
                                      onClick={() =>
                                        toggleEmployee(user.id, !user.status)
                                      }
                                    >
                                      <span />
                                    </div>
                                  </div>

                                  <a
                                    onClick={() => loadcontent(user.id)}
                                    className="mt-1 btn ripple btn-info btn-xs w-100"
                                  >
                                    Edit Profile
                                  </a>
                                  <br />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
            </div>
          </div>
        </div>
        {/* End Main Content*/}
        {/* Main Footer*/}
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">Fship</a>. Developed by{" "}
                  <a href="http://fship.in/">FSHIP</a> All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*End Footer*/}
      </div>
      {/* End Page */}
      {/* Large Modal */}

      {/*End Large Modal */}
      <div className="modal" id="modaldemo-pan">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">PAN</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <img
                    src="https://newadmin.webkype.com/assets/img/pngs/default-img.gif"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-sm-4">
                  <h6>Pan No. : CLDPK8793</h6>
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Large Modal */}
      {/*End Large Modal */}
      <div className="modal" id="modaldemo-cheque">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Cheque</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <img
                    src="https://newadmin.webkype.com/assets/img/pngs/cheque-img.jpg"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-sm-4">
                  <h6>Cheque No. : 0123456789</h6>
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Large Modal */}
      {/*End Large Modal */}
      <div className="modal" id="modaldemo-address">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Address</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <p>
                    3-15/10/403 Newark, Street no 5, Next To Pizza Hut,
                    Bangalore, Karnataka, 560003, India.
                  </p>
                  <p className="mb-0">soniataylor344@example.com</p>
                  <p>+91 08023310451</p>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Large Modal */}
      {/*End Large Modal */}
      <div className="modal" id="modaldemo-employee-form">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Employee Form</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="row row-sm">
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Department</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                      <option>Sales</option>
                      <option>Marketing</option>
                      <option>Accounts</option>
                      <option>Procurements</option>
                      <option>Contracting</option>
                      <option>Transport</option>
                      <option>Legal</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Designation</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                      <option>Executive</option>
                      <option>Manager</option>
                      <option>Intern</option>
                    </select>
                  </div>
                  <div className="col-lg-4 form-group">
                    <label className="form-label">
                      Date of Joining: <span className="tx-danger">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-text border-end-0">
                        <i className="fe fe-calendar lh--9 op-6" />
                      </div>
                      <input
                        className="form-control fc-datepicker"
                        placeholder="MM/DD/YYYY"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Reporting Boss-A</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Reporting Boss-B</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-12 form-group mb-0">
                    <div className="row">
                      <div className="col-sm-12">
                        {" "}
                        <label className="form-label">Assign Permission</label>
                      </div>
                      <div className="col-sm-12">
                        <div className="parsley-checkbox row" id="cbWrapper">
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              <input
                                data-parsley-class-handler="#cbWrapper"
                                data-parsley-errors-container="#cbErrorContainer"
                                data-parsley-mincheck={2}
                                name="browser[]"
                                required=""
                                type="checkbox"
                                defaultValue={1}
                              />
                              <span>Sales</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={2}
                              />
                              <span>Marketing</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={3}
                              />
                              <span>Accounts</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Procurements</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Contracting</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Transport</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Legal</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn ripple btn-primary" type="button">
                Save changes
              </button>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
      {/*End Large Modal */}
      {/* Back-to-top */}
      <a href="#top" id="back-to-top">
        <i className="fe fe-arrow-up" />
      </a>
    </>
  );
}

export default MainPage2;
