import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopHeader from "../Components/TopHeader";
import Prince from "../Components/Prince";
import { useNavigate } from "react-router-dom";

function Case() {
  const Token = localStorage.getItem("Token");
  const initialBodyData = {
    name: "",
    discription: "",
  };

  const apiUrl = process.env.REACT_APP_URL;

  const [cases, setCases] = useState([]);
  const [bodyData, setbodyData] = useState(initialBodyData);

  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [discription, setDiscription] = useState("");

  const loadcontent = (id) => {
    setId(id);
    setIsModalOpen1(true);
  };
  function handleChange(event) {
    const { name, value } = event.target;
    setbodyData((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(event.target);
  }

  useEffect(() => {
    const fetchCase = async () => {
      try {
        let result = await fetch(`${apiUrl}/caseMaster/getCaseType/${id}`, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        result = await result.json();

        const { data } = result;

        setId(data.id);
        setName(data.name);
        setDiscription(data.description);
      } catch (e) {
        console.log(e);
      }
    };

    if (id === "" || id === undefined) {
    } else {
      fetchCase();
    }
  }, [id]);

  async function handleUpdate() {
    try {
      const caseData = {
        name,
        discription,
      };
      let response = await fetch(`${apiUrl}/caseMaster/updateCaseType/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
        body: JSON.stringify(caseData),
      });

      response = await response.json();

      if (response.status === "success") {
        toast.success("Case Updated Successfully");
        window.location.reload();
      } else {
        toast.error("Master Update Failed");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const bodyDataToSend = new FormData();
    for (const key in bodyData) {
      if (bodyData[key] !== null) {
        bodyDataToSend.append(key, bodyData[key]);
      }
    }

    try {
      const response = await fetch(`${apiUrl}/caseMaster/addCaseType`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: bodyDataToSend,
      });
      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message);
      }
      toast.success("Case added successfully");
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getCaseType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCases(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const filteredUsers = cases.filter((user) => {
    return user.name.toString().includes(search);
  });
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleOpenModal1 = () => {
    setIsModalOpen1(true);
  };

  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />

        <div
          className={`modal ${isModalOpen ? "show" : ""} bg-black-7`}
          style={{ display: isModalOpen ? "block" : "none" }}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content rounded-3">
              <div className="modal-header">
                <h5 className="modal-title">Add Case</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div style={{ width: "100%" }}>
                <div className="card-body">
                  <div>
                    <h6 className="main-content-label mb-3"></h6>
                  </div>
                  <div className="row row-sm">
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Case type</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter case"
                        name="name"
                        value={bodyData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-8  form-group">
                      <label className="form-label">
                        Description(Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Typing..."
                        name="discription"
                        value={bodyData.discription}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 pl-0">
                    <a
                      className="btn btn-outline-danger"
                      type="submit"
                      onClick={handleSubmit}
                      style={{ color: "#fff" }}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal ${isModalOpen1 ? "show" : ""} bg-black-7`}
          style={{ display: isModalOpen1 ? "block" : "none" }}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content rounded-3">
              <div className="modal-header">
                <h5 className="modal-title">Edit Case</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal1}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div style={{ width: "100%" }}>
                <div className="card-body">
                  <div>
                    <h6 className="main-content-label mb-3"></h6>
                  </div>
                  <div className="row row-sm">
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Case type</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter case"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-8  form-group">
                      <label className="form-label">
                        Description(Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Typing..."
                        name="discription"
                        value={discription}
                        onChange={(e) => setDiscription(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 pl-0">
                    <a
                      className="btn btn-outline-danger"
                      type="submit"
                      onClick={handleUpdate}
                      style={{ color: "#fff" }}
                    >
                      Update
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Case List</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Case</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Cases{" "}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={handleOpenModal}
                    >
                      <i className="fe fe-plus me-2" /> Add Case
                    </button>{" "}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body py-3">
                      <div className="row">
                        <div className="col-sm-6 input-group">
                          <span className="input-group-text" id="basic-addon1">
                            @
                          </span>
                          <input
                            type="search"
                            className="form-control form-control"
                            placeholder="Search..."
                            aria-controls="example1"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>{" "}
              </div>
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered text-nowrap mb-0 text-center">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.map((c) => (
                              <tr key={c.id}>
                                <td> {c.id} </td>
                                <td>
                                  {c.name}
                                  <br />
                                </td>
                                <td>
                                  {c.description}
                                  <br />
                                </td>
                                <td>
                                  <button
                                    onClick={() => loadcontent(c.id)}
                                    className="btn btn-outline-danger btn-sm"
                                  >
                                    Edit Case
                                  </button>
                                  <br />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Row */}
            </div>
          </div>
        </div>

        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">Fship</a>. Designed by{" "}
                  <a href="https://fship.in">FSHIP</a> All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*End Footer*/}
      </div>

      <div className="modal" id="modaldemo-pan">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">PAN</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <img
                    src="https://newadmin.webkype.com/assets/img/pngs/default-img.gif"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-sm-4">
                  <h6>Pan No. : CLDPK8793</h6>
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="modaldemo-cheque">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Cheque</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <img
                    src="https://newadmin.webkype.com/assets/img/pngs/cheque-img.jpg"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-sm-4">
                  <h6>Cheque No. : 0123456789</h6>
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="modaldemo-address">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Address</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <p>
                    3-15/10/403 Newark, Street no 5, Next To Pizza Hut,
                    Bangalore, Karnataka, 560003, India.
                  </p>
                  <p className="mb-0">soniataylor344@example.com</p>
                  <p>+91 08023310451</p>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="modaldemo-employee-form">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Employee Form</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="row row-sm">
                  <div className="col-sm-4 form-group">
                    <label className="form-label">Company</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Department</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                      <option>Sales</option>
                      <option>Marketing</option>
                      <option>Accounts</option>
                      <option>Procurements</option>
                      <option>Contracting</option>
                      <option>Transport</option>
                      <option>Legal</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Designation</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                      <option>Executive</option>
                      <option>Manager</option>
                      <option>Intern</option>
                    </select>
                  </div>
                  <div className="col-lg-4 form-group">
                    <label className="form-label">
                      Date of Joining: <span className="tx-danger">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-text border-end-0">
                        <i className="fe fe-calendar lh--9 op-6" />
                      </div>
                      {/* <input
                        className="form-control fc-datepicker"
                        placeholder="MM/DD/YYYY"
                        type="text"
                      /> */}
                    </div>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Reporting Boss-A</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Reporting Boss-B</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-12 form-group mb-0">
                    <div className="row">
                      <div className="col-sm-12">
                        {" "}
                        <label className="form-label">Assign Permission</label>
                      </div>
                      <div className="col-sm-12">
                        <div className="parsley-checkbox row" id="cbWrapper">
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              {/* <input
                                data-parsley-class-handler="#cbWrapper"
                                data-parsley-errors-container="#cbErrorContainer"
                                data-parsley-mincheck={2}
                                name="browser[]"
                                required=""
                                type="checkbox"
                                defaultValue={1}
                              /> */}
                              <span>Sales</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              {/* <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={2}
                              /> */}
                              <span>Marketing</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              {/* <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={3}
                              /> */}
                              <span>Accounts</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              {/* <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              /> */}
                              <span>Procurements</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              {/* <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              /> */}
                              <span>Contracting</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              {/* <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              /> */}
                              <span>Transport</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              {/* <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              /> */}
                              <span>Legal</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn ripple btn-primary" type="button">
                Save changes
              </button>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
      {/*End Large Modal */}
      {/* Back-to-top */}
      <a href="#top" id="back-to-top">
        <i className="fe fe-arrow-up" />
      </a>
    </>
  );
}

export default Case;
