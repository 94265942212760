import React from "react";

const Pagination = ({
  currentUrl,
  currentPageNo,
  totalCount,
  startIndex,
  endIndex,
  totalPages,
}) => {
  let page = "?page=";
  if (currentUrl?.includes("?")) {
    page = "&page=";
  }

  return (
    <nav
      aria-label="Page navigation"
      className={`d-flex justify-content-end mt-3 ${
        totalCount === 0 ? "d-none" : ""
      }`}
    >
      <ul className="pagination w-100" style={{ overflowY: "auto" }}>
        <li className="page-item">
          <a
            className="page-link"
            style={{ minWidth: "235px" }}
            href={`${currentUrl}${page}${currentPageNo - 1}`}
            aria-label="Previous"
          >
            Showing {startIndex + 1} to {endIndex + 1} of {totalCount} results
          </a>
        </li>
        {currentPageNo > 1 && (
          <li className="page-item">
            <a
              className="page-link"
              href={`${currentUrl}${page}${currentPageNo - 1}`}
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
        )}
        {[...Array(totalPages).keys()].map((i) => (
          <li
            key={i + 1}
            className={`page-item ${i + 1 === currentPageNo ? "active" : ""}`}
          >
            <a className="page-link" href={`${currentUrl}${page}${i + 1}`}>
              {i + 1}
            </a>
          </li>
        ))}
        {currentPageNo < totalPages && (
          <li className="page-item">
            <a
              className="page-link"
              href={`${currentUrl}${page}${currentPageNo + 1}`}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
