import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import "../../Pages/NewAssignCase.css";

function SelectDropdown({ dropDownData, handleChange, selectedData, Text }) {
  const renderValue = (value) => {
    const selectedElement = dropDownData.find((item) => item.name === value);
    return selectedElement ? (
      <span style={{ fontSize: "12px" }}>{selectedElement.name}</span>
    ) : (
      ""
    );
  };
  const handleSelectChange = (event) => {
    const selectedName = event.target.value;
    const selectedItem = dropDownData.find(
      (item) => item.name === selectedName
    );
    handleChange(selectedItem); // Pass the complete item object
  };

  return (
    <Box>
      <FormControl sx={{ minWidth: 400 }} size="small">
        <InputLabel
          id="select-category-label"
          sx={{
            fontSize: "0.75rem",
            fontWeight: "400",
            marginTop: "3px",
            color: "#000",
          }}
        >
          {Text}
        </InputLabel>
        <Select
          labelId="select-category-label"
          id="select-category"
          value={selectedData}
          label="Select Category"
          onChange={handleSelectChange}
          renderValue={renderValue}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {dropDownData.map((item) => (
            <MenuItem
              key={item.id}
              value={item.name}
              style={{ width: "400px" }}
            >
              <div className="menu-item-content">
                <p className="heading">{item.name}</p>
                <p className="subheading">{item.description}</p>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectDropdown;
