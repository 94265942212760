import React from "react";
import { useState, useRef } from "react";
import Box from "@mui/material/Box";
import cloudIcon from "../../assets/img/cloudIcone.png";
import docIcon from "../../assets/img/pngegg.png";
import styles from "./BrowesFileBox.module.css";

function BrowesFileBox({
  handleDeleteFile,
  handleFileChange,
  selectedFile,
  text,
  url,
}) {
  const fileInputRef = useRef(null);
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      {" "}
      <Box
        sx={{
          mt: 2,
        }}
      >
        <p className={styles.heading}>
          OR
          <br />
          {text}
        </p>
        <a href={url} className={styles.subheading}>
          <>
            {" "}
            <img
              src="https://app.shiprocket.in/support-web/assets/icons/download.svg"
              alt="icone"
            />{" "}
            Download Sample xlsx Format Only.{" "}
          </>
        </a>
        {!selectedFile && (
          <div className={styles.selectedfile}>
            <div>
              <label style={{ cursor: "pointer" }}>
                <img src={cloudIcon} alt="Cloud Icon" />
              </label>
              <input
                type="file"
                id="file-upload"
                accept=".xlsx"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <p className={styles.dragdrop}>
                Drag & Drop to Upload File.
                <br />
                OR
              </p>

              <button
                type="button"
                className={styles.button}
                onClick={handleBrowseClick}
              >
                Browse File
              </button>
            </div>
          </div>
        )}
        <Box>
          {" "}
          {selectedFile && (
            <div className={styles.preview}>
              <div style={{ display: "flex" }}>
                {selectedFile.type === "image/jpeg" ||
                selectedFile.type === "image/png" ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Selected File"
                    style={{ maxHeight: "40px" }}
                  />
                ) : (
                  <img src={docIcon} alt="file" style={{ maxHeight: "40px" }} />
                )}
                <div className={styles.previewname}>{selectedFile.name}</div>
              </div>
              <button onClick={handleDeleteFile} className={styles.button}>
                Delete
              </button>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
}

export default BrowesFileBox;
