import React, { useState, useEffect, useContext } from "react";

import { MdDateRange } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import Prince from "../Components/Prince";

import { useNavigate, Link } from "react-router-dom";
import TopHeader from "../Components/TopHeader";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";

import { FaUserFriends } from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { SiGoogleadsense } from "react-icons/si";
import { FaUserGraduate } from "react-icons/fa";
import { FaUserSlash } from "react-icons/fa";
import { FaPiggyBank } from "react-icons/fa6";
import { MdFiberNew } from "react-icons/md";
import { MdAddCall } from "react-icons/md";
import { RiChatFollowUpFill } from "react-icons/ri";
import { MdConnectWithoutContact } from "react-icons/md";
import { FaHandSparkles } from "react-icons/fa";

import { MdLocalOffer } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";
import { GrIntegration } from "react-icons/gr";
import { FaThumbsUp } from "react-icons/fa";
import { BsGraphDownArrow } from "react-icons/bs";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaClock } from "react-icons/fa";
import { MdOutlinePendingActions } from "react-icons/md";
import { MdIncompleteCircle } from "react-icons/md";
import { MdOutlineWifiCalling } from "react-icons/md";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Tilt } from "react-tilt";
import { DataContext } from "../context/DataContext";
import Chart from "../Components/Chart";

const Firstpage = () => {
  const result = useContext(DataContext);

  const [employee, setEmployee] = useState({});
  const [allCount, setAllCount] = useState("");

  const [employeeId, setEmployeeId] = useState([]);

  const [leadCount, setLeadCount] = useState("");
  const [leadCounts, setLeadCounts] = useState("");
  const [leadCounts1, setLeadCounts1] = useState("");
  const [leadCounts2, setLeadCounts2] = useState("");
  const [leadCounts3, setLeadCounts3] = useState("");
  const [leadCounts4, setLeadCounts4] = useState("");
  const [leadCounts5, setLeadCounts5] = useState("");
  const [leadCounts6, setLeadCounts6] = useState("");
  const [leadCounts7, setLeadCounts7] = useState("");
  const [leadCounts8, setLeadCounts8] = useState("");
  const [leadCounts9, setLeadCounts9] = useState("");
  const [leadCounts10, setLeadCounts10] = useState("");
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [leadCounts11, setLeadCounts11] = useState("");
  const [leadCounts12, setLeadCounts12] = useState("");
  const [leadCounts13, setLeadCounts13] = useState("");
  const [leadCounts14, setLeadCounts14] = useState("");
  const [leadCounts15, setLeadCounts15] = useState("");
  const [leadCounts16, setLeadCounts16] = useState("");
  const [leadCounts17, setLeadCounts17] = useState("");
  const [leadCounts18, setLeadCounts18] = useState("");
  const [leadCounts19, setLeadCounts19] = useState("");
  const [leadCounts20, setLeadCounts20] = useState("");
  const [leadCounts21, setLeadCounts21] = useState("");
  const [leadCounts22, setLeadCounts22] = useState("");
  const [leadCounts23, setLeadCounts23] = useState("");
  const [leadCounts24, setLeadCounts24] = useState("");
  const [leadCounts25, setLeadCounts25] = useState("");
  const [leadCounts26, setLeadCounts26] = useState("");
  const [leadCounts27, setLeadCounts27] = useState("");
  const [allCaseCount, setAllCaseCount] = useState("");

  const initialFormData = {
    id: "",
    from: "",
    to: "",
  };
  const initialObject = {
    from: "",
    to: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const [filterByObj, setFilterByObj] = useState(initialObject);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const defaultOptions = {
    reverse: false,
    max: 35,
    perspective: 1000,
    scale: 1.1,
    speed: 1000,
    transition: true,
    axis: null,
    reset: true,
    easing: "cubic-bezier(.03,.98,.52,.99)",
  };

  const handleDateChange1 = (ranges) => {
    const adjustedStartDate = new Date(ranges.selection.startDate);
    adjustedStartDate.setHours(23, 59, 59, 999);

    const adjustedEndDate = new Date(ranges.selection.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    setFilterByObj({
      ...filterByObj,
      to: adjustedEndDate.toISOString(),
      from: adjustedStartDate.toISOString(),
    });

    setDate(ranges.selection);
  };

  const handleDateFilterClick = () => {
    setDateFilterModalOpen(true);
  };
  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };
  useEffect(() => {
    setEmployee(result.employeeData);
  });
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/employee/empDropdownSub`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const fetchDataFromApi = () => {
    const url = `${apiUrl}/employee/employeeDashboard?id=${
      formData.id
    }&to=${filterByObj.to?.substring(0, 10)}&from=${filterByObj.from?.substring(
      0,
      10
    )}`;
    // console.log(filterByObj);
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setLeadCount(data.data.leadCount);
          setLeadCounts(data.data.openLeadCount);
          setLeadCounts1(data.data.wonLeadCount);
          setLeadCounts2(data.data.lostLeadCount);
          setLeadCounts3(data.data.kycLeadCount);
          setLeadCounts4(data.data.newLeadCount);
          setLeadCounts5(data.data.callattemptedLeadCount);
          setLeadCounts6(data.data.contactinfutureLeadCount);
          setLeadCounts7(data.data.followUPCount);
          setLeadCounts8(data.data.proposalLeadCount);
          setLeadCounts9(data.data.negotiationLeadCount);
          setLeadCounts10(data.data.integrationinprogressLeadCount);
          setLeadCounts11(data.data.closeCount);
          setLeadCounts12(data.data.loststageCount);
          setLeadCounts13(data.data.overDueActivityCount);
          setLeadCounts14(data.data.pendingActivityCount);
          setLeadCounts15(data.data.completedActivityCount);
          setLeadCounts16(data.data.newCallCount);
          setLeadCounts17(data.data.customerCount);
          setLeadCounts18(data.data.newCaseCount);
          setAllCaseCount(data.data?.allCaseCount);
          setLeadCounts19(data.data.closedCount);
          setLeadCounts20(data.data.pendingOnTechCount);
          setLeadCounts21(data.data.pendingOnFirstMileOpsCount);
          setLeadCounts22(data.data.pendingOnLastMileOpsCount);
          setLeadCounts23(data.data.pendingOnWeightTeamCount);
          setLeadCounts24(data.data.pendingOnSalesCount);
          setLeadCounts25(data.data.pendingOnLegalCount);
          setLeadCounts26(data.data.pendingOnClientCount);
          setLeadCounts27(data.data.inProcessCaseCount);
          setAllCount(data.data);
        } else {
          console.error("API request was not successful:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchDataFromApi();
  }, [, formData.id, filterByObj.to, filterByObj.from]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNewClick = (stage) => {
    navigate("/leads/Open", { state: { stage } });
  };

  const handleInprogressClick = (pending) => {
    navigate("/cases/In Progress", { state: { pending } });
  };
  // const handleCallAttemptedClick = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };
  // const handleContactInFutureClick = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };
  // const handleFollowUpClick = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };
  // const handleProposalClick = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };
  // const handleNegotiationClick = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };
  // const handleIntegrationClick = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };

  // const handleclosedWon = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };
  // const handleLostClick = (stage) => {
  //   navigate("/leads/Open", { state: { stage } });
  // };
  console.log(employee);
  return (
    <>
      <div className="page">
        <TopHeader />
        <Prince />
        <div className="main-content  pt-0" style={{ backgroundColor: "#fff" }}>
          <div className="main-container container-fluid ">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <div
                    className="row align-items-center p-2 rounded-3 "
                    style={{ backgroundColor: "rgb(62, 72, 112)" }}
                  >
                    <img
                      src={
                        employee.profilePic
                          ? employee.profilePic
                          : `${apiUrl}/uploads/man404.jpg`
                      }
                      alt="user-img"
                      style={{
                        width: "85px",
                        height: "60px",
                        borderRadius: "100%",
                        left: 35,
                      }}
                    />
                    <div
                      className="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12 "
                      style={{ marginLeft: "1px" }}
                    >
                      <h4 className="d-flex  mb-1" style={{ width: "200px" }}>
                        <span className="font-weight-bold text-white mt-2 ">
                          <span
                            style={{
                              fontStyle: "italic",
                              fontSize: "24px",
                              fontFamily: "cursive",
                              marginRight: "7px",
                            }}
                          >
                            Hello
                          </span>{" "}
                          <span
                            style={{
                              // fontStyle: "italic",
                              fontSize: "20px",
                              fontFamily: "cursive",
                            }}
                          >
                            {employee?.firstName?.charAt(0).toUpperCase() +
                              employee?.firstName?.slice(1)}{" "}
                          </span>
                          <FaHandSparkles
                            size={32}
                            style={{ display: "inline" }}
                          />
                          <hr className="mt-0 mb-0" />
                          <p
                            style={{
                              fontSize: "13px",
                              float: "right",
                              fontWeight: "200",
                              // color: "#577B8D",
                              // fontFamily: "fantasy",
                            }}
                          >
                            Fship Business Dashboard
                          </p>
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div></div>

                <div className="d-flex">
                  <div>
                    <div
                      className=" justify-content-start mt-2"
                      style={{ marginLeft: 0 }}
                    >
                      <button
                        className="calender btn btn-info"
                        onClick={handleDateFilterClick}
                        style={{
                          fontSize: "11px",
                          backgroundColor: "rgb(62, 72, 112)",
                          border: "none",
                        }}
                      >
                        <MdDateRange size={16} style={{ display: "inline" }} />{" "}
                        {`${format(date.startDate, "MMM dd yyyy")} -- ${format(
                          date.endDate,
                          "MMM dd yyyy"
                        )}`}
                      </button>
                      <div
                        className={`modal ${
                          dateFilterModalOPen ? "show" : ""
                        } bg-black-7`}
                        style={{
                          display: dateFilterModalOPen ? "block" : "none",
                        }}
                        tabIndex="-1"
                        role="dialog"
                      >
                        <div
                          className="modal-dialog modal-dialog modal-xl"
                          role="document"
                        >
                          <div
                            className="modal-content"
                            style={{ borderRadius: "8px" }}
                          >
                            <div className="modal-header">
                              <h5 className="modal-title">Select Date Range</h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCloseDateFilterModal}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div
                              className="modal-body"
                              style={{ width: "100%" }}
                            >
                              <DateRangePicker
                                ranges={[date]}
                                onChange={handleDateChange1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <FormControl sx={{ m: 1, width: 250 }} size="small">
                        <InputLabel id="demo-select-small-label">
                          Select
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          name="id"
                          value={formData.id}
                          label="Select"
                          onChange={handleInputChange}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {employeeId.map((option, index) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row row-sm">
                <div className="w-100">
                  {employee.userType === "1" ||
                  employee.userRole === null ||
                  employee.userRole === "" ? (
                    <div>
                      {" "}
                      <h4
                        style={{
                          marginLeft: "6vw",
                          marginBottom: "10px",
                          marginTop: "20px",
                          fontWeight: "900",
                          color: "#173B45",
                        }}
                      >
                        Leads Dashboard
                      </h4>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        {employee.userType === "1" && (
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1">
                            <Tilt options={defaultOptions}>
                              <div className="card custom-card">
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#C5FF95",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <FaUserFriends size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1 col ">
                                        Total Staff
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {employee.staffCount}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        )}
                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/leads/All-leads");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F3D0D7",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <SiGoogleads size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      All Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.leadCount
                                          : leadCount}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>

                        {/* <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/NewLeads/new");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F3D0D7",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <SiGoogleads size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      All Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.leadCount
                                          : leadCount}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div> */}

                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          // onClick={() => {
                          //   navigate("/leads/Open");
                          // }}
                          onClick={() => {
                            handleNewClick("Open");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F6FDC3",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <SiGoogleadsense size={32} />
                                  </div>
                                  <div className="card-item-title  mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Open Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.openLeadCount
                                          : leadCounts}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/leads/Won");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F0EBE3",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaUserGraduate size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Won Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.wonLeadCount
                                          : leadCounts1}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/leads/Lost");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#FEC7B4",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaUserSlash size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Lost Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.lostLeadCount
                                          : leadCounts2}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : employee.userRole === "Agent" ? (
                    <div>
                      {" "}
                      <h4
                        style={{
                          marginLeft: "6vw",
                          marginBottom: "10px",
                          marginTop: "20px",
                          fontWeight: "900",
                          color: "#173B45",
                        }}
                      >
                        Leads Dashboard
                      </h4>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        {employee.userType === "1" && (
                          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1">
                            <Tilt options={defaultOptions}>
                              <div className="card custom-card">
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#C5FF95",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <FaUserFriends size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1 col ">
                                        Total Staff
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {employee.staffCount}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        )}

                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/leads/All-leads");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F3D0D7",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <SiGoogleads size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      All Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.leadCount
                                          : leadCount}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>

                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/leads/Open");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F6FDC3",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <SiGoogleadsense size={32} />
                                  </div>
                                  <div className="card-item-title  mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Open Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.openLeadCount
                                          : leadCounts}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/leads/Won");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F0EBE3",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaUserGraduate size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Won Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.wonLeadCount
                                          : leadCounts1}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-8 col-lg-8 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/leads/Lost");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#FEC7B4",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaUserSlash size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Lost Leads
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.lostLeadCount
                                          : leadCounts2}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {employee.userType === "1" ||
                  employee.userRole === null ||
                  employee.userRole === "" ? (
                    <div>
                      <h4
                        style={{
                          marginLeft: "6vw",
                          marginBottom: "10px",
                          marginTop: "20px",
                          fontWeight: "900",
                          color: "#173B45",
                        }}
                      >
                        Sales Dashboard
                      </h4>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2">
                          <div className="card custom-card mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#FFFDD7",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaPiggyBank size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      KYC Complete
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.kycLeadCount
                                          : leadCounts3}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/new");
                          // }}
                          onClick={() => {
                            handleNewClick("new");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#ACE2E1",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdFiberNew size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      New
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.newLeadCount
                                          : leadCounts4}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/call-attempted");
                          // }}
                          onClick={() => {
                            handleNewClick("call-attempted");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F1EF99",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdAddCall size={32} />
                                  </div>
                                  <div className="card-item-title  mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Call Attempted
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.callattemptedLeadCount
                                          : leadCounts5}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/contact-in-future");
                          // }}
                          onClick={() => {
                            handleNewClick("contact-in-future");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#58A399",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdConnectWithoutContact size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Contact In-Future
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.contactinfutureLeadCount
                                          : leadCounts6}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/follow-up");
                          // }}
                          onClick={() => {
                            handleNewClick("follow-up");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#8DECB4",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <RiChatFollowUpFill size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Follow Up
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.followUPCount
                                          : leadCounts7}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/proposal");
                          // }}
                          onClick={() => {
                            handleNewClick("proposal");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#C6EBC5",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdLocalOffer size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Proposal
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.proposalLeadCount
                                          : leadCounts8}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/negotiation");
                          // }}
                          onClick={() => {
                            handleNewClick("negotiation");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#D6DAC8",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaHandshake size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Negotiation
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.negotiationLeadCount
                                          : leadCounts9}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/integration-inprogress");
                          // }}
                          onClick={() => {
                            handleNewClick("integration-inprogress");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F9F7C9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <GrIntegration size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      In-Integration
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.integrationinprogressLeadCount
                                          : leadCounts10}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/closed-won-SYTS");
                          // }}
                          onClick={() => {
                            handleNewClick("closed-won-SYTS");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#7FC7D9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaThumbsUp size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      closed-won-SYTS
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.closeCount
                                          : leadCounts11}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/lost");
                          // }}
                          onClick={() => {
                            handleNewClick("lost");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#B4D4FF",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <BsGraphDownArrow size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Lost
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.loststageCount
                                          : leadCounts12}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/overdue">
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#FF8F8F",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <FaClock size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Overdue
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee.overDueActivityCount
                                            : leadCounts13}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/pending">
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#A3B763",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlinePendingActions size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingActivityCount
                                            : leadCounts14}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/completed">
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#B0A695",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdIncompleteCircle size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Completed
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.completedActivityCount
                                            : leadCounts15}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>

                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/newCall">
                            <div className="card custom-card mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#DAFFFB",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlineWifiCalling size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        New Calls
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.newCallCount
                                            : leadCounts16}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>

                        {/* col end */}
                      </div>
                    </div>
                  ) : employee.userRole === "Agent" ? (
                    <div>
                      <h4
                        style={{
                          marginLeft: "6vw",
                          marginBottom: "10px",
                          marginTop: "20px",
                          fontWeight: "900",
                          color: "#173B45",
                        }}
                      >
                        Sales Dashboard
                      </h4>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2">
                          <div className="card custom-card mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#FFFDD7",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaPiggyBank size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      KYC Complete
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.kycLeadCount
                                          : leadCounts3}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/new");
                          // }}
                          onClick={() => {
                            handleNewClick("new");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#ACE2E1",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdFiberNew size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      New
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.newLeadCount
                                          : leadCounts4}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/call-attempted");
                          // }}
                          onClick={() => {
                            handleNewClick("call-attempted");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F1EF99",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdAddCall size={32} />
                                  </div>
                                  <div className="card-item-title  mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Call Attempted
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.callattemptedLeadCount
                                          : leadCounts5}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/contact-in-future");
                          // }}
                          onClick={() => {
                            handleNewClick("contact-in-future");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#58A399",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdConnectWithoutContact size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Contact In-Future
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.contactinfutureLeadCount
                                          : leadCounts6}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/follow-up");
                          // }}
                          onClick={() => {
                            handleNewClick("follow-up");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#8DECB4",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <RiChatFollowUpFill size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Follow Up
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.followUPCount
                                          : leadCounts7}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/proposal");
                          // }}
                          onClick={() => {
                            handleNewClick("proposal");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#C6EBC5",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdLocalOffer size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Proposal
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.proposalLeadCount
                                          : leadCounts8}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/negotiation");
                          // }}
                          onClick={() => {
                            handleNewClick("negotiation");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#D6DAC8",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaHandshake size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Negotiation
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.negotiationLeadCount
                                          : leadCounts9}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/integration-inprogress");
                          // }}
                          onClick={() => {
                            handleNewClick("integration-inprogress");
                          }}
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F9F7C9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <GrIntegration size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      In-Integration
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.integrationinprogressLeadCount
                                          : leadCounts10}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/closed-won-SYTS");
                          // }}
                          onClick={() => {
                            handleNewClick("closed-won-SYTS");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#7FC7D9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaThumbsUp size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      closed-won-SYTS
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.closeCount
                                          : leadCounts11}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/NewLeads/lost");
                          // }}
                          onClick={() => {
                            handleNewClick("lost");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#B4D4FF",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <BsGraphDownArrow size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Lost
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.loststageCount
                                          : leadCounts12}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/overdue">
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#FF8F8F",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <FaClock size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Overdue
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee.overDueActivityCount
                                            : leadCounts13}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/pending">
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#A3B763",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlinePendingActions size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingActivityCount
                                            : leadCounts14}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/completed">
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#B0A695",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdIncompleteCircle size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Completed
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.completedActivityCount
                                            : leadCounts15}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>

                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <Link to="/newCall">
                            <div className="card custom-card mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#DAFFFB",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlineWifiCalling size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        New Calls
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.newCallCount
                                            : leadCounts16}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </Link>
                        </div>

                        {/* col end */}
                      </div>
                    </div>
                  ) : null}
                  {employee.userType === "1" ||
                  employee.userRole === null ||
                  employee.userRole === "" ? (
                    <div>
                      {" "}
                      <h4
                        style={{
                          marginLeft: "6vw",
                          marginBottom: "10px",
                          fontWeight: "900",
                          color: "#173B45",
                          marginTop: "40px",
                        }}
                      >
                        Customer & Case Dashboard
                      </h4>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/customers");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F3D0D7",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <SiGoogleads size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Customer
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.customerCount
                                          : leadCounts17}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          onClick={() => {
                            navigate("/cases/All");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#C6EBC5",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdLocalOffer size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      All Case
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee?.allCaseCount
                                          : allCaseCount}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          onClick={() => {
                            navigate("/cases/New");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#C6EBC5",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdLocalOffer size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      New Case
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.newCaseCount
                                          : leadCounts18}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          onClick={() => {
                            navigate("/cases/Closed");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#D6DAC8",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaHandshake size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Closed Case
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.closedCount
                                          : leadCounts19}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/In Progress");
                          // }}
                          onClick={() => handleInprogressClick("In Progress")}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#D6DA",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <BsGraphUpArrow size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      In Progress
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.inProcessCaseCount
                                          : leadCounts27}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/Pending on Tech");
                          // }}
                          onClick={() =>
                            handleInprogressClick("Pending on Tech")
                          }
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F9F7C9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <GrIntegration size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Pending on Tech
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.pendingOnTechCount
                                          : leadCounts20}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/Pending on First mile ops");
                          // }}
                          onClick={() =>
                            handleInprogressClick("Pending on First mile ops")
                          }
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#7FC7D9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaThumbsUp size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Pending on first mile
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.pendingOnFirstMileOpsCount
                                          : leadCounts21}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/Pending on Last mile Ops");
                          // }}
                          onClick={() =>
                            handleInprogressClick("Pending on Last mile Ops")
                          }
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#B4D4FF",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <BsGraphDownArrow size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Pending on last mile
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.pendingOnLastMileOpsCount
                                          : leadCounts22}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            onClick={() =>
                              handleInprogressClick("Pending on weight team")
                            }
                          >
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#FF8F8F",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <FaClock size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on weight
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee.pendingOnWeightTeamCount
                                            : leadCounts23}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            onClick={() =>
                              handleInprogressClick("Pending on sales")
                            }
                          >
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#A3B763",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlinePendingActions size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on sales
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingOnSalesCount
                                            : leadCounts24}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            to="/cases/Pending on legal"
                            onClick={() =>
                              handleInprogressClick("Pending on legal")
                            }
                          >
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#B0A695",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdIncompleteCircle size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on legal
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingOnLegalCount
                                            : leadCounts25}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            onClick={() =>
                              handleInprogressClick("Pending on client")
                            }
                          >
                            <div className="card custom-card mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#DAFFFB",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlineWifiCalling size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on client
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingOnClientCount
                                            : leadCounts26}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : [
                      "KAM",
                      "Client Support",
                      "Tech-Support",
                      "Finance",
                      "Logistics Operations",
                      "Legal",
                    ].includes(employee.userRole) ? (
                    <div>
                      {" "}
                      <h4
                        style={{
                          marginLeft: "6vw",
                          marginBottom: "10px",
                          fontWeight: "900",
                          color: "#173B45",
                          marginTop: "40px",
                        }}
                      >
                        Customer & Case Dashboard
                      </h4>
                      <div
                        className="row"
                        style={{ marginLeft: "6vw", marginBottom: "10px" }}
                      >
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 col-xl-2 m-1"
                          role="button"
                          onClick={() => {
                            navigate("/customers");
                          }}
                        >
                          <div className="card custom-card">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F3D0D7",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <SiGoogleads size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Customer
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.customerCount
                                          : leadCounts17}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          onClick={() => {
                            navigate("/cases/All");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#C6EBC5",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdLocalOffer size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      All Case
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee?.allCaseCount
                                          : allCaseCount}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          onClick={() => {
                            navigate("/cases/New");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#C6EBC5",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <MdLocalOffer size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      New Case
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.newCaseCount
                                          : leadCounts18}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          onClick={() => {
                            navigate("/cases/Closed");
                          }}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#D6DAC8",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaHandshake size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Closed Case
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.closedCount
                                          : leadCounts19}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/In Progress");
                          // }}
                          onClick={() => handleInprogressClick("In Progress")}
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#D6DA",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <BsGraphUpArrow size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      In Progress
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.inProcessCaseCount
                                          : leadCounts27}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/Pending on Tech");
                          // }}
                          onClick={() =>
                            handleInprogressClick("Pending on Tech")
                          }
                        >
                          <div className="card custom-card   mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#F9F7C9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <GrIntegration size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Pending on Tech
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.pendingOnTechCount
                                          : leadCounts20}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/Pending on First mile ops");
                          // }}
                          onClick={() =>
                            handleInprogressClick("Pending on First mile ops")
                          }
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#7FC7D9",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <FaThumbsUp size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Pending on first mile
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.pendingOnFirstMileOpsCount
                                          : leadCounts21}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div
                          className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3"
                          role="button"
                          // onClick={() => {
                          //   navigate("/cases/Pending on Last mile Ops");
                          // }}
                          onClick={() =>
                            handleInprogressClick("Pending on Last mile Ops")
                          }
                        >
                          <div className="card custom-card  mb-0">
                            <Tilt options={defaultOptions}>
                              {" "}
                              <div
                                className="card-body"
                                style={{
                                  backgroundColor: "#B4D4FF",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="card-item">
                                  <div className="card-item-icon card-icon">
                                    <BsGraphDownArrow size={32} />
                                  </div>
                                  <div className="card-item-title mb-2">
                                    <label className="main-content-label tx-13 font-weight-bold mb-1">
                                      Pending on last mile
                                    </label>
                                  </div>
                                  <div className="card-item-body">
                                    <div className="card-item-stat">
                                      <h4 className="font-weight-light">
                                        {formData.id == ""
                                          ? employee.pendingOnLastMileOpsCount
                                          : leadCounts22}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tilt>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            onClick={() =>
                              handleInprogressClick("Pending on weight team")
                            }
                          >
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#FF8F8F",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <FaClock size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on weight
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee.pendingOnWeightTeamCount
                                            : leadCounts23}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            onClick={() =>
                              handleInprogressClick("Pending on sales")
                            }
                          >
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#A3B763",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlinePendingActions size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on sales
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingOnSalesCount
                                            : leadCounts24}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            to="/cases/Pending on legal"
                            onClick={() =>
                              handleInprogressClick("Pending on legal")
                            }
                          >
                            <div className="card custom-card  mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#B0A695",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdIncompleteCircle size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on legal
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingOnLegalCount
                                            : leadCounts25}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-8 col-md-6 col-lg-4 m-1 col-xl-2 mt-3">
                          <div
                            onClick={() =>
                              handleInprogressClick("Pending on client")
                            }
                          >
                            <div className="card custom-card mb-0">
                              <Tilt options={defaultOptions}>
                                {" "}
                                <div
                                  className="card-body"
                                  style={{
                                    backgroundColor: "#DAFFFB",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <div className="card-item">
                                    <div className="card-item-icon card-icon">
                                      <MdOutlineWifiCalling size={32} />
                                    </div>
                                    <div className="card-item-title mb-2">
                                      <label className="main-content-label tx-13 font-weight-bold mb-1">
                                        Pending on client
                                      </label>
                                    </div>
                                    <div className="card-item-body">
                                      <div className="card-item-stat">
                                        <h4 className="font-weight-light">
                                          {formData.id == ""
                                            ? employee?.pendingOnClientCount
                                            : leadCounts26}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tilt>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">Fship</a>. Developed by{" "}
                  <a href="http://fship.in/">FSHIP</a> All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#top" id="back-to-top">
        <i className="fe fe-arrow-up" />
      </a>
    </>
  );
};

export default Firstpage;
