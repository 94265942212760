import React, { useState } from "react";
import SelectComponent from "./SelectComponent"; // Adjust the import path as needed
let num = 0;
function NewLeadSelect({
  leadSourceOptions,
  leadPipelineOptions,
  businessCategoryOptions,
  partnerOptions,
  shipmentPotentialOptions,
  clientPersonaOptions,
  employeeId,
  isKycDone,
  employee,
  onFilterChange,
}) {
  console.log("AMAN", num++);
  const [filterByLeadSource, setFilterByLeadSource] = useState([]);
  const [filterByLeadPipeline, setFilterByLeadPipeline] = useState([]);
  const [filterByBusinessCategory, setFilterByBusinessCategory] = useState([]);
  const [filterByPartner, setFilterByPartner] = useState([]);
  const [filterByShipmentPotential, setFilterByShipmentPotential] = useState(
    []
  );
  const [filterByClientPersona, setFilterByClientPersona] = useState([]);
  const [filterByEmployeeId, setFilterByEmployeeId] = useState([]);
  const [filterByIskycDone, setFilterByIskycDone] = useState([]);

  // Handlers
  const handleCheckboxChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByLeadSource(values);
    onFilterChange && onFilterChange({ filterByLeadSource: values });
  };

  const handleCheckboxChange1 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByLeadPipeline(values);
    onFilterChange && onFilterChange({ filterByLeadPipeline: values });
  };

  const handleCheckboxChange2 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByBusinessCategory(values);
    onFilterChange && onFilterChange({ filterByBusinessCategory: values });
  };

  const handleCheckboxChange3 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByPartner(values);
    onFilterChange && onFilterChange({ filterByPartner: values });
  };

  const handleCheckboxChange4 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByShipmentPotential(values);
    onFilterChange && onFilterChange({ filterByShipmentPotential: values });
  };

  const handleCheckboxChange5 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByClientPersona(values);
    onFilterChange && onFilterChange({ filterByClientPersona: values });
  };

  const handleCheckboxChange6 = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByIskycDone(values);
    onFilterChange && onFilterChange({ filterByIskycDone: values });
  };

  // const handleCheckboxChange7 = (selectedOptions) => {
  //   console.log(selectedOptions);
  //   const values = selectedOptions
  //     ? selectedOptions.map((option) => option.value)
  //     : [];
  //   setFilterByEmployeeId(values);
  //   onFilterChange && onFilterChange({ filterByEmployeeId: values });
  // };

  const handleCheckboxChange7 = (selectedOptions) => {
    const ids = selectedOptions
      ? selectedOptions.map((option) => option.id) // Assuming each option has an 'id' property
      : [];
    console.log(ids);
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilterByEmployeeId(values);
    onFilterChange && onFilterChange({ filterByEmployeeId: ids });
  };

  return (
    <>
      <SelectComponent
        label="Lead Source"
        options={leadSourceOptions}
        handleSelectChange={handleCheckboxChange}
        selectedOptions={filterByLeadSource}
      />

      <SelectComponent
        label="Lead Pipeline"
        options={leadPipelineOptions}
        handleSelectChange={handleCheckboxChange1}
        selectedOptions={filterByLeadPipeline}
      />

      <SelectComponent
        label="Business Category"
        options={businessCategoryOptions}
        handleSelectChange={handleCheckboxChange2}
        selectedOptions={filterByBusinessCategory}
      />

      <SelectComponent
        label="Shipping Partner"
        options={partnerOptions}
        handleSelectChange={handleCheckboxChange3}
        selectedOptions={filterByPartner}
      />

      <SelectComponent
        label="Shipment Potential"
        options={shipmentPotentialOptions}
        handleSelectChange={handleCheckboxChange4}
        selectedOptions={filterByShipmentPotential}
      />

      <SelectComponent
        label="Client Persona"
        options={clientPersonaOptions}
        handleSelectChange={handleCheckboxChange5}
        selectedOptions={filterByClientPersona}
      />

      <SelectComponent
        label="Lead Owner"
        options={employeeId}
        handleSelectChange={handleCheckboxChange7}
        selectedOptions={filterByEmployeeId}
        includeNotAssigned={employee.userType !== "0"}
      />

      <div className="col-sm-2 mt-3">
        <label>Minimum Expected Shipment</label>
        <input
          type="search"
          className="form-control"
          placeholder="Minimum Expected Shipment..."
          aria-controls="example1"
          onChange={(e) => {
            onFilterChange &&
              onFilterChange({
                minimumExpectedShipmentToFship: e.target.value,
              });
          }}
        />
      </div>

      <SelectComponent
        label="KYC"
        options={isKycDone}
        handleSelectChange={handleCheckboxChange6}
        selectedOptions={filterByIskycDone}
      />
    </>
  );
}

export default NewLeadSelect;
