import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";

import Prince from "../../Components/Prince";

import TopHeader from "../../Components/TopHeader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import spiner from "../../assets/img/spiner.gif";
import { DateRangePicker } from "react-date-range";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Loader from "../../assets/img/loader.gif";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

import CreateTicket from "../createTicketCase.js/CreateTicket";
import { DataContext } from "../../context/DataContext";
import SelectComponent from "../Sales CRM/SelectComponent";

function Customers({ onClose }) {
  const [customerId, setCustomerId] = useState("");
  const result = useContext(DataContext);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leadPipelineOptions, setLeadPipelineOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);
  const [clientPersonaOptions, setClientPersonaOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [isKycDone, setIsKycDone] = useState([]);

  const [caseList, setCaseList] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [statusType, setStatusType] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [caseId, setCaseId] = useState(0);
  const [statusList, setStatusList] = useState([]);
  const [caseSubType, setCaseSubType] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [select1Value, setSelect1Value] = useState("");
  const [select2Value, setSelect2Value] = useState("");
  const [select3Value, setSelect3Value] = useState("");
  const [select4Value, setSelect4Value] = useState("");
  const [IsWonModalOpen, setIsWonModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("AWB Number");
  const [textInput, setTextInput] = useState("");
  const [employee, setEmployee] = useState({});
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [modelOPen, setModalOpen] = useState(false);
  const [modelOPen2, setModalOpen2] = useState(false);
  const [modelOPen3, setModalOpen3] = useState(false);
  const [modelOPen4, setModalOpen4] = useState(false);
  const [statusModalOPen, setStatusModalOpen] = useState(false);
  const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [userType, setUserType] = useState({});
  const [employees, setEmployees] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [newStatusLeadId, setNewStatusLeadId] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [sourceTo, setSourceTo] = useState("");
  const [stageTo, setStageTo] = useState("");
  const [leadPipelineTo, setPipelineTo] = useState("");
  const [bulkAssignId, setBulkAssignId] = useState([]);
  const [assignedLeadId, setAssignedLeadId] = useState("");
  const { status } = useParams();
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isCorrectNumber, setIsCorrectNumber] = useState(false);
  const [isCorrectNumberAgain, setIsCorrectNumberAgain] = useState(false);

  const [customerData, setCustomerData] = useState([]);

  const [searchResults, setSearchResults] = useState("");

  const [leadStatusOptions, setLeadStatusOptions] = useState([]);

  const [filterByObj, setFilterByObj] = useState({
    leadSource: "",
    leadPipeline: "",
    businessCategory: "",
    shipmentPotential: "",
    clientPersona: "",
    partner: "",
    to: "",
    from: "",
    search: "",
    isKycDone: "",
    minimumExpectedShipmentToFship: "",
    employeeId: "",
    genericEmailPattern: "",
    // kamId: "",
    customerId: "",
  });

  const dummyData = [
    {
      "Shipment Status": "In Transit",
      "Pickup Location": "Warehouse A",
      "Pickup schedule date": "2024-04-20",
      "Picked Up Date": "2024-04-21",
      "Shipment charges": 50.0,
      "Manifested weight": 20.5,
      "Billed weight": 18.5,
      "NDR Reason": "Recipient not available",
      "Shipment zone": "Zone 1",
      "Consignee City": "New York",
      "Consignee Pincode": "10001",
      "Consignee State": "NY",
      "Consignee Contact number( Encrypted)": "***********",
      "product name": "Product A",
      "product quantity": 2,
    },
  ];

  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const containerRef = useRef(null);
  const containerRef1 = useRef(null);
  const containerRef2 = useRef(null);
  const containerRef3 = useRef(null);
  const containerRef4 = useRef(null);
  const containerRef5 = useRef(null);
  const containerRef6 = useRef(null);
  const containerRef7 = useRef(null);
  const [leadId, setLeadId] = useState([]);

  const [options, setOptions] = useState([]);
  const [selectedKamTypeOption, setSelectedKamTypeOption] = useState({});

  const [filterByLeadSource, setFilterByLeadSource] = useState("");
  const [filterByLeadPipeline, setFilterByLeadPipeline] = useState("");
  const [filterByBusinessCategory, setFilterByBusinessCategory] = useState("");
  const [filterByShipmentPotential, setFilterByShipmentPotential] =
    useState("");
  const [filterByClientPersona, setFilterByClientPersona] = useState("");
  const [filterByIskycDone, setFilterByIskycDone] = useState("");
  const [filterByEmployeeId, setFilterByEmployeeId] = useState([]);
  const [filterByPartner, setFilterByPartner] = useState("");

  const [kamType, setKamType] = useState([]);
  const [kamEmployee, setKamEmployee] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  // You can adjust this based on your preference
  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [expanded5, setExpanded5] = useState(false);
  const [expanded6, setExpanded6] = useState(false);
  const [expanded7, setExpanded7] = useState(false);
  const [filterByEmployeeIdKam, setFilterByEmployeeIdKam] = useState([]);

  const [pageSize, setPageSize] = useState(20);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    scrollToTop();
  };
  const handlePageSize = (value) => {
    setPageSize(value.target.innerText);
    // scrollToBottom();
    scrollToTop();
    // alert(``)
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    const url = `${apiUrl}/customer/customerExport?leadSource=${filterByObj.leadSource}&customerId=${filterByObj.customerId}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&id=&mobileNumber=&emailId=&search=${filterByObj.search}&isKycDone=${filterByObj.isKycDone}&minimumExpectedShipmentToFship=${filterByObj.minimumExpectedShipmentToFship}&employeeId=${filterByObj.employeeId}&genericEmailPattern=${filterByObj.genericEmailPattern}&page=${currentPage}&pageSize=${pageSize}`;
    // IMPORT
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          //console.log(data.data.fileURL);
          setFrom(data.data.fileURL);
        }
      });
  };
  // console.log(totalPages);
  const handleConfirm1 = () => {
    window.location.href = from;

    setIsModalOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  // const handleCheckboxChange1 = (event) => {
  //   setFilterByLeadPipeline(event.target.value);
  // };

  const handleCheckboxChange1 = useCallback(
    (selected) => {
      setFilterByLeadPipeline(
        selected ? selected.map((option) => option.value) : []
      );
      setFilterByEmployeeIdKam(
        selected ? selected.map((option) => option.id) : []
      );
    },
    [filterByEmployeeId]
  );

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef7.current &&
        !containerRef7.current.contains(event.target)
      ) {
        setExpanded7(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef7]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef6.current &&
        !containerRef6.current.contains(event.target)
      ) {
        setExpanded6(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef6]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef5.current &&
        !containerRef5.current.contains(event.target)
      ) {
        setExpanded5(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef5]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef4.current &&
        !containerRef4.current.contains(event.target)
      ) {
        setExpanded4(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef4]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef3.current &&
        !containerRef3.current.contains(event.target)
      ) {
        setExpanded3(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef3]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef2.current &&
        !containerRef2.current.contains(event.target)
      ) {
        setExpanded2(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef2]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef1.current &&
        !containerRef1.current.contains(event.target)
      ) {
        setExpanded1(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef1]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [containerRef]);
  useEffect(() => {
    setCurrentPage(1);
  }, [status]);

  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      customerId: filterByBusinessCategory,
    });
  }, [filterByBusinessCategory]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      partner: filterByPartner,
    });
  }, [filterByPartner]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      shipmentPotential: filterByShipmentPotential,
    });
  }, [filterByShipmentPotential]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      clientPersona: filterByClientPersona,
    });
  }, [filterByClientPersona]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      isKycDone: filterByIskycDone,
    });
  }, [filterByIskycDone]);
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      employeeId: filterByEmployeeId,
    });
  }, [filterByEmployeeId]);

  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      leadSource: filterByLeadSource,
    });
  }, [filterByLeadSource]);

  const showCheckboxes1 = () => {
    setExpanded1(!expanded1);
  };
  useEffect(() => {
    setFilterByObj({
      ...filterByObj,
      employeeId: filterByEmployeeIdKam,
    });
  }, [filterByEmployeeIdKam]);

  useEffect(() => {
    setEmployee(result.employeeData);
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/13`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setKamType(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/employee/kamEmp?kycType=${selectedKamTypeOption}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          //console.log(data);
          setKamEmployee(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, [selectedKamTypeOption]);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/customer/kamDropDown`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data && Array.isArray(data.data)) {
          setLeadPipelineOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  //Quality
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/caseM/customerDropdown`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setBusinessCategoryOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${apiUrl}/master/getAllMasterData/13`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched data
        setOptions(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/employee/empDropdownSub`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.data);
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/5`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setClientPersonaOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/11`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setPartnerOptions(data.data);
      })
      .catch((error) => {
        console.error("Error fetching Partners:", error);
      });
  }, []);

  const handleInputChange = (e) => {
    setTextInput(e.target.value);
    setIsCorrectNumber(false);
  };

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  const handleSelect1Change = (e) => {
    const selectedOptionId = e.target.selectedOptions[0].id;
    setStatusId(selectedOptionId);
    // console.log(selectedOptionId);
    setSelect1Value(e.target.value);
    // setSelect1Value(e.target.value);
  };

  const handleSelect2Change = (e) => {
    setSelect2Value(e.target.value);
  };

  const handleSelect3Change = (e) => {
    setSelect3Value(e.target.value);
    setCaseId(e.target.selectedOptions[0].id);
  };

  const handleSelect4Change = (e) => {
    setSelect4Value(e.target.value);
  };

  const handleSubmitCase = async (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      // Prepare data to send to the backend
      const data = {
        awbNo: textInput,
        orderId: textInput,
        issues: textareaValue,
        status: select2Value,
        masterStatus: select1Value,
        caseType: select3Value,
        caseSubType: select4Value,
      };

      try {
        // Send data to the backend
        const response = await fetch(
          `${apiUrl}/case/addCase?customerId=${customerId}&${
            selectedOption == "AWB Number" ? "awbNo" : "orderId"
          }=${textInput}&companyId=${customerData[0]?.companyId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }
        const response2 = await response.json();

        toast.success(response2.message);
        navigate("/cases/All");
      } catch (error) {
        // Handle error
        toast.error(error.message);
        console.error("Error:", error.message);
      }
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000); // Adjust the timeout value as needed
    }
  };

  // console.log(customerData[0]?.companyId);
  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getAllCaseSubType/${caseId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseSubType(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [caseId]);

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/allStatus`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setStatusList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/allStage?statusId=${statusId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setStatusType(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [statusId]);
  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getCaseType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    setEmployee(result.employeeData);
    setTimeout(() => {
      setLoaded(true);
    }, 800);
  }, []);

  const capitalizeFirstLetterOfEachWord = (str) => {
    return str?.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleAddCase = (e) => {
    e.preventDefault();
    setLoader1(true);

    fetch(
      `${apiUrl}/case/getOrderDetails?awbNo=${
        selectedOption === "AWB Number" ? textInput : ""
      }&orderId=${selectedOption === "Order ID" ? textInput : ""}&customerId=${
        customerData[0]?.id
      }`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSearchResults(data.data);
        if (data.data.length !== 0) {
          setIsCorrectNumber(true);
        } else {
          setIsCorrectNumberAgain(true);
        }
        setLoader1(false);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  //  console.log(searchResults);
  const handleDateChange = (ranges) => {
    setToDate(new Date(ranges.selection.startDate).toISOString());
    setFromDate(new Date(ranges.selection.endDate).toISOString());
    setFilterByObj({
      ...filterByObj,
      to: new Date(ranges.selection.endDate).toISOString(),
      from: new Date(ranges.selection.startDate).toISOString(),
    });
    setDate(ranges.selection);
  };

  const [from, setFrom] = useState("");

  const apiUrl = process.env.REACT_APP_URL;
  // console.log(apiUrl);

  const Token = localStorage.getItem("Token");
  // console.log("Token:", Token);

  const navigate = useNavigate();

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/21`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIsKycDone(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const fetchAllCustomer = () => {
    setLoader(true);
    const url = `${apiUrl}/customer/customerList?leadSource=${filterByObj.leadSource}&customerId=${filterByObj.customerId}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&id=&mobileNumber=&emailId=&search=${filterByObj.search}&isKycDone=${filterByObj.isKycDone}&minimumExpectedShipmentToFship=${filterByObj.minimumExpectedShipmentToFship}&employeeId=${filterByObj.employeeId}&genericEmailPattern=${filterByObj.genericEmailPattern}&page=${currentPage}&pageSize=${pageSize}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            // let filtLeads = data.data.filter(item => item.status === status);
            setFilteredLeads(data.data);
            const totalCount = data.totalCount;
            setTotalPages(Math.ceil(totalCount / pageSize));

            setLoader(false);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  //Quality
  // console.log(filteredLeads);
  useEffect(() => {
    fetchAllCustomer();
  }, [currentPage, filterByObj]);

  useEffect(() => {
    fetch(`${apiUrl}/employee/allEmpDesig`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/5`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setClientPersonaOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/11`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setPartnerOptions(data.data);
      })
      .catch((error) => {
        console.error("Error fetching Partners:", error);
      });
  }, []);

  const updateStatus = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("status", newStatus);
    const response = await fetch(
      `${apiUrl}/lead/changeStatus/${newStatusLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    //console.log(response, "statuss=======>>>>>>>");

    if (!response.ok) {
      toast.error(response.message);
    }
    setModalOpen(false);

    toast.success("Status Successfully Updated");

    window.location.reload();
  };
  const handleWonCloseModal = () => {
    setIsWonModalOpen(false);
  };

  // Bulk Assign
  const handleAssign = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("empId", assignedTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadsId", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/assignManyLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      // console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen(false);

      const data = await response.json();
      toast.success("Assigned successfully");

      window.location.reload();
      return;
    }

    const response = await fetch(
      `${apiUrl}/lead/assignedLead/${assignedLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    // console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    // console.log("Form submitted successfully!", data);
    window.location.reload();
  };

  // Bulk Source

  // const handleAssignKAM = async () => {
  //   // const formDataToSend = new FormData();
  //   // formDataToSend.append("leadSource", sourceTo);

  //   if (bulkAssignId.length) {
  //     // formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
  //     const response = await fetch(
  //       `${apiUrl}employee/assignKam?customerIds=${bulkAssignId}&kamId=${assignedTo}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${Token}`,
  //         },
  //         // body: formDataToSend,
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     setModalOpen2(false);

  //     const data = await response.json();
  //     toast.success("Assigned successfully");
  //     // console.log("Form submitted successfully!", data);
  //     window.location.reload();
  //     return;
  //   }

  //   // const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
  //   //   method: "POST",
  //   //   headers: {
  //   //     Authorization: `Bearer ${Token}`,
  //   //   },
  //   //   body: formDataToSend,
  //   // });
  //   // // console.log(response, "hereeeee=======>>>>>>>");

  //   // if (!response.ok) {
  //   //   throw new Error(`HTTP error! Status: ${response.status}`);
  //   // }
  //   // setModalOpen2(false);

  //   // const data = await response.json();
  //   // toast.success("Assigned successfully");
  //   // // console.log("Form submitted successfully!", data);
  //   // window.location.reload();
  // };
  const handleAssignKAM = async () => {
    try {
      if (bulkAssignId.length) {
        const response = await fetch(
          `${apiUrl}/customer/assignKam?customerIds=${bulkAssignId}&kamId=${assignedTo}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Assigned successfully");
        setModalOpen2(false);
        // Optionally, update your component state here instead of reloading
        // updateStateWithNewData(data);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      toast.error("Failed to assign KAM. Please try again.");
      console.error("Error in assigning KAM:", error);
    }
  };

  // Bulk Stage
  const handleAssign3 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("stage", stageTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      //  console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen3(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      //  console.log("Form submitted successfully!", data);
      window.location.reload();
      return;
    }

    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    //  console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen3(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    // console.log("Form submitted successfully!", data);
    window.location.reload();
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [status]);
  // bulk pipeline
  const handleAssign4 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadPipeline", leadPipelineTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      // console.log(response, "hereeeee=======>>>>>>>");

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen4(false);

      const data = await response.json();
      toast.success("Assigned successfully");
      // console.log("Form submitted successfully!", data);
      window.location.reload();
      return;
    }

    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    //console.log(response, "hereeeee=======>>>>>>>");

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen4(false);

    const data = await response.json();
    toast.success("Assigned successfully");
    // console.log("Form submitted successfully!", data);
    window.location.reload();
  };

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatusOptions(data.data);
          //   console.log(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/customer/customerList`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setCustomerData(data.data);
          //  console.log(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsModalOpen(false);
  };

  const handleCloseModal2 = () => {
    setModalOpen2(false);
    setModalOpen2(false);
  };

  const handleCloseModal3 = () => {
    setModalOpen3(false);
    setModalOpen3(false);
  };

  const handleCloseModal4 = () => {
    setModalOpen4(false);
    setModalOpen4(false);
  };

  const handleCloseStatusModal = () => {
    setStatusModalOpen(false);
  };

  const handleCloseDateFilterModal = () => {
    setDateFilterModalOpen(false);
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");
    //  console.log("Token:", Token);

    fetch(`${apiUrl}/employee/employees`, {
      headers: {
        Authorization: `Bearer ${Token}`, // Include the Token in the Authorization header
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            //   console.log(data.data, "all employees");
            setEmployees(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    setUserType(result.employeeData);
  }, []);

  console.log(bulkAssignId);

  return (
    <div className="page">
      <TopHeader />
      <Prince />
      <div className="main-content side-content pt-0 m-0">
        <div className="main-container container-fluid">
          <div className="inner-body">
            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Customers List
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a>Customers </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    New Customers List
                  </li>
                </ol>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <div className="row">
                <div className="card custom-card">
                  <div className="card-body py-3">
                    <div className="row">
                      {" "}
                      <div className="col-sm-4">
                        <label>Search Here</label>
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search..."
                          aria-controls="example1"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setFilterByObj({
                              ...filterByObj,
                              search: e.target.value,
                            });
                          }}
                          style={{ border: "1px Solid #8E3E63" }}
                        />
                      </div>
                      {leadPipelineOptions.length && (
                        <div className="col-sm-2 ">
                          {/* <label>KAM</label>
                          <select
                            name="leadPipeLine"
                            value={filterByLeadPipeline}
                            className="form-control form-select select2"
                            onChange={handleCheckboxChange1}
                            style={{ border: "1px solid #D2649A" }}
                          >
                            <option value={""}>Select</option>
                            {leadPipelineOptions.map((data, index) => {
                              return (
                                <option value={data.id} key={index}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select> */}
                          <SelectComponent
                            label={"KAM"}
                            options={leadPipelineOptions}
                            handleSelectChange={handleCheckboxChange1}
                            selectedOptions={filterByLeadPipeline}
                          ></SelectComponent>
                        </div>
                      )}
                      <div className="mt-3">
                        <button
                          onClick={handleOpenModal}
                          className="btn btn-outline-secondary me-3"
                        >
                          Export
                        </button>
                        <button
                          onClick={() => {
                            setFilterByObj({
                              leadSource: "",
                              leadPipeline: "",
                              businessCategory: "",
                              shipmentPotential: "",
                              clientPersona: "",
                              partner: "",
                              to: "",
                              from: "",
                              isKycDone: "",
                              genericEmailPattern: "",
                              employeeId: "",
                              minimumExpectedShipmentToFship: "",
                            });
                            setDate({
                              startDate: new Date(),
                              endDate: new Date(),
                              key: "selection",
                            });
                            window.location.reload();
                          }}
                          className="btn btn-outline-secondary"
                        >
                          Clear Filter
                        </button>
                      </div>
                      <></>
                      <div>
                        <div
                          className={`modal ${
                            isModalOpen ? "show" : ""
                          } bg-black-7`}
                          style={{ display: isModalOpen ? "block" : "none" }}
                          tabIndex="-1"
                          role="dialog"
                        >
                          <div
                            className="modal-dialog modal-dialog-centered modal-xl"
                            role="document"
                          >
                            <div className="modal-content rounded-3">
                              <div className="modal-header">
                                <h5 className="modal-title">download excel</h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={handleCloseModal}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>

                              <div
                                className="modal-body "
                                style={{ width: "100%" }}
                              >
                                {from !== "" ? (
                                  <p>Click here to download</p>
                                ) : (
                                  <p>
                                    Please wait , Download button will appear
                                    soon...{" "}
                                  </p>
                                )}
                                {from !== "" ? (
                                  <button
                                    className="btn ripple btn-primary"
                                    type="button"
                                    onClick={handleConfirm1}
                                  >
                                    Download
                                  </button>
                                ) : (
                                  <img src={spiner} height={"50px"}></img>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className={`modal ${
                              dateFilterModalOPen ? "show" : ""
                            } bg-black-7`}
                            style={{
                              display: dateFilterModalOPen ? "block" : "none",
                            }}
                            tabIndex="-1"
                            role="dialog"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered modal-xl"
                              role="document"
                            >
                              <div
                                className="modal-content rounded-3"
                                style={{ borderRadius: "8px" }}
                              >
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Select Date Range
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleCloseDateFilterModal}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>

                                <div
                                  className="modal-body"
                                  style={{ width: "100%" }}
                                >
                                  <DateRangePicker
                                    ranges={[date]}
                                    onChange={handleDateChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        // <div className="col-lg-2">
                        //   <img src={Loader} height={"50px"}></img>
                        // </div>
                        <div className="loading-overlay">
                          <img src={Loader} alt="Loading..." />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>{" "}
                  </div>
                </div>
              </div>
              {/* Row */}
            </div>

            <div
              className={`modal ${IsWonModalOpen ? "show" : ""}`}
              style={{
                display: IsWonModalOpen ? "block" : "none",
                borderRadius: "20px",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div className="modal-content rounded-3">
                  <div
                    className="modal-header"
                    style={{ borderRadius: "30px" }}
                  >
                    <h5 className="modal-title">Add Case</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleWonCloseModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div>
                    <form
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        className="radio-group"
                        style={{ marginTop: "20px" }}
                      >
                        <label style={{ marginLeft: "20px" }}>
                          <input
                            type="radio"
                            value="AWB Number"
                            checked={selectedOption === "AWB Number"}
                            onChange={handleOptionChange}
                            style={{ marginRight: "15px" }}
                            name="option"
                            id="AWB Number"
                          />
                          AWB Number
                        </label>
                        <label style={{ marginLeft: "20px" }}>
                          <input
                            type="radio"
                            value="Order ID"
                            checked={selectedOption === "Order ID"}
                            onChange={handleOptionChange}
                            style={{ marginRight: "15px" }}
                            name="option"
                            id="Order ID"
                          />
                          Order ID
                        </label>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        {" "}
                        <input
                          type="text"
                          value={textInput}
                          placeholder={
                            selectedOption === "Order ID"
                              ? "Enter Order ID"
                              : "Enter AWB No."
                          }
                          onChange={handleInputChange}
                          className="input-box"
                          style={{
                            padding: "6px 12px",
                            background: "rgb(247, 248, 248)",
                            border: "1px solid rgb(60, 63, 68)",
                            borderRadius: "4px",
                            fontSize: "13px",
                            color: "rgb(60, 63, 68)",

                            height: "46px",
                            appearance: "none",
                            transition: "border 0.15s ease 0s",
                            outline: "none",
                            boxShadow: "none",

                            width: "250px",
                            height: "40px",
                          }}
                        />
                        {textInput.trim() !== "" ? (
                          <button
                            type="submit"
                            className="calender btn btn-info"
                            style={{
                              backgroundColor: "#032863",
                              marginLeft: "20px",
                            }}
                            onClick={(e) => handleAddCase(e)}
                          >
                            Search
                          </button>
                        ) : null}
                      </div>

                      {loader1 ? (
                        // <div className="col-lg-2">
                        //   <img src={Loader} height={"50px"}></img>
                        // </div>
                        <div className="loading-overlay">
                          <img src={Loader} alt="Loading..." />
                        </div>
                      ) : (
                        <></>
                      )}
                    </form>
                  </div>
                  {isCorrectNumber ? (
                    <>
                      {" "}
                      <div
                        className="table-responsive"
                        style={{ width: "97%", marginLeft: "10px" }}
                      >
                        {" "}
                        {loader ? (
                          // <div className="col-lg-2">
                          //   <img src={Loader} height={"50px"}></img>
                          // </div>
                          <div className="loading-overlay">
                            <img src={Loader} alt="Loading..." />
                          </div>
                        ) : (
                          <></>
                        )}
                        <table className="table table-striped table-bordered text-nowrap mb-1 mr-1 ">
                          <thead>
                            <tr>
                              <th>Shipment Status</th>
                              <th>Pickup Location</th>
                              <th>Pickup Schedule Date</th>
                              <th>Picked Up Date</th>
                              <th>Shipment Charges</th>
                              <th>Manifested Weight</th>
                              <th>Billed Weight</th>
                              <th>NDR Reason</th>
                              <th>Shipment Zone</th>
                              <th>Consignee City</th>
                              <th>Consignee Pincode</th>
                              <th>Consignee State</th>
                              <th>Consignee Contact Number (Encrypted)</th>
                              <th>Product Name</th>
                              <th>Product Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchResults.map((user, index) => (
                              <tr key={index}>
                                <td> {user.shipmentStatus}</td>
                                <td> {user.pickupLocation}</td>
                                <td> {user.pickupScheduleDate}</td>
                                <td> {user.pickedUpDate}</td>
                                <td> {user.shipmentCharges}</td>
                                <td> {user.manifestedWeight}</td>
                                <td> {user.billedWeight}</td>
                                <td> {user.ndrReason}</td>
                                <td> {user.shipmentZone}</td>
                                <td> {user.consigneeCity}</td>
                                <td> {user.consigneePincode}</td>
                                <td> {user.consigneeState}</td>
                                <td> {user.consigneeContactNumber}</td>
                                <td>
                                  {" "}
                                  {user.crmOrderProductModels[0].productName}
                                </td>
                                <td>
                                  {" "}
                                  {
                                    user.crmOrderProductModels[0]
                                      .productQuantity
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "30px",
                        }}
                      >
                        <select
                          style={{
                            height: "40px",
                            width: "100%",
                            minWidth: "10ch",
                            maxWidth: "30ch",
                            border: "1px solid #808080",
                            borderRadius: "0.25em",
                            padding: "0.25em 0.5em",
                            fontSize: "0.75rem",
                            cursor: "pointer",
                            lineHeight: "1.1",
                            backgroundColor: "#fff",
                            backgroundImage:
                              "linear-gradient(to top, #f9f9f9, #fff 33%)",
                          }}
                          value={select1Value}
                          onChange={handleSelect1Change}
                        >
                          <option value=""> Select Status </option>
                          {statusList.map((list) => (
                            <option
                              key={list.id}
                              id={list.id}
                              value={list.status}
                            >
                              {list.status}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{
                            width: "100%",
                            height: "40px",
                            minWidth: "10ch",
                            maxWidth: "30ch",
                            border: "1px solid #808080",
                            borderRadius: "0.25em",
                            padding: "0.25em 0.5em",
                            fontSize: "0.75rem",
                            cursor: "pointer",
                            lineHeight: "1",
                            backgroundColor: "#fff",
                            backgroundImage:
                              "linear-gradient(to top, #f9f9f9, #fff 33%)",
                          }}
                          value={select2Value}
                          onChange={handleSelect2Change}
                        >
                          <option value="">Select Sub Status</option>
                          {statusType.map((list) => (
                            <option key={list.id} value={list.stage}>
                              {list.stage}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: "30px",
                        }}
                      >
                        <select
                          style={{
                            height: "40px",
                            width: "100%",
                            minWidth: "10ch",
                            maxWidth: "30ch",
                            border: "1px solid #808080",
                            borderRadius: "0.25em",
                            padding: "0.25em 0.5em",
                            fontSize: "0.75rem",
                            cursor: "pointer",
                            lineHeight: "1.1",
                            backgroundColor: "#fff",
                            backgroundImage:
                              "linear-gradient(to top, #f9f9f9, #fff 33%)",
                          }}
                          value={select3Value}
                          onChange={handleSelect3Change}
                        >
                          <option value=""> Select Case Type</option>
                          {caseList.map((list) => (
                            <option
                              key={list.id}
                              value={list.name}
                              id={list.id}
                            >
                              {list.name}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{
                            width: "100%",
                            height: "40px",
                            minWidth: "10ch",
                            maxWidth: "30ch",
                            border: "1px solid #808080",
                            borderRadius: "0.25em",
                            padding: "0.25em 0.5em",
                            fontSize: "0.75rem",
                            cursor: "pointer",
                            lineHeight: "1",
                            backgroundColor: "#fff",
                            backgroundImage:
                              "linear-gradient(to top, #f9f9f9, #fff 33%)",
                          }}
                          value={select4Value}
                          onChange={handleSelect4Change}
                        >
                          <option value="">Select Case Subtype</option>
                          {caseSubType.map((list) => (
                            <option key={list.id} value={list.name}>
                              {list.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ margin: "60px", marginBottom: "0" }}>
                        <label>Description</label>
                        <textarea
                          style={{
                            width: "100%",
                            minHeight: "100px",
                            border: "1px solid #808080",
                            borderRadius: "0.25em",
                            padding: "0.5em",
                            fontSize: "1rem",
                            lineHeight: "1.5",
                            resize: "vertical", // Allow vertical resizing}}
                            placeholder: "Enter your text here...",
                            color: "#808080",
                          }}
                          value={textareaValue}
                          onChange={handleTextareaChange}
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="calender btn btn-info"
                        style={{
                          backgroundColor: "#032863",
                          width: "100px",
                          margin: "0 auto",
                          marginBottom: "10px",
                        }}
                        onClick={handleSubmitCase}
                      >
                        Submit
                      </button>
                    </>
                  ) : isCorrectNumberAgain ? (
                    <div>
                      <hr />
                      <h2 style={{ textAlign: "center", marginTop: "20px" }}>
                        {" "}
                        No Data Found
                      </h2>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className=" bg-white rounded-3 mb-3">
              {loaded ? (
                <>
                  {bulkAssignId.length ? (
                    <a
                      // href=""
                      className="btn ripple btn-info btn-sm mt-3 mx-4 text-center"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      style={{ color: "#fff" }}
                    >
                      Assign to KAM
                    </a>
                  ) : (
                    <></>
                  )}
                  <div className="table-responsive p-4">
                    <table className="table table-striped table-bordered text-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              id="bulkSelect"
                              onChange={(e) => {
                                let newArr = [];
                                const allLeadsCheck =
                                  document.getElementsByClassName("selectLead");
                                if (e.target.checked) {
                                  for (
                                    let i = 0;
                                    i < filteredLeads.length;
                                    i++
                                  ) {
                                    newArr.push(filteredLeads[i].id);
                                  }
                                  setBulkAssignId(newArr);
                                  for (
                                    let i = 0;
                                    i < allLeadsCheck.length;
                                    i++
                                  ) {
                                    allLeadsCheck[i].checked = true;
                                  }
                                } else {
                                  newArr = [];
                                  setBulkAssignId([]);
                                  for (
                                    let i = 0;
                                    i < allLeadsCheck.length;
                                    i++
                                  ) {
                                    allLeadsCheck[i].checked = false;
                                  }
                                }
                                // console.log(newArr, "======>>>>changed");
                              }}
                            />
                          </th>
                          <th>ID</th>
                          <th>SOURCE</th>
                          {/* <th>PHOTO</th> */}
                          <th> KAM</th>
                          <th>CLIENT</th>
                          <th>CLIENT INFO</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredLeads.map((user) => (
                          <tr key={user.id}>
                            <td className="p-4">
                              <input
                                defaultValue="Initial Value"
                                type="checkbox"
                                className="selectLead"
                                onChange={(e) => {
                                  let newArray = [...bulkAssignId];
                                  if (e.target.checked) {
                                    newArray.push(user.id);
                                    setBulkAssignId(newArray);
                                  } else {
                                    newArray = [...bulkAssignId];
                                    newArray = newArray.filter((data) => {
                                      return data !== user.id;
                                    });
                                    setBulkAssignId(newArray);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <p>
                                ID-<b>{user.id}</b>
                              </p>
                            </td>
                            <td>
                              <div className="mb-0">
                                Source:{" "}
                                <b>
                                  {capitalizeFirstLetterOfEachWord(
                                    user.leadSource
                                  )}
                                </b>
                                <br />
                                Status:{" "}
                                <b>
                                  {user.masterStatus?.charAt(0).toUpperCase() +
                                    user.masterStatus?.slice(1)}
                                </b>
                                <br />
                                Stage:{" "}
                                <b>
                                  {user.status?.charAt(0).toUpperCase() +
                                    user.status?.slice(1)}
                                </b>
                                <br />
                                Pipeline:{" "}
                                <b>
                                  {capitalizeFirstLetterOfEachWord(
                                    user.leadPipeline
                                  )}
                                </b>
                                <br />
                                {
                                  <p className="mb-0">
                                    Company ID:
                                    <b>{user.companyId}</b>
                                  </p>
                                }
                                <p className="mb-0">
                                  KYC:
                                  <b>{user.isKycDone}</b>
                                </p>
                                <p className="mb-0">
                                  Remark:
                                  <b>{user.remark}</b>
                                </p>
                                {
                                  <p className="mb-0">
                                    Added On:
                                    <b>
                                      {new Date(user.createdAt)
                                        .toISOString()
                                        .slice(0, 10)}
                                    </b>
                                  </p>
                                }
                              </div>
                            </td>
                            <td>
                              <div className="mb-0">
                                {user.employee.firstName && (
                                  <div>
                                    <b>
                                      {capitalizeFirstLetterOfEachWord(
                                        user.employee.firstName
                                      )}{" "}
                                      {capitalizeFirstLetterOfEachWord(
                                        user.employee.lastName
                                      )}
                                    </b>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <p className="mb-0">
                                <b>
                                  {user.primaryContactName
                                    ?.charAt(0)
                                    .toUpperCase() +
                                    user.primaryContactName?.slice(1)}
                                </b>
                                <br />
                                Email: <b>{user.emailId}</b>
                                <br />
                                Phone: <b>{user.mobileNumber}</b>
                                <br />
                                Company ID: <b>{user.externalCompanyId}</b>
                                <br />
                                Company Name: <b>{user.externalCompanyName}</b>
                                <br />
                                Code Percentage:{" "}
                                <b>{user.externalcodPercentage}</b>
                                <br />
                                Paid Percentage:{" "}
                                <b>{user.externalprePaidPercentage}</b> <br />
                                Total Order: <b>{user.externaltotalOrder}</b>
                                <br />
                                Total Shipment:{" "}
                                <b>{user.externaltotalShipment}</b>
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                Company:{" "}
                                <b>
                                  {capitalizeFirstLetterOfEachWord(
                                    user.companyName
                                  )}
                                </b>
                                <br />
                                Designation:{" "}
                                <b>
                                  {capitalizeFirstLetterOfEachWord(
                                    user.primaryContactDesignation
                                  )}
                                </b>
                                <br />
                                Business Category:{" "}
                                <b>{user.businessCategory}</b>
                                <br />
                                Current Shipping Partner:{" "}
                                <b>{user.currentShippingPartner}</b>
                                <br />
                                Shipment Potential:{" "}
                                <b>{user.shipmentPotential}</b>
                                <br />
                                Min Expected Shipment to Fship:{" "}
                                <b>{user.minimumExpectedShipmentToFship}</b>
                                <br />
                                Client Persona: <b>{user.clientPersona}</b>
                                <br />
                                UTM:{" "}
                                <b>
                                  {user.utmSource}-{user.utmMedium}-
                                  {user.utmCampaign}-{user.utmTerm}-
                                  {user.utmContent}
                                </b>
                              </p>
                            </td>
                            <td>
                              <a
                                className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                                onClick={() => navigate(`/report/${user.id}`)}
                                style={{ color: "#fff" }}
                              >
                                Activity
                              </a>
                              <br />
                              <a
                                className="btn ripple btn-info btn-xs w-100 mb-1 mt-1"
                                onClick={() => navigate(`/cases/${user.id}`)}
                                style={{ color: "#fff" }}
                              >
                                All Cases
                              </a>
                              <br />

                              <CreateTicket customerId={user.id}></CreateTicket>
                            </td>
                            <td>
                              <div
                                className={`modal ${
                                  statusModalOPen ? "show" : ""
                                }`}
                                style={{
                                  display: statusModalOPen ? "block" : "none",
                                }}
                                tabIndex="-1"
                                role="dialog"
                              >
                                <div
                                  className="modal-dialog modal-dialog-centered modal-xl"
                                  role="document"
                                >
                                  <div className="modal-content rounded-3">
                                    <div className="modal-header">
                                      <h5 className="modal-title">
                                        Lead Stage :{" "}
                                        {user?.status.toUpperCase()}
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={handleCloseStatusModal}
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>

                                    <div
                                      className="modal-body"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="row row-sm-1">
                                        <div className="col-sm-4 form-group">
                                          <label className="form-label">
                                            Set Stage :{" "}
                                          </label>
                                          <select
                                            name="newStatus"
                                            value={newStatus}
                                            className="form-control form-select select2"
                                            onChange={(e) => {
                                              setNewStatus(e.target.value);
                                            }}
                                          >
                                            <option>Select</option>
                                            {leadStatusOptions.map(
                                              (data, index) => {
                                                return (
                                                  <option
                                                    value={data.name}
                                                    key={index}
                                                  >
                                                    {data.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                        <div className="col-lg-12">
                                          <button
                                            className="btn ripple btn-primary"
                                            type="button"
                                            onClick={updateStatus}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {filteredLeads.length == 0 && !loader ? (
                      <div className="text-center row m-5">
                        <h1>No Data Found</h1>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <h1 className="text-center">Loading...</h1>
              )}
              <div
                style={{
                  padding: "20px",
                  paddingTop: 0,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Select
                    defaultValue="20"
                    onChange={(value) => handlePageSize(value)}
                    style={{
                      marginTop: "10px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  >
                    <Option value="20">20</Option>
                    <Option value="50">50</Option>
                    <Option value="100">100</Option>
                    <Option value="200">200</Option>
                    <Option value="500">500</Option>
                  </Select>
                  <span
                    style={{
                      marginTop: "16px",
                      color: "#808080",
                    }}
                  >
                    Page Size
                  </span>
                </div>
                <Stack spacing={2} style={{ marginTop: "20px" }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>{" "}
              </div>
              <div
                className={`modal ${modelOPen ? "show" : ""} bg-black-7`}
                style={{ display: modelOPen ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content rounded-3">
                    <div className="modal-header">
                      <h5 className="modal-title">Assign</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="assignedTo"
                            value={assignedTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setAssignedTo(e.target.value);
                            }}
                          >
                            <option>Select</option>
                            {leadPipelineOptions.map((data, index) => {
                              console.log(data);
                              return (
                                <option value={data.id} key={index}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            onClick={handleAssignKAM}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal ${modelOPen2 ? "show" : ""}`}
                style={{ display: modelOPen2 ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content rounded-3">
                    <div className="modal-header">
                      <h5 className="modal-title">Bulk KAM Assign</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal2}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="sourceTo"
                            value={sourceTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setSourceTo(e.target.value);
                            }}
                          >
                            <option value={""}>By Lead Source</option>
                            {leadSourceOptions.map((data, index) => {
                              return (
                                <option value={data.name} key={index}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            // onClick={handleAssignKAM}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className={`modal ${modelOPen3 ? "show" : ""}`}
                style={{ display: modelOPen3 ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content rounded-3">
                    <div className="modal-header">
                      <h5 className="modal-title">Bulk Stage</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal3}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="stageTo"
                            value={stageTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setStageTo(e.target.value);
                            }}
                          >
                            <option>Select</option>
                            {leadStatusOptions.map((data, index) => {
                              return (
                                <option value={data.name} key={index}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            onClick={handleAssign3}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div
                className={`modal ${modelOPen4 ? "show" : ""}`}
                style={{ display: modelOPen4 ? "block" : "none" }}
                tabIndex="-1"
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-xl"
                  role="document"
                >
                  <div className="modal-content rounded-3">
                    <div className="modal-header">
                      <h5 className="modal-title">Bulk PipeLine</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal4}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body" style={{ width: "100%" }}>
                      <div className="row row-sm-1">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Assign : </label>
                          <select
                            name="leadPipelineTo"
                            value={leadPipelineTo}
                            className="form-control form-select select2"
                            onChange={(e) => {
                              setPipelineTo(e.target.value);
                            }}
                          >
                            <option value={""}>By Lead Pipeline</option>
                            {leadPipelineOptions.map((data, index) => {
                              return (
                                <option value={data.name} key={index}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <button
                            className="btn ripple btn-primary"
                            type="button"
                            onClick={handleAssign4}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
