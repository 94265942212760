import React, { useState } from "react";

const InputForm = () => {
  const initialValues = Array(10).fill("");
  const [values, setValues] = useState(initialValues);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = (index, event) => {
    const newValues = [...values];
    newValues[index] = event.target.value ? Number(event.target.value) : "";
    setValues(newValues);

    const totalSum = newValues.reduce(
      (acc, curr) => acc + (Number(curr) || 0),
      0
    );

    if (totalSum >= 100) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <div>
      {values.map((value, index) => (
        <input
          key={index}
          type="number"
          value={value}
          onChange={(event) => handleChange(index, event)}
          disabled={isDisabled && value === ""}
        />
      ))}
    </div>
  );
};

export default InputForm;
