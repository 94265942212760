import { useRef, useCallback } from "react";
import JoditEditor from "jodit-react";
import debounce from "lodash.debounce";

function Editor({ value, onChange }) {
  const editor = useRef(null);
  const apiUrl = process.env.REACT_APP_URL;

  const config = {
    buttons: [
      "bold",
      "italic",
      "underline",
      "ul",
      "ol",
      "outdent",
      "indent",
      "font",
      "fontsize",
      "paragraph",
      "align",
      "undo",
      "redo",

      "link",
    ],
    removeButtons: [
      "image",
      "file",
      "video",
      "table",
      "cut",
      "copy",
      "paste",
      "hr",
      "eraser",
      "source",
      "dots",
      "speechRecognize",
      "fullsize",
    ],
    toolbarSticky: false,
  };

  const debouncedOnChange = useCallback(
    debounce((newContent) => {
      onChange(newContent);
    }, 1000),
    [onChange]
  );

  return (
    <div>
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        onChange={debouncedOnChange}
      />
    </div>
  );
}

export default Editor;
