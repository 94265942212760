import React from "react";
import { useState } from "react";
import "./new.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { useNavigate } from "react-router-dom";
import Loader from "../../assets/img/loader.gif";

let num = 0;
const TableComponent = ({
  userType,
  filteredUsers,
  bulkAssignId,
  setBulkAssignId,
  handleSetEditLead,
  setModalOpen,
  setAssignedLeadId,
  setStatusModalOpen,
  setNewStatusLeadId,
  setStageStatus,
  handleLeadStatus,
  handleWonLead,
  setLeadId,
  // loader,
  handlePageChange,
  handlePageSize,
  totalPages,
  currentPage,
  // handleFilterLeads,
}) => {
  const [loader, setLoader] = useState(false);
  // console.log(handleFilterLeads);
  const loadcontent = (id) => {
    navigate(`/DatelsLeads/${id}`);
  };

  const handleLoader = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };
  //console.log(userType);
  const navigate = useNavigate();
  console.log("Table", num++);
  // const [leadData, setLeadData] = useState([]);

  // useEffect(() => {
  //   setLeadData(filteredUsers);
  // }, [triggerTable]);
  // console.log(filteredUsers);
  const capitalizeFirstLetterOfEachWord = (str) => {
    return str?.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleBulkSelect = (e) => {
    let newArr = [];
    const allLeadsCheck = document.getElementsByClassName("selectLead");
    if (e.target.checked) {
      newArr = filteredUsers.map((user) => user.id);
      setBulkAssignId(newArr);
      for (let i = 0; i < allLeadsCheck.length; i++) {
        allLeadsCheck[i].checked = true;
      }
    } else {
      setBulkAssignId([]);
      for (let i = 0; i < allLeadsCheck.length; i++) {
        allLeadsCheck[i].checked = false;
      }
    }
  };
  //  console.log(bulkAssignId);
  const handleCheckboxChange = (e, userId) => {
    let newArray = [...bulkAssignId];
    if (e.target.checked) {
      newArray.push(userId);
      setBulkAssignId(newArray);
    } else {
      newArray = newArray.filter((data) => data !== userId);
      setBulkAssignId(newArray);
    }
  };
  // console.log(userType);
  return (
    <div className="bg-white m-3 rounded-3 p-4">
      {true ? (
        <div className="table-responsive">
          <table className="table table-striped table-bordered text-nowrap mb-0">
            <thead>
              <tr>
                {userType.userType === "1" && (
                  <th>
                    <input
                      type="checkbox"
                      id="bulkSelect"
                      onChange={handleBulkSelect}
                    />
                  </th>
                )}
                <th>ID</th>
                <th>SOURCE</th>
                <th>Assigned To</th>
                <th>CLIENT</th>
                <th>CLIENT INFO</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  {userType?.userType === "1" && (
                    <td className="p-4">
                      <input
                        type="checkbox"
                        className="selectLead"
                        onChange={(e) => handleCheckboxChange(e, user.id)}
                      />
                    </td>
                  )}
                  <td>
                    <p style={{ marginBottom: "6px" }}>
                      LD-<b>{user.id}</b>
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      Source: <b>{user.leadSource}</b>
                      <br />
                      Status:{" "}
                      <b>
                        {user.masterStatus?.charAt(0).toUpperCase() +
                          user.masterStatus?.slice(1)}
                      </b>
                      <br />
                      Stage:{" "}
                      <b>
                        {user.status?.charAt(0).toUpperCase() +
                          user.status?.slice(1)}
                      </b>
                      <br />
                      Pipeline:{" "}
                      <b>
                        {capitalizeFirstLetterOfEachWord(user.leadPipeline)}
                      </b>
                      <br />
                      Company ID: <b>{user.companyId}</b>
                      <br />
                      KYC: <b>{user.isKycDone}</b>
                      <br />
                      Remark: <b>{user.remark}</b>
                      <br />
                      Added On:{" "}
                      <b>
                        {new Date(user.createdAt).toISOString().slice(0, 10)}
                      </b>
                    </p>
                  </td>
                  <td>{user.assignedTo && <p>{user.assignedTo}</p>}</td>
                  <td>
                    <p className="mb-0">
                      {user.primaryContactName?.charAt(0).toUpperCase() +
                        user.primaryContactName?.slice(1)}
                      <br />
                      Email: <b>{user.emailId}</b>
                      <br />
                      Phone: <b>{user.mobileNumber}</b>
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      Company:{" "}
                      <b>{capitalizeFirstLetterOfEachWord(user.companyName)}</b>
                      <br />
                      Designation:{" "}
                      <b>
                        {capitalizeFirstLetterOfEachWord(
                          user.primaryContactDesignation
                        )}
                      </b>
                      <br />
                      Business Category: <b>{user.businessCategory}</b>
                      <br />
                      Current Shipping Partner:{" "}
                      <b>{user.currentShippingPartner}</b>
                      <br />
                      Shipment Potential: <b>{user.shipmentPotential}</b>
                      <br />
                      Min Expected Shipment to Fship:{" "}
                      <b>{user.minimumExpectedShipmentToFship}</b>
                      <br />
                      Client Persona: <b>{user.clientPersona}</b>
                      <br />
                      UTM:{" "}
                      <b>{`${user.utmSource}-${user.utmMedium}-${user.utmCampaign}-${user.utmTerm}-${user.utmContent}`}</b>
                    </p>
                  </td>
                  <td>
                    <a
                      className="btn ripple btn-warning btn-xs w-100 mb-1 mt-1"
                      onClick={() => loadcontent(user.id)}
                      style={{ color: "#fff" }}
                    >
                      Activity
                    </a>
                    <br />
                    <a
                      className="btn ripple btn-danger btn-xs w-100 mb-1 mt-1"
                      onClick={() => navigate(`/lead/contacts/${user.id}`)}
                      style={{ color: "#fff" }}
                    >
                      Contacts
                    </a>
                    <br />
                    <button
                      type="button"
                      className="btn ripple btn-success btn-xs w-100 mb-1"
                      onClick={handleSetEditLead}
                      data-lead={JSON.stringify(user)}
                    >
                      Edit Lead
                    </button>
                    <br />
                    <button
                      className="btn ripple btn-info btn-xs w-100 mb-1"
                      onClick={() => {
                        setModalOpen(true);
                        setAssignedLeadId(user.id);
                      }}
                      style={{ color: "#fff" }}
                    >
                      {window.location.href.includes("NewLeads") ||
                      window.location.href.includes("All-leads") ||
                      window.location.href.includes("leads/Open")
                        ? "Assign"
                        : "Open & ReAssign"}
                    </button>
                    <br />
                    {(window.location.href.includes("NewLeads") ||
                      window.location.href.includes("All-leads") ||
                      window.location.href.includes("leads/Open")) && (
                      <button
                        className="btn btn-warning btn-xs w-100 mb-1"
                        onClick={() => {
                          setStatusModalOpen(true);
                          setNewStatusLeadId(user.id);
                          setStageStatus(user?.status.toUpperCase());
                        }}
                        style={{ color: "#fff" }}
                      >
                        Stage
                      </button>
                    )}
                    <br />
                    <button
                      type="button"
                      className="btn ripple btn-secondary btn-xs w-100 mb-1"
                      onClick={handleLeadStatus}
                      data-lead={JSON.stringify(user)}
                    >
                      Lead Life Cycle
                    </button>
                    <br />
                    {user.masterStatus === "Won" && !user.isCustomer && (
                      <button
                        type="button"
                        className="btn ripple btn-primary btn-xs w-100 mb-1"
                        onClick={(e) => {
                          handleWonLead(e);
                          setLeadId(user.id);
                        }}
                        style={{ color: "#fff" }}
                      >
                        Assign to KAM
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredUsers.length === 0 && !loader && (
            <div className="text-center row m-5">
              <h1>No Leads Found</h1>
            </div>
          )}
        </div>
      ) : (
        <h1 className="text-center">Loading...</h1>
      )}
      {loader ? (
        <>
          {" "}
          <div className="loading-overlay">
            <img src={Loader} alt="Loading..." />
          </div>
        </>
      ) : (
        <></>
      )}
      <div
        style={{
          paddingBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <Select
            defaultValue="20"
            onChange={(value) => {
              handlePageSize(value);
              handleLoader();
            }}
            style={{
              marginTop: "10px",
              height: "20px",
              marginRight: "10px",
            }}
          >
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
            <Option value="200">200</Option>
            <Option value="500">500</Option>
          </Select>
          <span
            style={{
              marginTop: "16px",
              color: "#808080",
            }}
          >
            Page Size
          </span>
        </div>
        <Stack spacing={2} style={{ marginTop: "20px" }}>
          <Pagination
            count={isNaN(totalPages) ? 0 : totalPages}
            page={currentPage}
            variant="outlined"
            onChange={handlePageChange}
            onClick={() => handleLoader()}
            color="primary"
          />
        </Stack>{" "}
      </div>
    </div>
  );
};

export default TableComponent;
