import React, { useState, useEffect, useContext } from "react";
import "../assets/plugins/pickr-master/themes/classic.min.css";
import "../assets/css/style.css";
import "./UserProfileDropdown.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./prince.css";
import FullScreenToggle from "./FullScreenToggle";
import { DataContext } from "../context/DataContext";

function UserProfileDropdown() {
  const [employeeData, setEmployee] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const result = useContext(DataContext);

  const toggleDropdown = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    setEmployee(result.employeeData);
  });
  // console.log(employeeData);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isDropdownOpen && !event.target.closest(".your-dropdown-class")) {
        // Close the dropdown only if it's open and the click is outside the dropdown
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  // console.log(isDropdownOpen);

  async function signOut() {
    const url = `${apiUrl}/employee/employeeLogOut`;

    const Token = localStorage.getItem("Token");
    // console.log("Token " + Token);

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    response = await response.json();
    if (response.status === "success") {
      toast.success(response.message);
    }
    localStorage.removeItem("Token");
    navigate("/");
  }
  const apiUrl = process.env.REACT_APP_URL;

  return (
    <div
      className="dropdown main-profile-menu"
      style={{ display: "flex", marginRight: "20px" }}
    >
      <FullScreenToggle />
      <div className="nav-link icon full-screen-link">
        <i
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          onClick={signOut}
          title="Log Out"
          className="fe fe-power"
          style={{ cursor: "pointer", marginRight: "4px" }}
        ></i>
      </div>
      <div
        className="main-img-user"
        // onClick={toggleDropdown}
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title={`${
          employeeData.firstName?.charAt(0).toUpperCase() +
          employeeData.firstName?.slice(1)
        }`}
      >
        <div style={{ marginTop: "5px" }}>
          {/* <i className="fe fe-power"></i>{" "} */}
          <img
            alt="avatar"
            src={
              employeeData.profilePic
                ? employeeData.profilePic
                : `${apiUrl}/uploads/man404.jpg`
            }
            style={{ width: "50px", height: "32px" }}
          />{" "}
        </div>
      </div>
    </div>
  );
}

export default UserProfileDropdown;
