import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import imageIcone from "../assets/img/imageIcone.png";
import { useRef, useState } from "react";
import note from "../assets/img/note.png";
import InputField from "./createTicket/InputField";
import UploadImageBox from "./createTicket/UploadImageBox";
import NotesComp from "./createTicket/NotesComp";
import DescribeBox from "./createTicket/DescribeBox";

function Finance() {
  return (
    <div>
      <Box>
        {" "}
        <p className="text-xs my-3">
          Kindly provide your bank account details that are registered with us
          to help us in verifying your information.
        </p>
        <div
          className="max-w-screen-lg  rounded-lg p-6  "
          style={{ backgroundColor: "#eaedf7" }}
        >
          <p className="m-2 text-xs text-bold font-semibold">Helpful tips</p>
          <p className="m-2 text-xs">Enter your Bank Details</p>
        </div>
        <div className="my-3 flex gap-3">
          {" "}
          <InputField></InputField>
          <InputField></InputField>
        </div>
        <div className="my-3">
          <InputField></InputField>
        </div>
        <UploadImageBox></UploadImageBox>
        <NotesComp></NotesComp>
        <DescribeBox></DescribeBox>
        <p className="my-4 text-xs font-medium">
          NOTE: We can’t transfer your wallet balance into your bank account
          till we have open shipments and negative wallet balance.
        </p>
      </Box>
    </div>
  );
}

export default Finance;
