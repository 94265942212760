import React from "react";

import DescribeBox from "../../Components/createTicket/DescribeBox";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import NotesComp from "../../Components/createTicket/NotesComp";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import CreateButton from "../../Components/createTicket/CreateButton";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketRequestClaim({
  text,
  selectedValue4Id,
  caseId,
  customerId,
  setOpen,
  handleInputValueDescription,
  description,
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [issueOption, setIssueOption] = useState([]);

  const [otherIssueDescribe, setOtherIssueDescribe] = useState("");
  const [issueDescribe, setIssueDescribe] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/28`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIssueOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);

  const handleOtherIssueDescribe = (event) => {
    setOtherIssueDescribe(event.target.value);
  };
  const handleIssueDescribe = (event) => {
    setIssueDescribe(event.target.value);
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleChange = (e) => {
    setSelectedOption(e.name);
  };
  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);

    formData.append("uploadImage", selectedImage);

    formData.append("discriptionIssue", issueDescribe);
    formData.append("otherIssueDescribe", otherIssueDescribe);

    formData.append("selectIssue", selectedOption);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };
  return (
    <Box className={"mt-4"}>
      {selectedValue4Id == 30 && (
        <SelectDropdown
          dropDownData={issueOption}
          handleChange={handleChange}
          selectedData={selectedOption}
          Text={text}
        ></SelectDropdown>
      )}
      {`${selectedOption}` == " Others " ? (
        <DescribeBox
          text={"Describe Issue :"}
          selectedOption={selectedOption}
          handleInputValue={handleOtherIssueDescribe}
          value={otherIssueDescribe}
        ></DescribeBox>
      ) : (
        ""
      )}

      {selectedValue4Id == 29 && (
        <DescribeBox
          text={selectedValue4Id == 29 ? text : "Describes your issue:"}
          handleInputValue={handleInputValueDescription}
          value={description}
        ></DescribeBox>
      )}
      {selectedValue4Id == 30 && (
        <DescribeBox
          text={"Describes your issue:"}
          handleInputValue={handleIssueDescribe}
          value={issueDescribe}
        ></DescribeBox>
      )}
      {selectedValue4Id == 30 && (
        <UploadImageBox
          text={"Upload Attachment (optional):"}
          handleFileChange={handleFileChange}
          handleRemoveImage={handleRemoveImage}
          selectedImage={selectedImage}
          selectedImagePreview={selectedImagePreview}
        ></UploadImageBox>
      )}
      {selectedValue4Id == 30 && (
        <NotesComp
          text={"File should be in png, jpg, jpeg format only. Max Size: 2 MB."}
        ></NotesComp>
      )}
      {selectedValue4Id === 30 && (
        <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
      )}
    </Box>
  );
}

export default CreateTicketRequestClaim;
