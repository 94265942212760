import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "1px solid #304463",
  boxShadow: 24,
  p: 4,
  borderRadius: "7px",
};

function EmailPopUp({ handleClose, open, item }) {
  const sanitizeMessage = (message) => {
    return DOMPurify.sanitize(message);
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const suffix = getOrdinalSuffix(day);
    return `${day}${suffix} ${month} ${year}`;
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const date = new Date(item.createdAt);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
        >
          {/* <hr /> */}
          <Box sx={style}>
            <img
              src="https://app.shiprocket.in/support-web/assets/icons/close_icon.svg"
              className="float-end cursor-pointer"
              alt="Close"
              onClick={handleClose}
            />

            {item.receiverEmail && (
              <Typography
                variant="subtitle1"
                component="p"
                sx={{ fontSize: "12px" }}
              >
                <b>To:</b> {item.receiverEmail}
              </Typography>
            )}
            {item.ccEmail && (
              <Typography
                variant="subtitle1"
                component="p"
                sx={{ fontSize: "12px" }}
              >
                <b>Cc:</b> {item.ccEmail}
              </Typography>
            )}
            <Typography
              variant="subtitle1"
              // component="p"
              component="div"
              sx={{ fontSize: "12px" }}
            >
              <b>Date:</b> {formatDate(date)}
            </Typography>
            <Typography
              variant="subtitle1"
              // component="p"
              component="div"
              sx={{ fontSize: "12px" }}
            >
              <b>Time:</b> {formatTime(date)}
            </Typography>
            <Box
              sx={{
                border: "1px solid #7393B3",
                padding: "5px",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontSize: "14px" }}
              >
                {item.subject}
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid #7393B3",
                padding: "10px",
                borderRadius: "8px",
                marginTop: "10px",
                overflowY: "auto",
                maxHeight: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontSize: "13px" }}
                component="div"
              >
                {" "}
                <div>{parse(sanitizeMessage(item.message))}</div>
                <b>
                  <span>Attachment : </span>
                </b>
                <em>
                  <a href={item.uploadedFile} target="_blank">
                    Click here
                  </a>
                </em>
              </Typography>
            </Box>
            <button
              className="btn btn-primary mt-2 "
              onClick={handleClose}
              style={{ fontSize: "12px" }}
            >
              Close
            </button>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default EmailPopUp;
