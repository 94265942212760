import React from "react";

import Content from "./Components/Content";
import Prince from "./Components/Prince";
import "../src/assets/css/style.css";
import "./amit.css";
import "../src/assets/css/icon-list.css";
import TopHeader from "./Components/TopHeader";

const Employee = () => {
  return (
    <>
      <div>
        <TopHeader />
        <Prince />
        <Content />
      </div>
    </>
  );
};

export default Employee;
