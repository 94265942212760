import React from "react";
import { useNavigate } from "react-router-dom";
import MainPage from "./MainPage";
import logo from "../assets/img/logo.png";
// import FullScreenToggle from "./FullScreenToggle";

function TopHeader({ employeeData }) {
  const Navigate = useNavigate();

  return (
    <div className="navbar-container" style={{ backgroundColor: "white" }}>
      <div className="navbar-logo pt-2">
        <img
          src={logo}
          className="header-brand-img text-start float-start  error-logo-light"
          alt="logo"
          onClick={() => {
            Navigate("/Dashboard");
          }}
          role="button"
          style={{ marginLeft: "5px" }}
        />
        {/* <FullScreenToggle></FullScreenToggle> */}
      </div>
      <div className="main-header-right d-flex flex-row-reverse">
        <button
          className="navbar-toggler navresponsive-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent-4"
          aria-controls="navbarSupportedContent-4"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fe fe-more-vertical header-icons navbar-toggler-icon" />
        </button>
        {/* Navresponsive closed */}
        <div className="navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent-4"
          >
            <div className="d-flex order-lg-2 ms-auto">
              {/* Search */}
              <div className="dropdown header-search">
                <a className="nav-link icon header-search">
                  <i className="fe fe-search header-icons" />
                </a>
                <div className="dropdown-menu">
                  <div className="main-form-search p-2">
                    <div className="input-group">
                      <div className="input-group-btn search-panel">
                        <select className="form-control select2">
                          <option label="All categories"> </option>
                          <option>Staff/Agent</option>
                          <option>Sales Lead</option>
                          <option>Subscriber</option>
                          <option>Cases/Incident</option>
                          <option>Lawyer</option>
                        </select>
                      </div>
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search for anything..."
                      />
                      <button className="btn search-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-search"
                        >
                          <circle cx={11} cy={11} r={8} />
                          <line x1={21} y1={21} x2="16.65" y2="16.65" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <MainPage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
