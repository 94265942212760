import React from "react";

const AssignModal = ({
  modelOpen,
  assignedTo,
  employees,
  setAssignedTo,
  handleAssign,
  handleCloseModal,
}) => {
  return (
    <div
      className={`modal bg-black-7 ${modelOpen ? "show" : ""}`}
      style={{ display: modelOpen ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content rounded-3">
          <div className="modal-header">
            <h5 className="modal-title">Assign</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body" style={{ width: "100%" }}>
            <div className="row row-sm-1">
              <div className="col-sm-4 form-group">
                <label className="form-label">Assign : </label>
                <select
                  name="assignedTo"
                  value={assignedTo}
                  className="form-control form-select select2"
                  onChange={(e) => setAssignedTo(e.target.value)}
                >
                  <option>Select</option>
                  {employees.map((data, index) => (
                    <option value={data.id} key={index}>
                      {data.firstName + " " + data.lastName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-12">
                <button
                  className="btn ripple btn-primary"
                  type="button"
                  onClick={handleAssign}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignModal;
