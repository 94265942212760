import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import { Flip, ToastContainer } from "react-toastify";
import { createRoot } from "react-dom/client";
import Loader from "./Loader";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Router>
    {/* <Loader /> */}
    <App />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      transition={Flip}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </Router>
);
