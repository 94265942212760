import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";

function RadioButton({ handleChange, selectedValue, toggle }) {
  // Initialize state with the default value

  return (
    <Box sx={{ mt: 2 }}>
      <FormControl>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          sx={{
            color: "#000",
            fontWeight: "400",
            fontSize: "12px",
            marginBottom: 0,
          }}
        >
          {toggle == 20
            ? " Your Issue is related to "
            : toggle == 35
            ? "Please select the platform where you are facing this issue:"
            : "Number of AWB(s) with this issue"}{" "}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedValue} // Set the value of the radio group
          onChange={handleChange} // Attach the change handler
        >
          <FormControlLabel
            value={
              toggle == 20
                ? "Channel Integration"
                : toggle == 35
                ? "Web (Desktop)"
                : "Single AWB"
            }
            control={
              <Radio
                sx={{
                  color: "rgb(62, 72, 112)",
                  "&.Mui-checked": { color: "rgb(62, 72, 112)" },
                }}
              />
            }
            label={
              toggle == 20
                ? "Channel Integration"
                : toggle == 35
                ? "Web (Desktop)"
                : "Single AWB"
            }
            sx={{
              color: "#000",
              fontSize: "10px",
              ".MuiFormControlLabel-label": { fontSize: "12px" },
            }}
          />
          <FormControlLabel
            value={
              toggle == 20
                ? " API Integration "
                : toggle == 35
                ? "Android"
                : "Multiple AWBs"
            }
            control={
              <Radio
                sx={{
                  color: "rgb(62, 72, 112)",
                  "&.Mui-checked": { color: "rgb(62, 72, 112)" },
                }}
              />
            }
            label={
              toggle == 20
                ? " API Integration "
                : toggle == 35
                ? "Android"
                : "Multiple AWBs"
            }
            sx={{
              color: "#000",
              fontSize: "10px",
              ".MuiFormControlLabel-label": { fontSize: "12px" },
            }}
          />
          {toggle == 35 && (
            <FormControlLabel
              value="iOS"
              control={
                <Radio
                  sx={{
                    color: "rgb(62, 72, 112)",
                    "&.Mui-checked": { color: "rgb(62, 72, 112)" },
                  }}
                />
              }
              label="iOS"
              sx={{
                color: "#000",
                fontSize: "10px",
                ".MuiFormControlLabel-label": { fontSize: "12px" },
              }}
            />
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default RadioButton;
