import React from "react";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import Box from "@mui/material/Box";
import NotesComp from "../../Components/createTicket/NotesComp";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import CreateButton from "../../Components/createTicket/CreateButton";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");
function CreateTicketTDSForm({
  caseId,
  customerId,
  selectedValue4Id,
  setOpen,
}) {
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("discriptionIssue", description);
    formData.append("uploadImage", selectedImage);

    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");
        // setOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };
  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <Box className="mt-3">
      <p className="fw-bold fs-12 ">
        To claim your taxed amount, we kindly request you to upload the TDS
        certificate. This will help ensure compliance with legal requirements
        and facilitate the smooth processing of your tax claim.
      </p>
      {/* <a href="" className="fs-12">
        {" "}
        Download Sample TDS Certificate
      </a> */}
      <UploadImageBox
        text={"Upload TDS Certificate"}
        handleFileChange={handleFileChange}
        handleRemoveImage={handleRemoveImage}
        selectedImage={selectedImage}
        selectedImagePreview={selectedImagePreview}
      ></UploadImageBox>
      <NotesComp
        text={"File should be in pdf, doc, docx format only. Max Size: 5 MB."}
      ></NotesComp>
      <DescribeBox
        text={"Describe the Issue (optional):"}
        handleInputValue={handleInputValueDescription}
      ></DescribeBox>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketTDSForm;
