import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { useRef, useState } from "react";
// import imageIcone from "../assets/img/imageIcone.png";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

function CheckBox({
  checkboxData,
  handleInputChangeCheckBox,
  handleCheckboxChange,
  checked,
  checkBoxInputValues,
}) {
  return (
    <Box className="mt-3">
      <p className="my-4 font-semibold text-xs">
        Help us understand how we can help you by selecting an option below. You
        can select more than one option.
      </p>

      <FormGroup>
        {checkboxData.map((item, index) => (
          <Box key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked[index]}
                  onChange={() => handleCheckboxChange(index)}
                  style={{
                    color: "rgb(62, 72, 112)",
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: "12px" }}>
                  {item.label}
                </Typography>
              }
            />
            <br />
            {index > 0 && checked[index] && (
              <TextField
                label="Company Name"
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  width: "50%",
                  "& .MuiInputLabel-root": {
                    fontSize: "12px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "12px",
                  },
                }}
                value={checkBoxInputValues[index]}
                onChange={(event) => handleInputChangeCheckBox(index, event)}
                InputProps={{
                  sx: {
                    "& input": {
                      color: "#373A40", // Change this to the desired color
                    },
                  },
                }}
              />
            )}
          </Box>
        ))}
      </FormGroup>
    </Box>
  );
}

export default CheckBox;
