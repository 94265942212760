import React from "react";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import NotesComp from "../../Components/createTicket/NotesComp";
import Box from "@mui/material/Box";
import UploadImageBox from "../../Components/createTicket/UploadImageBox";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CreateButton from "../../Components/createTicket/CreateButton";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketWithGST({
  caseId,
  customerId,
  selectedValue4Id,
  setOpen,
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImagePreview(null);
    // fileInputRef.current.value = "";
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append("uploadImage", selectedImage);
    formData.append("discriptionIssue", description);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${Token}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      // toast.error("Failed to create case. Please try again.");
    }
  };

  return (
    <Box>
      <DescribeBox
        text={"Describe the Issue:"}
        handleInputValue={handleInputValueDescription}
      ></DescribeBox>
      <UploadImageBox
        text={"Upload Attachments (optional):"}
        handleFileChange={handleFileChange}
        handleRemoveImage={handleRemoveImage}
        selectedImage={selectedImage}
        selectedImagePreview={selectedImagePreview}
      ></UploadImageBox>
      <NotesComp
        text={"File should be in png, jpg, jpeg format only. Max Size: 2 MB."}
      ></NotesComp>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketWithGST;
