import React, { useState } from "react";
import Prince from "../Components/Prince";
import TopHeader from "../Components/TopHeader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AddCase() {
  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");
  const initialBodyData = {
    name: "",
  };
  const navigate = useNavigate();

  const [bodyData, setbodyData] = useState(initialBodyData);

  function handleChange(event) {
    const { name, value } = event.target;
    setbodyData(() => ({
      [name]: value,
    }));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const bodyDataToSend = new FormData();
    for (const key in bodyData) {
      if (bodyData[key] !== null) {
        bodyDataToSend.append(key, bodyData[key]);
      }
    }

    try {
      const response = await fetch(`${apiUrl}/caseMaster/addCaseType`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: bodyDataToSend,
      });
      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message);
      }
      toast.success("Case added successfully");
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
      // console.log(error);
    }
  }

  return (
    <>
      {/* Main Header*/}
      <div className="page">
        <TopHeader />
        <Prince />
        {/* End Sidemenu */}
        {/* Main Content*/}
        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Add Case</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Internal Case </a>
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button type="button" className="btn btn-outline-primary">
                      <i className="fe fe-download-cloud me-2" /> Print
                    </button>
                  </div>
                </div>
              </div>

              <div className="row row-sm">
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3"></h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-sm-4  form-group">
                          <label className="form-label">Case type</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter case"
                            name="name"
                            value={bodyData.name}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-lg-4 form-group">
                          <div className="input-group"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row row-sm">
                <div className="col-12 mb-3">
                  <a
                    className="btn btn-outline-danger"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ color: "#fff" }}
                  >
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCase;
