import React from "react";
import styles from "./CreateButton.module.css";
import Box from "@mui/material/Box";

function CreateButton({ handleCreateCase }) {
  return (
    <>
      <Box>
        {" "}
        <button
          type="button"
          className={styles.customButton}
          onClick={handleCreateCase}
        >
          Create Case
        </button>
      </Box>
    </>
  );
}

export default CreateButton;
