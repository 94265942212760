import React, { useRef } from "react";
import imageIcone from "../../assets/img/imageIcone.png";
import Box from "@mui/material/Box";

function UploadImageBox({
  text,
  handleFileChange,
  handleRemoveImage,
  selectedImage,
  selectedImagePreview,
}) {
  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box className="mt-4">
      <p className="text-xs my-2 fw-bold">{text}</p>
      <div className="w-36 h-32 rounded-xl border-dashed border-blue-300 border-1 pt-2">
        {!selectedImage && (
          <div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <div
              className="bg-gray-100 w-24 h-20 mx-auto rounded-xl pt-4 cursor-pointer"
              onClick={handleDivClick}
            >
              <img src={imageIcone} alt="Image.." className="max-h-6 mx-8" />
            </div>
            <div className="flex ml-8 mt-2 text-purple-700">
              <img
                src="https://app.shiprocket.in/support-web/assets/icons/upload.svg"
                alt="icon"
              />
              <span className="text-xs ml-1" style={{ fontSize: "10px" }}>
                Upload Image
              </span>
            </div>
          </div>
        )}

        {selectedImagePreview && (
          <div className="relative m-1">
            <img
              src={selectedImagePreview}
              alt="Selected"
              className="w-36 h-28 object-cover rounded-md"
            />
            <button
              onClick={handleRemoveImage}
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center"
            >
              &times;
            </button>
          </div>
        )}
      </div>
    </Box>
  );
}

export default UploadImageBox;
