import React, { useState, useEffect } from "react";
import TopHeader from "../Components/TopHeader";
import Prince from "../Components/Prince";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Description } from "@mui/icons-material";

function CaseSubtype() {
  const [cases, setCases] = useState([]);
  const Token = localStorage.getItem("Token");
  const [search, setSearch] = useState("");
  const [caseList, setCaseList] = useState([]);
  const navigate = useNavigate();
  const initialBodyData = {
    mastersId: "",
    name: "",
    discription: "",
  };

  const [bodyData, setbodyData] = useState(initialBodyData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiUrl = process.env.REACT_APP_URL;
  const deletecontent = (id) => {
    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/caseMaster/deleteCaseSubType/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          alert("Record Deleted");

          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getAllCaseSubType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCases(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getCaseType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // console.log(cases);
  const filteredUsers = cases.filter((user) => {
    return user.name.toString().includes(search);
  });
  async function handleSubmit(event) {
    event.preventDefault();
    const bodyDataToSend = new FormData();
    for (const key in bodyData) {
      if (bodyData[key] !== null) {
        bodyDataToSend.append(key, bodyData[key]);
      }
    }

    try {
      const response = await fetch(`${apiUrl}/caseMaster/addCaseSubType`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: bodyDataToSend,
      });
      const result = await response.json();
      if (result.status === "error") {
        throw new Error(result.message);
      }
      toast.success("Case sub type added successfully");
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
      // console.log(error);
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  function handleChange(event) {
    const { name, value } = event.target;
    setbodyData({
      ...bodyData,
      [name]: value,
    });
  }

  const handlemastersId = (event) => {
    const selectedCaseId = event.target.value;

    setbodyData({
      ...bodyData,
      mastersId: selectedCaseId,
    });
  };
  return (
    <>
      {/* Main Header*/}
      <div className="page">
        <TopHeader />
        <Prince />

        {/* End Main Header*/}
        {/* Sidemenu */}

        {/* End Sidemenu */}
        {/* Main Content*/}
        <div
          className={`modal ${isModalOpen ? "show" : ""} bg-black-7`}
          style={{ display: isModalOpen ? "block" : "none" }}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content rounded-3">
              <div className="modal-header">
                <h5 className="modal-title">Case Subtype</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div style={{ width: "100%" }}>
                <div className="card-body">
                  {" "}
                  <div className="row row-sm">
                    <div className="col-lg-12 col-md-12">
                      <div className="card custom-card mb-0">
                        <div className="card-body">
                          <div className="row row-sm">
                            <div className="col-sm-6  form-group">
                              <label className="form-label">Case Type</label>
                              <select
                                onChange={handlemastersId}
                                className="form-control form-select"
                                name="mastersId"
                              >
                                <option>Select Case Type</option>
                                {caseList.map((list) => (
                                  <option key={list.id} value={list.id}>
                                    {list.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-sm-6  form-group">
                              <label className="form-label">
                                Case Sub type
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter value"
                                name="name"
                                value={bodyData.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className=" form-group ">
                            <label className="form-label">Description</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Typing.."
                              name="discription"
                              value={bodyData.discription}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ paddingLeft: "15px" }}>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-outline-danger"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content  pt-0">
          <div className="main-container container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Case Sub type
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Case </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Case Sub type{" "}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={handleOpenModal}
                    >
                      <i className="fe fe-plus me-2" />
                      Add Sub type
                    </button>{" "}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body py-3">
                      <div className="row">
                        <div className="col-sm-6 input-group">
                          <span className="input-group-text" id="basic-addon1">
                            @
                          </span>
                          <input
                            type="search"
                            className="form-control form-control"
                            placeholder="Search..."
                            aria-controls="example1"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>{" "}
              </div>
              {/* Row */}
              <div className="row row-sm">
                <div className="col-lg-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered text-nowrap mb-0 text-center">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Case</th>
                              <th>Case Sub type</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredUsers.map((c) => (
                              <tr key={c.id}>
                                <td> {c.id} </td>
                                <td>
                                  {c.masterName}
                                  <br />
                                </td>
                                <td>
                                  {c.name}
                                  <br />
                                </td>
                                <td>
                                  {c.description}
                                  <br />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">Fship</a>. Developed by{" "}
                  <a href="http://fship.in/">FSHIP</a> All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="modaldemo-pan">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">PAN</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <img
                    src="https://newadmin.webkype.com/assets/img/pngs/default-img.gif"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-sm-4">
                  <h6>Pan No. : CLDPK8793</h6>
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="modaldemo-cheque">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Cheque</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <img
                    src="https://newadmin.webkype.com/assets/img/pngs/cheque-img.jpg"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-sm-4">
                  <h6>Cheque No. : 0123456789</h6>
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="modaldemo-address">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Address</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <p>
                    3-15/10/403 Newark, Street no 5, Next To Pizza Hut,
                    Bangalore, Karnataka, 560003, India.
                  </p>
                  <p className="mb-0">soniataylor344@example.com</p>
                  <p>+91 08023310451</p>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <select className="form-control select2">
                      <option>Status</option>
                      <option>Approved</option>
                      <option>Disapproved</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn ripple btn-primary w-100"
                      type="button"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="modaldemo-employee-form">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Employee Form</h6>
              <button
                aria-label="Close"
                className="btn-close"
                data-bs-dismiss="modal"
                type="button"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="row row-sm">
                  <div className="col-sm-4 form-group">
                    <label className="form-label">Company</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Department</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                      <option>Sales</option>
                      <option>Marketing</option>
                      <option>Accounts</option>
                      <option>Procurements</option>
                      <option>Contracting</option>
                      <option>Transport</option>
                      <option>Legal</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Designation</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                      <option>Executive</option>
                      <option>Manager</option>
                      <option>Intern</option>
                    </select>
                  </div>
                  <div className="col-lg-4 form-group">
                    <label className="form-label">
                      Date of Joining: <span className="tx-danger">*</span>
                    </label>
                    <div className="input-group">
                      <div className="input-group-text border-end-0">
                        <i className="fe fe-calendar lh--9 op-6" />
                      </div>
                      <input
                        className="form-control fc-datepicker"
                        placeholder="MM/DD/YYYY"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Reporting Boss-A</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-4  form-group">
                    <label className="form-label">Reporting Boss-B</label>
                    <select className="form-control select select2">
                      <option>Select</option>
                    </select>
                  </div>
                  <div className="col-sm-12 form-group mb-0">
                    <div className="row">
                      <div className="col-sm-12">
                        {" "}
                        <label className="form-label">Assign Permission</label>
                      </div>
                      <div className="col-sm-12">
                        <div className="parsley-checkbox row" id="cbWrapper">
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              <input
                                data-parsley-class-handler="#cbWrapper"
                                data-parsley-errors-container="#cbErrorContainer"
                                data-parsley-mincheck={2}
                                name="browser[]"
                                required=""
                                type="checkbox"
                                defaultValue={1}
                              />
                              <span>Sales</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={2}
                              />
                              <span>Marketing</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox mg-b-5">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={3}
                              />
                              <span>Accounts</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Procurements</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Contracting</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Transport</span>
                            </label>
                          </div>
                          <div className="col-xl-3">
                            <label className="ckbox">
                              <input
                                name="browser[]"
                                type="checkbox"
                                defaultValue={4}
                              />
                              <span>Legal</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn ripple btn-primary" type="button">
                Save changes
              </button>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>

      <a href="#top" id="back-to-top">
        <i className="fe fe-arrow-up" />
      </a>
    </>
  );
}

export default CaseSubtype;
