import React from "react";

const LeadStatusModal = ({
  statusModalOpen,
  stageStatus,
  newStatus,
  leadStatusOptions,
  setNewStatus,
  updateStatus,
  handleCloseStatusModal,
}) => {
  return (
    <div
      className={`modal bg-black-7 ${statusModalOpen ? "show" : ""}`}
      style={{
        display: statusModalOpen ? "block" : "none",
      }}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content rounded-3">
          <div className="modal-header">
            <h5 className="modal-title">Lead Stage : {stageStatus}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseStatusModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body" style={{ width: "100%" }}>
            <div className="row row-sm-1">
              <div className="col-sm-4 form-group">
                <label className="form-label">Set Stage : </label>
                <select
                  name="newStatus"
                  value={newStatus}
                  className="form-control form-select select2"
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <option>Select</option>
                  {leadStatusOptions.map((data, index) => (
                    <option key={index} value={data.name}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-12">
                <button
                  className="btn ripple btn-primary"
                  type="button"
                  onClick={updateStatus}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadStatusModal;
