import React from "react";
import { useState, useEffect } from "react";
import SelectDropdown from "../../Components/createTicket/SelectDropdown";
import DescribeBox from "../../Components/createTicket/DescribeBox";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import CreateButton from "../../Components/createTicket/CreateButton";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { TextField } from "@mui/material";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");

function CreateTicketAccountLedger({ caseId, customerId, selectedValue4Id }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption1, setSelectedOption1] = useState("");
  const [customDate, setCustomDate] = useState(null);
  const [timeOption, setTimeOption] = useState([]);
  const [description, setDescription] = useState("");

  const handleCreateCase = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("caseType", caseId);
    formData.append("caseSubType", selectedValue4Id);
    formData.append(
      "timePeriod",
      selectedOption1 === "custom-date" ? customDate : selectedOption1
    );
    formData.append("discriptionIssue", description);
    const url = customerId.companyId
      ? `${apiUrl}/caseM/addCase?companyId=${customerId.companyId}`
      : `${apiUrl}/caseM/addCase?customerId=${customerId}`;
    try {
      const response = await fetch(url, {
        method: "POST",

        body: formData,
      });

      if (response.ok) {
        toast.success("Case Created successfully!");

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Failed to create case. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to create case. Please try again.");
    }
  };
  useEffect(() => {
    // Make an API request to fetch gender options
    fetch(`${apiUrl}/master/getAllMasterData/27`, {
      headers: {
        // Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setTimeOption(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching gender options:", error);
      });
  }, []);
  //console.log(timeOption);
  const handleChange = (e) => {
    setSelectedOption(e.name);
  };
  const handleInputValueDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleChange1 = (event) => {
    setSelectedOption1(event.target.value);
  };

  const handleDateChange = (event) => {
    setCustomDate(event.target.value);
  };

  return (
    <Box className="mt-3">
      <div>
        <FormControl sx={{ minWidth: 400 }} size="small">
          <InputLabel
            id="custom-select-label"
            sx={{
              fontSize: "0.75rem",
              fontWeight: "400",
              marginTop: "3px",
              color: "#000",
            }}
          >
            Select Date
          </InputLabel>
          <Select
            labelId="select-category-label"
            value={selectedOption1}
            onChange={handleChange1}
          >
            <MenuItem value="">Select None</MenuItem>

            {timeOption.map((item, index) => (
              <MenuItem
                key={item.id}
                value={item.name}
                style={{ width: "400px" }}
              >
                <div className="menu-item-content">
                  <p className="heading">{item.name}</p>
                </div>
              </MenuItem>
            ))}
            <MenuItem value="custom-date" style={{ width: "400px" }}>
              <div className="menu-item-content">
                <p className="heading">Custom Date</p>
              </div>
            </MenuItem>
          </Select>
        </FormControl>
        <br />

        {selectedOption1 === "custom-date" && (
          <TextField
            type="date"
            value={customDate}
            onChange={handleDateChange}
            // fullWidth
            margin="normal"
            id="outlined-size-small"
            size="small"
            InputProps={{
              sx: {
                "& input": {
                  color: "#373A40",
                },
              },
            }}
          />
        )}
      </div>
      <DescribeBox
        text={"Describe the Issue (optional):"}
        handleInputValue={handleInputValueDescription}
        value={description}
      ></DescribeBox>
      <CreateButton handleCreateCase={handleCreateCase}></CreateButton>
    </Box>
  );
}

export default CreateTicketAccountLedger;
