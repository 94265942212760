import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewLeads from "./NewLeads";
import { toast } from "react-toastify";
import EditLead from "./EditLead";
import axios from "axios";
import Loader from "../../assets/img/loader.gif";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format, set } from "date-fns";
import LeadStatus from "./page/LeadStatus";
import { useLocation } from "react-router-dom";
import "./new.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useCallback } from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { DataContext } from "../../context/DataContext";
import SelectComponent from "./SelectComponent";
import _ from "lodash";
import TabComponent from "./TabComponent";
import NewLeadSelect from "./NewLeadSelect";
import TableComponent from "./TableComponent";
import SearchComponent from "./SearchComponent";
import DateFilterComponent from "./DateFilterComponent";
import FilterButtonComponent from "./FilterButtonComponent";
import TabTableComponent from "./TabTableComponent";
import ExportModalComponent from "./ExportModalComponent";
import AssignModal from "./Sales Crm Modal/AssignModal";
import LeadStatusModal from "./Sales Crm Modal/LeadStatusModal";
import BulkLeadSourceModal from "./Sales Crm Modal/BulkLeadSourceModal";
import BulkPipelineModal from "./Sales Crm Modal/BulkPipelineModal";
import BulkStageModal from "./Sales Crm Modal/BulkStageModal";
import WonModal from "./Sales Crm Modal/WonModal";
let num = 0;
function NewLeadsTable() {
  console.log("AMAN", num++);
  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const result = useContext(DataContext);
  const location = useLocation();
  const [stageStatus, setStageStatus] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [employee, setEmployee] = useState({});
  const [filteredLeads, setFilteredLeads] = useState([]);
  //const [triggerTable, setTriggerTable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  //const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [statusModalOPen, setStatusModalOpen] = useState(false);
  // const [dateFilterModalOPen, setDateFilterModalOpen] = useState(false);
  const [userType, setUserType] = useState({});
  const [employees, setEmployees] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [newStatusLeadId, setNewStatusLeadId] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [sourceTo, setSourceTo] = useState("");
  const [stageTo, setStageTo] = useState("");
  const [leadPipelineTo, setPipelineTo] = useState("");
  const [bulkAssignId, setBulkAssignId] = useState([]);
  const [assignedLeadId, setAssignedLeadId] = useState("");
  const { status } = useParams();
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedKamTypeOption, setSelectedKamTypeOption] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);
  const [leadStatusOptions, setLeadStatusOptions] = useState([]);
  const [filterByGenericEmailPattern, setFilterByGenericEmailPattern] =
    useState(false);
  const [kamType, setKamType] = useState([]);
  const [kamEmployee, setKamEmployee] = useState([]);
  const [filterByObj, setFilterByObj] = useState({
    leadSource: "",
    leadPipeline: "",
    businessCategory: "",
    shipmentPotential: "",
    clientPersona: "",
    partner: "",
    to: "",
    from: "",
    search: "",
    isKycDone: "",
    minimumExpectedShipmentToFship: "",
    employeeId: "",
    genericEmailPattern: "",
  });
  const { stage } = location.state || {};
  // console.log(stage);
  //const [linkActive, setLinkActive] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  //const [applyFilter, setApplyFilter] = useState(false);
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [leadPipelineOptions, setLeadPipelineOptions] = useState([]);
  const [businessCategoryOptions, setBusinessCategoryOptions] = useState([]);
  const [shipmentPotentialOptions, setShipmentPotentialOptions] = useState([]);
  const [clientPersonaOptions, setClientPersonaOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [isKycDone, setIsKycDone] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);

  const [kamTypeEmployee, setKamTypeEmployee] = useState("");
  const [leadData, setLeadData] = useState({});
  const [leadData1, setLeadData1] = useState({});
  const [IsUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [IsWonModalOpen, setIsWonModalOpen] = useState(false);
  const [IsUpdateModalOpen1, setIsUpdateModalOpen1] = useState(false);
  //const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  // const filteredUsers = filteredLeads.filter((user) => user);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const formatToISOString = (date) => {
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
  };

  const handlePageChange = (event, newPage) => {
    if (newPage) {
      setCurrentPage(newPage); // Assuming setCurrentPage is a state setter
      scrollToTop(); // Assuming this function scrolls the page to the top
    } else {
      setCurrentPage(1); // Fallback to page 1 if newPage is undefined
    }
  };

  // console.log(currentPage);
  const handlePageSize = (value) => {
    setPageSize(value.target.innerText);
    scrollToTop();
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
    const {
      leadSource,
      leadPipeline,
      businessCategory,
      partner,
      shipmentPotential,
      clientPersona,
      minimumExpectedShipmentToFship,
      employeeId,
      isKycDone,
      from,
      to,
    } = filterByObj;
    const url = `${apiUrl}/lead/exportLead?id=&mobileNumber=&emailId=&leadSource=${leadSource}&leadPipeline=${leadPipeline}&businessCategory=${businessCategory}&currentShippingPartner=${partner}&shipmentPotential=${shipmentPotential}&clientPersona=${clientPersona}&minimumExpectedShipmentToFship=${minimumExpectedShipmentToFship}&employeeId=${employeeId}&isKycDone=${isKycDone}&from=${from}&to=${to}&status=${
      status == "Open"
        ? ""
        : status === "Won"
        ? ""
        : status === "Lost"
        ? ""
        : status === "All-leads"
        ? ""
        : status
    }&masterStatus=${
      status == "Open"
        ? "Open"
        : status === "Won"
        ? "Won"
        : status === "Lost"
        ? "Lost"
        : status === "All-leads"
        ? ""
        : ""
    }`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // console.log(data.data.fileURL);
          setFrom(data.data.fileURL);
        }
      });
  };
  const handleConfirm = () => {
    window.location.href = from;

    setIsModalOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleKamEmployee = (e) => {
    setKamTypeEmployee(e.target.value);
  };
  const handleAssignKam = () => {
    postData();
  };

  useEffect(() => {
    setEmployee(result.employeeData);
    setUserType(result.employeeData);
  }, []);
  // console.log(userType);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/13`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setKamType(data.data);
        }
      });
  }, []);

  // useEffect(() => {
  //   const newFilterByObj = {
  //     leadSource: filterByLeadSource,
  //     leadPipeline: filterByLeadPipeline,
  //     businessCategory: filterByBusinessCategory,
  //     shipmentPotential: filterByShipmentPotential,
  //     clientPersona: filterByClientPersona,
  //     partner: filterByPartner,
  //     to: "",
  //     from: "",
  //     search: search,
  //     isKycDone: filterByIskycDone,
  //     minimumExpectedShipmentToFship: "",
  //     employeeId: employeeIdOfLeadOwner,
  //     genericEmailPattern: filterByGenericEmailPattern,
  //   };

  //   updateFilterByObj(newFilterByObj);
  // }, [
  //   filterByLeadSource,
  //   filterByLeadPipeline,
  //   filterByBusinessCategory,
  //   filterByClientPersona,
  //   filterByPartner,
  //   filterByShipmentPotential,
  //   filterByIskycDone,
  //   filterByEmployeeId,
  //   filterByGenericEmailPattern,
  // ]);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/1`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadSourceOptions(data.data);
        }
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/21`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setIsKycDone(data.data);
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (selectedKamTypeOption) {
      // Make an API request to fetch gender options
      fetch(`${apiUrl}/employee/kamEmp?kycType=${selectedKamTypeOption}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && Array.isArray(data.data)) {
            setKamEmployee(data.data);
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [selectedKamTypeOption]);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/2`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadPipelineOptions(data.data);
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/3`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setBusinessCategoryOptions(data.data);
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/20`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOptions(data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/employee/empDropdownSub`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setEmployeeId(data.data);
        }
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    setEmployee(result.employeeData);
  });
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/4`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setShipmentPotentialOptions(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/5`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setClientPersonaOptions(data.data);
        }
      })
      .catch((error) => {});
  }, []);
  console.log(bulkAssignId);
  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/11`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPartnerOptions(data.data);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData/8`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setLeadStatusOptions(data.data);
        }
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("Token");
    fetch(`${apiUrl}/employee/employees`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setEmployees(data.data);
          }
        }
      });
  }, []);

  const updateStatus = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("status", newStatus);
    const response = await fetch(
      `${apiUrl}/lead/changeStatus/${newStatusLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    if (!response.ok) {
      toast.error(response.message);
    }
    setModalOpen(false);

    const data = await response.json();
    toast.success("Status Successfully Updated");
    window.location.reload();
  };
  const handleAssign = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("empId", assignedTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadsId", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/assignManyLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen(false);
      // const data = await response.json();
      toast.success("Assigned successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return;
    }
    const response = await fetch(
      `${apiUrl}/lead/assignedLead/${assignedLeadId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen(false);
    toast.success("Assigned successfully");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    // window.location.reload();
  };
  const handleAssign2 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadSource", sourceTo);
    // console.log(bulkAssignId);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen2(false);
      toast.success("Assigned successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      // window.location.reload();
      return;
    }
    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen2(false);
    // const data = await response.json();
    toast.success("Assigned successfully");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    // window.location.reload();
  };
  const handleAssign3 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("stage", stageTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen3(false);
      // const data = await response.json();
      toast.success("Assigned successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return;
    }
    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen3(false);
    const data = await response.json();
    toast.success("Assigned successfully");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const handleAssign4 = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("leadPipeline", leadPipelineTo);
    if (bulkAssignId.length) {
      formDataToSend.append("leadArray", JSON.stringify(bulkAssignId));
      const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setModalOpen4(false);
      const data = await response.json();

      toast.success("Assigned successfully");
      // window.location.reload();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }
    const response = await fetch(`${apiUrl}/lead/updateBulkLead`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      body: formDataToSend,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    setModalOpen4(false);
    // const data = await response.json();
    toast.success("Assigned successfully");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    // window.location.reload();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsModalOpen(false);
    window.location.reload();
  };
  const handleCloseModal2 = () => {
    setModalOpen2(false);
  };
  const handleCloseModal3 = () => {
    setModalOpen3(false);
    setModalOpen3(false);
  };
  const handleCloseModal4 = () => {
    setModalOpen4(false);
    setModalOpen4(false);
  };
  const handleCloseStatusModal = () => {
    setStatusModalOpen(false);
  };

  const handleWonCloseModal = () => {
    setIsWonModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearch(event);
  };
  async function postData() {
    try {
      const response = await fetch(
        `${apiUrl}/customer/addCustomer?empId=${kamTypeEmployee}&leadId=${leadId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({
            id: { kamTypeEmployee },
            leadId: { leadId },
          }),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data.message);
        navigate("/customers");
      } else {
        toast.error(data.message);
      }
    } catch (error) {}
  }
  const handleSetEditLead = (e) => {
    e.preventDefault();
    const lead = JSON.parse(e.target.dataset.lead);
    setIsUpdateModalOpen(true);
    setLeadData(lead);
  };

  const handleWonLead = (e) => {
    e.preventDefault();
    setIsWonModalOpen(true);
  };

  const handleLeadStatus = (e) => {
    e.preventDefault();
    const lead = JSON.parse(e.target.dataset.lead);
    setIsUpdateModalOpen1(true);
    setLeadData1(lead);
  };
  const closeEditModal = () => {
    setIsUpdateModalOpen(false);
  };
  const closeEditModal1 = () => {
    setIsUpdateModalOpen1(false);
  };
  const handleOpenLeads = () => {
    const url = `${apiUrl}/lead/getAllMasterLeads/${status}?leadSource=${filterByObj.leadSource}&leadPipeline=${filterByObj.leadPipeline}&businessCategory=${filterByObj.businessCategory}&currentShippingPartner=${filterByObj.partner}&shipmentPotential=${filterByObj.shipmentPotential}&clientPersona=${filterByObj.clientPersona}&to=${filterByObj.to}&from=${filterByObj.from}&search=${filterByObj.search}&isKycDone=${filterByObj.isKycDone}&minimumExpectedShipmentToFship=${filterByObj.minimumExpectedShipmentToFship}&employeeId=${filterByObj.employeeId}&genericEmailPattern=${filterByObj.genericEmailPattern}&page=${currentPage}&pageSize=${pageSize}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" && Array.isArray(data.data)) {
          // setFilteredLeads(data.data);
          setTotalPages(Math.ceil(data.totalCount / pageSize));
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      })
      .finally(() => {});
  };

  const handleFilterLeads = (data) => {
    setFilteredLeads(data);
  };
  const handleChange = (event) => {
    // Update the selected option when the select value changes
    setSelectedKamTypeOption(event.target.value);
  };

  const handleCheck = (isCheck) => {
    // setFilterByGenericEmailPattern(isCheck);
    setFilterByObj((prevState) => ({
      ...prevState,
      genericEmailPattern: isCheck,
    }));
  };

  const onFilterChange = (data) => {
    const {
      filterByLeadSource = "",
      filterByLeadPipeline = "",
      filterByBusinessCategory = "",
      filterByShipmentPotential = "",
      filterByClientPersona = "",
      filterByPartner = "",
      to = "",
      from = "",
      search = "",
      filterByIskycDone = "",
      filterByEmployeeId = "",
      filterByGenericEmailPattern = "", // Keep default empty string
      minimumExpectedShipmentToFship = "",
    } = data || {}; // Fallback to empty object if `data` is undefined

    // Update state with structured values
    setFilterByObj((prevState) => ({
      ...prevState,
      leadSource: filterByLeadSource,
      leadPipeline: filterByLeadPipeline,
      businessCategory: filterByBusinessCategory,
      shipmentPotential: filterByShipmentPotential,
      clientPersona: filterByClientPersona,
      partner: filterByPartner,
      to: to || prevState.to, // Retain previous value if not provided
      from: from || prevState.from, // Retain previous value if not provided
      search: search || prevState.search,
      isKycDone: filterByIskycDone || prevState.isKycDone,
      minimumExpectedShipmentToFship:
        minimumExpectedShipmentToFship ||
        prevState.minimumExpectedShipmentToFship,
      employeeId: filterByEmployeeId || prevState.employeeId,
      // genericEmailPattern:
      //   filterByGenericEmailPattern || prevState.genericEmailPattern, // Retain previous value if empty
    }));
  };

  const handleDateFilterData = (date) => {
    // Convert dates to ISO strings
    const formattedTo = formatToISOString(date.endDate);
    const formattedFrom = formatToISOString(date.startDate);

    // Update state with date changes
    setFilterByObj((prevState) => ({
      ...prevState,
      to: formattedTo,
      from: formattedFrom,
    }));
  };

  const handleApplyFilter = (stage) => {
    console.log(stage);
    setApplyFilter(!applyFilter);
    setLoader(true);
  };
  const handleClearFilter = () => {
    window.location.reload();
  };

  return (
    <div>
      {" "}
      <div className="page">
        <div className="">
          <NewLeads employeeData={employee} setLoader={setLoader} />
          {IsUpdateModalOpen && (
            <EditLead myLead={leadData} onClose={closeEditModal} />
          )}
          {IsUpdateModalOpen1 && (
            <LeadStatus myLead={leadData1} onClose={closeEditModal1} />
          )}
          <div style={{ marginTop: "150px" }}>
            <div className="">
              <div className="col-lg-12">
                <div className="card custom-card">
                  <div className="card-body py-3">
                    <div className="row">
                      <SearchComponent
                        handleSearch={handleSearch}
                        setFilterByObj={setFilterByObj}
                        filterByObj={filterByObj}
                      ></SearchComponent>
                      {/* <DateFilterComponent></DateFilterComponent>  */}
                      <DateFilterComponent
                        setDateForApi={handleDateFilterData}
                      ></DateFilterComponent>
                      <NewLeadSelect
                        leadSourceOptions={leadSourceOptions}
                        leadPipelineOptions={leadPipelineOptions}
                        businessCategoryOptions={businessCategoryOptions}
                        partnerOptions={partnerOptions}
                        shipmentPotentialOptions={shipmentPotentialOptions}
                        clientPersonaOptions={clientPersonaOptions}
                        employeeId={employeeId}
                        isKycDone={isKycDone}
                        employee={employee}
                        onFilterChange={onFilterChange}
                      ></NewLeadSelect>
                      <FilterButtonComponent
                        handleCheck={handleCheck}
                        handleClearFilter={handleClearFilter}
                        handleOpenModal={handleOpenModal}
                        handleApplyFilter={handleApplyFilter}
                      ></FilterButtonComponent>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            {/* Row */}
            <TabTableComponent
              userType={userType}
              filterByObj={filterByObj}
              currentPage={currentPage}
              pageSize={pageSize}
              filteredLeads={filteredLeads}
              bulkAssignId={bulkAssignId}
              totalPages={totalPages}
              modalOpen={modalOpen}
              modalOpen2={modalOpen2}
              modalOpen3={modalOpen3}
              modalOpen4={modalOpen4}
              setModalOpen={setModalOpen}
              setModalOpen2={setModalOpen2}
              setModalOpen3={setModalOpen3}
              setModalOpen4={setModalOpen4}
              handleFilterLeads={handleFilterLeads}
              handlePageChange={handlePageChange}
              handlePageSize={handlePageSize}
              setAssignedLeadId={setAssignedLeadId}
              handleSetEditLead={handleSetEditLead}
              setNewStatusLeadId={setNewStatusLeadId}
              setStageStatus={setStageStatus}
              setStatusModalOpen={setStatusModalOpen}
              handleLeadStatus={handleLeadStatus}
              setBulkAssignId={setBulkAssignId}
              setLeadId={setLeadId}
              setLoader={setLoader}
              handleWonLead={handleWonLead}
              status={status}
              stage={stage}
              handleApplyFiter={handleApplyFilter}
              applyFilter={applyFilter}
              // handleIsLinkActive={handleIsLinkActive}
            ></TabTableComponent>
          </div>
          <ExportModalComponent
            isModalOpen={isModalOpen}
            handleCloseModal={handleCloseModal}
            handleConfirm={handleConfirm}
            from={from}
          />
          <AssignModal
            modelOpen={modalOpen}
            handleAssign={handleAssign}
            handleCloseModal={handleCloseModal}
            setAssignedTo={setAssignedTo}
            employees={employees}
            assignedTo={assignedTo}
          />
          <LeadStatusModal
            setStageStatus={setStageStatus}
            setNewStatusLeadId={setNewStatusLeadId}
            setStatusModalOpen={setStatusModalOpen}
            statusModalOpen={statusModalOPen}
            stageStatus={stageStatus}
            newStatus={newStatus}
            leadStatusOptions={leadStatusOptions}
            setNewStatus={setNewStatus}
            updateStatus={updateStatus}
            handleCloseStatusModal={handleCloseStatusModal}
          />
          <BulkLeadSourceModal
            modelOpen2={modalOpen2}
            sourceTo={sourceTo}
            leadSourceOptions={leadSourceOptions}
            setSourceTo={setSourceTo}
            handleAssign2={handleAssign2}
            handleCloseModal2={handleCloseModal2}
          />
          <BulkPipelineModal
            modelOpen4={modalOpen4}
            leadPipelineTo={leadPipelineTo}
            leadPipelineOptions={leadPipelineOptions}
            setPipelineTo={setPipelineTo}
            handleAssign4={handleAssign4}
            handleCloseModal4={handleCloseModal4}
          />
          <BulkStageModal
            modelOpen3={modalOpen3}
            stageTo={stageTo}
            leadStatusOptions={leadStatusOptions}
            setStageTo={setStageTo}
            handleAssign3={handleAssign3}
            handleCloseModal3={handleCloseModal3}
          />
          <WonModal
            IsWonModalOpen={IsWonModalOpen}
            handleWonCloseModal={handleWonCloseModal}
            selectedKamTypeOption={selectedKamTypeOption}
            handleChange={handleChange}
            options={options}
            kamTypeEmployee={kamTypeEmployee}
            handleKamEmployee={handleKamEmployee}
            kamEmployee={kamEmployee}
            handleAssignKam={handleAssignKam}
          />

          {loader ? (
            <>
              {" "}
              <div className="loading-overlay">
                <img src={Loader} alt="Loading..." />
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="main-footer text-center">
            <div className="container">
              <div className="">
                <div className="col-md-12">
                  <span>
                    Copyright © 2024 <a href="">FSHIP</a>. Developed by{" "}
                    <a href="http://fship.in/">FSHIP</a> All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewLeadsTable;
