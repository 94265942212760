import React from "react";
import styles from "./HeadingTop.module.css";

function HeadingTop({ handleClose, companyId }) {
  return (
    <div>
      {companyId?.companyId ? (
        ""
      ) : (
        <img
          src="https://app.shiprocket.in/support-web/assets/icons/close_icon.svg"
          className="float-end cursor-pointer"
          alt="Close"
          onClick={handleClose}
        />
      )}
      <p className={styles.heading}>Create Ticket</p>
      <p className={styles.subheading}>
        Create a ticket by entering the following details
      </p>
    </div>
  );
}

export default HeadingTop;
