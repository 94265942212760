import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

function LeadStatus({ myLead, onClose }) {
  const [leadValue1, setLeadValue1] = useState({});
  useEffect(() => {
    setLeadValue1(myLead);
  }, [myLead]);

  const apiUrl = process.env.REACT_APP_URL;
  // console.log(apiUrl);
  const Token = localStorage.getItem("Token");
  // console.log("Token:", Token);

  const [user, setUser] = useState([]);

  useEffect(() => {
    fetch(`${apiUrl}/lead/leadStatusHistory?leadId=${myLead.id}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setUser(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching status:", error);
      });
  }, []);

  return (
    <>
      <div
        className={`modal bg-black-7 show`}
        style={{ display: "block" }}
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content rounded-2">
            <div className="modal-header">
              <h5 className="modal-title">Lead Status: {leadValue1.id}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="card custom-card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered text-nowrap mb-0 text-center">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Duration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.map((data) => (
                        <tr key={data.id}>
                          <td>{data.status}</td>
                          <td>
                            {data.duration}
                            <br />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main Footer*/}
        <div className="main-footer text-center">
          <div className="container">
            <div className="row row-sm">
              <div className="col-md-12">
                <span>
                  Copyright © 2024 <a href="">FSHIP</a>. Developed by{" "}
                  <a href="http://fship.in/">FSHIP</a> All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*End Footer*/}
      </div>
    </>
  );
}

export default LeadStatus;
