import React from "react";
import Box from "@mui/material/Box";

function CompanyDetails() {
  return (
    <Box className="mt-3">
      <p className="my-2 font-medium text-xs">Your company details</p>
      <div className="w-[100%]  bg-gray-200 p-2 rounded-2 ">
        <p className="my-2 text-xs">Company Name: Deetya</p>
        <p className="my-2 text-xs">Billing Address: A 50, UG Floor,</p>
        <p className="my-2 text-xs">Ram Dutt Enclave Uttam Nagar</p>
        <p className="my-2 text-xs">GSTIN:</p>
      </div>
    </Box>
  );
}

export default CompanyDetails;
