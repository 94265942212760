import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

export const DataContext = createContext();

const apiUrl = process.env.REACT_APP_URL;

export const DataProvider = ({ children }) => {
  const [employeeData, setEmployeeData] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("Token"));

  async function getEmp() {
    const url = `${apiUrl}/employee/employee`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Auth: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === "success") {
        setEmployeeData(data.data);
      } else {
        toast.error("Failed to fetch employee data");
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (token) {
      getEmp();
    }
  }, [token]);

  return (
    <DataContext.Provider value={{ employeeData, setToken }}>
      {children}
    </DataContext.Provider>
  );
};
