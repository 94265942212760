import React from "react";
import TopHeader from "../Components/TopHeader";
import Prince from "../Components/Prince";
import { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsPersonLinesFill } from "react-icons/bs";
import "./Support.css";
import pdfLogo from "../../src/assets/img/pdfLogo.png";
import excelLogo from "../../src/assets/img/excelLogo.png";
import { FaEye } from "react-icons/fa6";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import Editor from "./Editor";
import styles from "./Support.module.css";
import EmailPopUp from "./EmailPopUp";

const apiUrl = process.env.REACT_APP_URL;

const SOCKET_URL = `${apiUrl}`;

const socket = io(SOCKET_URL);

function Support(apiData) {
  const caseId = useParams();

  const token = localStorage.getItem("Token");

  const [subject, setSubject] = useState("");
  const [openEmail, setOpenEmail] = React.useState(false);
  const [itemDataPopUp, setItemDataPopUp] = useState("");
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [emailFile, setEmailFile] = useState([]);
  const [modelOpenAwb, setModalOpenAwb] = useState(false);
  const [modelOpenOrderId, setModalOpenOrderId] = useState(false);

  const boxStyle = {
    width: "693px",
    height: "50px",
    padding: "2px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    borderRadius: "2px",
  };

  const handleCloseMailPopUp = () => {
    setOpenEmail(false);
  };
  const handleOpenMailPopUp = () => {
    setOpenEmail(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEmailFile = async (event) => {
    const file = event.target.files[0];

    setEmailFile(file);
  };
  //console.log(emailFile);
  const [content, setContent] = useState("");
  const handleEditorChange = (newContent) => {
    setContent(newContent);
  };
  // const sendEmail = async () => {
  //   if (emailFile) {
  //     const payload = {
  //       receiverEmail: to,
  //       ccEmail: cc,
  //       subject: subject,
  //       message: content,
  //       uploadedFile: emailFile,
  //     };

  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/caseM/sendEmail?caseId=${caseId.caseId}`,
  //         {
  //           method: "POST",
  //           headers: {
  //             Authorization: `Bearer ${Token}`,
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(payload),
  //         }
  //       );
  //       const data = await response.json();
  //       if (response.ok) {
  //         setSubject("");
  //         setCc("");
  //         setContent("");
  //         toast.success("Email sent successfully!");
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 3000);
  //       } else {
  //         toast.error(data.message);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }
  // };

  const sendEmail = async () => {
    if (!to || !subject || !content) {
      toast.error("Please fill out all required fields.");
      return;
    }

    const formData = new FormData();
    formData.append("receiverEmail", to);
    formData.append("ccEmail", cc);
    formData.append("subject", subject);
    formData.append("message", content);

    if (emailFile) {
      formData.append("uploadedFile", emailFile); // Append the file to FormData
    }

    try {
      const response = await fetch(
        `${apiUrl}/caseM/sendEmail?caseId=${caseId.caseId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${Token}`,
            // Do not set 'Content-Type' to 'application/json' when using FormData
          },
          body: formData,
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSubject("");
        setCc("");
        setContent("");
        toast.success("Email sent successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(data.message || "Failed to send email.");
      }
    } catch (error) {
      toast.error("An error occurred while sending the email.");
      console.error("Error:", error);
    }
  };

  const [emailData, setEmailData] = useState();
  const [awbList, setAwbList] = useState([]);
  const [orderIdList, setOrderIdList] = useState([]);
  const [datestamp, setDatestamp] = useState("");
  const [isNewUser, setIsNewUser] = useState(true);
  const [attachment, setAttachment] = useState("");
  const [attachment1, setAttachment1] = useState("");
  const [showEmojiPicker1, setShowEmojiPicker1] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [message1, setMessage1] = useState("");
  const [messages1, setMessages1] = useState([]);
  const [caseSubType1, setCaseSubType] = useState([]);
  const [caseList, setCaseList] = useState([]);
  const [userType, setUserType] = useState([]);

  const [employee, setEmployee] = useState("");

  const fileInputRef1 = useRef(null);
  const [employeeList, setEmployeeList] = useState({});
  const [arrayBuffer, setArrayBuffer] = useState("");
  const apiUrl = process.env.REACT_APP_URL;

  const Token = localStorage.getItem("Token");

  const chatWindowRef = useRef(null);
  const chatWindowRef1 = useRef(null);
  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  useEffect(() => {
    async function getEmp() {
      const url = `${apiUrl}/employee/employee`;

      const Token = localStorage.getItem("Token");
      // console.log("Token " + Token);

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Auth: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      if (response.status === "success") {
        setEmployee(response.data);
      } else if (response.status === "error") {
      }
    }
    getEmp();
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getCaseType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseList(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseM/getCaseEmail?caseId=${caseId.caseId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setEmailData(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getCurrentDate = () => {
    const today = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return today.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    setDatestamp(currentDate);
  }, []);

  const capitalizeFirstLetterOfEachWord = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    }
  };

  useEffect(() => {
    if (chatWindowRef1.current) {
      chatWindowRef1.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages1, attachment1]);
  useEffect(() => {
    const Token = localStorage.getItem("Token");

    fetch(`${apiUrl}/caseMaster/getAllCaseSubType`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (Array.isArray(data.data)) {
            setCaseSubType(data.data);
          } else {
            console.error("API response does not contain users array:", data);
          }
        } else {
          console.error("API request was not successful:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleAwbNoList = () => {
    fetch(`${apiUrl}/caseM/awbList?caseId=${caseId.caseId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setAwbList(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching AWB list:", error);
      });
  };

  const handleCloseModalAwb = () => {
    setModalOpenAwb(false);
  };
  const handleCloseModalOrderId = () => {
    setModalOpenOrderId(false);
  };
  const handleOrderIdList = () => {
    // setModalOpenAwb(true);

    fetch(`${apiUrl}/caseM/ordeIdList?caseId=${caseId.caseId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setOrderIdList(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching AWB list:", error);
      });
  };

  const handleFileChange1 = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        const arrayBuffer = await readFileAsArrayBuffer(file);
        setArrayBuffer(arrayBuffer);

        setAttachment1(file);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };

  const [allCaseList, setAllCaseList] = useState([]);

  useEffect(() => {
    setFilteredData(allCaseList);
  }, [allCaseList]);

  useEffect(() => {
    async function getEmp() {
      const url = `${apiUrl}/caseM/getCase?caseId=${caseId.caseId}`;

      const Token = localStorage.getItem("Token");

      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      response = await response.json();

      setEmployeeList(response.data);
      setAllCaseList(response.data);
      setTo(response.data.customer?.emailId);
    }
    getEmp();
  }, []);

  const sendMessage = () => {
    socket.emit("messageReceived", data);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString("en-US");
    const formattedTime = date.toLocaleTimeString("en-US");
    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    setIsNewUser(true);
  }, []);

  const sendMessage1 = () => {
    setShowEmojiPicker1(false);
    setIsNewUser(false);
    if (attachment1 && attachment1.size > 1 * 1024 * 1024) {
      toast.error("File size is too large. Please select a smaller file.");
    }
    const formData = new FormData();
    formData.append("caseId", caseId.caseId);
    formData.append("token", token);
    if (message1 || attachment1) {
      formData.append("message", message1);
      formData.append("attachment", attachment1);
      if (attachment1) {
        switch (attachment1.type) {
          case "application/pdf":
            formData.append("mimeType", "application/pdf");
            break;
          case "image/jpeg":
            formData.append("mimeType", "image/jpeg");
            break;
          case "image/jpg":
            formData.append("mimeType", "image/jpg");
            break;
          case "image/png":
            formData.append("mimeType", "image/png");
            break;
          case "image/svg+xml":
            formData.append("mimeType", "image/svg+xml");
            break;
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            formData.append(
              "mimeType",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
            break;
          case "application/vnd.ms-excel":
            formData.append("mimeType", "application/vnd.ms-excel");
            break;
          default:
            return;
        }
      }
    }
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    socket.emit("messageReceived", formDataObject);
    setMessage1("");
    setAttachment1(null);
  };

  useEffect(() => {
    sendMessage1();
  }, []);

  const isSameDay = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  function convertTimestamp(timestampString) {
    const [datePart, timePart] = timestampString.split(", ");
    const [day, monthStr, year] = datePart.split("/");
    const month = parseInt(monthStr) - 1;
    const [time, meridianPart] = timePart.split(" ");
    const [hourStr, minute, second] = time.split(":");
    let hourNum = parseInt(hourStr);

    if (meridianPart === "pm" && hourNum !== 12) {
      hourNum += 12;
    }

    if (meridianPart === "am" && hourNum === 12) {
      hourNum = 0;
    }
    const timestamp = new Date(year, month, day, hourNum, minute, second);

    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    return timestamp.toLocaleString("en-US", options);
  }
  const Time = convertTimestamp("24/5/2024, 12:18:53 pm");

  const handleEmojiSelect1 = (emoji) => {
    setMessage1(message1 + emoji.native);
    console.log(emoji);
  };

  const handleButtonClick = () => {
    sendMessage();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleButtonClick();
    }
  };
  const handleKeyPressFile = (event) => {
    if (event.key === "Enter") {
      handleButtonClick();
    }
  };
  const handleKeyPress1 = (event) => {
    if (event.key === "Enter") {
      sendMessage1();
    }
  };

  const formatTimestamp = (timestamp) => {
    const messageDate = new Date(timestamp);
    const currentDate = new Date();
    const sameDay = messageDate.toDateString() === currentDate.toDateString();
    let formattedTime = messageDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return formattedTime;
  };

  useEffect(() => {
    socket.on("chatHistory", (message1) => {
      setMessages1(message1);
    });

    socket.on("messageReceived", (message) => {
      setMessages1((prevMessages) => [...prevMessages, message]);
    });
  }, [socket]);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };
  const scrollToBottom1 = () => {
    if (chatWindowRef1.current) {
      chatWindowRef1.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleAwbNoList();
  }, [caseId]);

  useEffect(() => {
    handleOrderIdList();
  }, [caseId]);

  return (
    <>
      <div>
        <div className="page">
          {/* Main Header*/}
          <TopHeader />
          <Prince />

          <div
            className="main-content  pt-0 "
            // style={{ marginLeft: "0" }}
          >
            <div className="main-container container-fluid">
              <div className="inner-body">
                {/* Page Header */}
                <div className="page-header">
                  <div>
                    <h2 className="main-content-title tx-24 mg-b-5">
                      Support/NDR ID:<span>{caseId.caseId}</span>
                    </h2>
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Support / All Tickets /<span>{caseId.caseId}</span>
                      </li>
                    </ol>
                  </div>
                </div>
                {/* End Page Header */}
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                    <div className="card custom-card chat-account">
                      <div className="d-block pt-0">
                        <div className="card-body text-center">
                          <div className="user-lock text-center">
                            <a href="#">
                              <img
                                alt="avatar"
                                className="rounded-10"
                                src={
                                  filteredData?.customer?.employee
                                    ?.profilePic == "" ||
                                  filteredData?.customer === null
                                    ? "https://img.freepik.com/free-psd/3d-rendering-avatar_23-2150833572.jpg?t=st=1715169602~exp=1715173202~hmac=be6698750cd88fb3a0e0ffac84f72621f3d80b6e6529eb05c5a2874539394cdc&w=740"
                                    : `${apiUrl}/uploads/${filteredData?.customer?.employee?.profilePic}`
                                }
                              />
                            </a>
                          </div>
                          <a href="#">
                            <h5 className=" mb-1 mt-3 main-content-label">
                              {filteredData?.customer?.employee?.firstName}{" "}
                              {filteredData?.customer?.employee?.lastName}
                            </h5>
                          </a>
                          <p className="mb-0 mt-1 text-muted">
                            {filteredData?.customer?.employee.userRole}
                          </p>
                          <p className="mb-0 mt-0 text-muted">
                            {userType.companyName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card custom-card">
                      <div className="card-body py-3">
                        {employeeList?.awbNo?.length === 1 ? (
                          <p className="mb-0 border border-primary">
                            Awb No: <b>{employeeList.awbNo[0]?.awbNo}</b>
                          </p>
                        ) : employeeList?.awbNo?.length === 0 ? null : (
                          <p className="mb-0 border border-primary">
                            Awb No:{" "}
                            <b
                              style={{
                                cursor: "pointer",
                                color: "#032852",
                              }}
                              onClick={() => {
                                handleAwbNoList();
                                setModalOpenAwb(true);
                              }}
                            >
                              Click Here
                            </b>
                          </p>
                        )}
                        {employeeList?.orderId?.length === 1 ? (
                          <p className="mb-0 border border-primary  ">
                            Order Id: <b>{employeeList?.orderId[0]?.orderId}</b>
                          </p>
                        ) : employeeList?.orderId?.length === 0 ? null : (
                          <p className="mb-0 border border-primary">
                            Order Id:{" "}
                            <b
                              style={{
                                cursor: "pointer",
                                color: "#032852",
                              }}
                              onClick={() => {
                                handleOrderIdList();
                                setModalOpenOrderId(true);
                              }}
                            >
                              Click Here
                            </b>
                          </p>
                        )}

                        {employeeList?.selectReason ? (
                          <p className="mb-0 border border-primary">
                            Select Reason : <b>{employeeList.selectReason}</b>
                          </p>
                        ) : null}
                        {employeeList.beneficiaryName ? (
                          <p className="mb-0 border border-primary">
                            Beneficiary Account Name :{" "}
                            <b>{employeeList.beneficiaryName}</b>
                          </p>
                        ) : null}
                        {employeeList.beneficiaryAccountNumber ? (
                          <p className="mb-0 border border-primary">
                            Beneficiary Account Number :{" "}
                            <b>{employeeList.beneficiaryAccountNumber}</b>
                          </p>
                        ) : null}
                        {employeeList.beneficiaryIfscCode ? (
                          <p className="mb-0 border border-primary">
                            Beneficiary IFSC Code :{" "}
                            <b>{employeeList.beneficiaryIfscCode}</b>
                          </p>
                        ) : null}
                        {employeeList.uploadImage ? (
                          <p className="mb-0 border border-primary">
                            Upload Image:{" "}
                            <b>
                              <a
                                href={employeeList.uploadImage}
                                target="_blank"
                              >
                                Click Here
                              </a>
                            </b>
                          </p>
                        ) : null}
                        {employeeList.otherIssueDescribe ? (
                          <div className="mb-0 border border-primary">
                            Other Issue Describe :{" "}
                            <div style={boxStyle}>
                              <b>{employeeList.otherIssueDescribe}</b>
                            </div>
                          </div>
                        ) : null}
                        {employeeList.discriptionIssue ? (
                          <div className="mb-0 border border-primary">
                            Discription Issue :{" "}
                            <div style={boxStyle}>
                              {" "}
                              <b>{employeeList.discriptionIssue}</b>
                            </div>
                          </div>
                        ) : null}
                        {employeeList.selectIssue ? (
                          <p className="mb-0 border border-primary">
                            Select Issue : <b>{employeeList.selectIssue}</b>
                          </p>
                        ) : null}
                        {employeeList.transactionID ? (
                          <p className="mb-0 border border-primary">
                            Transaction ID : <b>{employeeList.transactionID}</b>
                          </p>
                        ) : null}
                        {employeeList.companyType ? (
                          <p className="mb-0 border border-primary">
                            Company Type: <b>{employeeList.companyType}</b>
                          </p>
                        ) : null}
                        {employeeList.relatedIssue ? (
                          <p className="mb-0 border border-primary">
                            Related Issue : <b>{employeeList.relatedIssue}</b>
                          </p>
                        ) : null}
                        {employeeList.selectChannel ? (
                          <p className="mb-0 border border-primary">
                            Select Channel : <b>{employeeList.selectChannel}</b>
                          </p>
                        ) : null}
                        {employeeList.selectOrderType ? (
                          <p className="mb-0 border border-primary">
                            Select Order Type:{" "}
                            <b>{employeeList.selectOrderType}</b>
                          </p>
                        ) : null}
                        {employeeList.timePeriod ? (
                          <p className="mb-0 border border-primary">
                            Time Period : <b>{employeeList.timePeriod}</b>
                          </p>
                        ) : null}
                        {employeeList.receivedIncorrectInvoice ? (
                          <p className="mb-0 border border-primary">
                            Received Incorrect Invoice :{" "}
                            <b>{employeeList.receivedIncorrectInvoice}</b>
                          </p>
                        ) : null}
                        {employeeList.incorrectMissingCompanyName ? (
                          <p className="mb-0 border border-primary">
                            Incorrect Missing Company Name :{" "}
                            <b>{employeeList.incorrectMissingCompanyName}</b>
                          </p>
                        ) : null}
                        {employeeList.incorrectMissingCompanyAddress ? (
                          <p className="mb-0 border border-primary">
                            Incorrect Missing Company Address :{" "}
                            <b>{employeeList.incorrectMissingCompanyAddress}</b>
                          </p>
                        ) : null}
                        {employeeList.incorrectMissingGSTIN ? (
                          <p className="mb-0 border border-primary">
                            Incorrect Missing GSTIN :{" "}
                            <b>{employeeList.incorrectMissingGSTIN}</b>
                          </p>
                        ) : null}
                        {employeeList.others ? (
                          <p className="mb-0 border border-primary">
                            Other : <b>{employeeList.others}</b>
                          </p>
                        ) : null}
                        {employeeList.invoiceNumber ? (
                          <p className="mb-0 border border-primary">
                            Invoice Number : <b>{employeeList.invoiceNumber}</b>
                          </p>
                        ) : null}
                        {employeeList.issuePlatform ? (
                          <p className="mb-0 border border-primary">
                            Platform Issue : <b>{employeeList.issuePlatform}</b>
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                    <div className="card custom-card chat-account">
                      <div className=" d-block pt-0">
                        <div className="card-body text-center">
                          <div className="user-lock text-center">
                            <a href="#">
                              <img
                                alt="avatar"
                                className="rounded-10"
                                src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?t=st=1715174620~exp=1715178220~hmac=dbed534076f1c7fc3f145a9fa7624fcd843b5e911c75225143770d6a8fb67d4a&w=740"
                              />
                            </a>
                          </div>
                          <a href="#">
                            <h5 className=" mb-1 mt-3 main-content-label">
                              {" "}
                              {filteredData?.customer?.primaryContactName}
                            </h5>
                          </a>

                          <p className="mb-0 mt-0 text-muted">
                            {filteredData?.customer?.mobileNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Row */}
                <div className="row row-sm">
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 col-xl-3"
                    style={{ display: "none" }}
                  >
                    <div className="card custom-card chat-account">
                      <div className="d-block pt-0">
                        <div className="card-body text-center">
                          <div className="user-lock text-center">
                            <a href="#">
                              <img
                                alt="avatar"
                                className="rounded-10"
                                src="/assets/img/users/user-img1.jpg"
                              />
                            </a>
                          </div>
                          <a href="#">
                            <h5 className=" mb-1 mt-3 main-content-label">
                              Rajiv Kumar
                            </h5>
                          </a>
                          <p className="mb-0 mt-1 text-muted">
                            KAM/Support Agent
                          </p>
                          <p className="mb-0 mt-1 text-muted">Fship Company</p>
                        </div>
                        <div className="card-body">
                          <h6 className="mb-3 tx-semibold">Contact Details</h6>
                          <div className="d-flex">
                            <div>
                              <p className="contact-icon text-primary m-0">
                                <i className="fe fe-phone" />
                              </p>
                            </div>
                            <div className="ms-3">
                              <p className="tx-13 mb-0 tx-semibold">Phone</p>
                              <p className="tx-12 text-muted">+1 135792468</p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>
                              <p className="contact-icon text-primary m-0">
                                <i className="fe fe-mail" />
                              </p>
                            </div>
                            <div className="ms-3">
                              <p className="tx-13 mb-0 tx-semibold">Email</p>
                              <p className="tx-12 text-muted">
                                harvey@gmail.com.
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>
                              <p className="contact-icon text-primary m-0">
                                <i className="fe fe-map-pin" />
                              </p>
                            </div>
                            <div className="ms-3">
                              <p className="tx-13 mb-0 tx-semibold">Address</p>
                              <p className="tx-12 text-muted mb-0">
                                California.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <h6 className="mb-3 tx-semibold">Shared Files</h6>
                          <div className="media mb-3">
                            <p className="contact-icon text-primary m-0">
                              <i className="mdi mdi-file-image" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Image1.jpg
                                </p>
                                <span className="tx-12 text-muted">200 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href="#" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="media mb-3">
                            <p className="contact-icon text-danger m-0">
                              <i className="mdi mdi-file-pdf" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Doc1.pdf
                                </p>
                                <span className="tx-12 text-muted">48 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href="#" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="media mb-3">
                            <p className="contact-icon text-info m-0">
                              <i className="mdi mdi-file-word" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Word1.doc
                                </p>
                                <span className="tx-12 text-muted">35 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href="#" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="media">
                            <p className="contact-icon text-warning m-0">
                              <i className="mdi mdi-file-powerpoint" />
                            </p>
                            <div className="media-body ms-3 d-flex">
                              <div>
                                <p className="tx-13 text-dark mb-0 tx-semibold">
                                  Example1.ppt
                                </p>
                                <span className="tx-12 text-muted">54 KB</span>
                              </div>
                              <div className="ms-auto my-auto">
                                <a href="#" className="btn px-0">
                                  <i className="fe fe-download my-auto text-muted tx-18" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {filteredData?.showEmail ? (
                    <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                      <div className="card custom-card">
                        <div className="pt-0">
                          <div className={styles.App}>
                            <input
                              type="email"
                              placeholder="To."
                              value={to}
                              onChange={(e) => setTo(e.target.value)}
                              className={styles.input}
                              style={{ color: "#808080" }}
                            />
                            <input
                              type="email"
                              placeholder="Cc."
                              value={cc}
                              onChange={(e) => setCc(e.target.value)}
                              className={styles.input}
                              style={{ color: "#808080" }}
                            />
                            <input
                              type="text"
                              placeholder="Subject"
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                              className={styles.input}
                              style={{ color: "#808080" }}
                            />

                            <Editor
                              value={content}
                              onChange={handleEditorChange}
                            ></Editor>
                            <div
                            // style={{
                            //   display: "flex",
                            //   justifyContent: "space-between",
                            // }}
                            >
                              {/* <div className="mt-3">
                                <input
                                  type="file"
                                  onChange={(event) => handleEmailFile(event)}
                                />
                              </div> */}
                              <div class="input-group mt-3">
                                <input
                                  type="file"
                                  class="form-control"
                                  id="inputGroupFile02"
                                  onChange={(event) => handleEmailFile(event)}
                                />
                                {/* <label
                                  class="input-group-text"
                                  for="inputGroupFile02"
                                >
                                  Upload
                                </label> */}
                              </div>
                              <button
                                onClick={sendEmail}
                                className="btn btn-primary ml-3 mt-3"
                              >
                                Send Email
                              </button>
                            </div>
                            <div
                              className="list-group-container"
                              style={{
                                height: "150px",
                                overflowY: "auto",
                                marginTop: "10px",
                              }}
                            >
                              <div
                                className="list-group mt-2 "
                                style={{ padding: "10px" }}
                              >
                                {emailData?.map((item, index) => (
                                  <a
                                    className="list-group-item list-group-item-action flex-column align-items-start"
                                    key={index}
                                    style={{
                                      border: "1px solid #B2BEB5",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                        left: "-10px",
                                        bottom: "40px",
                                        backgroundColor: "#219C90",
                                        color: "#f4f4f4",
                                        fontSize: "9px",
                                        borderRadius: "5px",
                                        padding: "3px",
                                      }}
                                    >
                                      {item.isCustomer === true
                                        ? "Customer"
                                        : "KAM"}
                                    </span>
                                    <div
                                      className="d-flex w-100 justify-content-between"
                                      style={{ height: "20px" }}
                                    >
                                      <span
                                        className="d-flex "
                                        style={{ width: "85%" }}
                                      >
                                        <FaEye
                                          className="mr-2"
                                          onClick={() => {
                                            handleOpenMailPopUp();
                                            setItemDataPopUp(item);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            marginTop: "3px",
                                          }}
                                        />

                                        <span
                                          className="text-truncate mb-0 font-weight-bold"
                                          style={{ width: "90%" }}
                                        >
                                          {`${item.subject}`}
                                        </span>
                                      </span>
                                      <small>
                                        {formatDate(item.createdAt)}
                                      </small>
                                    </div>
                                  </a>
                                ))}
                              </div>
                            </div>
                          </div>

                          <EmailPopUp
                            item={itemDataPopUp}
                            handleClose={handleCloseMailPopUp}
                            open={openEmail}
                          ></EmailPopUp>
                          <div className="main-content-body main-content-body-chat"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="col-sm-12 col-md-12 col-lg-7 col-xl-5"
                      style={{
                        position: "relative",
                        left: "10px",
                        top: "0px",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="card" style={{ width: "20rem" }}>
                        <h5 className="p-4 bg-black-1">
                          Assigned Employee List
                        </h5>
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}
                        >
                          {employeeList.EmployeeCasesM?.map((item, index) => (
                            <div key={index}>
                              <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={item?.employee?.profilePic}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={capitalizeFirstLetterOfEachWord(
                                    `${item.employee?.firstName}  ${item.employee?.lastName}`
                                  )}
                                  secondary={
                                    <React.Fragment>
                                      <Typography
                                        sx={{ display: "inline" }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      ></Typography>
                                      {item.employee?.email}
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                              <Divider variant="inset" component="li" />
                            </div>
                          ))}
                        </List>
                      </div>
                    </div>
                  )}

                  <div
                    className={
                      filteredData?.showEmail
                        ? "col-sm-12 col-md-12 col-lg-7 col-xl-5"
                        : "col-sm-6 col-md-6"
                    }
                    style={
                      filteredData?.showEmail
                        ? {}
                        : { position: "relative", left: "-242px" }
                    }
                  >
                    <div className="card custom-card">
                      <div className=" pt-0">
                        <div className="main-content-body main-content-body-chat">
                          <div
                            className="main-chat-header pt-3"
                            style={{
                              background: "#f4f6ff",
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="main-chat-msg-name">
                              <h6>Internal Notes &amp; Chat</h6>
                            </div>

                            <div>
                              <Button
                                aria-controls={
                                  open ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                                style={{ fontSize: "12px", color: "#808080" }}
                              >
                                Employees{" "}
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    color: "#41B06E",
                                  }}
                                >
                                  <BsPersonLinesFill size={20} />
                                </span>
                              </Button>
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                {employeeList.EmployeeCasesM?.map(
                                  (item, index) => (
                                    <MenuItem key={index}>
                                      {capitalizeFirstLetterOfEachWord(
                                        item.employee?.firstName
                                      )}{" "}
                                      {capitalizeFirstLetterOfEachWord(
                                        item.employee?.lastName
                                      )}
                                    </MenuItem>
                                  )
                                )}
                              </Menu>
                            </div>
                          </div>

                          <div
                            className="main-chat-body"
                            id="ChatBody"
                            style={{
                              overflowY: "scroll",
                              height: "500px",
                              padding: "10px",
                            }}
                          >
                            {messages1.length > 0 && (
                              <label className="main-chat-time">
                                <span
                                  style={{
                                    fontSize: "8px",
                                    fontFamily: "sans-serif",
                                    color: "#808080",
                                  }}
                                >
                                  {formatDate(messages1[0]?.createdAt)}
                                </span>
                              </label>
                            )}

                            {messages1.map((msg, index) => {
                              const showUsername =
                                index === 0 ||
                                messages1[index - 1]?.employeeId !==
                                  msg?.employeeId;
                              const showDateSeparator =
                                index === 0 ||
                                isSameDay(
                                  messages1[index - 1].createdAt,
                                  msg.createdAt
                                );

                              return (
                                <div
                                  className="content-inner"
                                  key={index}
                                  style={{ padding: "0" }}
                                >
                                  {!showDateSeparator && (
                                    <label className="main-chat-time">
                                      <span
                                        style={{
                                          fontSize: "8px",
                                          fontFamily: "sans-serif",
                                          color: "#808080",
                                        }}
                                      >
                                        {formatDate(msg.createdAt)}
                                      </span>
                                    </label>
                                  )}

                                  <div
                                    className={`media ${
                                      employee?.id !== msg?.employeeId
                                        ? "flex-row-reverse"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className="media-body"
                                      style={{ marginBottom: 0 }}
                                    >
                                      {msg.message == "" ? null : (
                                        <>
                                          {employee.id !== msg.employeeId &&
                                            showUsername && (
                                              <div style={{ display: "flex" }}>
                                                <p
                                                  style={{
                                                    marginBottom: "2px",
                                                    fontSize: "9px",
                                                    fontWeight: 200,
                                                    fontFamily: "sans-serif",
                                                    color: "#808080",

                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  {" "}
                                                  {capitalizeFirstLetterOfEachWord(
                                                    msg?.createdBy
                                                  )}
                                                </p>
                                              </div>
                                            )}

                                          <div
                                            className="main-msg-wrapper"
                                            style={{
                                              paddingRight: "50px",
                                              backgroundColor: "#dcf8c6",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            {msg.message}
                                            <span
                                              style={{
                                                position: "absolute",
                                                fontSize: "9px",
                                                top: "25px",
                                                color: "#808080",
                                                right: "6px",
                                              }}
                                            >
                                              {" "}
                                              {formatTimestamp(
                                                convertTimestamp(
                                                  msg.createdAtDate
                                                )
                                              )}
                                            </span>
                                          </div>
                                        </>
                                      )}
                                      <div>
                                        <a href="#">
                                          <i className="icon ion-android-more-horizontal" />
                                        </a>
                                      </div>
                                      {msg.message == ""
                                        ? msg.attachment && (
                                            <div>
                                              {msg?.attachment.endsWith(
                                                ".jpg"
                                              ) ||
                                              msg?.attachment.endsWith(
                                                ".png"
                                              ) ||
                                              msg?.attachment.endsWith(
                                                ".JPG"
                                              ) ||
                                              msg?.attachment.endsWith(
                                                ".PNG"
                                              ) ||
                                              msg?.attachment.endsWith(
                                                ".JPEG"
                                              ) ||
                                              msg?.attachment.endsWith(
                                                ".jpeg"
                                              ) ? (
                                                <>
                                                  {" "}
                                                  <div
                                                    style={{
                                                      position: "relative",
                                                      display: "inline-block",
                                                      marginBottom: "7px",
                                                    }}
                                                  >
                                                    {employee.id !==
                                                      msg.employeeId &&
                                                      showUsername && (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          {/* <FaUserCircle /> */}
                                                          <p
                                                            style={{
                                                              marginBottom:
                                                                "2px",
                                                              fontSize: "9px",
                                                              fontWeight: 200,
                                                              fontFamily:
                                                                "sans-serif",
                                                              color: "#808080",

                                                              marginLeft: "5px",
                                                            }}
                                                          >
                                                            {" "}
                                                            {capitalizeFirstLetterOfEachWord(
                                                              msg?.createdBy
                                                            )}
                                                          </p>
                                                        </div>
                                                      )}
                                                    <img
                                                      src={msg.attachment}
                                                      alt="Attachment"
                                                      style={{
                                                        maxWidth: "200px",
                                                        borderRadius: "8px",
                                                      }}
                                                      onLoad={scrollToBottom1}
                                                    />
                                                    <p
                                                      style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        left: -7,
                                                        width: "100%",
                                                        marginBottom: 0,
                                                        textAlign: "right",
                                                        fontSize: "9px",

                                                        color: "#fff",
                                                      }}
                                                    >
                                                      {formatTimestamp(
                                                        convertTimestamp(
                                                          msg.createdAtDate
                                                        )
                                                      )}
                                                    </p>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <div>
                                                    {employee.id !==
                                                      msg.employeeId &&
                                                      showUsername && (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <p
                                                            style={{
                                                              marginBottom:
                                                                "2px",
                                                              fontSize: "9px",
                                                              fontWeight: 200,
                                                              fontFamily:
                                                                "sans-serif",
                                                              color: "#808080",

                                                              marginLeft: "5px",
                                                            }}
                                                          >
                                                            {" "}
                                                            {capitalizeFirstLetterOfEachWord(
                                                              msg?.createdBy
                                                            )}
                                                          </p>
                                                        </div>
                                                      )}
                                                    <a
                                                      href={`${msg?.attachment}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        marginBottom: "10px",
                                                        marginTop: "15px",

                                                        color: "#fff",

                                                        borderRadius: "3px",
                                                      }}
                                                      onLoad={scrollToBottom1}
                                                    >
                                                      <img
                                                        src={
                                                          msg?.attachment.endsWith(
                                                            ".pdf"
                                                          )
                                                            ? pdfLogo
                                                            : excelLogo
                                                        }
                                                        alt="Attachment"
                                                        style={{
                                                          maxWidth: "100px",
                                                        }}
                                                      />
                                                    </a>{" "}
                                                    <p
                                                      style={{
                                                        fontWeight: 200,
                                                        fontFamily:
                                                          "sans-serif",
                                                        color: "#808080",
                                                        position: "relative",
                                                        left: "15px",
                                                        top: 0,
                                                        fontSize: "9px",
                                                      }}
                                                    >
                                                      {formatTimestamp(
                                                        convertTimestamp(
                                                          msg.createdAtDate
                                                        )
                                                      )}
                                                    </p>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div ref={chatWindowRef1} />
                                </div>
                              );
                            })}
                          </div>
                          <div className="main-chat-footer">
                            <nav className="nav">
                              <a
                                className="nav-link"
                                data-bs-toggle="tooltip"
                                // href
                                style={{ cursor: "pointer" }}
                                title="Attach a File"
                                onClick={() => fileInputRef1.current.click()}
                              >
                                <i className="fe fe-paperclip" />
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={handleFileChange1}
                                  ref={fileInputRef1}
                                />
                              </a>

                              <a
                                className="nav-link"
                                data-bs-toggle="tooltip"
                                // href
                                title="Emoji"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setShowEmojiPicker1(!showEmojiPicker1)
                                }
                              >
                                <i className="far fa-smile" />
                              </a>
                            </nav>
                            <input
                              className="form-control"
                              value={message1}
                              onChange={(e) => setMessage1(e.target.value)}
                              placeholder={
                                attachment1
                                  ? `File selected : ${attachment1.name}`
                                  : "Type your message here..."
                              }
                              onKeyDown={handleKeyPress1}
                            />
                            {message1 !== "" || attachment1 !== null ? (
                              <a
                                className="main-msg-send"
                                onClick={sendMessage1}
                              >
                                <i className="far fa-paper-plane" />
                              </a>
                            ) : null}
                            <div
                              style={{
                                zIndex: 23,
                                position: "absolute",
                                left: "0",
                                bottom: "70px",
                              }}
                            >
                              {showEmojiPicker1 && (
                                <Picker
                                  data={data}
                                  onEmojiSelect={handleEmojiSelect1}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Row */}
              </div>
            </div>
          </div>

          <div className="main-footer text-center">
            <div className="container">
              <div className="row row-sm">
                <div className="col-md-12">
                  <span>
                    Copyright © 2022 <a href="#">Fship</a>. Designed by{" "}
                    <a href="#">Spruko</a> All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/*End Footer*/}
        </div>

        <div className="modal" id="call_form">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">
                  Caller : +91 8130352808 (Connected).
                </h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 form-group tab_view">
                    <input type="radio" id="tab1" name="tab" defaultChecked />
                    <label htmlFor="tab1">LOTS Caller</label>
                    <input type="radio" id="tab2" name="tab" />
                    <label htmlFor="tab2">Marketplace Caller</label>
                    <article>
                      <div className="row mt-4">
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Vehicle Number</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Vehicle </p>
                            <select className="form-control select2">
                              <option>Select</option>
                              <option> Subscriber’s Vehicle</option>
                              <option>Per Trip Vehicle</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-2">
                          <button
                            className="btn ripple btn-primary mt-4 w-100"
                            type="button"
                          >
                            Send OTP
                          </button>
                        </div>
                        <div className="col-sm-2 form-group">
                          <label className="form-label">Enter OTP</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Subscriber</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label">
                            Active Subscription
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label">
                            Subscription Expires
                          </label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Incident Type </p>
                            <select className="form-control select2">
                              <option>Select</option>
                              <option>Eway Bill</option>
                              <option>Challan</option>
                              <option>Accident/Theft/Robbery</option>
                              <option>Impounding of Truck</option>
                              <option>Trucks</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Authority Involved: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                              <option> Police</option>
                              <option>Sales Tax</option>
                              <option>Toll Plaza</option>
                              <option>RTO, NHAI</option>
                              <option>Enforcement Squad</option>
                              <option>Traffic Police</option>
                              <option>Court</option>
                              <option>Local People</option>
                              <option>Other</option>
                            </select>
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Incident State: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Incident City: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Incident Area: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Area</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label"> Road Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label"> Pin</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-12 form-group">
                          <label className="form-label"> Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-12 form-group">
                          <label className="form-label"> Incident Story</label>
                          <textarea
                            className="form-control"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </article>
                    <article>
                      <div className="row mt-4">
                        <div className="col-sm-4 form-group">
                          <label className="form-label"> Caller Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Gender </p>
                            <select className="form-control select2">
                              <option>Select</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Case/Incident Type: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                              <option>Eway Bill</option>
                              <option>Challan</option>
                              <option>Accident/Theft/Robbery</option>
                              <option>Impounding of Truck</option>
                              <option>Trucks</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Authority Involved: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                              <option> Police</option>
                              <option>Sales Tax</option>
                              <option>Toll Plaza</option>
                              <option>RTO, NHAI</option>
                              <option>Enforcement Squad</option>
                              <option>Traffic Police</option>
                              <option>Court</option>
                              <option>Local People</option>
                              <option>Other</option>
                            </select>
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Incident State: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Incident City: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Incident Area: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-sm-4 form-group">
                          <label className="form-label">Area</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label"> Road Name</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-4 form-group">
                          <label className="form-label"> Pin</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <p className="mg-b-5">Role: </p>
                            <select className="form-control select2">
                              <option>Select</option>
                              <option>Driver </option>
                              <option>Owner </option>
                              <option>Fleet Manager</option>
                            </select>{" "}
                          </div>
                        </div>
                        {/* col-4 */}
                        <div className="col-sm-12 form-group">
                          <label className="form-label"> Address</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-sm-12 form-group">
                          <label className="form-label"> Incident Story</label>
                          <textarea
                            className="form-control"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="col-lg-12">
                    <button className="btn ripple btn-primary" type="button">
                      Add Case
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*End Large Modal */}
        </div>
        {/* End Page */}
        {/* Large Modal */}
        <div className="modal" id="add-lead">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">Add Support</h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p className="mg-b-5">Created By: </p>
                      <select className="form-control select2">
                        <option>Select</option>
                        <option>Internal Staff (KAM)</option>
                        <option>Client</option>
                      </select>
                    </div>
                  </div>
                  {/* col-4 */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p className="mg-b-5">Mode: </p>
                      <select className="form-control select2">
                        <option>Select</option>
                        <option>IVR No</option>
                        <option>WhatsApp</option>
                        <option>Chat</option>
                        <option>Email</option>
                      </select>
                    </div>
                  </div>
                  {/* col-4 */}
                  <div className="col-sm-4 form-group">
                    <label className="form-label">Ticket ID</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p className="mg-b-5">Category: </p>
                      <select className="form-control select2">
                        <option>Select</option>
                        <option>Operations</option>
                        <option>Finance</option>
                        <option>Sales</option>
                        <option>Technology</option>
                      </select>
                    </div>
                  </div>
                  {/* col-4 */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p className="mg-b-5">Issue Type: </p>
                      <select className="form-control select2">
                        <option>Select</option>
                        <option>Weight issues</option>
                        <option>Pickup issues</option>
                        <option>NDR issues</option>
                        <option>Delivery issues</option>
                        <option>Incorrect delivery/lost/damage</option>
                      </select>
                    </div>
                  </div>
                  {/* col-4 */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p className="mg-b-5">Priority: </p>
                      <select className="form-control select2">
                        <option>Select</option>
                        <option>High</option>
                        <option>Low</option>
                        <option>Medium</option>
                      </select>
                    </div>
                  </div>
                  {/* col-4 */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <p className="mg-b-5">Proof: </p>
                      <select className="form-control select2">
                        <option>Select</option>
                        <option>Screenshots</option>
                        <option>Attachments</option>
                        <option>Files</option>
                      </select>
                    </div>
                  </div>
                  {/* col-4 */}
                  <div className="col-sm-12 form-group">
                    <label className="form-label">
                      Issue Details and Descriptions
                    </label>
                    <textarea className="form-control" defaultValue={""} />
                  </div>
                  <div className="col-lg-12">
                    <button className="btn ripple btn-primary" type="button">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*End Large Modal */}
        </div>

        <div className="modal" id="modaldemo-id">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">ID</h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-8">
                    <img
                      src="/assets/img/pngs/default-img.gif"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <h6>ID : Law001</h6>
                    <div className="form-group">
                      <select className="form-control select2">
                        <option>Status</option>
                        <option>Approved</option>
                        <option>Disapproved</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn ripple btn-primary w-100"
                        type="button"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Large Modal */}
        <div className="modal" id="modaldemo-pan">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">PAN</h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-8">
                    <img
                      src="/assets/img/pngs/default-img.gif"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <h6>Pan No. : CLDPK8793</h6>
                    <div className="form-group">
                      <select className="form-control select2">
                        <option>Status</option>
                        <option>Approved</option>
                        <option>Disapproved</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn ripple btn-primary w-100"
                        type="button"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Large Modal */}
        {/*End Large Modal */}
        <div className="modal" id="modaldemo-cheque">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">Cheque</h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-8">
                    <img
                      src="/assets/img/pngs/cheque-img.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <h6>Cheque No. : 0123456789</h6>
                    <div className="form-group">
                      <select className="form-control select2">
                        <option>Status</option>
                        <option>Approved</option>
                        <option>Disapproved</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn ripple btn-primary w-100"
                        type="button"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Large Modal */}
        {/*End Large Modal */}
        <div className="modal" id="modaldemo-address">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">Address</h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-8">
                    <p>
                      3-15/10/403 Newark, Street no 5, Next To Pizza Hut,
                      Bangalore, Karnataka, 560003, India.
                    </p>
                    <p className="mb-0">soniataylor344@example.com</p>
                    <p>+91 08023310451</p>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <select className="form-control select2">
                        <option>Status</option>
                        <option>Approved</option>
                        <option>Disapproved</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn ripple btn-primary w-100"
                        type="button"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Large Modal */}
        {/*End Large Modal */}
        <div className="modal" id="modaldemo-lawyer-form">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">Assign Lawyer</h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="row row-sm">
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Lawyer State</label>
                      <select className="form-control select select2">
                        <option>Select</option>
                      </select>
                    </div>
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Lawyer City</label>
                      <select className="form-control select select2">
                        <option>Select</option>
                      </select>
                    </div>
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Lawyer Expertise </label>
                      <select className="form-control select select2">
                        <option>Select</option>
                      </select>
                    </div>
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Select Lawyer</label>
                      <select className="form-control select select2">
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button className="btn ripple btn-primary" type="button">
                  Assign Lawyer{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal" id="modaldemo-employee-form">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title">Assign</h6>
                <button
                  aria-label="Close"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  type="button"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="row row-sm">
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Select Department</label>
                      <select className="form-control select select2">
                        <option>Select</option>
                      </select>
                    </div>
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Select Designation</label>
                      <select className="form-control select select2">
                        <option>Select</option>
                      </select>
                    </div>
                    <div className="col-sm-4  form-group">
                      <label className="form-label">Select Employee </label>
                      <select className="form-control select select2">
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button className="btn ripple btn-primary" type="button">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal ${modelOpenAwb ? "show" : ""} bg-black-7`}
          style={{
            display: modelOpenAwb ? "block" : "none",
            overflowY: "auto",
          }}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content rounded-3">
              <div className="modal-header">
                <h5 className="modal-title">AWB Number List</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModalAwb}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <div className="row">
                  <div className="col form-group">
                    <ul className="list-group">
                      {awbList.map((item, index) => (
                        <li
                          className="list-group-item list-group-item-primary"
                          key={index}
                        >
                          {item.awbNo}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal ${modelOpenOrderId ? "show" : ""} bg-black-7`}
          style={{
            display: modelOpenOrderId ? "block" : "none",
            overflowY: "auto",
          }}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content rounded-3">
              <div className="modal-header">
                <h5 className="modal-title">Order Id List</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModalOrderId}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <div className="row">
                  <div className="col form-group">
                    <ul className="list-group">
                      {orderIdList.map((item, index) => (
                        <li
                          className="list-group-item list-group-item-primary"
                          key={index}
                        >
                          {item.orderId}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#top" id="back-to-top">
          <i className="fe fe-arrow-up" />
        </a>
      </div>
    </>
  );
}

export default Support;
